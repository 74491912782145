import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import Vector from '../../../../../assets/interventions/Vector 111.svg';
import PrimaryButton from '../../../../common/button/Button';

const useStyles = makeStyles((theme) => ({
  paper2: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginTop: '3%',
    padding: theme.spacing(2, 2, 2, 2),
  },
}));

const PreviosSavesData = (props) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper2}>
        <Grid container alignItems="center" xs={12} direction="row">
          <Grid xs={3} container direction="column">
            <Grid xs style={{ paddingBottom: '10px' }}>
              <Typography variant="caption" color="textSecondary">
                {`Saved on: `}
                <b style={{ color: '#2E2E2E' }}>{props.savedOn}</b>
              </Typography>
            </Grid>
            <Grid xs style={{ paddingTop: '10px' }}>
              <Typography variant="caption" color="textSecondary">
                {`Saved By: `}
                <b style={{ color: '#2E2E2E' }}>{props.savedBy}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={0} style={{ paddingRight: '30px' }}>
            <img src={Vector} alt="hhh" style={{ height: 'auto' }} />
          </Grid>
          <Grid xs={3} container direction="column" style={{ paddingLeft: '30px' }}>
            <Grid xs style={{ paddingBottom: '10px' }}>
              <Typography variant="caption" color="textSecondary">
                {`Water Saved: `}
                <b style={{ color: '#2E2E2E' }}>{props.waterSaved}</b>
              </Typography>
            </Grid>
            <Grid xs style={{ paddingTop: '10px' }}>
              <Typography variant="caption" color="textSecondary">
                {`Sump Size: `}
                <b style={{ color: '#2E2E2E' }}>{props.sumpSize}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={3} container direction="column" style={{ paddingLeft: '30px' }}>
            <Grid xs style={{ paddingBottom: '10px' }}>
              <Typography variant="caption" color="textSecondary">
                {`CAPEX: `}
                <b style={{ color: '#2E2E2E' }}>{props.capex}</b>
              </Typography>
            </Grid>
            <Grid xs style={{ paddingTop: '10px' }}>
              <Typography variant="caption" color="textSecondary">
                {`OPEX: `}
                <b style={{ color: '#2E2E2E' }}>{props.opex}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={0} style={{ paddingRight: '20px' }}>
            <img src={Vector} alt="hhh" style={{ height: 'auto' }} />
          </Grid>
          <Grid xs={1}>
            <PrimaryButton children={props.buttonName} onClick={() => props.buttonClick()} style={props.butonStyle} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default PreviosSavesData;

/*<Grid item xs={1} align="left">
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        Saved on:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2vh' }}>
                      <Typography variant="caption" color="textSecondary">
                        Saved By:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} align="left">
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textPrimary">
                        {data.createdAt.slice(0, 10)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2vh' }}>
                      <Typography variant="caption" color="textPrimary">
                        {data.createdBy !== 'system-default' ? userDetails.name : data.createdBy}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} alignItems="center">
                    <img src={Vector} alt="hhh" style={{ marginLeft: '3vh', height: 'auto' }} />
                  </Grid>
                  {/* <Grid item xs={1} align="left">
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        Water Saved:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2vh' }}>
                      <Typography variant="caption" color="textSecondary">
                        Sump Size:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} align="left">
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textPrimary">
                        822 KL
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2vh' }}>
                      <Typography variant="caption" color="textPrimary">
                        20 KL
                      </Typography>
                    </Grid>
                  </Grid> 
                  <Grid item xs={1} />
                  <Grid item xs={3} align="left">
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        {data && data.persistent.length !== 0 ? data.persistent[0].id.split('.')[1].toUpperCase() : null}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2vh' }}>
                      <Typography variant="caption" color="textSecondary">
                        {data && data.persistent.length !== 0 ? data.persistent[1].id.split('.')[1].toUpperCase() : null}:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} align="left">
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textPrimary">
                        {data && data.persistent.length !== 0 ? data.persistent[0].value : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2vh' }}>
                      <Typography variant="caption" color="textPrimary">
                        {data && data.persistent.length !== 0 ? data.persistent[1].value : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} alignItems="center">
                    <img src={Vector} alt="hhh" style={{ marginLeft: '3vh', height: 'auto' }} />
                  </Grid>
                  <Grid item xs={2}>
                    <PrimaryButton children="REVERT" onClick={() => props.handleRevert(data._id)} />
                  </Grid> */
