import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography, Grid } from '@material-ui/core';
import PrimaryButton from '../../../common/button/Button';
import line from '../../../../assets/currentprojectline.svg';
import phone from '../../../../assets/vendors/phone.svg';
import gmail from '../../../../assets/vendors/gmail.svg';
import PrimaryBar from '../../progressbar/Progressbar';
import axios from '../../../../config/Axios';
// import swal from 'sweetalert';
var _URL = window.URL || window.webkitURL;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    borderRadius: '10px',
    boxSizing: 'border-box',
    padding: theme.spacing(2, 0, 2, 4),
    marginBottom: '28px',
  },
  cover: {
    width: 190,
    height: 106.79,
    marginBottom: '20px',
    cursor: 'pointer',
  },
  primaryBar: {
    marginTop: '3%',
  },
  line: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    height: '90%',
  },
  textcolor: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

function VendorsCrad({
  value,
  image,
  name,
  mobile,
  email,
  totalprice,
  area,
  price,
  vendorname,
  profile,
  handleClick,
  handleSelectVendor,
  handleRemoveSelectVendor,
  selected,
  id,
  icon,
  handleRequestQuote,
  capexmultiplier,
}) {
  const classes = useStyles();
  // const value1 = vendorname.toLowerCase();
  // let match = useRouteMatch();

  return (
    <>
      <Grid alignItems="center">
        <Card className={classes.root}>
          <Grid container direction="row" xs={12}>
            <Grid item xs={3} container direction="column">
              <Grid xs item>
                <CardMedia className={classes.cover} image={icon} title="" onClick={handleClick} />
              </Grid>
              <Grid xs container item direction="row">
                {/* <Grid item xs={4}>
                  <Typography variant="h6">Resource</Typography>
                  <Typography variant="h6">Impact</Typography>
                </Grid>
                <Grid item xs={6} className={classes.primaryBar}>
                  <PrimaryBar value={value} />
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={3} container direction="column">
              <Grid item xs style={{ marginTop: '20px' }}>
                <Link onClick={handleClick} style={{ background: 'transparent', color: 'rgba(0, 0, 0, 0.87)' }}>
                  {vendorname}
                </Link>
                <br />
                <div style={{ marginTop: '6%' }}>
                  {selected === true ? (
                    <PrimaryButton children="Selected" style={{ width: '70%' }} />
                  ) : (
                    <PrimaryButton
                      onClick={handleSelectVendor}
                      children="select"
                      style={{ background: '#FFFFFF', color: 'gray', borderColor: '#00C58A', width: '70%' }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid item xs={2} container direction="column">
              <Grid item xs>
                <Typography variant="h6" className={classes.textcolor} style={{ marginTop: '20px' }}>
                  Area Required Per Unit Capacity
                </Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                  {area} sqm
                </Typography>
              </Grid>
              <Grid item xs>
                {/* <Typography variant="h6" className={classes.textcolor} style={{ marginTop: '20px' }}>
                  Price Increment Per Sqft
                </Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                  {price}
                </Typography> */}
                <Typography variant="h6" className={classes.textcolor} style={{ marginTop: '20px' }}>
                  Capex Per Unit Capacity
                </Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                  ₹{capexmultiplier}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={1}>
              <img src={line} alt="..." className={classes.line} />
            </Grid>

            <Grid item xs={3} container direction="column">
              <Grid xs item container direction="row" style={{ marginTop: '3%' }}>
                <Grid xs item style={{ marginTop: '3%' }}>
                  <Typography variant="h6" className={classes.textcolor}>
                    Sales Manager
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: '3%' }}>
                    {name}
                  </Typography>
                </Grid>
                <Grid xs item>
                  <img src={profile} alt="..." style={{ height: '60px' }} />
                </Grid>
              </Grid>
              <Grid xs item container direction="row" style={{ marginTop: '20px' }}>
                <Grid xs item>
                  <Typography variant="body1">
                    <img src={phone} alt="..." style={{ marginRight: '20px' }} />
                    {mobile}
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: '3%' }}>
                    <img src={gmail} alt="..." style={{ marginRight: '20px' }} />
                    {email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default VendorsCrad;

/* <div className={classes.namerow}>
            <Grid alignItems="center" className={classes.spacing1}>
              <Link onClick={handleClick} style={{ background: 'transparent', color: 'rgba(0, 0, 0, 0.87)', width: '50%', marginLeft: '-30px' }}>
                {vendorname}
              </Link>
              <Grid container alignItems="center" item xs={6} className={classes.spacing2}>
                {selected === true ? (
                  <PrimaryButton children="Selected" style={{ marginLeft: '-30px' }} />
                ) : (
                  <PrimaryButton
                    onClick={handleSelectVendor}
                    children="select"
                    style={{ background: '#FFFFFF', color: '#00C58A', borderColor: '#00C58A', marginLeft: '-30px' }}
                    style={{ background: '#FFFFFF', color: 'gray', borderColor: '#00C58A', marginLeft: '-30px' }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid alignItems="center" className={classes.spacing1}>
              <Typography variant="body2" style={{ marginLeft: '-30px', marginTop: '25px' }}>
                {totalprice}
              </Typography>

             
              <Grid container alignItems="center" item xs={12} className={classes.spacing2}>
                <PrimaryButton onClick={handleRequestQuote} children="Request latest quote" style={{ marginLeft: '-30px' }} />
              </Grid>
            </Grid>
          </div> */

/* <div className={classes.imagerow}>
            <Grid alignItems="center" className={classes.spacing1}>
              <Typography variant="h6" className={classes.textcolor}>
                Area Required To Install
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                {area}
              </Typography>
            </Grid>
            <Grid alignItems="center" className={classes.spacing1}>
              <Typography variant="h6" className={classes.textcolor} style={{ marginTop: '15px' }}>
                Price Increment Per Sqft
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                {price}
              </Typography>
            </Grid>
          </div> */

/* <div>
            <img src={line} alt="..." className={classes.line} />
          </div>
          <div className={classes.imagerow}>
            <Grid container alignItems="center" item xs={12} className={classes.spacing1}>
              <Grid item xs={6} style={{ marginTop: '-18px' }}>
                <Typography variant="h6" className={classes.textcolor}>
                  Sales Manager
                </Typography>
                <Typography variant="body1" style={{ marginTop: '13px' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img src={profile} alt="..." style={{ height: '60px', marginTop: '-15px' }} />
              </Grid>
            </Grid>
            <Grid alignItems="center" className={classes.spacing1}>
              <Grid container alignItems="center" item xs={12}>
                <Grid item xs={2}>
                  <img src={phone} alt="..." style={{ marginTop: '-20px' }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1" style={{ marginTop: '-20px' }}>
                    {mobile}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" item xs={12}>
                <Grid item xs={2}>
                  <img src={gmail} alt="..." />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1">{email}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </div> */
