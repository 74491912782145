import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import axios from '../../../../../config/Axios';
import { InterventionDetailsContext } from '../interventionDetails';
import ShowCustomizableParameters from './showCustomizableParameters';
import ShowMandates from './showMandates';
import ShowProjectParameters from './showProjectInputParameters';
import ShowSaveAndCancel from './showSaveAndCancel';

function EditInterventionDetails(props) {
  const {
    customizable,
    interventionProjectMappingId,
    projectId,
    inputs,
    activeSandboxId,
    mandates,
    mandateicon,
    boundaryMin,
    boundaryMax,
    projectinputs,
    mandateGenerals,
    mandateGeneralsIcon,
  } = props;

  const [source, setSource] = useState('');
  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [myArray, setMyArray] = useState([]);
  const { myArrayContext, handleMyArray } = useContext(InterventionDetailsContext);

  const handleSave = () => {
    if (myArrayContext && myArrayContext.length > 0) {
      const body = { type: 'dirty', values: myArrayContext && myArrayContext };
      axios
        .put(`/projectv2/${projectId}/interventions/${interventionProjectMappingId}/sandboxes/${activeSandboxId}`, body)
        .then((res) => {
          if (res && res.data.data) {
            props.onClose();
            props.handleRefresh();
            props.handleBackdropOpen();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const appendChanged = (id, value) => {
    const body = { id: id, value: !value ? 0 : value };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.id === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else setMyArray((oldArray) => [...oldArray, body]);
  };

  useEffect(() => {
    if (myArray.length > 0) handleMyArray(myArray);
  }, [myArray, inputs, handleMyArray]);

  useEffect(() => {
    if (myArrayContext && myArrayContext.length > 0) setMyArray(myArrayContext);
  }, [myArrayContext]);

  useEffect(() => {
    if (customizable && myArray) {
      const b = !myArray.find((d) => d.id === 'rooftoprwh.rrwhtanksizesource')
        ? customizable.find((d) => d.tags.includes('tank-size-options'))
        : myArray.find((d) => d.id === 'rooftoprwh.rrwhtanksizesource');
      if (b && b.value === 'Default') setSource('Default');
      else setSource('Custom');
    }
  }, [customizable, myArray]);

  return (
    <>
      <Grid container alignItems="flex-start" direction="row" xs={12} style={{ marginBottom: '3%' }}>
        <ShowCustomizableParameters
          customizable={customizable && customizable}
          myArray={myArray && myArray}
          setMaxError={(a) => setMaxError(a)}
          setMinError={(b) => setMinError(b)}
          appendChanged={(a, b) => appendChanged(a, b)}
          boundaryMin={boundaryMin && boundaryMin}
          boundaryMax={boundaryMax && boundaryMax}
          source={source && source}
          setSource={(s) => setSource(s)}
          minError={minError && minError}
          maxError={maxError && maxError}
        />
        <ShowMandates
          mandates={mandates && mandates}
          mandateicon={mandateicon}
          style={{ background: '#FDF5F2', borderRadius: '10px', paddingTop: '5px', paddingBottom: '5px', border: `2px solid #E29578`, marginBottom: '10px' }}
        />
        <ShowMandates
          mandates={mandateGenerals && mandateGenerals}
          mandateicon={mandateGeneralsIcon}
          style={{ background: `#F3FCFA`, borderRadius: '10px', paddingTop: '5px', paddingBottom: '5px', border: `2px solid #49DDB1`, marginBottom: '10px' }}
        />
        <ShowProjectParameters projectinputs={projectinputs && projectinputs} />
      </Grid>
      <ShowSaveAndCancel handleClose={props.onClose} handleSave={handleSave} minError={minError && minError} maxError={maxError && maxError} />
    </>
  );
}

export default EditInterventionDetails;
