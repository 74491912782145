export const ParentPage = {
  EDIT_DETAILS: 'editdetails',
  INTERVENTIONS: 'interventions',
};

export const ChildPage = {
  PLUMBING_DETAILS: 'plumbingdetails',
  INTERVENTIONS_SUMMARY: 'interventionssummary',
};

export const ComponentName = {
  GREYWATER_REUSED_TOOLTIP: 'Grey Water Reused',
  RO_SYSTEM_FOR_DRINKING_WATER: 'RO system for Drinking Water',
  SEAWAGE_TREATMENT_PLANT: 'Sewage Treatment Plant',
  FIXTURE_FLOW_OR_FLUSH_RATES: 'Fixture Flow/Flush Rates',
  CENTRALISED_RO: 'Centralized RO',
  EFFICIENT_FIXTURES: 'Efficient Fixtures',
  DUAL_FLUSH: 'Dual Flush',
  SMART_WATER_METERS: 'Smart Water Meters',
  ROOFTOP_RWH: 'Rooftop RWH',
  SURFACE_RWH: 'Surface RWH',
  GREY_WATER_REUSE: 'Grey Water Re-use',
  BLACK_WATER_REUSE: 'Black Water Re-use',
  SOLAR_PV: 'Solar PV',
  SOLAR_WATER_HEATER: 'Solar Water Heater',
  WET_WASTE_TO_BIOGAS: 'Wet Waste Management',
  COLLECTION_AND_SEGREGATION_POINT: 'Collection & Segregation Point',
  SANITARY_NAPKIN_INCINERATOR: 'Sanitary Napkin Incinerator',
  EXTERIOR_PAINTS: 'Exterior Paints',
};

export const ComponentTooltipData = {
  GREYWATER_REUSED_TOOLTIP: [
    { data: 'Grey Water is generally waste water from showers, baths, basins, and washing machines (i.e. from streams without fecal contamination)' },
  ],
  RO_SYSTEM_FOR_DRINKING_WATER: [
    {
      data: 'Water sourced from deep borewell is high in TDS (Total dissolved Solids) and unpalatable. RO (Reverse osmosis) system reduces the TDS  to WHO recommended value i.e. 300-500 mg/litre and makes it palatable.',
    },
    {
      data: 'For this reason occupants generally install individual RO filter systems at their apartments.',
    },
    {
      data: 'A Centralized RO system is one in which the whole building shares a central system & each apartment does not have individual systems.',
    },
  ],
  SEAWAGE_TREATMENT_PLANT: [
    {
      data: 'A Sewage Treatment Plant is an onsite plant to remove contaminants from wastewater.',
    },
    {
      data: `Wastewater can be treated in the following ways:`,
    },
    {
      data: `Combined - STP designed to treat both, grey & black water together`,
    },
    {
      data: `Separate - STP designed to treat grey & black water separately in two different streams`,
    },
  ],
  FIXTURE_FLOW_OR_FLUSH_RATES: [
    {
      data: 'The flow and flush rates of different types of fixtures being used.',
    },
  ],
  CENTRALISED_RO: [
    {
      data: 'A bigger capacity centralised RO system is 30-35% more efficient than the domestic individual RO systems.',
    },
    {
      data: 'Around 2.2 litres of fresh water is required to get 1 litre of drinking water from an individual RO system.',
    },
    {
      data: 'Hence, there is a hidden demand of fresh water and additional load of reject on the STP with indivual RO systems installed in each apartment.',
    },
    {
      data: 'Because of better efficiency of centralised RO systems, we can reduce both additional demand of fresh water & reject load on STP.',
    },
  ],
  EFFICIENT_FIXTURES: [
    {
      data: `Faucets, showerheads and WC's designed to achieve water savings by having a lower flow rate of water or less quantity of water per flush.`,
    },
    {
      data: 'Around 30% water wastage can be reduced by installing low flow or efficient fixtures.',
    },
  ],
  DUAL_FLUSH: [
    {
      data: `A Dual flush cistern / valve has two flush mechanisms:`,
    },
    {
      data: `1. lower flush rate for liquid waste`,
    },
    {
      data: `2. higher for solid wastes`,
    },
    {
      data: 'This system can help save large amounts of water.',
    },
  ],
  SMART_WATER_METERS: [
    {
      data: `These meters are installed on supply side in fresh water & treated water sumps and on demand side in apartments at each inlet point in toilets, kitchen & utility.`,
    },
    {
      data: `With these meters:`,
    },
    {
      data: `1. Residents only pay for their share of consumption`,
    },
    {
      data: `2. Prompt handling of water leakage helps in water savings`,
    },
    {
      data: `3. The analytics provided helps in sensitising residents for lowering their water consumption`,
    },
  ],
  ROOFTOP_RWH: [
    {
      data: `Rainwater is the purest form of water. With minimal treatment, rooftop runoff can be used for drinking/ domestic purposes.`,
    },
    {
      data: `This would be the immediate resource to augment existing water supply system by simply collecting the rooftop runoff in the sump.`,
    },
  ],
  SURFACE_RWH: [
    {
      data: `Runoff from the paved surfaces and internal roads can also be collected and used for domestic purposes.`,
    },
    {
      data: `Water thus collected not only helps in reducing fresh water demand but also helps in avoiding neighbourhood flodding.  `,
    },
  ],
  GREY_WATER_REUSE: [
    {
      data: `Grey water (waste water discharged  from washbasins, sink & bathing area) has no pathogens but only certain chemicals.`,
    },
    {
      data: `With appropriate tertiery treatment this water can be made potable to meet BIS requirements and can be reused for domestic purposes like handwashing, dishwashing & bathing.`,
    },
  ],
  BLACK_WATER_REUSE: [
    {
      data: `Black water (waste water and sewage from toilets) can be treated by a STP system & followed with ozonation/ UV/ chlorination to be reused for flushing & landscape purposes.`,
    },
  ],
  SOLAR_PV: [
    {
      data: `Solar photovoltaic energy or PV solar energy directly converts sunlight into electricity, using a technology based on the photovoltaic effect.`,
    },
    {
      data: `It's a clean & renewable energy and using the rooftop area the project's carbon footprint can be reduced.`,
    },
    {
      data: `India being a tropical country with more than 300 days of sunshine, it is recommended to maximise this onsite renewable energy source.`,
    },
  ],
  SOLAR_WATER_HEATER: [
    {
      data: `Solar water heaters use natural sun light to heat water. This system works on the thermosiphon principle and is designed to provide hot water without consuming expensive electricity.`,
    },
    {
      data: `This is the most effective way to generate hot water thereby saving costly power and is also environment friendly.`,
    },
  ],
  WET_WASTE_TO_BIOGAS: [
    {
      data: `Generating biogas from daily wet/ kitchen waste not only helps in managing the waste but also in generating gas/ electricity which can either be used for cooking or common area lighting.`,
    },
    {
      data: `The slurry formed during the process can be used as manure for the landscaped areas.`,
    },
  ],
  COLLECTION_AND_SEGREGATION_POINT: [
    {
      data: `Space required to segregate collected waste into various sub streams of waste i.e. dry, wet, bio sanitary, medical, e-waste & toxic waste.`,
    },
  ],
  SANITARY_NAPKIN_INCINERATOR: [
    {
      data: `Improper disposal of sanitary napkins is a major environmental issue. A single commercially available non - organic sanitary pad takes up to 250-800 years to decompose or may even never decompose at all.`,
    },
    {
      data: `A sanitary napkin incinerator is an electrical machine which burns used sanitary napkins and turns it into ash.`,
    },
  ],
  EXTERIOR_PAINTS: [
    {
      data: `Paint being applied on the external surfaces`,
    },
  ],
};

export const TooltipData = [
  {
    parentPage: ParentPage.EDIT_DETAILS,
    childPage: ChildPage.PLUMBING_DETAILS,
    componentName: ComponentName.GREYWATER_REUSED_TOOLTIP,
    content: ComponentTooltipData.GREYWATER_REUSED_TOOLTIP,
  },
  {
    parentPage: ParentPage.EDIT_DETAILS,
    childPage: ChildPage.PLUMBING_DETAILS,
    componentName: ComponentName.RO_SYSTEM_FOR_DRINKING_WATER,
    content: ComponentTooltipData.RO_SYSTEM_FOR_DRINKING_WATER,
  },
  {
    parentPage: ParentPage.EDIT_DETAILS,
    childPage: ChildPage.PLUMBING_DETAILS,
    componentName: ComponentName.SEAWAGE_TREATMENT_PLANT,
    content: ComponentTooltipData.SEAWAGE_TREATMENT_PLANT,
  },
  {
    parentPage: ParentPage.EDIT_DETAILS,
    childPage: ChildPage.PLUMBING_DETAILS,
    componentName: ComponentName.FIXTURE_FLOW_OR_FLUSH_RATES,
    content: ComponentTooltipData.FIXTURE_FLOW_OR_FLUSH_RATES,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.CENTRALISED_RO,
    content: ComponentTooltipData.CENTRALISED_RO,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.EFFICIENT_FIXTURES,
    content: ComponentTooltipData.EFFICIENT_FIXTURES,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.DUAL_FLUSH,
    content: ComponentTooltipData.DUAL_FLUSH,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.SMART_WATER_METERS,
    content: ComponentTooltipData.SMART_WATER_METERS,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.ROOFTOP_RWH,
    content: ComponentTooltipData.ROOFTOP_RWH,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.SURFACE_RWH,
    content: ComponentTooltipData.SURFACE_RWH,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.GREY_WATER_REUSE,
    content: ComponentTooltipData.GREY_WATER_REUSE,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.BLACK_WATER_REUSE,
    content: ComponentTooltipData.BLACK_WATER_REUSE,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.SOLAR_PV,
    content: ComponentTooltipData.SOLAR_PV,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.SOLAR_WATER_HEATER,
    content: ComponentTooltipData.SOLAR_WATER_HEATER,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.WET_WASTE_TO_BIOGAS,
    content: ComponentTooltipData.WET_WASTE_TO_BIOGAS,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.COLLECTION_AND_SEGREGATION_POINT,
    content: ComponentTooltipData.COLLECTION_AND_SEGREGATION_POINT,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.SANITARY_NAPKIN_INCINERATOR,
    content: ComponentTooltipData.SANITARY_NAPKIN_INCINERATOR,
  },
  {
    parentPage: ParentPage.INTERVENTIONS,
    childPage: ChildPage.INTERVENTIONS_SUMMARY,
    componentName: ComponentName.EXTERIOR_PAINTS,
    content: ComponentTooltipData.EXTERIOR_PAINTS,
  },
];
