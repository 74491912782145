import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';

const capitalizeOpex = (str, searchValue) => {
  if (str.includes(searchValue)) {
    let result = str.replace(searchValue, searchValue.toUpperCase());
    return result;
  } else {
    return str;
  }
};

function SecondaryImpactCard(props) {
  const useStyles = makeStyles((theme) => ({
    paper1: {
      outline: 'none',
      width: props.width,
      height: props.height,
      padding: theme.spacing(2, 2, 1, 0),
      background: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    },
    alignTypo: {
      marginTop: '10%',
    },
    alignUnits: {
      paddingLeft: 10,
    },
    typo1: {
      color: '#3BC7EE',
    },
    typo2: {
      color: '#26E0A3',
    },
  }));
  const classes = useStyles();
  const { inputs } = props;
  //Validate order
  const resource = inputs ? inputs.filter((item) => item && item.tags[0] === 'passive-resource') : '';
  const opex = inputs ? inputs.filter((item) => item && item.tags[0] === 'passive-opex') : '';

  // const resource2 = inputs
  //   ? inputs.filter((item) => item.tags[0] === 'passive-resource' && item.name.split('.')[3] === `${'Energy' || 'Water' || 'Waste'}_Saved`)
  //   : '';
  // const opex2 = inputs
  //   ? inputs.filter((item) => item.tags[0] === 'passive-opex' && item.name.split('.')[3] === `${'Energy' || 'Water' || 'Waste'}_Opex Savings`)
  //   : '';

  return (
    <>
      <Card className={classes.paper1}>
        <Grid container alignItems="center" direction="row" xs={12}>
          <Grid xs={3} container alignItems="center" direction="column">
            <Typography style={{ textAlign: 'center' }} color="textSecondary" variant="h6">
              {capitalizeOpex(resource && resource.length !== 0 ? resource[0].label : '', 'Opex')}
            </Typography>
            <Typography className={classes.alignTypo} variant="h4">
              <b className={classes.typo1}>{resource && resource.length !== 0 ? numberWithCommas(resource && resource[0].value) : 'value'}</b>
            </Typography>
            <Typography className={classes.alignTypo} color="textSecondary">
              {resource && resource.length !== 0 ? resource[0].unit : 'unit'}
            </Typography>
          </Grid>

          <Grid xs={3} container alignItems="center" direction="column">
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              {opex && opex.length !== 0 ? capitalizeOpex(opex && opex[0].label, 'Opex') : ''}
            </Typography>
            <Typography className={classes.alignTypo} variant="h4">
              <b className={classes.typo1}>{opex && opex.length !== 0 ? numberWithCommas(opex && opex[0].value) : 'value'}</b>
            </Typography>
            <Typography className={classes.alignTypo} color="textSecondary">
              {opex && opex.length !== 0 ? opex && opex[0].unit : 'unit'}
            </Typography>
          </Grid>

          <Grid xs={3} container alignItems="center" direction="column">
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              {resource && resource.length !== 0 ? capitalizeOpex(resource && resource[1].label, 'Opex') : ''}
            </Typography>
            <Typography className={classes.alignTypo} variant="h4">
              <b className={classes.typo2}>{resource && resource.length !== 0 ? numberWithCommas(resource && resource[1].value) : 'value'}</b>
            </Typography>
            <Typography className={classes.alignTypo} color="textSecondary">
              {resource && resource.length !== 0 ? resource && resource[1].unit : 'unit'}
            </Typography>
          </Grid>

          <Grid xs={3} container alignItems="center" direction="column">
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              {opex && opex.length !== 0 ? capitalizeOpex(opex && opex[1].label, 'Opex') : ''}
            </Typography>
            <Typography className={classes.alignTypo} variant="h4">
              <b className={classes.typo2}>{opex && opex.length !== 0 ? numberWithCommas(opex && opex[1].value) : 'value'}</b>
            </Typography>
            <Typography className={classes.alignTypo} color="textSecondary">
              {opex && opex.length !== 0 ? opex && opex[1].unit : 'unit'}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
export default SecondaryImpactCard;
