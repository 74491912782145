import NotFound from '../components/common/notfound/Index';
import Home from '../components/views/landingPage/Home';
import ForgotPassword from '../components/views/users/ForgotPassword';
import ResetPassword from '../components/views/users/ResetPassword';
import Marketplace from '../components/views/marketplace/views/landing/Marketplace';
import Categories from '../components/views/marketplace/views/categories/Categories';
import ProductDetails from '../components/views/marketplace/views/productDetails/ProductDetails';
import CertificationAndSuperCategories from '../components/views/marketplace/views/certification-and-super-categories/CertificationAndSuperCategories';
import LegalTerms from '../components/views/marketplace/views/terms-and-conditions/Terms-and-conditions';
import Faq from '../components/views/marketplace/views/faq/Faq';
import PrivacyPolicy from '../components/views/marketplace/views/privacy-policy/PrivacyPolicy';
import ResourcesKnowledgeHub from '../components/views/marketplace/views/resources-and-knowledgehub/ResourcesKnowledgeHub';
import Cement from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Cement';
import Steel from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Steel';
import WallBlocks from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/WallBlocks';
import Paint from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Paints';
import Glazing from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Glazing';
import InteriorFlooring from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/InteriorFlooring';
import ImagePreview from '../components/views/marketplace/common/ImageCheck';

const publicRouting = [
  {
    path: '/',
    component: Home,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/resources/steel',
    component: Steel,
    exact: true,
    requiredRoles: [],
  },
  // {
  //   path: '/marketplace/image',
  //   component: ImagePreview,
  //   exact: true,
  //   requiredRoles: [],
  // },
  {
    path: '/marketplace/resources/wallblocks',
    component: WallBlocks,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/resources/cement',
    component: Cement,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/resources/paints',
    component: Paint,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/resources/glazing',
    component: Glazing,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/resources/interiorflooring',
    component: InteriorFlooring,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/resources',
    component: ResourcesKnowledgeHub,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/faq',
    component: Faq,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/categories/:id/products/:iid',
    component: ProductDetails,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/categories',
    component: Categories,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/terms-and-conditions',
    component: LegalTerms,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace/categorydetails',
    component: CertificationAndSuperCategories,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/marketplace',
    component: Marketplace,
    exact: true,
    requiredRoles: [],
  },

  // {
  //   path: '/team',
  //   component: NewTeam,
  //   exact: true,
  //   requiredRoles: [],
  // },
  {
    path: '/forgotpassword',
    component: ForgotPassword,
    exact: true,
    requiredRoles: [],
  },
  {
    path: '/resetpassword/:id/:token/:email',
    component: ResetPassword,
    exact: true,
    requiredRoles: [],
  },

  {
    path: '*/',
    component: NotFound,
    requiredRoles: [],
  },
];

export default publicRouting;
