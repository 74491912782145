import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgressComponent from './LinerProgress';
import numberWithCommas from '../../../../../utils/numberToComma';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: 290,
    padding: 18,
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
}));

// check out for a common component
const SelectedInterv = (props) => {
  const classes = useStyles();
  const newintervention = props.newintervention;

  let energyCategory = newintervention && newintervention.categorized.find((category) => category.name === 'Energy');
  let waterCategory = newintervention && newintervention.categorized.find((category) => category.name === 'Water');
  let wasteCategory = newintervention && newintervention.categorized.find((category) => category.name === 'Waste');
  let materialsCategory = newintervention && newintervention.categorized.find((category) => category.name === 'Materials');
  const energyPercentage =
    energyCategory &&
    energyCategory?.subcategories
      .find((s) => s.name === 'Renewable Sources')
      ?.interventions[0]?.quantities?.details?.find((data) => data.name === 'energy.totalpercentagesaving');
  console.log(energyPercentage, 'energy percentage');

  let waterpercentage =
    waterCategory &&
    waterCategory.subcategories.find((category) => category.name === 'Source Creation') &&
    waterCategory.subcategories.find((category) => category.name === 'Source Creation').interventions[0] &&
    waterCategory.subcategories
      .find((category) => category.name === 'Source Creation')
      .interventions[0].quantities?.details?.find((data) => data.name === 'water.totalpercentagesaving');
  let wastepercentage =
    wasteCategory &&
    wasteCategory.subcategories[0] &&
    wasteCategory.subcategories[0].interventions[0] &&
    wasteCategory.subcategories[0].interventions[0].quantities?.details?.find((data) => data.name === 'waste.totalpercentagesaving');

  const energySavings =
    energyCategory &&
    energyCategory &&
    energyCategory?.quantities &&
    energyCategory?.quantities?.resource?.category &&
    energyCategory?.quantities?.resource?.category?.value;

  const energyUnit =
    energyCategory &&
    energyCategory &&
    energyCategory.quantities &&
    energyCategory?.quantities?.resource?.category &&
    energyCategory?.quantities?.resource?.category?.unit;

  const waterSavings =
    waterCategory &&
    waterCategory &&
    waterCategory?.quantities &&
    waterCategory?.quantities?.resource?.category &&
    waterCategory?.quantities?.resource?.category?.value;

  const waterUnit =
    waterCategory &&
    waterCategory &&
    waterCategory.quantities &&
    waterCategory?.quantities?.resource?.category &&
    waterCategory?.quantities?.resource?.category?.unit;

  const wasteSavings =
    wasteCategory &&
    wasteCategory &&
    wasteCategory?.quantities &&
    wasteCategory?.quantities?.resource?.category &&
    wasteCategory?.quantities?.resource?.category?.value;

  const wasteUnit =
    wasteCategory &&
    wasteCategory &&
    wasteCategory?.quantities &&
    wasteCategory?.quantities?.resource?.category &&
    wasteCategory?.quantities?.resource?.category?.unit;

  const materialsSavings =
    materialsCategory &&
    materialsCategory &&
    materialsCategory.quantities &&
    materialsCategory?.quantities?.resource?.category &&
    materialsCategory?.quantities?.resource?.category?.value;

  const materialsUnit =
    materialsCategory &&
    materialsCategory &&
    materialsCategory?.quantities &&
    materialsCategory?.quantities?.resource?.category &&
    materialsCategory?.quantities?.resource?.category?.unit;

  const energyGoals = props.goals && props.goals.find((goal) => goal.name === 'Energy') && props.goals.find((goal) => goal.name === 'Energy');
  const waterGoals = props.goals && props.goals.find((goal) => goal.name === 'Water') && props.goals.find((goal) => goal.name === 'Water');
  const wasteGoals = props.goals && props.goals.find((goal) => goal.name === 'Waste') && props.goals.find((goal) => goal.name === 'Waste');
  return (
    <>
      <Container className={classes.gridContainer}>
        <Grid container xs={12} style={{ marginBottom: 30, marginTop: 10 }}>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: '#F28D14', letterSpacing: '1px', paddingBottom: '4%' }}>
                <b>Energy</b>
              </Typography>
            </Grid>{' '}
            <LinearProgressComponent bgcolor="#F28D14" value={!energyPercentage ? 0 : energyPercentage?.value > 100 ? 100 : energyPercentage?.value} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {(energySavings && energySavings > 0) || (energySavings && energySavings === 0) ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${numberWithCommas(energySavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6">{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">{energyUnit && energyUnit}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: '#3BC7EE', letterSpacing: '1px', paddingBottom: '4%' }}>
                <b>Water</b>
              </Typography>
            </Grid>{' '}
            <LinearProgressComponent bgcolor="#3BC7EE" value={waterpercentage && waterpercentage.value} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {waterSavings > 0 || waterSavings === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${numberWithCommas(waterSavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6">{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">{waterUnit && waterUnit}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} style={{ marginTop: 30 }}>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: '#BC8A5F', letterSpacing: '1px', paddingBottom: '4%' }}>
                <b>Waste</b>
              </Typography>
            </Grid>{' '}
            <LinearProgressComponent bgcolor="#BC8A5F" value={wastepercentage && wastepercentage.value} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {wasteSavings > 0 || wasteSavings === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${numberWithCommas(wasteSavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6">{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">{wasteUnit && wasteUnit}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  // color: ' #916AAC',
                  color: '#9E9E9E',
                  letterSpacing: '1px',
                  paddingBottom: '4%',
                }}
              >
                <b>Material</b>
              </Typography>
            </Grid>{' '}
            <LinearProgressComponent bgcolor="#916AAC" value={0} disabled={true} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {materialsSavings > 0 || materialsSavings === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ color: '#9E9E9E' }}>{`${numberWithCommas(materialsSavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6" style={{ color: '#9E9E9E' }}>{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" style={{ color: '#9E9E9E' }}>
                  {materialsUnit && materialsUnit}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SelectedInterv;
