import React, { useState, useEffect } from 'react';
import axios from '../../../config/Axios';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import useApiRequest from '../../hooks/useApiRequest';
import Header from '../../common/header/Header';
import Cards from './Cards';
import PrimaryButton from '../../common/button/Button';
import waterIcon from '../../../assets/Group 99.svg';
import healthIcon from '../../../assets/Group 137.svg';
import energyIcon from '../../../assets/Group 94.svg';
import carbonIcon from '../../../assets/Group 93.svg';
import wasteIcon from '../../../assets/wastegoalsicon.svg';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';
import CasaSkeleton from '../../common/skeleton/CasaSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '8%',
    marginLeft: '20%',
  },
  rootmobile: {
    paddingTop: '4%',
  },
  setgoalsheading: {
    paddingTop: '3%',
    paddingLeft: '10%',
    fontWeight: '200',
    lineHeight: '35px',
    fontSize: '22px',
    letterSpacing: '0.571429px',
    color: '#000000',
  },
  goalsheading: {
    paddingTop: '2%',
    paddingLeft: '10%',
    lineHeight: '25px',
    letterSpacing: '0.714286px',
  },
}));

const iconColorMap = {
  Water: { icon: waterIcon, style: { backgroundColor: '#CFF2FA', color: '#74DEFB' } },
  Waste: { icon: wasteIcon, style: { backgroundColor: 'rgb(243, 221, 200)' } },
  'Occupants Health': {
    icon: healthIcon,
    style: { backgroundColor: '#FEE7EC', color: '#FBA9AA' },
  },
  'Carbon Footprint': {
    icon: carbonIcon,
    style: { backgroundColor: '#E1E6FF', color: '#AAC3EC' },
  },
  Energy: {
    icon: energyIcon,
    style: { backgroundColor: '#FFF4D5', color: '#FFAF50' },
  },
};

function SetGoals(props) {
  let history = useHistory();
  let skeleton = [1, 2, 3];
  const matches = useMediaQuery('(min-width:749px)');
  const project_id = props.match.params.id;
  const classes = useStyles();

  const [newGoals, setNewGoals] = useState('');
  const [myArray, setMyArray] = useState([]);

  const [{ status, response }, makeRequest] = useApiRequest(`/projectv2/${project_id}`, {
    verb: 'get',
  });

  if (status === null) makeRequest();

  // create an array of goal id project id and value
  const appendGoalsChanged = (obj) => {
    const body = { goalProjectMappingId: obj.goalProjectMappingId, value: obj.value };
    var n =
      myArray &&
      myArray.find((data) => {
        return data.goalProjectMappingId === obj.goalProjectMappingId;
      });
    if (n) {
      n.value = obj.value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  //get goals for project new routes
  useEffect(() => {
    if (response) {
      const Sorted =
        response.data &&
        response.data.data.data &&
        response.data.data.data.goals.categories[0].sort((a, b) => (a.name === 'Energy' && b.name === 'Water' ? -1 : b.name === 'Waste' ? -1 : 0));
      setNewGoals(Sorted && Sorted);
    }
  }, [response]);

  const handleGoBack = () => history.goBack();
  const handleSubmit = () => {
    if (myArray && myArray.length !== 0) {
      const body = { values: myArray && myArray };
      axios
        .put('/ProjectGoalMapping', body)
        .then((res) => {
          if (res) {
            history.push(`/projects/${project_id}/insights`);
          }
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    } else {
      history.push(`/projects/${project_id}/insights`);
    }
  };

  return (
    <React.Fragment>
      <Grid container direction="column" item xs>
        <Header />
        <Grid className={matches ? classes.root : classes.rootmobile} xs>
          <Grid style={{ marginLeft: '8%' }}>
            <CommonHeadingWithIcon heading="Sustainability Goals Setting" handleClick={handleGoBack} />
          </Grid>
          <Grid>
            <Typography className={classes.setgoalsheading}>
              Research shows that setting goals upfront improves the <br /> adherence by 90%. We have started you off by choosing <br />
              goals for this project based on resource & geospatial <br /> data sets corresponding to your project details.
            </Typography>
            <Grid>
              <Typography variant="h5" className={classes.goalsheading}>
                Sustainability Goals
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ marginLeft: '10%' }}>
            {newGoals
              ? newGoals.map((item, index) => {
                  return (
                    <Cards
                      key={index}
                      cardName={item.name}
                      title1={item.metadata.title}
                      description1={item.metadata.subtitle}
                      description2={item.metadata.note}
                      icon={iconColorMap[item.name] ? iconColorMap[item.name].icon : waterIcon}
                      id={item.metadata.hasOwnProperty('goalMappingId') ? item.metadata.goalMappingId : 0}
                      rangevalue={item.quantities.targetPercentage.value}
                      style={iconColorMap[item.name] ? iconColorMap[item.name].style : { backgroundColor: '#CFF2FA', color: '#74DEFB' }}
                      styleNew={{ marginLeft: '10%' }}
                      handleChange={appendGoalsChanged}
                    />
                  );
                })
              : skeleton &&
                skeleton.map((d, index) => {
                  return (
                    <>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        key={index}
                        style={index === 0 ? { height: 'Auto', marginBottom: '4%', marginTop: '5%' } : { height: 'Auto', marginBottom: '4%' }}
                      >
                        <Grid alignItems="left" justify="flex-start" item xs={12}>
                          <CasaSkeleton variant="rect" height="50vh" width="60%" animation="wave" />
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
          </Grid>

          <Grid container item xs={12} style={{ paddingTop: '3%', marginLeft: '10%' }}>
            <Grid item xs={3}>
              <PrimaryButton onClick={handleSubmit} children="done" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SetGoals;
