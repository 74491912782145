import { useState, useEffect } from 'react';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
import { impactProductsQuery } from '../graphql-queries/ImpactProducts.query';

const useImpactProductsSpotlight = () => {
  const [imapctProductsData, setImpactProductsData] = useState('');
  const [getImpactProducts, SendImpactProducts] = useGraphqlRequest(impactProductsQuery);

  if (getImpactProducts && !getImpactProducts.response) SendImpactProducts();

  useEffect(() => {
    if (getImpactProducts.response !== null) {
      const currentImpactProductsData = getImpactProducts?.response?.data?.data?.impactProducts?.data?.find(
        (element) => element?.attributes?.name === 'Beyond Green'
      );
      setImpactProductsData(currentImpactProductsData && currentImpactProductsData);
    }
  }, [getImpactProducts]);

  return { imapctProductsData };
};

export default useImpactProductsSpotlight;
