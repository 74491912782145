import React from 'react';
import { Container, Tabs, Tab } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Energy from './tabview/Energy';
import Water from './tabview/Water';
import Waste from './tabview/Waste';
import Materials from './tabview/Materials';

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: '0.6px solid #CCCCCC',
  },
  indicator: {
    backgroundColor: '#F28D14',
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
    minWidth: '70px',
    labelIcon: {
      color: '#9E9E9E',
      '&:focus': {
        fontStyle: 'normal',
        fontWeight: '900',
      },
    },
    image: {
      height: '500px',
      width: '500px',
    },
  },
}))(Tab);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    position: 'sticky',

    top: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: '#555',
  },
  link: {
    marginRight: theme.spacing(2),
    color: '#555',
    fontSize: 16,
  },
}));

const Rightbar = (props) => {
  const { newintervention, goals, project } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [name, setName] = React.useState(0);

  const handleChange = (event, label) => setValue(label);
  const handleLabel = (label) => setName(name < 3 ? name + 1 : 0);

  return (
    <>
      <Container className={classes.container} data-tour="goaltracking">
        <StyledTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <StyledTab label="Energy" onClick={handleLabel} />
          <StyledTab label="Water" onClick={handleLabel} />
          <StyledTab label="Waste" onClick={handleLabel} />
          <StyledTab
            disabled
            label="Materials"
            // onClick={handleLabel}
          />
        </StyledTabs>
        {value === 0 && (
          <Energy
            project={project}
            newintervention={newintervention}
            energyGoals={goals && goals.find((goal) => goal.name === 'Energy') && goals.find((goal) => goal.name === 'Energy')}
          />
        )}
        {value === 1 && (
          <Water
            project={project}
            newintervention={newintervention}
            energyGoals={goals && goals.find((goal) => goal.name === 'Water') && goals.find((goal) => goal.name === 'Water')}
          />
        )}
        {value === 2 && (
          <Waste
            project={project}
            newintervention={newintervention}
            energyGoals={goals && goals.find((goal) => goal.name === 'Waste') && goals.find((goal) => goal.name === 'Waste')}
          />
        )}
        {value === 3 && (
          <Materials
            project={project}
            newintervention={newintervention}
            energyGoals={goals && goals.find((goal) => goal.name === 'Materials') && goals.find((goal) => goal.name === 'Materials')}
          />
        )}
      </Container>
    </>
  );
};

export default Rightbar;
