export default function numberWithCommas(x) {
  if (x !== undefined) {
    if (!x.toString().includes('.')) {
      x = x.toString();
      let lastThree = x.substring(x.length - 3);
      let otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== '') lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
      return res;
    } else if (x.toString().includes('.')) {
      x = x.toString();
      let afterPoint = '';
      if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length);
      x = Math.floor(x);
      x = x.toString();
      let lastThree = x.substring(x.length - 3);
      let otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== '') lastThree = ',' + lastThree;
      let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
      return res;
    } else if (x.toString() === '0') {
      return 0;
    }
  } else {
    return 0;
  }
}
