import cement from '../../../../../assets/Marketplace/material-resources/cement-resource-3.svg';
import cement2 from '../../../../../assets/Marketplace/material-resources/cement-resource-4.svg';
import cementIcon1 from '../../../../../assets/Marketplace/material-resources/CementIcon1.svg';
import cementIcon2 from '../../../../../assets/Marketplace/material-resources/CementIcon2.svg';
import cementIcon3 from '../../../../../assets/Marketplace/material-resources/CementIcon3.svg';
import cementIcon4 from '../../../../../assets/Marketplace/material-resources/CementIcon4.svg';
import cementIcon5 from '../../../../../assets/Marketplace/material-resources/CementIcon5.svg';
import cementIcon6 from '../../../../../assets/Marketplace/material-resources/CementIcon6.svg';
import cementIcon7 from '../../../../../assets/Marketplace/material-resources/CementIcon7.svg';
import steelresource1 from '../../../../../assets/Marketplace/material-resources/steel-resource-1.svg';
import steelimage1 from '../../../../../assets/Marketplace/Resources/MS-02 1.svg';
import steelimage2 from '../../../../../assets/Marketplace/Resources/pngwing 1.svg';
import steelimage3 from '../../../../../assets/Marketplace/Resources/SVGZ_McK_Decarbonizing cement_Ex1_web_Exhibit only-04 1.svg';
import steelimage4 from '../../../../../assets/Marketplace/Resources/Alloy steel-05 1.svg';
import steelimage5 from '../../../../../assets/Marketplace/Resources/Untitled 1.svg';
import steelimage6 from '../../../../../assets/Marketplace/Resources/Steel-feature-06 1.svg';
import steelimage7 from '../../../../../assets/Marketplace/Resources/Untitled(1) 1.svg';
import wallblocks from '../../../../../assets/Marketplace/material-resources/wallblocks.png';
import paintesimage1 from '../../../../../assets/Marketplace/Resources/image.jpg';
import paintimage2 from '../../../../../assets/Marketplace/Resources/Untitled.png';
import paintiamge3 from '../../../../../assets/Marketplace/Resources/paint.png';
import glazingimage1 from '../../../../../assets/Marketplace/Resources/glazingimage1.png';
import glazingimage2 from '../../../../../assets/Marketplace/Resources/glazingimage2.png';
import glazingimage3 from '../../../../../assets/Marketplace/Resources/glazingimage3.png';
import glazingimage4 from '../../../../../assets/Marketplace/Resources/glazingimage4.png';
import glazingimage5 from '../../../../../assets/Marketplace/Resources/glazingimage5.png';
import glazingimage6 from '../../../../../assets/Marketplace/Resources/glazingimage6.png';
import glazingimage7 from '../../../../../assets/Marketplace/Resources/glazingimage7.png';
import glazingimage8 from '../../../../../assets/Marketplace/Resources/glazingimage8.png';
import glazingimage9 from '../../../../../assets/Marketplace/Resources/glazingimage9.png';
import glazingimage10 from '../../../../../assets/Marketplace/Resources/glazingimage10.png';
import glazingimage111 from '../../../../../assets/Marketplace/Resources/glazingimage111.png';

import interiorflooring1 from '../../../../../assets/Marketplace/Resources/interiorflooring1.png';
import interiorflooring2 from '../../../../../assets/Marketplace/Resources/interiorflooring2.png';
import interiorflooring3 from '../../../../../assets/Marketplace/Resources/interiorflooring3.png';
import interiorflooring4 from '../../../../../assets/Marketplace/Resources/interiorflooring4.png';
import interiorflooring5 from '../../../../../assets/Marketplace/Resources/interiorflooring5.png';
import interiorflooring6 from '../../../../../assets/Marketplace/Resources/interiorflooring6.png';
import interiorflooring7 from '../../../../../assets/Marketplace/Resources/interiorflooring7.png';
import interiorflooring8 from '../../../../../assets/Marketplace/Resources/interiorflooring8.png';
import interiorflooring9 from '../../../../../assets/Marketplace/Resources/interiorflooring9.png';
import interiorflooring10 from '../../../../../assets/Marketplace/Resources/interiorflooring10.png';
import interiorflooring11 from '../../../../../assets/Marketplace/Resources/interiorflooring11.png';
import interiorflooring12 from '../../../../../assets/Marketplace/Resources/interiorflooring12.png';
import interiorflooring13 from '../../../../../assets/Marketplace/Resources/interiorflooring13.png';
import interiorflooring14 from '../../../../../assets/Marketplace/Resources/interiorflooring14.jpg';
import paintimage4 from '../../../../../assets/Marketplace/Resources/Paint2.png';
export const types = {
  steppers: 'steppers',
  text: 'text',
  image: 'image',
  table: 'table',
  points: 'points',
  heading: 'heading',
  subhead: 'subheading',
  textwithpoints: 'textwithpoints',
  imagefulllength: 'imagefulllength',
  textwithpointsandsubpoints: 'textwithpointsandsubpoints',
};

export const ResourcesData = [
  {
    id: 1,
    iid: 'Cement',
    title: 'Cement For Residential Appartment',
    data: [
      {
        id: 1,
        type: types.steppers,
        data: [
          { id: 1, title: 'Identify', text: 'the type of cement and the factors that fit the intended requirement' },
          { id: 2, title: 'Replace', text: 'with blended cement as a sustainable alternative' },
          { id: 3, title: 'Choose', text: 'the right cement product' },
          { id: 4, title: 'Consult', text: 'your structural engineer for the right mix' },
        ],
      },
      {
        id: 2,
        type: types.image,
        image: undefined,
        source: 'Source: Laying the foundation for zero-carbon environment',
      },
      {
        id: 3,
        type: types.text,
        title: undefined,
        text: 'Cement is a binding agent used in construction that sets and hardens to adhere to building units such as stones, bricks, tiles, etc . This industry is responsible for a staggering 7% of global carbon dioxide (CO2) emissions, making it a major contributor to climate change. Ordinary cement contains higher amount of a substance called clinker which is responsible for 90% of the emissions. To reduce this emissions, materials such as fly ash, slag, or natural pozzolans, can replace some of the clinker content in cement without compromising its performance. So selection of greener cements like PSC, PCC and PPC can help reduce carbon footprint by up to 30%.',
      },
      {
        id: 4,
        type: types.image,
        image: undefined,
        source: 'Source: "Blended Cement: Green, Durable & Sustainable” by Global Cement & Concrete Association',
      },
      {
        id: 5,
        type: types.table,
        data: [],
      },
      {
        id: 6,
        type: types.text,
        description:
          'There are different types of cement available, each with its unique properties suitable for specific applications. Some major types of cement commercially used in India are -',
        title: 'Types of Cement',
        data: [
          {
            type: types.text,
            title: 'Ordinary Portland cement (OPC)',
            text: 'OPC is most commonly used cement in construction. It is manufactured by a mixture of clinker and gypsum and other materials. This cement has an early setting time, is relatively costlier and is less environment-friendly compared to other blended cements.',
          },
          {
            type: types.text,
            title: null,
            text: 'Three different grades of OPC are available in the Indian market,',
          },
          {
            type: types.points,
            title: undefined,
            points: [
              {
                type: types.text,
                title: 'Grade 33',
                text: 'This grade of cement has a minimum compressive strength of 33 MPa after 28 days of curing. It is suitable for non-structural applications like plastering, flooring, and small residential constructions.',
              },
              {
                type: types.text,
                title: 'Grade 43',
                text: 'This grade of cement has a minimum compressive strength of 43 MPa after 28 days. It is commonly used for general construction purposes, including residential buildings, bridges, and pavements',
              },
              {
                type: types.text,
                title: 'Grade 53',
                text: 'This grade cement has a minimum compressive strength of 53 MPa after 28 days. It provides higher strength and is typically used in applications that require high-performance concrete, such as high-rise buildings, infrastructure projects, and precast elements.',
              },
            ],
          },
          {
            type: types.text,
            title: 'Portland Slag Cement (PSC)',
            text: 'Portland Slag Cement is a blended cement which combines OPC clinker and GBF. it is an sustainable alternative to conventional cement.',
          },
          {
            type: types.text,
            title: 'Portland Pozzolana Cement (PPC)',
            text: 'PPC is a blended cement that combines OPC clinker, gypsum, and pozzolanic materials such as fly ash, calcined clay, or silica fume.',
          },
          {
            type: types.text,
            title: 'Portland Composite Cement(PCC)',
            text: 'Portland Composite Cement is a blended cement that combines OPC clinker, gypsum, and a combination of pozzolanic materials such as fly ash, silica fume, and/or GBFS.',
          },
          {
            type: types.image,
            image: cement,
            source: undefined,
          },
          {
            type: types.heading,
            title: 'Guidelines to choose the right material for your project',
          },
          {
            type: types.points,
            title: 'General Factors',
            points: [
              {
                type: types.text,
                icon: undefined,
                title: `Manufacturer's Reputation:`,
                text: `Manufacturer's Reputation: Vet the reputation and track record of the cement manufacturer. Look for reliability, technical support, warranties, certifica and references because a reputable manufacturer is more likely to provide high-quality and consistent cement for your project`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Durability:`,
                text: `Durability: Cement durability refers to the ability of cement-based materials to withstand environmental and structural stresses over time without significant deterioration. It is a crucial factor in ensuring the long-term performance and lifespan of construction materials and structures.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: 'Cost-effectiveness: ',
                text: `Balance the cost of the cement with your project's budget without compromising quality or performance.`,
              },
            ],
          },
          {
            type: types.points,
            title: 'Technical Factors',
            points: [
              {
                type: types.text,
                icon: undefined,
                title: `Compressive strength:`,
                text: `Compressive strength is the cement's ability to withstand pressure. Evaluate the strength class of cement (e.g., 33, 43, or 53 grade) to ensure it meets the structural requirements of your project. Higher-grade cement offers higher strength but may have a faster setting time.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Fineness: `,
                text: `Fineness refers to the particle size distribution of the cement. A finer cement offers better workability and a quicker hydration rate. Check the cement's specific surface area, typically measured in square centimeters per gram (cm²/g), to determine its fineness.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: 'Workability:',
                text: `Workability is the ease with which the cement can be mixed, placed, and compacted. Choose a cement that offers the desired workability for your project's specific application.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: 'Heat of Hydration:',
                text: ` The heat of hydration is the amount of heat generated during the cement's hardening process. Managing heat during concrete curing is crucial, particularly in hot climatic regions like India. Choosing a cement type with a suitable heat of hydration can help control the temperature rise and minimize the risk of thermal cracking.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Setting Time:`,
                text: ` The setting time is the period it takes for the cement to harden. Consider your project's time constraints and select a cement with an appropriate initial and final setting time.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Compatibility with Aggregates:`,
                text: `  Ensure that the cement is compatible with the aggregates you intend to use in the concrete mix.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Resistance against Chemical Attacks:`,
                text: `Given the potential exposure to chemical substances in various applications, such as industrial environments or sewage systems, selecting a cement type with good resistance against chemical attacks is important to maintain the durability of the structure.`,
              },
            ],
          },
          {
            type: types.image,
            image: cement2,
            source: undefined,
          },
          {
            type: types.heading,
            title: 'Environmental Impact Factors',
          },
          {
            type: types.points,
            title: undefined,
            points: [
              {
                type: types.text,
                icon: cementIcon1,
                title: `Carbon Emissions`,
                text: ` Cement production is a major source of greenhouse gas emissions contributed by manufacturing, raw materials souring, and transportation in industry. Choose cement types that have less global warming potential like blended cement with industrial by-products such as PSC, PPC & GGBS cement that can mitigate this impact.`,
              },
              {
                type: types.text,
                icon: cementIcon2,
                title: `Energy-Efficient Manufacturing`,
                text: `Adopt cement products that prioritize energy-efficient manufacturing processes, reducing the energy consumption. Look for cement with low embodied energy. `,
              },
              {
                type: types.text,
                icon: cementIcon3,
                title: `Resource Consumption`,
                text: ` The cement industry relies on abiotic resources like minerals (limestone, clay, and sand) and fossil fuels (coal and natural gas) contributing to resource consumption and Abiotic Depletion Potential (ADP). Sustainable practices in mining, energy efficiency, and alternative materials are crucial to mitigate its impact. Choose cement with low ADP. `,
              },
              {
                type: types.text,
                icon: cementIcon4,
                title: `Waste Management`,
                text: `  The cement production process generates waste in the form of dust, sludge, and solid waste. Look for manufacturers that follow waste management practices, such as recycling or reusing waste materials.`,
              },
              {
                type: types.text,
                icon: cementIcon5,
                title: `Water Management `,
                text: `Cement production requires water for cooling, cleaning, and other processes. Choose cement manufactured with water-efficient processes or those that minimize water consumption during manufacturing. `,
              },
              {
                type: types.text,
                icon: cementIcon6,
                title: `Local Sourcing and Manufacturing `,
                text: ` The transportation of cement from manufacturing plants to construction sites contributes to air pollution and CO2 emissions. Choose locally produced cement or manufacturers with efficient transportation systems to minimize the carbon emissions and Acidification Potential(AP).`,
              },
              {
                type: types.text,
                icon: cementIcon7,
                title: `Certification`,
                text: `Look for environmentally certified cement products, such as those with the GreenPro Certification by the Confederation of Indian Industry (CII) or the Leadership in Energy and Environmental Design (LEED) certification. These certifications ensure that the cement meets specific environmental performance criteria.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: undefined,
                text: `By considering these environmental impact factors when selecting cement, you can make a responsible choice that contributes to reducing the overall ecological footprint of your construction project`,
              },
            ],
          },
          {
            type: types.table,
            data: [],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    iid: 'Steel',
    title: 'Steel for Residential Apartments',
    data: [
      {
        id: 1,
        type: types.steppers,
        data: [
          { id: 1, title: 'Seek', text: 'consultation for structural compliance' },
          { id: 2, title: 'Check', text: 'recycled content in steel' },
          { id: 3, title: 'Inquire', text: 'about the specific production method to assess environmental impact' },
          { id: 4, title: 'Choose', text: 'he most sustainably produced steel product' },
        ],
      },
      {
        id: 2,
        type: types.text,
        title: undefined,
        text: 'The steel industry has historically been associated with significant carbon emissions and resource consumption. However, modern advancements and sustainable practices within the industry now offer environmentally conscious options. Choose steel with minimal industry impact involves assessing factors such as recycling, energy efficiency, carbon reduction, responsible sourcing, and adherence to eco-friendly standards. Steel contributed to about 8% of global CO2 emissions.',
      },
      {
        id: 3,
        type: 'image',
        image: steelresource1,
        source: undefined,
      },
      {
        id: 4,
        type: types.heading,
        title: 'Technical properties Of steel',
      },
      {
        id: 5,
        type: types.table,
        data: [
          { value1: '', value2: 'Typically used in Residential Buildings' },
          { value1: 'Type of Steel', value2: 'Fe-415, Fe-500, Fe-550' },
          { value1: 'Yield Strength (MPa)', value2: 'High rise 415-550' },
          { value1: 'Tensile Strength (MPa)', value2: 'High rise 500 - 600' },
          { value1: 'Ductility', value2: '18-20%' },
          { value1: 'Corrosion Resistance', value2: 'Medium to high' },
          { value1: 'Fire Resistance', value2: 'High (60 mins)' },
          { value1: 'Cost-effectiveness', value2: 'Medium to High' },
          { value1: 'Durability', value2: 'High' },
          { value1: 'Elongation', value2: '10-20%' },
          { value1: 'UTS/YS Ratio', value2: '1.06 to 1.10' },
        ],
      },
      {
        id: 6,
        type: types.heading,
        title: 'Types of Steel Used in building construction',
      },
      {
        id: 7,
        type: types.image,
        image: steelimage1,
        source:
          'Mild Steel: Mild steel known as MS is the most commonly used steel. It is strong and long-lasting, and it makes a solid foundation. It does not break when bent, is extremely flexible and malleable, has high plasticity, and can withstand natural disasters such as earthquakes without generating cracks in the steel.',
      },
      {
        id: 8,
        type: types.image,
        image: steelimage2,
        source:
          'Steel Rebar: Steel rebar or a steel mesh is used with concrete to create structures that can withstand greater tension. Rebar steel offers durability and aesthetic appeal, as well as local resistance and stiffness that extend across a large area where other types of steel aren’t often prepared.',
      },
      {
        id: 9,
        type: types.text,
        title: undefined,
        text: 'Thermo-Mechanically Treated Bars (TMT Bars): TMT bars are used in reinforced cement concrete (RCC) work. Some qualities of TMT bars are high tensile strength, economical and safe, better earthquake and corrosion resistance, and better bonding strength and toughness. Variations of the grades are also available with suffixes D & SD, For example, Fe-415D and Fe-500SD. D stands for ductility, which indicates higher ductility than alternative grade bar of the same tensile strength, SD stands for super ductile rebar, and ductility is provided to increase the yield strength of rebar.',
      },
      {
        id: 10,
        type: types.subhead,
        title: 'Four different types of TMT steel bar grades manufactured in India.',
      },
      {
        id: 11,
        type: types.textwithpoints,
        data: [
          'Fe 415 Grade: Fe 415 grade TMT steel bars possess higher elongation properties and are economically feasible making them the best choice for small constructions. They are preferred for areas prone to natural disasters such as earthquakes. It comes with corrosion-resistant properties.',
          'Fe 500 Grade: Fe-500 grade TMT steel bars are known for their versatility making them the ideal choice for underground constructions and multi-story buildings. They have good stability and elasticity and provide high seismic security from earthquakes and other natural calamities.',
          'Fe 550 Grade: Fe-500 grade TMT steel bars have higher tensile strength and are put into applications where Fe-500 grade becomes a little difficult to manage. Examples of Fe-550 grade applications are marine constructions.',
          'Fe 600 Grade: FE 600 grade TMT steel bars have the highest tensile to yield strength, ductility and elongation compared to all other grades of TMT steel bars. This grade of TMT steel bars comes into use for heavy constructions that require maximum strength and durability that lasts for a lifetime and withstand harsh weather conditions and earthquakes.',
          'High Strength Deformed Bars (HSD Bars): TMT and HSD bars impart high strength to the structure. When compared to other steel bars, HSD bars have lower carbon content, which allows for better ductility, strength, and welding ability.',
        ],
      },
      {
        id: 12,
        type: types.text,
        title: undefined,
        text: 'High Strength Deformed Bars (HSD Bars): TMT and HSD bars impart high strength to the structure. When compared to other steel bars, HSD bars have lower carbon content, which allows for better ductility, strength, and welding ability.',
      },
      {
        id: 13,
        type: types.image,
        image: steelimage3,
        source:
          'Stainless Steel: Stainless steel is an alloy made up primarily of iron and chromium. This steel has a higher resistance to corrosion compared to carbon steel, making it ideal for outdoor applications or areas exposed to moisture or salt water. It also offers superior mechanical properties than other steels, making it a popular choice for structural components such as bridges.',
      },
      {
        id: 14,
        type: types.image,
        image: steelimage4,
        source:
          'Alloy Steel: Alloy steel is a combination of iron, manganese, chromium, nickel, molybdenum, vanadium, and titanium. alloy steels unique combination of properties, including strength, corrosion resistance, heat resistance, and wear resistance, makes it a valuable material in construction, contributing to the longevity and reliability of construction projects.',
      },
      {
        id: 15,
        type: types.text,
        title: undefined,
        text: 'High alloy steel: is utilized during construction to hold liquids, mining materials, chemicals, and pharmaceutical equipment.',
      },
      {
        id: 16,
        type: types.text,
        title: undefined,
        text: 'Low alloy steel: is suitable for various applications, including seamless rolling ring forging and studying outlets',
      },
      {
        id: 17,
        type: types.image,
        image: steelimage5,
        source:
          'Carbon Steel: Carbon steel, also known as Black Bar, is an alloy of iron and carbon, which is widely used in the construction industry due to its strength, low cost, and ease of fabrication. Although carbon steel offers good value for money, it is prone to corrosion.',
      },
      {
        id: 18,
        type: types.imagefulllength,
        image: steelimage6,
      },
      {
        id: 19,
        type: types.heading,
        title: 'Environmental Impact Indicators',
      },
      {
        id: 20,
        type: types.textwithpoints,
        data: [
          'Carbon Emissions: Steel production is a significant contributor to greenhouse gas emissions, stemming from manufacturing processes, raw material extraction, and transportation. To mitigate this impact, choose steel products with low embodied carbon, such as those with a high recycled content or produced using low-carbon technologies.',
          'Energy-Efficient Manufacturing: Adopt for steel manufacturers that prioritize energy-efficient production processes to reduce overall energy consumption. Look for steel products with low embodied energy, indicating efficient production methods.',
          'Resource Conservation: The steel industry heavily relies on non-renewable resources like iron ore, coal, and limestone, contributing to resource consumption and abiotic depletion potential. To minimize this impact, select steel with a low abiotic depletion potential and support responsible sourcing practices.',
          'Waste Management: Steel production generates waste in various forms. Choose manufacturers that implement waste management practices, including recycling and reusing waste materials, reducing waste generation.',
          'Water Efficiency: Steel manufacturing requires water for cooling and other processes. Adopt steel products that are manufactures with efficient water management practices or those that minimize water consumption during production. look for low water scarcity potential.',
          'Air Pollution and Acidification Control: Steel production releases air pollutants, including particulate matter and sulfur dioxide (SO2), contributing to air pollution and acidification potential. Choose manufacturers with effective emission control technologies to reduce these impacts',
          'Local Sourcing and Transportation: Consider the environmental impact of transporting steel from manufacturing plants to construction sites, including air pollution and CO2 emissions. Choose locally produced steel or manufacturers with efficient transportation systems to minimize these impacts.',
          'Certifications: Look for environmentally certified steel products, including certifications such as ISO 14001, as well as green product certifications specifically relevant in India, such as GreenPro (awarded by the Confederation of Indian Industry), IGBC Green Building Products Certification BEE Star Label, and EcoMark Certification (by the Bureau of Indian Standards). These certifications ensure adherence to specific environmental performance criteria and eco-friendly standards.',
        ],
      },
      {
        id: 21,
        type: types.text,
        text: 'By considering these environmental impact factors and relevant certifications, you can make informed choices when selecting steel, promoting sustainability and minimizing the ecological footprint of your construction or manufacturing projects, especially in the context of India.',
      },
      {
        id: 22,
        type: types.heading,
        title: 'More about steel production methods',
      },
      {
        id: 23,
        type: types.imagefulllength,
        image: steelimage7,
      },
      {
        id: 24,
        type: types.text,
        title: undefined,
        text: "There are different methods of steel manufacturing based on the technology used. Here's an explanation of the different routes of steel production and their uses-",
      },
      {
        id: 25,
        type: types.textwithpointsandsubpoints,
        title: undefined,
        data: [
          {
            heading: 'Blast Furnace-Basic Oxygen Furnace (BF-BOF) Route:',
            body: 'Uses: The BF-BOF route is the most common and traditional method of steel production. It is used for large-scale production of carbon steels and certain alloy steels. Process: The process involves the reduction of iron ore in a blast furnace to produce pig iron, which is then refined in a basic oxygen furnace (BOF) by blowing oxygen through the molten metal. This removes impurities and adjusts the carbon content to produce steel.Applications: BF-BOF steel is used in various applications such as construction, automotive manufacturing, appliances, infrastructure, and general-purpose structural applications.',
          },
          {
            heading: 'Electric Arc Furnace (EAF) Route:',
            body: 'Uses: The EAF route is known for its flexibility and versatility. It is primarily used for recycling scrap steel and producing specialty steels.Process: In the EAF process, scrap steel is melted in an electric arc furnace using electric arcs generated by graphite electrodes. Alloying elements and fluxes can be added to achieve the desired steel composition.Applications: EAF steel is used in applications such as construction, automotive components, pipelines, machinery, electrical appliances, and high-quality steel products requiring specific chemical compositions.',
          },
          {
            heading: 'Direct Reduced Iron (DRI) Route:',
            body: 'Uses: The DRI route is an alternative to traditional iron production methods. DRI is primarily used as a feedstock in steelmaking processes, such as EAF, to produce steel. It can also be used as a supplement or replacement for traditional iron sources in the BF-BOF route. Process: The DRI process involves the reduction of iron ore in the solid state, typically using natural gas or coal as a reducing agent. This produces direct reduced iron, which can be used as a raw material for steelmaking.Applications: DRI is used as a feedstock in EAF steelmaking to produce various steel grades. It provides flexibility in raw material selection and can contribute to lower greenhouse gas emissions and energy consumption compared to traditional iron production methods.',
          },
        ],
      },
      {
        id: 26,
        type: types.text,
        title: undefined,
        text: 'The selection of a steel production route depends on factors such as the desired steel grade, production scale, raw material availability, process flexibility, energy efficiency, and environmental considerations. Each route offers advantages and caters to different market demands and applications within the steel industry. Guidelines to choose the right material for your project.',
      },
      {
        id: 27,
        type: types.table,
        data: [
          { value1: 'Environmental Impacts', value2: 'Steel Rebar', value3: 'BOF Steel', value4: 'DRI Steel', value5: 'EAF Steel' },
          { value1: 'Global Warming Potential (GWP) (kg CO2-Eq)', value2: '2.6', value3: '2.8', value4: '2.1', value5: '0.83' },
          { value1: 'Embodied energy inc. ren. Feedstock (MJ)', value2: '30', value3: '24', value4: '38', value5: '9.9' },
          { value1: 'Ozone depletion potential (ODP)(kg R11 eq.)', value2: '1.5E-11', value3: '5.7E-12', value4: '1.2E-11', value5: '1.5E-11' },
          { value1: 'Acidification potential (kg SO2 eq.)', value2: '0.011', value3: '0.034', value4: '0.0066', value5: '0.0087' },
          { value1: 'Eutrophication potential(kg PO4 eq.)', value2: '1.09E-04', value3: '5.0E-04', value4: '1.3E-04', value5: '1.95E+00 (kg NMVOC-eq_*' },
          {
            value1: 'Photochemical ozone creation potential (POCP)(kg ethene eq.)',
            value2: '6.1E-04',
            value3: '1.5E-03',
            value4: '3.8E-04',
            value5: '4.7E-04',
          },
          {
            value1: 'SDG',
            value2: '12: Responsible Production and Consumption',
            value3: '12: Responsible Production and Consumption',
            value4: '12: Responsible Production and Consumption',
            value5: '12: Responsible Production and Consumption',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    iid: 'Wallblocks',
    title: 'Wall Blocks for Residential Apartments',
    data: [
      {
        id: 1,
        type: types.steppers,
        data: [
          { id: 1, title: 'Identify', text: 'a locally available wall block' },
          { id: 2, title: 'Check', text: 'project aesthetics and technical requirements' },
          { id: 3, title: 'Evaluate', text: 'heat insulation properties' },
          { id: 4, title: 'Choose', text: 'sustainably manufactured and disposable option' },
        ],
      },
      {
        id: 2,
        type: types.text,
        title: undefined,
        text: 'Choosing a wall block tailored to the climate zone, emphasizing thermal performance and weather resistance, significantly reduces maintenance and ensures energy savings. Investment in low environmental impact wall materials can go a long way to reduce your project’s carbon footprint and benefit the environment.',
      },
      {
        id: 3,
        type: types.heading,
        title: 'Technical Properties of Wall blocks',
        text: undefined,
      },
      {
        id: 4,
        type: types.table,
        data: [],
      },
      {
        id: 5,
        type: types.points,
        title: 'Types Of Wall Blocks Commonly Used in India:',
        points: [
          {
            type: types.text,
            icon: cementIcon1, //change
            title: `Concrete Blocks:`,
            text: ` Concrete masonry units (CMUs), are precast blocks made from a mixture of cement, aggregates (such as sand or gravel), and water.`,
          },
          {
            type: types.text,
            icon: cementIcon2, //change
            title: `Autoclaved Aerated Concrete (AAC):`,
            text: `AAC is a precast building material made from a mixture of sand, lime, cement, water, and a small amount of aluminum powder. AAC is lightweight and has thermal and sound insulation properties. AAC blocks are used for non-load-bearing walls. `,
          },
          {
            type: types.text,
            icon: cementIcon3, //change
            title: `Fly Ash Bricks: `,
            text: `Fly ash bricks are made from a mixture of fly ash (a byproduct of coal combustion), cement, sand, and water. Fly ash bricks are lightweight and have good thermal insulation properties. They are used as an eco-friendly alternative to traditional clay bricks`,
          },
          {
            type: types.text,
            icon: cementIcon4, //change
            title: `Compressed Stabilized Earth Blocks (CSEB): `,
            text: `  CSEBs are made from a mixture of soil, stabilizers (such as cement or lime), and water. The suitability of the available soil needs to be determined before use. CSEB blocks can be made directly on the site, reducing energy-intensive manufacturing processes, or purchased as precast blocks. They provide good thermal and sound insulation.`,
          },
          {
            type: types.text,
            icon: cementIcon5, //change
            title: `Agrocrete Blocks:`,
            text: `Agrocrete blocks are sustainable wall materials made from agricultural waste such as rice husks, wheat straw, or bagasse (sugarcane residue). These waste materials are combined with cement and water to form blocks. These blocks are lightweight and provide good thermal insulation.`,
          },
          {
            type: types.text,
            icon: cementIcon6, //change
            title: `Porotherm Blocks:  `,
            text: ` Porotherm blocks are characterized by their hollow, vertically perforated structure. Porotherm blocks offer good thermal insulation, sound insulation, and moisture resistance. They help maintain a comfortable indoor environment in regions with extreme outdoor temperature variations. They can be used for load-bearing and non-load-bearing walls.`,
          },
          {
            type: types.text,
            icon: cementIcon7, //change
            title: `Recycled Material Wall Blocks:`,
            text: `Recycled material wall blocks are typically a combination of concrete, aggregates, and waste materials such as crushed glass, plastic, or rubber. They can be used for non-structural walls, landscaping, or decorative purposes.`,
          },
        ],
      },
      {
        id: 6,
        type: types.heading,
        title: 'Guidelines to choose the right wall blocks for your project',
      },
      {
        id: 7,
        type: types.points,
        title: 'General Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Size and Weight: `,
            text: ` Consider the size and weight of the wall blocks as per the requirement.  Smaller dimension, lightweight blocks can be used for non-load bearing external walls and partitions. Make sure that they are easy to handle and transport`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Aesthetic Appeal:`,
            text: `Consider the aesthetic appeal of the wall block if it is an exposed wall. If the wall is to be plastered and painted, lower-grade wall blocks can be used`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Layering: ',
            text: `Different wall blocks can be used to create a composite wall assembly in order to meet different requirements not fulfilled by just one. Choose appropriate layering options to optimize comfort and appeal.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Cost-effectiveness: ',
            text: `Evaluate the cost of materials, installation, and long-term maintenance to ensure cost-effectiveness.`,
          },
        ],
      },
      {
        id: 8,
        type: types.points,
        title: 'Technical Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Compressive strength:`,
            text: `This is the ability of the wall block to withstand loads. Choose a wall block with a compressive strength as per the type of the wall (Load-bearing or non-load-bearing).`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Density:`,
            text: `It is typically measured in kilograms per cubic meter (Kg/m3). Higher-density blocks are stronger and can be used for load-bearing purposes. Low-density blocks are lightweight and can help reduce the dead weight on the structure (This will reduce the required quantity of structural steel and concrete).`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Workability:',
            text: `The ease with which the wall blocks can be handled, cut, and installed. Consider factors such as weight, surface finish, and ease of cutting or shaping during construction.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Thermal Insulation: ',
            text: ` Thermal Conductivity is the ability of wall blocks to resist heat transfer. Lower thermal conductivity helps to reduce heat loss or gain through the walls, maintaining comfortable indoor temperatures.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Sound Insulation:`,
            text: ` Sound Transmission Class (STC) is a rating of the ability of the wall blocks to reduce sound transmission. Higher STC ratings would mean lesser transmission. `,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Water Absorption:`,
            text: ` Wall blocks with low water absorption rates are preferred as they are less prone to damage from moisture, freeze-thaw cycles, and efflorescence. Low absorption promotes durability.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Fire Resistance:`,
            text: ` Fire rating is the duration for which the wall can withstand the fire. Building codes provide wall fire ratings of 30min, 60 min, and 90 min based on the space function enclosed within them.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Compliance with Standards:`,
            text: `  Ensure that the wall blocks meet relevant building codes, standards, and regulations for structural performance, fire safety, and energy efficiency.`,
          },
        ],
      },
      {
        id: 9,
        type: types.points,
        title: 'Environmental Impact Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Energy Efficiency:`,
            text: `Look for blocks with high thermal insulation properties to reduce energy consumption for heating or cooling the building. `,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Carbon Footprint: `,
            text: `Assess the embodied carbon of the wall blocks. This refers to the greenhouse gas emissions associated with the entire lifecycle of the blocks, including raw material extraction, manufacturing, transportation, and end-of-life disposal. Choose blocks with a lower embodied carbon to minimize environmental impact.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Water Management:',
            text: `Consider the water usage associated with the production of the wall blocks. Choose blocks manufactured with water-efficient processes or those that minimize water consumption during manufacturing.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Waste Management:',
            text: `Consider the waste generated during the manufacturing process. Look for manufacturers that have effective waste management practices, such as recycling or reusing production waste.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Recyclability and Reusability:`,
            text: ` Evaluate the end-of-life recycling potential of the material. Blocks that can be easily recycled or repurposed reduce waste generation and minimize the need for virgin materials in future construction projects.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Material Composition: `,
            text: ` Look for wall blocks made from sustainable raw materials. Materials like recycled concrete, fly ash, or reclaimed aggregates are preferable over blocks made from virgin materials..`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Local Sourcing and Manufacturing:`,
            text: `Opt for wall blocks that are locally sourced and manufactured. This reduces transportation distances, lowers carbon emissions from shipping, and supports the local economy.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Certification and Standards:`,
            text: `Look for wall blocks that adhere to recognized environmental certification schemes or standards. Example - GreenPro. Such certifications ensure that the blocks meet specific environmental performance criteria.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: undefined,
            text: `Consider factors such as raw material extraction, manufacturing, transportation, use, and disposal. Choose blocks taking into account their environmental impacts across the board.`,
          },
        ],
      },
      {
        id: 10,
        type: types.heading,
        title: 'Miscellaneous Information',
      },
      {
        id: 11,
        type: types.points,
        title: 'Some of the types of walls used in construction:',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Load Bearing Wall:`,
            text: `A load-bearing wall is an active structural element of a building that supports and holds the weight of a floor or roof structure by conducting its weight to a foundation structure below it.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Non-Load Bearing Wall: `,
            text: `Non-Load Bearing Wall is capable only of supporting its own weight and if used as an exterior wall it can resist the force of the wind blowing against it. Such walls are well suited for framed structures and used in both interior and exterior applications of buildings.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Shear Wall:',
            text: `A shear wall is a structural panel that is made of steel braced frames which can be very effective and can resist lateral forces acting on it. Particularly shear walls are of great importance in large, or high-rise buildings, or buildings in areas of high wind and seismic activity.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Retaining Wall: ',
            text: ` Retaining walls function as a supportive element to restrain soil to a slope that would not naturally keep it stable at the typical steep or vertical slope. The main purpose of constructing a retaining wall at a specific location is to hold soil behind them, depending on the project.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Brick Masonry Wall: `,
            text: ` Brick is one of the versatile and durable building materials as a result Brick masonry wall has been used in building construction for thousands of years with considerable modifications. Several building Materials used for its construction are Burnt clay bricks, sand-lime bricks (calcium silicate bricks), Concrete bricks, Fly ash clay bricks, and Firebrick.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Rubble Stone Masonry Wall:`,
            text: ` While Constructing Rubble Stone Masonry Walls, more or less large stones are arranged together to bound in such a way that the stones and the regular placement of stones extend through the thickness of the wall.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Core Wall: `,
            text: `Construction of this wall starts from the foundation of the building and the height as of the building. This wall functions as a column and shear wall.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Precast Wall: `,
            text: `Construction of Precast concrete walls is initiated by casting concrete in a reusable wall mold or form which is further cured in a controlled environment, transported securely to the construction site, and lifted into the place of installation.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Parapet Wall: `,
            text: `A parapet is usually the topmost reach of a wall that elongates above the roof level and provides a specific amount of protection to balconies, roofs, gutters, walkways of houses, etc.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Boundary Wall: `,
            text: `A boundary wall is a cast-in-situ or prefabricated structure available in a wide range of designs that serve as a boundary wall or fencing of immovable property.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Reinforced Brick Wall:`,
            text: `The requirement of reinforcement is in horizontal and vertical directions throughout the wall.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Cavity Wall:`,
            text: `The cavity wall is constructed into two stages inner and outer, with a cavity ranging from 50-100 mm. Among these, the interior walls are thinner and thicker walls are used as exterior elements.`,
          },
        ],
      },
      {
        id: 12,
        type: types.image,
        image: wallblocks, //change
        source: undefined,
      },
      {
        id: 13,
        type: types.table,
        data: [],
      },
    ],
  },
  {
    id: 4,
    iid: 'Paints',
    title: 'Paints for Residential Apartments',
    data: [
      {
        id: 1,
        type: types.steppers,
        data: [
          { id: 1, title: 'Identify', text: 'the type of paint according to the application and desired finish' },
          { id: 2, title: 'Choose', text: 'a paint that is non-toxic and easy to maintain' },
          { id: 3, title: 'Inquire', text: 'about the manufacturing process to assess environmental impact' },
          { id: 4, title: 'Select', text: 'the most sustainably produced paint product' },
        ],
      },
      {
        id: 2,
        type: types.text,
        title: undefined,
        text: 'The paint industry has historically been linked to environmental concerns, including volatile organic compounds (VOC) emissions and hazardous materials. However, modern advancements and eco-friendly practices now provide environmentally conscious options. To minimize the industry impact of your paint selection, focus on factors such as low VOC content, water-based formulations, responsible sourcing, and compliance with eco-friendly standards.',
      },
      {
        id: 3,
        type: types.heading,
        title: 'Technical properties Of steel',
      },
      {
        id: 4,
        type: types.table,
        data: [
          { value1: 'Properties', value2: 'Solvent-Based Paints (Default)', value3: 'Water-Based Paints (Recommended)' },
          { value1: 'Finish or Sheen', value2: 'Wide variety', value3: 'Wide variety' },
          { value1: 'Durability', value2: 'High', value3: 'Medium to high' },
          { value1: 'Coverage', value2: 'Excellent', value3: 'Good to excellent' },
          { value1: 'Color Retention', value2: 'Can yellow over time', value3: 'Excellent' },
          { value1: 'VOCs', value2: 'High (250-500 g/L)', value3: 'Low or zero (0-50 g/L)' },
          { value1: 'Mold and Mildew Resistance', value2: 'Good with additives', value3: 'Good with additives' },
          { value1: 'Application', value2: 'Can be trickier to apply', value3: 'Easier to apply' },
          { value1: 'Dry Time (hours)', value2: '6-8 (Touch Dry), 24 (Hard Dry)', value3: '1-2 (Touch Dry), 4 (Hard Dry)' },
          { value1: 'Stain Resistance', value2: 'Excellent', value3: 'Good to excellent' },
          { value1: 'Odor', value2: 'Stronger odor', value3: 'Less odor' },
          { value1: 'Cost', value2: 'Generally more expensive', value3: 'Generally less expensive' },
          { value1: 'Adhesion', value2: 'Good, especially on prepared surfaces', value3: 'Good, often better on a wider range of surfaces' },
        ],
      },
      {
        id: 5,
        type: types.subhead,
        title: 'Classification of Paints based on various aspects:',
      },
      {
        id: 6,
        type: types.textwithpointsandsubpoints,
        title: undefined,
        image: paintesimage1,
        imageindex: 1,
        data: [
          {
            heading: 'Composition: Paints can be categorized based on their composition.',
            body: 'Water-Based Paints: These paints have lower VOC levels, are eco-friendly, and dry quickly. Suitable for both interior and exterior applications.Oil-Based Paints: Provide superior durability and moisture resistance but also emit higher VOCs with longer drying time.Solvent-Based Paints: Known for excellent adhesion and durability but emit higher VOCs with longer drying time.Epoxy Paints: Epoxy paints have exceptional durability, chemical resistance, and adhesion, ideal for industrial applications.Enamel Paints: Offer durability, a glossy finish, and are used in areas requiring frequent cleaning.',
          },
          {
            heading: 'Finish Types: Different finish types offer unique aesthetics and functionality.',
            body: 'Flat/Matte Finish: Non-reflective, hides imperfections, and provides a subtle look.Eggshell Finish: Slightly glossy, durable, and suitable for moderate traffic areas.Satin Finish: Smooth with a soft sheen, ideal for high-traffic areas.Semi-Gloss Finish: Offers moderate glossiness, durability, and a sleek appearance.Glossy/High Gloss Finish: Highly reflective and shiny, providing a striking effect.',
          },
          {
            heading: 'Application: Paints should be chosen based on their intended use. ',
            body: 'Interior Paints: For indoor surfaces, with various finish options like matte, eggshell, satin, and semi-gloss.Exterior Paints: Designed to withstand outdoor conditions, providing resistance against weather, UV radiation, and moisture.Wood Finishes: Enhance the natural beauty of wood while protecting it from environmental factors.Metal Paints: Provide protection against rust, corrosion, and environmental elements.Masonry Paints: Designed for masonry surfaces and offer durability and weather resistance.Specialized Paints: Tailored for specific needs, such as anti-microbial, anti-graffiti, heat-resistant, and floor paints.',
          },
        ],
      },
      {
        id: 7,
        type: types.heading,
        title: 'Guidelines to choose the right material for your project',
      },
      {
        id: 8,
        type: types.heading,
        title: 'General Factors:',
      },
      {
        id: 9,
        type: types.textwithpoints,
        data: [
          'Purpose: Determine the purpose of the paint, whether it is for protection, decoration, or both. This will help guide your selection and determine the specific requirements of the paint.',
          'Surface Type: Identify the type of surface you will be painting, such as interior walls, exterior walls, ceilings, trim, or specific materials like wood, metal, or masonry. Different surfaces may require different types of paint or surface preparation.',
          'Environmental Conditions: Consider the environmental conditions the painted surface will be exposed to. For exterior surfaces, think about factors such as sunlight, rain, wind, temperature fluctuations, and pollution levels. Interior surfaces may have specific requirements based on the room/s function, such as moisture-prone areas like bathrooms or high-traffic areas like hallways.',
          'Ventilation: Maintain adequate ventilation to disperse paint fumes and maintain air quality.',
          'Safety Data Sheets (SDS): Familiarize yourself with the SDS for each paint product to understand its hazards, handling, and first-aid measures.',
          'Aesthetics: Determine the desired color, finish, and overall aesthetics of the building. Consider the building`s architectural style, design preferences, surrounding environment, and any branding or visual identity requirements.',
          'Ease of Application: Consider paints that are easy to apply and provide good coverage. Paints with good flow and leveling properties can result in a smoother finish, reducing the appearance of brush or roller marks.',
          'Maintenance: Evaluate the maintenance requirements of the paint. Some paints are easier to clean and maintain, allowing for simple touch-ups or cleaning without significant effort or expense.',
          `Manufacturer's Warranty: Check if the paint manufacturer offers a warranty or guarantee for their product. A warranty can provide assurance about the quality and performance of the paint, giving you peace of mind.`,
        ],
      },
      {
        id: 10,
        type: types.heading,
        title: 'Properties',
      },
      {
        id: 11,
        type: types.textwithpoints,
        image: paintimage2,
        imageindex: 1,
        data: [
          `Durability: Look for paints that offer excellent durability and can withstand the rigors of the building environment. Consider paints that are resistant to weathering, abrasion, fading, chipping, and cracking. Durable paints will ensure a longer lifespan and reduced maintenance costs.`,
          `VOC Content: Consider the volatile organic compound (VOC) content of the paint. Low VOC or zero VOC paints emit fewer harmful chemicals, promoting better indoor air quality, reducing human health impacts and environmental impact.`,
          `Adhesion: Choose paints that have good adhesion properties, particularly for the specific surfaces you are painting. The paint should adhere well to the substrate and form a strong bond to prevent peeling or flaking over time.`,
          `Breathability: It's important to select paints that allow the building materials to breathe. Breathable paints allow moisture vapor to escape from the substrate, preventing moisture buildup and potential damage, such as blistering or mold growth.`,
          `Coverage: Assess the paint's coverage capacity, which refers to how well it conceals the underlying surface. Cosse paints with good coverage reduce the number of coats required and provide a more uniform and attractive finish.`,
          `Washability and Stain Resistance: For areas prone to dirt, moisture, or frequent cleaning, choose paints with good washability and stain resistance. These paints are easier to clean and maintain, preserving the building's appearance.`,
          `Color Retention: Consider the paint's ability to retain its color over time. High-quality paints with good color retention properties resist fading or yellowing, ensuring long-lasting vibrancy.`,
          `UV Resistance: If the painted surfaces will be exposed to direct sunlight, consider paints that offer high UV resistance. UV-resistant paints will prevent color fading and help maintain the paint's appearance over time.`,
          `Fire Resistance: In certain applications where safety is a priority, fire-resistant paints may be required. These paints are designed to retard the spread of flames and contribute to fire safety.`,
          `Drying Time: Evaluate the paint's drying time, especially if you have time constraints or require a quick turnaround for the project.`,
          `Resistance to Mold and Mildew: In areas with high humidity, choose paints that offer resistance to mold and mildew growth. These paints contain additives that inhibit the development of fungal growth, contributing to a healthier indoor environment.`,
          `Application Method: Consider the application method you will be using, whether it's brushing, rolling, or spraying. Some paints may be better suited for specific application techniques.`,
        ],
      },
      {
        id: 12,
        type: types.text,
        title: undefined,
        text: 'By considering these general and technical factors, you can make an informed decision when selecting paint for buildings, ensuring that it meets the specific requirements of the project and delivers the desired results.',
      },
      {
        id: 13,
        type: types.image,
        image: paintiamge3,
        source: 'Source: Climate connection',
      },
      {
        id: 14,
        type: types.heading,
        title: 'Environmental Impact Factors',
      },
      {
        id: 15,
        type: types.textwithpoints,
        data: [
          `Low VOC Formulations: Opt for paints with low levels of volatile organic compounds (VOCs) to improve indoor air quality and reduce harmful emissions.`,
          `Water-Based Paints: Choose water-based paints for eco-friendliness, as they have lower VOC content, quick drying times, and easy water cleanup.`,
          `Recycled Content: Look for paints with recycled content, reducing the need for new resources and conserving energy.`,
          `Eco-Friendly Certifications: Consider paints certified by organizations like Green Seal or EcoLogo, indicating adherence to eco-friendly standards.`,
          `Waste Reduction: Support manufacturers that employ efficient waste management practices to minimize environmental impact.`,
          `Water Efficiency: Choose paints from manufacturers with water-efficient processes to reduce water consumption during production.`,
        ],
      },
      {
        id: 16,
        type: types.heading,
        title: 'Miscellaneous Information',
      },
      {
        id: 17,
        type: types.textwithpointsandsubpoints,
        title: undefined,
        data: [
          {
            heading: 'Specialized Purpose: Paints can serve specific needs in construction.',
            body: 'Primer Paints: Prepare surfaces for the topcoat, improve adhesion, and enhance durability.Anti-Corrosive Paints: Protect metal surfaces from corrosion and rust.Fire-Retardant Paints: Delay the spread of flames and create a fire-resistant barrier.Thermal Insulating Paints: Provide thermal insulation to reduce heat transfer.Waterproofing Paints: Prevent water penetration and moisture-related issues.Anti-Microbial Paints: Inhibit the growth of bacteria, mold, and mildew.Traffic Marking Paints: Specifically formulated for road and traffic markings.',
          },
        ],
      },
      {
        id: 18,
        type: types.image,
        image: paintimage4,
        source: 'Source: Homes and garden',
      },
      {
        id: 19,
        type: types.heading,
        title: 'Environmental Impacts of Paints',
      },
      {
        id: 20,
        type: types.table,
        data: [
          { value1: 'Name', value2: 'Solvent Based', value3: 'Water Based' },
          { value1: 'GWP (Global Warming Potential)(kg CO2 eq.)', value2: '4.79', value3: '3.73' },
          { value1: 'Embodied energy inc. ren. Feedstock (MJ)', value2: '', value3: '' },
          { value1: 'Ozone depletion potential (ODP)(kg R11 eq.)', value2: '3.88E-07', value3: '6.55E-08' },
          { value1: 'Acidification potential (kg SO2 eq.)', value2: '1.07', value3: '0.68' },
          { value1: 'Eutrophication potential(kg PO4 eq.)', value2: '9.04E-03', value3: '2.11E-03' },
          { value1: 'Smog Formation (kg O3e / sqm)', value2: '1.41', value3: '0.21' },
          { value1: 'SDG', value2: '12: Responsible Production and Consumption', value3: '12: Responsible Production and Consumption' },
        ],
      },
    ],
  },
  {
    id: 3,
    iid: 'Glazing',
    title: 'Glazing for Residential Apartments',
    data: [
      {
        id: 1,
        type: types.steppers,
        data: [
          { id: 1, title: 'Identify', text: 'the type of glazing requirement' },
          { id: 2, title: 'Check', text: 'the technical factors for ensuring visual comfort' },
          { id: 3, title: 'Inquire', text: 'about the production method to assess the environmental impact' },
          { id: 4, title: 'Choose', text: 'the most performative and sustainable option' },
        ],
      },
      {
        id: 2,
        type: types.text,
        title: undefined,
        text: 'Glazing gives aesthetic appeal to a building and is essential for daylight and ventilation of indoor spaces. It is important to choose the right glazing for your building as it significantly affects energy consumption depending on various factors. Here is a comprehensive guide to help you select the right glazing option for your project.',
      },
      {
        id: 3,
        type: types.heading,
        title: 'Technical Properties of Glazing',
        text: undefined,
      },
      {
        id: 4,
        type: types.table,
        data: [],
      },
      {
        id: 5,
        type: types.image,
        image: glazingimage1,
        source: 'Source: Archdaily (Courtesy: Vitrocsa)',
      },
      {
        id: 6,
        type: types.image,
        image: glazingimage2,
        source: 'Source: Reports and Data',
      },
      {
        id: 7,
        type: types.heading,
        title: 'Types of glazing commonly used in India:',
        text: undefined,
      },
      {
        id: 8,
        type: types.points,
        title: 'Glazing Units:',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Single Glazed Unit (SGU)`,
            text: ` Single-glazed glass windows comprise a single layer of glass. Such windows allow more heat loss and maximum transmission of natural light.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Double Glazed Unit (DGU)`,
            text: `Double-glazing windows are designed with two glass panes, facilitating better thermal insulation and noise control. Additional insulation is achieved by the gap between the two glass layers, often filled with inert gases such as Argon. The ability to let in natural light is similar to that of single-glazed glass windows.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Triple Glazed Unit (DGU)`,
            text: `Triple glass glazing windows provide maximum energy efficiency as they comprise three glass panes. The presence of additional panes helps in noise reduction. They work better in providing insulation compared to single-pane glass windows. Such windows are preferred for constructing French windows or large-sized windows, ensuring improved protection.`,
          },
        ],
      },
      {
        id: 9,
        type: types.points,
        title: 'Glazing Materials:',
        points: [
          {
            type: types.text,
            icon: glazingimage3,
            title: `Clear Float Glass:`,
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: ` Description: `,
                text: `Clear float glass is a basic and affordable glazing option widely used in construction. 
                Properties:  It offers moderate thermal insulation. It allows good natural light penetration       
                Suitable Applications: Clear float glass is suitable for interior applications where minimal insulation is required and in mild climate conditions.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Properties: `,
                text: `Properties:  It offers moderate thermal insulation. It allows good natural light penetration       
                Suitable Applications: Clear float glass is suitable for interior applications where minimal insulation is required and in mild climate conditions.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Suitable Applications:`,
                text: `Suitable Applications: Clear float glass is suitable for interior applications where minimal insulation is required and in mild climate conditions.`,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: `Reflective Glass:`,
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: ` Description: `,
                text: `Reflective glass, also known as solar control glass, has a metallic coating that reflects a portion of the solar radiation. It comes in various colors and shades.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Properties: `,
                text: `Reflective glass has a lower solar heat gain coefficient (SHGC), reducing heat gain from the sun. It offers moderate thermal insulation. It provides good glare control and reduces visible light transmission (VLT). However, the reflective coating may impact the external appearance.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Suitable Applications:`,
                text: `Reflective glass is suitable for buildings in hot climates. It can help reduce cooling loads and maintain visual comfort.`,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Low-E (Low-Emissivity) Glass:',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: ` Description: `,
                text: `Low-E glass is coated with a microscopically thin, transparent layer that reflects heat radiation while allowing visible light to pass through.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Properties: `,
                text: `Low-E glass offers enhanced thermal insulation with a low U-value. It has a high VLT, allowing ample daylighting. It provides good solar control by reflecting a significant amount of infrared heat radiation. It helps maintain a comfortable indoor environment and can improve energy efficiency. `,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Suitable Applications:`,
                text: `Low-E glass is suitable for climates with extreme temperatures, where minimizing heat loss or gain is essential.`,
              },
            ],
          },
          {
            type: types.text,
            icon: glazingimage4,
            title: 'Tinted Glass: ',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: `Description: `,
                text: `Tinted glass is produced by adding colorants to the glass during manufacturing, resulting in various shades and levels of opacity.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Properties: `,
                text: `Tinted glass offers moderate thermal insulation with a moderate U-value. It reduces solar heat gain and glare by absorbing or reflecting a portion of the solar radiation. It also reduces visible light transmission (VLT) and provides increased privacy. However, it may slightly darken the interior space. `,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Suitable Applications:`,
                text: `Tinted glass is suitable for areas that require solar control, privacy, and reduced glare, such as windows facing direct sunlight.`,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Laminated Glass:',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: `Description: `,
                text: `Laminated glass consists of two or more glass layers bonded together with an interlayer of polyvinyl butyral (PVB) or ethylene-vinyl acetate (EVA) resin.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Properties: `,
                text: `Laminated glass offers enhanced safety and security. When broken, the interlayer holds the glass fragments together, reducing the risk of injury. It also provides sound insulation benefits. Laminated glass can be manufactured with varying thicknesses to meet specific requirements. `,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Suitable Applications:`,
                text: `Laminated glass is suitable for areas requiring increased safety and security, such as storefronts, areas near high-traffic zones, or buildings in noise-sensitive environments.`,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Toughened Glass:',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: `Description: `,
                text: `Toughened glass, also known as tempered glass, undergoes a process of controlled thermal treatment to increase its strength compared to regular glass.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Properties: `,
                text: `Toughened glass has improved impact resistance and is less prone to breakage. When shattered, it breaks into small, relatively harmless pieces`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Suitable Applications:`,
                text: `Toughened glass is suitable for creating interior partitions, and glass doors, and is used as glazing in places where safety is concerned. Easy to maintain, It has improved resistance to environmental factors such as UV radiation.`,
              },
            ],
          },
          {
            type: types.text,
            icon: glazingimage5, //add
            title: 'Acoustic Glass:',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: `Description: `,
                text: ` Glass with enhanced sound insulation properties.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: ` Properties: `,
                text: ` Acoustic glass is used for reduced noise transmission.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Suitable Applications:`,
                text: ` It is suitable for buildings near high-noise areas, recording studios, and conference rooms.`,
              },
            ],
          },
        ],
      },
      {
        id: 10,
        type: types.heading,
        title: 'Guidelines to choose the right glazing for your project',
        text: undefined,
      },
      {
        id: 11,
        type: types.points,
        title: 'General Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Thickness and Size:`,
            text: `When selecting glazing, consider the appropriate thickness as per the requirement. For sliding/patio doors or glass facades, the pane thickness is usually higher for better stability.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Frame:`,
            text: `Pairing the right frame with glazing enhances thermal efficiency significantly. Additionally, prioritize environmental impact, favoring recyclable options like PVC/UPVC or eco-friendly hardwood frames.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Aesthetics and Design: ',
            text: `Consider the architectural style and design intent of the building when selecting glazing materials, including color, transparency, and patterns. Some glazing materials also allow for customization, such as decorative or frosted glass, to add aesthetic value to the building.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Maintenance and Durability: ',
            text: `  Assess the glazing material's resistance to weather conditions prevalent in the project's location. Consider the long-term durability of the material, including resistance to scratches, corrosion, and degradation.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Cost and Budget: `,
            text: ` Balance the desired properties and performance of glazing materials with the project's budgetary constraints. Evaluate the life cycle costs of the chosen glazing solution. `,
          },
        ],
      },
      {
        id: 12,
        type: types.points,
        title: 'Technical Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: ` Thermal Performance and Energy Efficiency:`,
            text: ``,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: `U-Value (Thermal Transmittance): `,
                text: `U value is the measure of the insulating capacity of the glass. Lower U-values indicate better insulation. Choose glazing with low U-values to minimize heat loss or gain.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Solar Heat Gain Coefficient (SHGC): `,
                text: `SHGC indicates how much of the sun's energy is transmitted through the window as heat. Lower SHGC values reduce solar heat gain. Consider the climate and orientation of the building to determine the appropriate SHGC for energy efficiency.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Insulation: `,
                text: `Look for glazing materials with enhanced thermal insulation properties to minimize heat transfer. Double or triple-glazing with air or gas-filled spaces between the panes provides additional insulation.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Low-E (Low-Emissivity) Coating:  `,
                text: ` Coatings that reflect infrared heat radiation can improve energy efficiency by reducing heat gain or loss.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: `Orientation:  `,
                text: ` Select the glazing as per the orientation. The northern facade experiences lower heat gain in India, hence a glazing with lower thermal performance can be chosen. Alternatively, for the other facades, look for high-performance glazing. `,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: `Day-lighting and Visual Comfort: `,
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: 'Visible Light Transmittance (VLT): ',
                text: `VLT refers to the amount of visible light that passes through the glazing material. Higher VLT values allow more natural daylight to enter the building, reducing the need for artificial lighting.`,
              },
              {
                type: types.text,
                icon: undefined,
                title: 'Glare Control:',
                text: `Consider the use of glazing with appropriate tinting, reflective coatings, or shading devices to control glare and ensure occupant comfort.`,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Sound Insulation:',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: 'Sound Transmission Class (STC):',
                text: `Determine the level of sound insulation required for the project. Select glazing with higher STC values for noise-sensitive environments or buildings located in high-noise areas.`,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Safety and Security: ',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: undefined,
                title: 'Impact Resistance: ',
                text: `Depending on the building's location and potential hazards, consider using laminated or toughened glass for increased impact resistance.`,
              },
            ],
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Fire Safety:  ',
            text: undefined,
            points: [
              {
                type: types.text,
                icon: glazingimage6, //change
                title: 'Fire Rating: ',
                text: `Ensure compliance with local fire safety regulations. Choose fire-rated glazing materials where required, such as fire-resistant glass.`,
              },
            ],
          },
        ],
      },

      {
        id: 13,
        type: types.image,
        image: glazingimage111,
        source: `Source: buildinggreen.com`,
      },
      {
        id: 14,
        type: types.points,
        title: 'Environmental Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Embodied Energy:`,
            text: `The embodied energy of glazing refers to the total energy consumed during the entire life cycle of the glass, including raw material extraction, manufacturing, transportation, installation, and disposal. Choose glazing with lower embodied energy to minimize the environmental impact associated with energy consumption.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Greenhouse Gas Emissions:`,
            text: `Assess the greenhouse gas emissions associated with the production of glazing materials. Consider glass manufacturers that employ energy-efficient processes, utilize renewable energy sources, or have implemented carbon reduction strategies to minimize carbon dioxide (CO2) and other greenhouse gas emissions.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Water Management:',
            text: `Consider the water usage associated with the production of glazing. Choose blocks manufactured with water-efficient processes or those that minimize water consumption during manufacturing.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: 'Waste Management:',
            text: `  Consider the waste generated during the manufacturing process. Look for manufacturers that have effective waste management practices, such as recycling or reusing production waste.`,
          },
          {
            type: types.text,
            icon: glazingimage7,
            title: `Recyclability and Reusability: `,
            text: ` Consider the recyclability of glazing materials. Opt for glass products that can be easily recycled at the end of their life cycle, reducing the need for landfill disposal and promoting a circular economy. Additionally, assess the potential for reusing or repurposing glazing materials to further minimize waste. `,
          },
          {
            type: types.text,
            icon: glazingimage8,
            title: `Energy Efficiency and Thermal Performance: `,
            text: `Choose glazing with excellent thermal performance as per your climate zone and building’s space function. Appropriate VLT, SHGC, and U-value can help reduce the energy demand for artificial lighting, heating, and cooling in buildings, leading to lower energy consumption and associated environmental impacts. It will also reduce your electricity bill!`,
          },
          {
            type: types.text,
            icon: glazingimage9,
            title: `Indoor Environmental Quality:  `,
            text: ` Evaluate the impact of glazing on indoor environmental quality. Look for glass products that offer good acoustic insulation properties to minimize noise pollution. Additionally, consider coatings or treatments that reduce ultraviolet (UV) radiation transmission, protecting occupants and interior materials.`,
          },
          {
            type: types.text,
            icon: glazingimage9,
            title: `Certification and Standards:`,
            text: ` Look for glazing that meets the benchmarks for VLT, SHGC, and U-value, as per your climate type, from recognized standards such as Eco Niwas Samhita.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: ``,
            text: `Consider factors such as energy consumption, greenhouse gas emissions, raw material extraction, water usage, and waste generation throughout the life cycle of the glass. Making informed decisions when selecting glazing for a construction project reduces the project's overall environmental footprint.`,
          },
        ],
      },
      {
        id: 15,
        type: types.image,
        image: glazingimage10,
        source: undefined,
      },
      {
        id: 16,
        type: types.heading,
        title: 'Environmental Impact of Glazing',
        text: undefined,
      },
      {
        id: 17,
        type: types.table,
        data: [],
      },
    ],
  },
  {
    id: 4,
    iid: 'Interior Flooring',
    title: 'Interior Flooring for Residential Apartments',
    data: [
      {
        id: 1,
        type: types.steppers,
        data: [
          { id: 1, title: 'Assess', text: 'the need for each space' },
          { id: 2, title: 'Consider', text: ' durability, traffic and maintenance ' },
          { id: 3, title: 'Check', text: 'for low-VOC, high recycled content and renewable material options' },
          { id: 4, title: 'Select', text: 'the most sustainable option with lowest carbon footprint' },
        ],
      },
      {
        id: 2,
        type: types.text,
        title: undefined,
        text: `Choosing flooring materials that are tailored to your specific climate zone can have a significant impact on your construction project. Emphasizing factors like thermal performance and weather resistance not only reduces maintenance needs but also leads to energy savings, promoting sustainability and environmental responsibility. Opting for flooring options with a low environmental impact can help minimize your project's carbon footprint and benefit the environment.`,
      },
      {
        id: 3,
        type: types.heading,
        title: 'Commonly Used Flooring Types in Residential Construction ',
        text: undefined,
      },
      {
        id: 4,
        type: types.table,
        data: [],
      },
      {
        id: 5,
        type: types.heading,
        title: 'Technical Properties of Commonly used Indoor Flooring Materials',
        text: undefined,
      },
      {
        id: 6,
        type: types.table,
        data: [],
      },
      {
        id: 7,
        type: types.points,
        title: 'Types Of Flooring Materials ',
        points: [
          {
            type: types.text,
            icon: interiorflooring1, //available
            title: `Hardwood:`,
            text: ` Hardwood flooring is made from solid wood planks or strips. It’s durable, lasts for decades, and can be refinished multiple times. However , it can scratch and get water damage.`,
          },
          {
            type: types.text,
            icon: interiorflooring2, //available
            title: `Marble`,
            text: ` It’s highly porous and fairly easy to maintain, sometimes lasting more than 25 years. Marble is not stain-resistant and requires proper sealing. Due to its natural occurrence and limited availability compared to other types of flooring, marble may be priced steeply`,
          },
          {
            type: types.text,
            icon: interiorflooring3, //available
            title: `Vitrified tile`,
            text: ` It’s affordable and suitable for both interior and exterior use. Generally coated with an external glaze, these tiles have a low water-absorption rate and are durable.`,
          },
          {
            type: types.text,
            icon: interiorflooring4, //available
            title: `Laminate`,
            text: ` Laminate flooring is a synthetic product that mimics the look of wood, stone, or tile, topped with a clear protective layer. It’s affordable, durable, scratch-resistant and easy to clean. But it may not withstand heavy foot traffic or water damage.`,
          },
          {
            type: types.text,
            icon: interiorflooring5, //available
            title: `Vinyl`,
            text: `Vinyl flooring is made from PVC (polyvinyl chloride) and other synthetic materials It’s synthetic, affordable, easy to maintain, and moisture-resistant. But it may emit VOCs and not be as durable as other materials.`,
          },
          {
            type: types.text,
            icon: interiorflooring6, //available
            title: `Ceramic or porcelain tile`,
            text: `They are durable and water-resistant, making them a popular choice for bathrooms and kitchens.`,
          },
          {
            type: types.text,
            icon: interiorflooring7, //available
            title: `Carpet`,
            text: ` Carpet is a soft and comfortable flooring option that can be made from synthetic or natural materials such as an affordable alternative to solid hardwood flooring. `,
          },
          {
            type: types.text,
            icon: interiorflooring8, //available
            title: `Cork`,
            text: ` Cork is soft, durable, and provides good insulation, sustainable & eco-friendly flooring options. `,
          },
          {
            type: types.text,
            icon: interiorflooring9, //available
            title: `Bamboo`,
            text: ` Bamboo flooring is made from fast-growing bamboo plants and is a sustainable and eco-friendly flooring option. It is durable, easy to maintain, and has a unique look. `,
          },
          {
            type: types.text,
            icon: interiorflooring10, //available
            title: `Engineered wood`,
            text: ` It is made from layers of wood veneer and plywood that are bonded together. It is an alternative to solid hardwood flooring.`,
          },
          {
            type: types.text,
            icon: interiorflooring11, //available
            title: `Granite`,
            text: ` Due to its high stain resistance and durability, this is a type of flooring that’s fairly easy to maintain. It maintains a cool temperature.`,
          },
          {
            type: types.text,
            icon: interiorflooring12, //available
            title: `Athangudi tiles`,
            text: ` Athangudi tiles are cement tiles that are handmade over glass surfaces. Athangudi tiles flooring is also eco-friendly and made of locally-available sand, cement and naturally occurring oxides.`,
          },
          {
            type: types.text,
            icon: interiorflooring13, //available
            title: `Terrazzo`,
            text: `Terrazzo flooring is a type of interior flooring known for its unique and decorative appearance. It is created by embedding chips of marble, quartz, glass, or other aggregates into a binder, such as cement or epoxy resin.Terrazzo flooring is highly durable, able to withstand heavy foot traffic, scratches, and stains. It requires low maintenance, typically needing only regular sweeping and damp mopping to keep it clean. Its longevity makes it a cost-effective option in the long run.`,
          },
        ],
      },
      {
        id: 8,
        type: types.heading,
        title: 'Guidelines to choose the right wall blocks for your project',
        text: undefined,
      },
      {
        id: 9,
        type: types.points,
        title: 'General Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Subfloor Inspection`,
            text: ` Ensure your subfloor is in good condition, free from moisture or structural issues, to prevent future tile problems.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Installation by Professionals`,
            text: `  Tile installation requires skill. Hire experienced professionals to ensure proper installation and avoid issues like uneven tiles.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Tile Handling`,
            text: ` Handle tiles with care to prevent breakage or chipping during transportation and installation.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Safety Measures`,
            text: ` Consider slip-resistant tiles for areas prone to moisture or spills, like bathrooms and kitchens, to enhance safety.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Tile Layout`,
            text: `Plan the tile layout carefully to avoid awkward cuts or misalignment, ensuring a polished look`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Proper Grouting`,
            text: `Adequate grouting and sealing are essential to prevent water seepage and maintain cleanliness.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Installation Method`,
            text: ` Decide on the installation method, whether it's adhesive, mortar, or a click-and-lock system`,
          },
        ],
      },
      {
        id: 10,
        type: types.points,
        title: 'Technical Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Material Type:`,
            text: ` Choose between materials like ceramic, porcelain, natural stone, vinyl, or hardwood based on your project's needs and aesthetic preferences.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Abrasion Resistance: `,
            text: ` Evaluate a tile's ability to withstand foot traffic and wear over time, especially in high-traffic areas.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Water Absorption: `,
            text: ` Consider the tile's water absorption rate, as it affects its suitability for wet areas like bathrooms and kitchens.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Slip Resistance:`,
            text: ` Ensure that the tile provides adequate slip resistance, particularly in areas prone to moisture or spills.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Hardness:`,
            text: `Assess the tile's hardness to determine its resistance to scratching and indentation.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Stain Resistance:`,
            text: `Look for tiles that are resistant to staining and easy to clean, especially in areas prone to spills.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Thermal Conductivity: `,
            text: ` Consider how well the tile conducts heat, which can impact its suitability for underfloor heating systems.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Sound Insulation:  `,
            text: ` Evaluate the tile's ability to reduce noise transmission between floors or rooms.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Fire Resistance: `,
            text: `Check the tile's fire rating to ensure it complies with safety standards.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Dimensional Stability:`,
            text: ` Assess the tile's ability to resist changes in size or shape due to temperature and humidity fluctuations.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Chemical Resistance: `,
            text: ` Consider the tile's resistance to chemical spills and ease of cleaning.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Color and Pattern:`,
            text: `  Choose colors and patterns that align with your design vision.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Tile Size and Shape: `,
            text: `Select the right size and shape of tiles to match your space.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Surface Finish: `,
            text: ` Determine the finish that suits your aesthetic and practical requirements.`,
          },
        ],
      },
      {
        id: 11,
        type: types.points,
        title: 'Environmental Impact Factors',
        points: [
          {
            type: types.text,
            icon: undefined,
            title: `Embodied Energy:`,
            text: ` Choose flooring with lower embodied energy, as it indicates reduced energy consumption and associated greenhouse gas emissions.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Carbon Footprint:  `,
            text: ` Consider the carbon footprint associated with the flooring. This includes emissions from raw material extraction, manufacturing, transportation, installation, maintenance, and end-of-life management. Select flooring options with lower embodied carbon such as those made from renewable or recycled materials.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Recycled Content:  `,
            text: `Look for flooring options that incorporate recycled content, such as recycled wood or plastic. This reduces the demand for virgin materials and diverts waste from landfills.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Water management: `,
            text: `Consider the water usage associated with the production of the wall blocks. Choose tiles manufactured with water-efficient processes or those that minimize water consumption during manufacturing.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Waste Management:`,
            text: `Consider the potential for recycling or proper disposal at the end of the flooring's life. Choose materials that can be recycled or repurposed, reducing waste generation. Additionally, explore flooring options that are biodegradable or compostable.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Durability and Maintenance:`,
            text: `Select flooring materials that are durable and require minimal maintenance. Longer-lasting flooring reduces the need for frequent replacements, saving resources and reducing waste generation.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Material Sustainability:`,
            text: ` Look for flooring materials that are environmentally friendly and sustainable. Consider options such as bamboo, cork, reclaimed wood, or linoleum, which are renewable and have a lower environmental impact compared to materials like vinyl or carpet.`,
          },
          {
            type: types.text,
            icon: undefined,
            title: `Transportation:   `,
            text: `Consider the distance the flooring materials need to travel to reach your location. Choosing locally sourced materials can help reduce the carbon footprint associated with transportation`,
          },
          {
            type: types.text,
            icon: undefined,
            title: undefined,
            text: `By taking these technical factors into account, you can make a more environmentally sound decision when selecting flooring, considering its entire life cycle and minimizing its environmental impact.`,
          },
        ],
      },
      {
        id: 12,
        type: types.image,
        image: interiorflooring14, //change
        source: `Source: Arch Daily `,
      },
      {
        id: 13,
        type: types.table,
        data: [],
      },
    ],
  },
];
