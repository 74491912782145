import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import Vector from '../../../../../assets/Vector279.svg';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';
import AddCustomIntervention from './AddCustomIntervention';
// import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '0%',
    flexGrow: '1',
  },
  heading: {
    marginTop: '3%',
    width: '90%',
    marginLeft: '3%',
  },

  smallpaper1: {
    width: '85%',
    height: '10vh',
    padding: theme.spacing(1.5, 2, 0, 3),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
    background: '#66E3BE',
    borderRadius: '10px',
  },
  smallpaper2: {
    width: '85%',
    height: '10vh',
    borderRadius: '10px',
    padding: theme.spacing(0.2, 2, 0, 3),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
  },
  textColor: {
    color: 'rgba(255, 255, 255, 0.82)',
  },
  content: {
    marginTop: '28%',
  },
}));

function InterventionOverview({
  financeSavings,
  resourceSavings,
  handleRefresh,
  editCustomintervention,
  editCustominterventionID,
  editModalClose,
  deselectID,
  openBackdrop,
  handleCloseBackdrop,
  handleOpenBackdrop,
}) {

  const classes = useStyles();

  return (
    <>
      <Grid
        container
        alignItems="left"
        item
        xs={12}
      // style={{ marginTop: '1%' }}
      >
        <Grid
          // container
          item
          xs={5}
          align="left"
          direction="column"
        >
          <Typography variant="h5">
            <b>Financial Impact (₹ cr)</b>
          </Typography>
          <Paper className={classes.smallpaper1}>
            <Grid container alignItems="center" item xs={12} direction="row">
              <Grid container xs={4} direction="column">
                <Typography variant="h6" color="textSecondary" className={classes.textColor}>
                  Capex Increase
                </Typography>
                <Grid xs style={{ marginTop: '-5%' }}></Grid>
                <Typography variant="h5" className={classes.textColor}>
                  <b>
                    {financeSavings && financeSavings.capex ? (
                      rsToCr('Rs', financeSavings.capex.value)
                    ) : (
                      <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
                    )}
                  </b>
                </Typography>
              </Grid>
              <Grid container xs={4} direction="column">
                <Typography variant="h6" color="textSecondary" className={classes.textColor}>
                  Total Capex
                </Typography>
                <Grid xs style={{ marginTop: '-5%' }}></Grid>
                <Typography variant="h5" className={classes.textColor}>
                  <b>
                    {financeSavings && financeSavings.totalCapex ? (
                      rsToCr('Rs', financeSavings?.totalCapex?.value)
                    ) : (
                      <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
                    )}
                  </b>
                </Typography>
              </Grid>
              {/* <Grid item xs={1}></Grid> */}
              <Grid container xs={4} direction="column">
                <Typography variant="h6" color="textSecondary" className={classes.textColor}>
                  Annual Savings
                </Typography>
                <Grid xs style={{ marginTop: '-5%' }}></Grid>
                <Typography variant="h5" className={classes.textColor}>
                  <b>
                    {financeSavings && financeSavings.capex ? (
                      rsToCr('Rs', financeSavings.opexSavings.value)
                    ) : (
                      <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
                    )}
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid
          // container
          item
          xs={5}
          align="left"
          direction="column"
        >
          <Typography variant="h5">
            <b>Carbon Savings</b>
          </Typography>
          <Paper className={classes.smallpaper2}>
            <Grid container alignItems="center" item xs={12} direction="row">
              <Grid item xs={6}>
                <Typography variant="h5">
                  <b>
                    {resourceSavings && resourceSavings.carbon ? (
                      numberWithCommas(resourceSavings.carbon.value)
                    ) : (
                      <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" />
                    )}
                  </b>{' '}
                  {resourceSavings && resourceSavings.carbon ? resourceSavings.carbon.unit : null}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <img alt="v" src={Vector} style={{ height: 'auto' }}></img>
              </Grid>
              <Grid container xs={5} direction="column">
                {resourceSavings && resourceSavings.carbonConverted ? (
                  <Typography variant="h6" color="textSecondary">
                    {`${numberWithCommas(resourceSavings.carbonConverted.value)} ${resourceSavings.carbonConverted.unit}`}
                  </Typography>
                ) : (
                  <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={2} align="left">
          <AddCustomIntervention
            handleRefresh={() => handleRefresh()}
            editModalClose={(value) => editModalClose(value)}
            deselectID={(value) => deselectID(value)}
            editCustomintervention={editCustomintervention && editCustomintervention}
            editCustominterventionID={editCustominterventionID && editCustominterventionID}
            openBackdrop={openBackdrop}
            handleCloseBackdrop={(value) => handleCloseBackdrop(value)}
            handleOpenBackdrop={(value) => handleOpenBackdrop(value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default InterventionOverview;
