import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import ErrorIcon from '@material-ui/icons/Info';
import Tooltip from '../../../../common/tooltip/Tooltip';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

function CommonCardNew({ capex, capexTotalValue, capexTotalConvertedValue, title, inputs, height, width, tooltipBody, tooltipReady, selectedIntervention, showTotalCapex }) {
  const useStyles = makeStyles((theme) => ({
    paper1: {
      outline: 'none',
      height: height,
      minWidth: width,
      padding: theme.spacing(2, 1, 2, 4),
      background: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    },
    alignTypo: {
      marginTop: '5%',
    },
    alignUnits: {
      paddingLeft: 10,
    },
    errorIcon: {
      color: '#66E3BE',
      cursor: 'pointer',
      height: '70%',
      marginTop: '2%',
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Card className={classes.paper1}>

        <Grid container direction="row" xs={12} >
          {inputs &&
            inputs.map((item, index) => {
              return (
                <>
                  {/* {item.label !== `Tank Size - Default Recommendation` && ( */}
                  <Grid xs={6} container direction="row" style={index > 1 ? { marginTop: '6%', } : null}>
                    <Grid xs={12} container direction="row" >
                      <Typography color="textSecondary">{item?.label?.toLowerCase() === 'capex' ? 'Capex Increase' : item?.label} </Typography>
                      {tooltipReady && (item.label === 'SDG Number' || (selectedIntervention && selectedIntervention === 'Sanitary Napkin Incinerator')) ? (
                        <>
                          <CustomizedTooltips
                            icon="info"
                            title={
                              <>
                                <Typography>{tooltipBody && tooltipBody ? tooltipBody : ''}</Typography>
                              </>
                            }
                            placement="right"
                          />
                        </>
                      ) : (
                        ''
                      )}

                    </Grid>

                    <Grid xs={12} direction="row">
                      <Typography className={classes.alignTypo} style={Math.sign(item.value) === -1 ? {} : {}}>
                        {Array.isArray(item.value) && item.value.join(` & `)}
                        {!Array.isArray(item.value) && (
                          <b style={item.value < 0 ? { color: '#26E0A3' } : { color: 'black' }}>{item && item.value && isNaN(item.value) ? item.value : item && item.value && `${numberWithCommas(item.value)}`}</b>
                        )}
                        <span className={classes.alignUnits}>{item.unit}</span>
                      </Typography>

                    </Grid>
                  </Grid >
                  {index === 1 && title === 'Intervention Results' && <Grid xs={12} style={{ marginTop: '2%', }}>
                    {capex ? <Typography style={{ fontSize: '11px' }} ><b style={{ marginRight: '2%' }}>{numberWithCommas(capexTotalValue?.value)}</b>{`Rs`} in Total CAPEX</Typography> : <Typography style={{ fontSize: '11px' }} ><b style={{ marginRight: '2%' }}>{numberWithCommas(capexTotalConvertedValue?.value)}</b>{`Rs/Sqft`} in Total CAPEX</Typography>}
                  </Grid >}

                  {/* )} */}
                </>
              );
            })}
        </Grid>

      </Card >
    </>
  );
}
export default CommonCardNew;
{/* <Grid xs={6} direction="row">
                      <Typography className={classes.alignTypo} style={Math.sign(item.value) === -1 ? {} : {}}>
                        {item?.label?.toLowerCase() === 'capex' ? <Typography style={{ fontSize: '11px', }}>{item?.label?.toLowerCase() === 'capex' ? 'Capex Increase' : item?.label} </Typography> : ''}
                      </Typography>

                    </Grid> */}