import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';
import CategoryRow from './categoryRow';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import numberWithCommas from '../../../../../utils/numberToComma';

import EnergyFlowDiagram from '../flow-diagram/EnergyFlowDiagram';
import WasteFlowDiagram from '../flow-diagram/WasteFlowDiagram';
import WaterFlowDiagram from '../flow-diagram/WaterFlowDiagram';

// import CustomLink from '../../../../../common/link/CustomLink';
// import Skeleton from '@material-ui/lab/Skeleton';

//////////////////////
// import { green } from '@material-ui/core/colors';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//////////////////////////////////////////

// const GreenCheckbox = withStyles({
//   root: {
//     color: green[400],
//     '&$checked': {
//       color: green[600],
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const cols = ['Category', 'IGBC Points', 'CAPEX (ONE-TIME)', 'OPEX Savings (Annual)', 'Resource Impact'];

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.571429px',
    textTransform: 'uppercase',
  },
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',

    lineHeight: '22px',
    letterSpacing: '0.571429px',
    color: '#000000',
    fontWeight: '600',
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    maxWidth: '100%',
    border: '1px solid rgba(0, 0, 0, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    // boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
  },
  container: {
    width: '100%',
    marginBottom: '3%',
  },
  checkboxlabel: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
    // position: 'static',
  },
  linkfont: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    color: '#00C58A',
  },
  flowDiagram: {
    textDecoration: 'underline',
    color: '#00C58A',
    cursor: 'pointer',
  },
  paper: {
    position: 'absolute',
    // width: '550px',
    outline: 'none',
    height: 'auto',
    // maxHeight: '800',
    width: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 2),
  },
  close: {
    marginLeft: '95%',
  },
}));

let sortedData;
export default function CategoryTable(props) {
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const data = props.tabledata;
  if (props.data === 'Energy') {
    sortedData = data && data;
  }
  if (props.data === 'Water') {
    sortedData = data && data.sort((a, b) => (a.name === 'Demand Optimization' ? -1 : 0));
  }
  if (props.data === 'Waste') {
    sortedData = data && data.sort((a, b) => (a.name === 'Reduction' ? -1 : 0));
  }
  if (props.data === 'Materials') {
    sortedData = data && data.sort((a, b) => (a.name === 'Reduction' ? -1 : 0));
  }
  const category = props.category;
  const allinterv = props.allinterv;
  const classes = useStyles();
  const [checkState, setCheckState] = useState(false);
  const handleChange = () => setCheckState(!checkState);
  let energyflowdiagram = allinterv && allinterv.find((interv) => interv.name === 'Energy').quantities.flowDiagram;
  let waterflowdiagram = allinterv && allinterv.find((interv) => interv.name === 'Water').quantities.flowDiagram;
  let wasteflowdiagram = allinterv && allinterv.find((interv) => interv.name === 'Waste').quantities.flowDiagram;
  // console.log(sortedData, 'sorted data ***********');

  const customInterventionsIndex = sortedData && sortedData?.findIndex((item) => item.name === 'Custom Interventions');
  if (customInterventionsIndex !== -1) {
    const customInterventions = sortedData && sortedData?.splice(customInterventionsIndex, 1)[0];
    sortedData && sortedData?.push(customInterventions);
  }

  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <CloseIcon className={classes.close} onClick={handleClose} />
      {props && props.data === 'Energy'
        ? allinterv &&
        energyflowdiagram && (
          <EnergyFlowDiagram
            mainGrid={`${numberWithCommas(energyflowdiagram.left.mainGrid.value)}`}
            residentiallighting={`${numberWithCommas(energyflowdiagram.right.residentialLighting.value)}`}
            residentialhvac={`${numberWithCommas(energyflowdiagram.right.residentialHvac.value)}`}
            residentialfan={`${numberWithCommas(energyflowdiagram.right.residentialFan.value)}`}
            residentialhotwater={`${numberWithCommas(energyflowdiagram.right.residentialHotWater.value)}`}
            homeappliances={`${numberWithCommas(energyflowdiagram.right.homeAppliances.value)}`}
            commonarea={`${numberWithCommas(energyflowdiagram.right.commonArea.value)}`}
            dieselgenerator={`${numberWithCommas(energyflowdiagram.left.dieselGenerator.value)}`}
            solarweaterheater={`${numberWithCommas(energyflowdiagram.left.solarWaterHeater.value)}`}
            solarpv={`${numberWithCommas(energyflowdiagram.left.solarPv.value)}`}
            biogas={`${numberWithCommas(energyflowdiagram.left.biogas.value)}`}
            maingridtohotwater={`${numberWithCommas(energyflowdiagram.connections[0].value.value)}`}
            maingridtolighting={`${numberWithCommas(energyflowdiagram.connections[1].value.value)}`}
            dieslgeneratortolighting={`${numberWithCommas(energyflowdiagram.connections[5].value.value)}`}
            dieselgeneratortoappliances={`${numberWithCommas(energyflowdiagram.connections[7].value.value)}`}
            dieslgeneratortofan={`${numberWithCommas(energyflowdiagram.connections[6].value.value)}`}
            dieslgeneratortocommonarea="NA"
            maingridtoresidentialfan={`${numberWithCommas(energyflowdiagram.connections[2].value.value)}`}
            maingridtohomeappliances={`${numberWithCommas(energyflowdiagram.connections[3].value.value)}`}
            maingridtocommonarea="NA"
            maingridtohvac={`${numberWithCommas(energyflowdiagram.connections[4].value.value)}`}
            buiogastocommonarea={`${numberWithCommas(energyflowdiagram.connections[10].value.value)}`}
            solarwaterheatertoresidentialhotwater={`${numberWithCommas(energyflowdiagram.connections[8].value.value)}`}
            solarpvtocommonarea={`${numberWithCommas(energyflowdiagram.connections[9].value.value)}`}
          />
        )
        : props && props.data === 'Waste'
          ? allinterv &&
          wasteflowdiagram && (
            <WasteFlowDiagram
              rejectwaste={numberWithCommas(wasteflowdiagram.left.RejectWaste.value)}
              sanitarywaste={numberWithCommas(wasteflowdiagram.left.SanitaryWaste.value)}
              medicalwaste={numberWithCommas(wasteflowdiagram.left.MedicalWaste.value)}
              ewaste={numberWithCommas(wasteflowdiagram.left.E_Waste.value)}
              drywaste={numberWithCommas(wasteflowdiagram.left.DryWaste.value)}
              organicwaste={numberWithCommas(wasteflowdiagram.left.OrganicWaste.value)}
            />
          )
          : props && props.data === 'Water'
            ? allinterv &&
            waterflowdiagram && (
              <WaterFlowDiagram
                TreatedGreyWater={numberWithCommas(waterflowdiagram.left.treatedGreyWater.value)}
                FreshWater={numberWithCommas(waterflowdiagram.left.freshWater.value)}
                CentralizedRo={numberWithCommas(waterflowdiagram.left.centralizedRO.value)}
                WTP={numberWithCommas(waterflowdiagram.left.wtp.value)}
                CommonAreaUse={numberWithCommas(waterflowdiagram.right.commonAreaUse.value)}
                DomesticWaterUse={numberWithCommas(waterflowdiagram.right.domesticWaterUse.value)}
                HealthFaucet={numberWithCommas(waterflowdiagram.right.healthFaucet.value)}
                Flushing={numberWithCommas(waterflowdiagram.right.flushing.value)}
                Drinking={numberWithCommas(waterflowdiagram.right.drinking.value)}
                GreyWaterSTP={numberWithCommas(waterflowdiagram.right.greyWaterStp.value)}
                BlackWaterSTP={numberWithCommas(waterflowdiagram.right.blackWaterStp.value)}
              />
            )
            : ''}
    </div>
  );
  return (
    <>
      <>
        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12}>
          <Grid container item xs={4} data-tour="diagram">
            <Typography className={classes.flowDiagram} onClick={handleOpen}>
              SHOW FLOW DIAGRAM
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="flex-end" alignItems="flex-end">
            <CustomCheckBox
              checkState={checkState}
              handleChange={handleChange}
              label={<Typography className={classes.checkboxlabel}>Show CAPEX (Rs)</Typography>}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.container} justify="center" alignItems="center" item xs={12} data-tour="table">
          <TableContainer component={Paper} className={classes.table}>
            <Table>
              <colgroup>
                <col style={{ width: '33.34%' }} />
              </colgroup>
              {/* table head */}
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Typography variant="body1">{cols[0]}</Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography variant="body1">Capex Increase</Typography>{' '}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1"> Total Capex</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">{cols[3].split('(')[0]}</Typography>
                    <Typography variant="body1">{'(' + cols[3].split('(')[1]}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">{props.impactcolumn1}</Typography>
                    <Typography variant="body1">{props.data === 'Materials' ? '(One-Time)' : props.impactcolumn2}</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {/* table body */}
              <TableBody>
                {sortedData &&
                  sortedData.map((row, index) => {
                    return (
                      <>
                        <CategoryRow
                          key={index}
                          row={row}
                          StyledTableCell={StyledTableCell}
                          checkState={checkState}
                          handleRefresh={() => props.handleRefresh()}
                          projectID={props.projectID}
                          category={category}
                          stopLoder={props.stopLoder}
                          state={checkState}
                          //   approval={props.approval}
                          data={props.data}
                          name={row.name}
                          handleEditCustomIntervention={(value) => props.handleEditCustomIntervention(value)}
                          handleEditCustominterventionID={(value) => props.handleEditCustominterventionID(value)}
                          handleOpenBackdrop={(v) => props.handleOpenBackdrop(v)}
                        />
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
          {body1}
        </Modal>
      </>
    </>
  );
}
