import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Page1Header from './Page1Header';
// import EclipseProgress from '../../interventionDetails/common/EclipseProgress';
import rsToCr from '../../../../../utils/rsToCr';
// import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: '#15B7B9',
    height: '250px',
  },
  paper2: {
    background: '#ffff',
    boxShadow: 'none',
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
  text1: {
    fontWeight: '700',
    fontSize: '30px',
    lineHeight: '35px',
    color: 'rgba(28, 36, 28, 0.93)',
    marginLeft: '15px',
    textTransform: 'uppercase',
  },
}));

const CustomBar = ({ bgcolor, barvalue }) => {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 30,
      background: '#FFFFFF',
      borderRadius: '0px 100px 100px 0px',
    },
    bar: {
      borderRadius: 20,
      background: `${bgcolor}`,
    },
  }))(LinearProgress);

  return (
    <>
      <BorderLinearProgress className={classes.margin} variant="determinate" value={`${barvalue}`} />
    </>
  );
};

const Page1 = (props) => {
  const classes = useStyles();
  const data = props.data;
  let saving = data && data.interventions.quantities.overall.finance.opexSavings.value;
  let fiveyearsaving = data && data.interventions.quantities.overall.finance.opexSavings.value * 5;
  let capex = data && data.interventions.quantities.overall.finance.capex.value;
  let energygoal = data && data.goals.categories[0].find((goal) => goal && goal.name === 'Energy');
  let watergoal = data && data.goals.categories[0].find((goal) => goal && goal.name === 'Water');
  let wastegoal = data && data.goals.categories[0].find((goal) => goal && goal.name === 'Waste');
  let newnum = capex * 100;
  let calculatedvalue = Math.round(newnum / fiveyearsaving);
  return (
    <Grid container direction="column" xs={12}>
      <Page1Header companyname={props.companyname} sustainabilitypercentage={props.sustainabilitypercentage} />
      <Paper className={classes.paper2}>
        <Typography variant="h6" style={{ marginTop: '50px', marginBottom: '30px', marginLeft: '23px' }}>
          Project savings in the next 5 years will be*
        </Typography>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography variant="h2" style={{ textAlign: 'center', color: '#10DDC2' }}>
              {isNaN(fiveyearsaving) ? rsToCr('Rs', 0) : rsToCr('Rs', fiveyearsaving)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h3" style={{ textAlign: 'center', marginTop: '10px' }}>
              Cr
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', marginTop: '10px' }}>
            <CustomBar bgcolor="#40E4CE" barvalue="90" />
          </Grid>
        </Grid>
        <Typography variant="h6" style={{ marginTop: '50px', marginBottom: '30px', marginLeft: '23px' }}>
          With a capital investment of
        </Typography>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography variant="h2" style={{ textAlign: 'center', color: '#F57170' }}>
              {rsToCr('Rs', capex)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h3" style={{ textAlign: 'center', marginTop: '10px' }}>
              Cr
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', marginTop: '10px' }}>
            <CustomBar bgcolor="#F78D8D" barvalue={`${calculatedvalue - 10}`} />
          </Grid>
        </Grid>
        <Typography variant="h6" style={{ marginTop: '70px', marginBottom: '50px', textAlign: 'right', marginRight: '50px' }}>
          *Project annual saving will be {isNaN(saving) ? rsToCr('Rs', 0) : rsToCr('Rs', saving)} Cr
        </Typography>
        <Paper className={classes.paper}>
          <Grid container item xs={12}>
            <Typography className={classes.text1} style={{ marginTop: '30px' }}>
              {' '}
              Current Progress
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={4} style={{ marginTop: '35px' }}>
              <Grid container item xs={12} direction="column" alignItems="center">
                <Typography variant="h6" style={{ textAlign: 'center', fontWeight: 600 }}>
                  ENERGY
                </Typography>
                <Typography variant="h5" style={{ color: '#FFFFFF' }}>
                  <b>{`${Math.round(energygoal && energygoal.quantities.actualPercentage.value)}%`}</b>
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center', fontSize: '11px', lineHeight: '15px', marginTop: '15px' }}>
                  Maximise on-site Renewable
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center', fontSize: '11px', lineHeight: '15px' }}>
                  Energy sources
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: '35px' }}>
              <Grid container item xs={12} direction="column" alignItems="center">
                <Typography variant="h6" style={{ textAlign: 'center', fontWeight: 600 }}>
                  WATER
                </Typography>
                <Typography variant="h5" style={{ color: '#FFFFFF' }}>
                  <b>{`${Math.round(watergoal && watergoal.quantities.actualPercentage.value)}%`}</b>
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center', fontSize: '11px', lineHeight: '15px', marginTop: '15px' }}>
                  Zero dependency on external
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center', fontSize: '11px', lineHeight: '15px' }}>
                  sources for Water
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: '35px' }}>
              <Grid container item xs={12} direction="column" alignItems="center">
                <Typography variant="h6" style={{ textAlign: 'center', fontWeight: 600 }}>
                  WASTE
                </Typography>
                <Typography variant="h5" style={{ color: '#FFFFFF' }}>
                  <b>{`${Math.round(wastegoal && wastegoal.quantities.actualPercentage.value)}%`}</b>
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center', fontSize: '11px', lineHeight: '15px', marginTop: '15px' }}>
                  Zero Waste to Landfill
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </Grid>
  );
};

export default Page1;
