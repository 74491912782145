export const getEnabledCount = (custom, interv) => {
  let intervCount = 0;
  let customCount = custom;
  interv
    .map((arr) => arr.length)
    .forEach((D) => {
      intervCount = intervCount + D;
    });
  return customCount + intervCount;
};

export const calculateEnabledCount = (data) => {
  const interv = data?.subcategories?.filter((interventions) => interventions?.name !== 'Custom Interventions');
  const custom = data?.subcategories?.filter((interventions) => interventions?.name === 'Custom Interventions');
  const customCount = custom[0]?.interventions?.filter((item) => item?.enabled === true)?.length;
  const intervCount = interv?.map((intervention) => intervention?.interventions?.filter((item) => item?.enabled === true));
  // console.log({ interv, customCount, intervCount }, 'dataaaa');
  return getEnabledCount(customCount, intervCount);
};
