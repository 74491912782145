import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { GreenRadio } from '../../../../common/RadioButton/GreenRadio';
import ComplexPIPchildren from './ComplexPIPChildren';
import { CAPEXPIPsForWWB } from '../EditProjectCommon';

function ComplexPIPS(props) {
  const { index, field, unit, myArray, projectPIP, appendChanged, type, PIPs } = props;

  const [radioButtonValue, setRadioButtonValue] = useState(
    projectPIP.find((d) => d.PIPScheme._id === field._id) ? projectPIP.find((d) => d.PIPScheme._id === field._id).value : ''
  );
  const [capexPips, setCapexPips] = useState(PIPs.filter((d) => d.name === CAPEXPIPsForWWB.OrganicComposter || d.name === CAPEXPIPsForWWB.Composting));
  const handleRadioButtonChange = (event) => setRadioButtonValue(event.target.value);

  useEffect(() => {
    if (radioButtonValue && field) appendChanged(field._id, radioButtonValue);
  }, [radioButtonValue, field]);

  return (
    <>
      <Grid
        container
        direction="row"
        item
        xs={12}
        key={index}
        style={
          index === 0 ? { height: 'Auto', marginTop: '3%', marginBottom: '3%', marginLeft: '2%' } : { height: 'Auto', marginLeft: '2%', marginBottom: '3%' }
        }
      >
        <Grid item alignItems="left" xs={12}>
          <Typography variant="h5">{field.displayName + `${type && type === 'basic' ? '*' : ''}`}</Typography>
        </Grid>
        {field.description && field.description !== 'NA' ? (
          <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {field.description}
            </Typography>
          </Grid>
        ) : null}

        <Grid container direction="row" item xs={12} key={index + 1} style={{ height: 'Auto' }}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={radioButtonValue} onChange={handleRadioButtonChange}>
              {field.options &&
                field.options.map((option, index) => {
                  if (option === 'Default') {
                    return <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />;
                  } else if (option === 'Organic Waste Composter') {
                    return (
                      <>
                        <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />
                        {option === 'Organic Waste Composter' &&
                          radioButtonValue === 'Organic Waste Composter' &&
                          capexPips &&
                          capexPips.map((c, i) => {
                            if (c.name === CAPEXPIPsForWWB.OrganicComposter)
                              return (
                                <ComplexPIPchildren
                                  index={i}
                                  unit="NA"
                                  field={c && c}
                                  myArray={myArray && myArray}
                                  projectPIP={projectPIP && projectPIP}
                                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                                  type={type}
                                />
                              );
                          })}
                      </>
                    );
                  } else if (option === 'Natural Composting') {
                    return (
                      <>
                        <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />
                        {option === 'Natural Composting' &&
                          radioButtonValue === 'Natural Composting' &&
                          capexPips &&
                          capexPips.map((c, i) => {
                            if (c.name === CAPEXPIPsForWWB.Composting)
                              return (
                                <ComplexPIPchildren
                                  index={i}
                                  unit="NA"
                                  field={c && c}
                                  myArray={myArray && myArray}
                                  projectPIP={projectPIP && projectPIP}
                                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                                  type={type}
                                />
                              );
                          })}
                      </>
                    );
                  }
                })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default ComplexPIPS;
