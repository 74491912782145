import React from 'react';
import { Grid, Typography, withWidth } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const HeadAndBackIcon = ({ handleClick, arrowColor, iconHeight, iconWidth, headingStyle, variant, heading, bold }) => {
  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={0} style={{ paddingTop: '0.6%' }}>
        <ArrowBackIosIcon
          onClick={handleClick}
          style={{
            color: arrowColor ? arrowColor : '#66E3BE',
            cursor: 'pointer',
            width: iconWidth ? iconWidth : '35.41px',
            height: iconHeight ? iconHeight : '35px',
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <Typography style={headingStyle} variant={variant}>
          {!bold ? heading : <b>{heading}</b>}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withWidth()(HeadAndBackIcon);
