import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { Link } from 'react-router-dom';
import axios from '../../../../../config/Axios';
import { InterventionDetailsContext } from '../interventionDetails';
import EditInterventionDetails from './editInterventionDetails';
import ShowConstants from './showConstants';
import ShowProjectAndConstAssumptionData from './showProjectAndCostAssumptionData';
import ShowCapexAndDetails from './showCapexAndDetails';
import ShowInterventionCostAssumptionsParameters from './showInterventionCostAssumptionParameters';
import ShowSaveAndCancel from './showSaveAndCancel';
import { NotetakingContext } from '../../../../../projectRouting/Index';

export const AllProjectContext = React.createContext();

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DataAndCostAssumptions = (props) => {
  const classes = useStyles();
  const { notetakingContext, handleNotetaking } = useContext(NotetakingContext)

  const { interventionProjectMappingId, projectId, inputs, activeSandboxId } = props;
  const { myArrayContext, handleMyArray } = useContext(InterventionDetailsContext);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState('');
  const [myArray, setMyArray] = useState([]);
  const [parameter, setParameter] = useState('basic');

  const constant = props.inputs && props.inputs ? props.inputs.filter((item) => item && item.tags.includes('constant')) : '';
  const projectCustomizable = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('project-customizable')) : '';
  const interventionCustomizable = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('intervention-customizable')) : '';
  const multiplierSource = inputs && inputs ? inputs.find((item) => item && item.tags.includes('multiplier-source')) : '';
  const capexMultiplier = inputs && inputs ? inputs.find((item) => item && item.tags.includes('capex-multiplier')) : '';
  const VendorcapexMultiplier = inputs && inputs ? inputs.find((item) => item && item.tags.includes('vendor-capex-multiplier')) : '';

  const basichandleClick = () => setParameter('basic');
  const advancehandleClick = () => setParameter('advance');
  const handleOpen = () => {
    // handleNotetaking()
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
    // handleNotetaking()
    props.handleCancel();
  };
  const handleSave = () => {
    if (myArray && myArray.length > 0) {
      const values = myArray.map((v) => ({
        id: v.id,
        value: !isNaN(v.value) ? (Number.isInteger(v.value) ? parseInt(v.value) : parseFloat(v.value)) : v.value,
      }));
      const body = { type: 'dirty', values: values };
      axios
        .put(`/projectv2/${props.projectId}/interventions/${props.interventionProjectMappingId}/sandboxes/${props.activeSandboxId}`, body)
        .then((res) => {
          if (res && res.data.data) {
            handleClose();
            props.handleRefresh();
            props.handleBackdropOpen();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const appendChanged = (id, value) => {
    const body = { id: id, value: !value ? 0 : value };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.id === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else setMyArray((oldArray) => [...oldArray, body]);
  };

  useEffect(() => {
    if (myArray.length > 0) handleMyArray(myArray);
  }, [myArray, handleMyArray]);

  useEffect(() => {
    if (multiplierSource) setSource(multiplierSource.value);
  }, [multiplierSource]);

  useEffect(() => {
    if (myArrayContext && myArrayContext.length > 0) setMyArray(myArrayContext);
  }, [myArrayContext]);

  const advanceParameter = (
    <Grid container alignItems="flex-start" xs={12} className={classes.grid}>
      <ShowInterventionCostAssumptionsParameters
        interventionCustomizable={interventionCustomizable}
        myArray={myArray}
        appendChanged={(a, b) => appendChanged(a, b)}
        onClick={props.onClick}
      />
      {multiplierSource && (
        <ShowCapexAndDetails
          source={source}
          setSource={(a) => setSource(a)}
          appendChanged={(a, b) => appendChanged(a, b)}
          capexMultiplier={capexMultiplier}
          VendorcapexMultiplier={VendorcapexMultiplier}
          myArray={myArray}
          multiplierSource={multiplierSource}
        />
      )}
      <ShowProjectAndConstAssumptionData
        projectCustomizable={projectCustomizable}
        myArray={myArray}
        appendChanged={(a, b) => appendChanged(a, b)}
        onClick={props.onClick}
      />
      <ShowConstants constant={constant && constant} myArray={myArray} appendChanged={(a, b) => appendChanged(a, b)} />
      <ShowSaveAndCancel handleClose={handleClose} handleSave={handleSave} />
    </Grid>
  );

  const basicParameters = (
    <EditInterventionDetails
      interventionProjectMappingId={interventionProjectMappingId}
      projectId={projectId}
      inputs={inputs}
      activeSandboxId={activeSandboxId && activeSandboxId}
      handleRefresh={() => props.handleRefresh()}
      handleBackdropOpen={() => props.handleBackdropOpen()}
      bordercolor="2px solid rgba(73, 221, 177, 0.5)"
      background="rgba(198, 242, 205, 0.25)"
      customizable={props.customizable}
      refresh={props.refresh}
      boundaryMin={props.boundaryMin}
      boundaryMax={props.boundaryMax}
      mandates={props.mandates}
      mandateGenerals={props.mandateGenerals && props.mandateGenerals}
      mandateicon={props.mandateicon}
      projectinputs={props.projectinputs}
      onClose={handleClose}
      mandateGeneralsIcon={props.mandateGeneralsIcon}
    />
  );

  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Paper style={{}} className={parameter === 'basic' ? classes.button1 : classes.button2} onClick={basichandleClick}>
              <Typography variant="h6" className={parameter === 'basic' ? classes.textinside1 : classes.textinside2} style={{}}>
                Basic Parameters
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={3}>
            <Paper style={{}} className={parameter === 'advance' ? classes.button1 : classes.button2} onClick={advancehandleClick}>
              <Typography variant="h6" className={parameter === 'advance' ? classes.textinside1 : classes.textinside2}>
                Advanced Parameters
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: '3%' }} />
        {parameter === 'basic' && basicParameters}
        {parameter === 'advance' && advanceParameter}
      </>
    </div>
  );
  return (
    <div>
      <Link
        className="button"
        style={{
          background: 'transparent',
          color: '#00C58A',
          cursor: 'pointer',
          width: '30%',
          fontWeight: 900,
        }}
        to="#"
        onClick={handleOpen}
      >
        <Typography variant="h6">
          <b>INTERVENTION PARAMETERS</b>
        </Typography>
      </Link>
      {/* overflow: 'visible' */}
      <Modal disableEnforceFocus style={{ position: 'absolute', zIndex: !notetakingContext ? 1000 : 0 }} open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        {body1}
      </Modal>
    </div>
  );
};
export default DataAndCostAssumptions;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '950px',
    outline: 'none',
    maxHeight: 500,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(5, 5, 2, 5),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  edit: {
    color: '#00C58A',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  grid: {
    marginTop: '2%',
  },
  textinside1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.5px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingTop: '10%',
  },
  textinside2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
    paddingTop: '10%',
  },
  button1: {
    filter: 'drop-shadow(0px 4px 20px rgba(0, 197, 138, 0.4))',
    width: '223px',
    height: '65px',
    background: '#66E3BE',
    borderRadius: '10px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  button2: {
    width: '223px',
    height: '65px',
    background: '#FFFFFF',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
}));
