import React from 'react';
import { Grid, Typography, FormControl, InputLabel, Select, makeStyles, MenuItem } from '@material-ui/core';

function IndoorFlooringPIPs({ index, _id, displayName, appendChanged, values, disabledValue, projectPIP, myArray }) {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="flex-start" direction="column" item xs={12} style={{ marginLeft: '4%', marginTop: '2%' }} key={index}>
        <Grid container alignItems="flex-start" item xs={12}>
          <Typography variant="h6" color={disabledValue ? 'textSecondary' : 'textPrimary'}>
            {displayName}
          </Typography>
        </Grid>
        <Grid container alignItems="flex-start" item xs={12}>
          <FormControl variant="outlined" style={{ width: '166px', borderRadius: `50px`, marginTop: '2%' }} className={classes.dropdown}>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              disabled={disabledValue}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="hhhhhhhhhh"
              placeholder="placeholder"
              className={classes.select}
              value={
                myArray && myArray.find((d) => d.PIPScheme === _id)
                  ? myArray && myArray.find((d) => d.PIPScheme === _id).value
                  : projectPIP && projectPIP.find((d) => d.PIPScheme === _id) && projectPIP.find((d) => d.PIPScheme === _id).value
              }
            >
              {values
                ? values.map((value, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          appendChanged(_id, value);
                        }}
                        value={value}
                        name={value}
                        style={{ paddingTop: '2%' }}
                      >
                        {value}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default IndoorFlooringPIPs;

const useStyles = makeStyles((theme) => ({
  dropdown: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `10px`,
        width: '166px',
        paddingBottom: '30px',
        [theme.breakpoints.down('md')]: {
          width: '166px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '110px',
        },
      },
    },
  },
  select: {
    padding: '1px',
    borderRadius: `15px`,
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      padding: '2px',
      borderRadius: `15px`,
    },
  },
}));
