import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import UnitAwareTextField from '../../../common/textfield/UnitAwareTextField';
import Skeletons from '../../../common/skeleton/Skeletons';

function UnitAwareTextFieldInputs(props) {
  const { requiredpips, unit, appendChanged, skeleton, area, appendChangedForRequiredPIP } = props;
  const [sortedRequiredPIPs, setSortedRequiredPIPs] = useState('');

  useEffect(() => {
    if (requiredpips) {
      const data = requiredpips.data.filter((field) => field.name !== 'clubhouse' && field.name !== 'location' && field.name !== 'preferences.units');
      const sortedData = data.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      setSortedRequiredPIPs(sortedData);
    }
  }, [requiredpips]);

  return (
    <>
      <Grid container item xs={12}>
        {sortedRequiredPIPs && requiredpips
          ? sortedRequiredPIPs.map((field, index) => {
              return (
                <>
                  <Grid alignItems="left" item xs={12}>
                    <Typography variant="h5">{field.displayName + '*'}</Typography>
                  </Grid>
                  {field.description && (
                    <Grid alignItems="center" item xs={7} style={{ marginBottom: '1%' }}>
                      <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.38)', lineHeight: '22px', letterSpacing: '0.571429px' }}>
                        {field.description}
                      </Typography>
                    </Grid>
                  )}
                  <UnitAwareTextField
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    area={area && area}
                    appendChangedForRequiredPIP={(a, b) => appendChangedForRequiredPIP(a, b)}
                  />
                </>
              );
            })
          : skeleton &&
            skeleton.map((d, index) => {
              return (
                <>
                  <Skeletons />
                  <br />
                  <br />
                </>
              );
            })}
      </Grid>
    </>
  );
}

export default UnitAwareTextFieldInputs;
