import VendorDetail from '../components/views/vendors/details/VendorDetail';
import InterventionDetails from '../components/views/interventions/interventionDetails/interventionDetails';
import Intervention from '../components/views/interventions/intervention/intervention';
import MainGrid from '../components/views/insightsPage/Main';
import MarketingDummy from '../components/views/marketing/MarketingDummyImage';
import DocumentDashboard from '../components/views/igbc-dashboard/documents-dashboard/DocumentDasboard';
import Certification from '../components/views/certification/Certification';
import Credit from '../components/views/certification/credit/Credit';

const projectRouting = [
  { exact: true, path: '/insights', component: MainGrid },
  { exact: true, path: '/interventions', component: Intervention },
  { exact: true, path: '/interventions/:iid', component: InterventionDetails },
  { exact: true, path: '/interventions/:iid/vendors/:id', component: VendorDetail },
  { exact: true, path: '/marketing', component: MarketingDummy },
  // { exact: true, path: '/certification', component: DocumentDashboard },
  { exact: true, path: '/certification', component: Certification },
  { exact: true, path: '/certification/credit/:creditId', component: Credit },
  { exact: true, path: '/certification/upload', component: DocumentDashboard },
];

export default projectRouting;
