import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import Fields from '../../../common/textfield/Fields';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import { ProjectContext } from '../../../../routing/IndexRouting';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
import MaterialPresetBrand from './components/MaterialsPresetBrand';
import MaterialPresetPaint from './components/MaterialsPresetPaint';
import MaterialPresetFinish from './components/MaterialsPresetFinish';
import Skeletons from '../../../common/skeleton/Skeletons';
import { TooltipData, ParentPage, ChildPage, ComponentName, ComponentTooltipData } from '../../../../staticData/TooltipStaticData';
import HandleErrorForWaterBasedSelection from './components/HandleErrorForWaterBasedSelection';
import { GreenRadio } from '../../../common/RadioButton/GreenRadio';
import { SomethingChanged } from '../EditProject';
import InteriorPaints from './components/InteriorPaints';
import IndoorFlooring from './components/IndoorFlooring';

const MaterialDetails = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let skeleton = [1, 2, 3, 4, 5];
  let { type, next, name } = props;
  const [projectPIP, setProjectPIP] = useState('');
  const [data, setData] = useState('');
  const [myArray, setMyArray] = useState([]);

  const [value, setValue] = React.useState(undefined);
  const [manualPips, setManualPips] = useState('');
  const [presetPips, setpresetPips] = useState('');

  // for exterior
  const [paintBrands, setPaintBrands] = useState('');
  const [paintFinishes, setPaintFinishes] = useState('');
  const [paintNames, setPaintNames] = useState('');

  // for interior
  const [interiorPaintBrands, setInteriorPaintBrands] = useState('');

  const [selectedPaintBrand, setSelectedPaintBrand] = useState('');
  const [selectedPaintFinish, setSelectedPaintFinish] = useState('');
  const [selectedPaintName, setSelectedPaintName] = useState('');

  const [solventEnabled, setSolventEnabled] = useState(false);
  const [waterBasedEnabled, setWaterBasedEnabled] = useState(false);
  const [manualEnabled, setManualEnabled] = useState(false);
  const [typeOfConcretePIP, setTypeOfConcretePIP] = useState('');
  const { projectData } = useContext(ProjectContext);

  const [indoorFlooringPips, setIndoorFlooringPips] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  // others
  const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
    verb: 'get',
  });

  if (status === null && type && type !== 'file') makeRequest();
  useEffect(() => {
    if (response) {
      const unsortedData = response && response.data.data.data;
      const manualPips = unsortedData && unsortedData.filter((d) => d.name === 'materials.manual.brand' || d.name === 'materials.manual.name');
      const presetPips =
        unsortedData &&
        unsortedData
          .filter((d) => d.name === 'materials.preset.brand' || d.name === 'materials.preset.finish' || d.name === 'materials.preset.name')
          .sort((a, b) => (a.name === 'materials.preset.brand' ? -1 : 0));
      setpresetPips(presetPips && presetPips);
      setManualPips(manualPips && manualPips);

      const indoorPips = unsortedData.filter(
        (d) =>
          d.name === 'materials.indoorflooring.livinganddining' ||
          d.name === 'materials.indoorflooring.kitchen' ||
          d.name === 'materials.indoorflooring.bedroom' ||
          d.name === 'materials.indoorflooring.bathroom' ||
          d.name === 'materials.indoorflooring.utilityandbalcony'
      );
      setIndoorFlooringPips(indoorPips && indoorPips);
      setTypeOfConcretePIP(unsortedData.find((d) => d.name === 'materials.typeofconcrete'));
      setData(unsortedData && unsortedData);
    }
  }, [response]);

  useEffect(() => {
    axios.get(`/PaintProvider?list=Brand&type=Exterior Paints`).then((res) => {
      setPaintBrands(res && res.data.data.data);
      axios.get(`/PaintProvider?list=Brand&type=Interior Paints`).then((res) => setInteriorPaintBrands(res && res.data.data.data));
    });
  }, []);

  useEffect(() => {
    if (selectedPaintBrand) {
      axios.get(`PaintProvider?list=Finish&type=Exterior Paints&brand=${selectedPaintBrand}`).then((res) => setPaintFinishes(res && res.data.data.data));
    }
  }, [selectedPaintBrand]);

  useEffect(() => {
    if (selectedPaintBrand && selectedPaintFinish) {
      axios
        .get(`PaintProvider?list=Paint&brand=${selectedPaintBrand}&finish=${selectedPaintFinish}&type=Exterior Paints`)
        .then((res) => setPaintNames(res && res.data.data.data));
    }
  }, [selectedPaintBrand, selectedPaintFinish]);

  useEffect(() => {
    if (projectPIP.length !== 0) {
      let arr2 = [];
      projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
      if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
    }
  }, [projectPIP]);

  useEffect(() => {
    if (projectPIP.length !== 0 && myArray.length !== 0) {
      myArray.forEach((ele) => {
        let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
        if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
      });
    }
  }, [projectPIP, myArray]);

  useEffect(() => {
    if (props.saveAfterSomethingChanged === true && next) {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      handleSave(next && next);
    }
  }, [props.saveAfterSomethingChanged, next]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/ProjectPIPMapping/get?project=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) setProjectPIP(response.data.data.data);
        })
        .catch((err) => console.log(err, 'error'));
    }
  }, [id]);

  useEffect(() => {
    if (projectPIP) {
      const presetType = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'materials.preset.type');
      const presetBrand = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'materials.preset.brand');
      const presetFinish = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'materials.preset.finish');
      const presetName = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'materials.preset.name');
      const presetTypeValue = presetType && presetType.value ? presetType.value : '';
      const presetBrandValue = presetBrand && presetBrand.value ? presetBrand.value : '';
      const presetFinishValue = presetFinish && presetFinish.value ? presetFinish.value : '';
      const presetNameValue = presetName && presetName.value ? presetName.value : '';
      if (presetBrandValue && presetBrandValue) setSelectedPaintBrand(presetBrandValue);
      if (presetFinishValue && presetFinishValue) setSelectedPaintFinish(presetFinishValue);
      if (presetNameValue && presetNameValue) setSelectedPaintName(presetNameValue);
      if (presetTypeValue && presetTypeValue === 'Solvent-Based Paints') setSolventEnabled(true);
      if (presetTypeValue && presetTypeValue === 'Use Paints from popular brands') setWaterBasedEnabled(true);
      if (presetTypeValue && presetTypeValue === 'Manual') setManualEnabled(true);
    }
  }, [projectPIP]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else history.push(`/projects/${id}/edit?type=${next}`);
    }
  };

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects`);
    history.push(`/projects/${id}/edit?type=basic`);
  };

  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else setMyArray((oldArray) => [...oldArray, body]);
  };

  const handleChange = (event) => {
    let presetPIP = data && data.find((pip) => pip.name === 'materials.preset.enabled');
    let materialPIP = data && data.find((pip) => pip.name === 'materials.manual.enabled');
    let presetTypePIP = data && data.find((pip) => pip.name === 'materials.preset.type');
    let presetBrandPIP = data && data.find((pip) => pip.name === 'materials.preset.brand');
    let presetFinishPIP = data && data.find((pip) => pip.name === 'materials.preset.finish');
    let prresetPaintPIP = data && data.find((pip) => pip.name === 'materials.preset.name');
    if (event.target.value === 'preset1') {
      setManualEnabled(false);
      setWaterBasedEnabled(false);
      appendChanged(presetBrandPIP && presetBrandPIP._id, '');
      appendChanged(presetFinishPIP && presetFinishPIP._id, '');
      appendChanged(prresetPaintPIP && prresetPaintPIP._id, '');
      appendChanged(presetPIP && presetPIP._id, true);
      appendChanged(materialPIP && materialPIP._id, false);
      appendChanged(presetTypePIP && presetTypePIP._id, 'Solvent-Based Paints');
    }
    if (event.target.value === 'preset2') {
      setManualEnabled(false);
      setSolventEnabled(false);
      appendChanged(presetPIP && presetPIP._id, true);
      appendChanged(materialPIP && materialPIP._id, false);
      appendChanged(presetTypePIP && presetTypePIP._id, 'Use Paints from popular brands');
    }
    if (event.target.value === 'other') {
      setSolventEnabled(false);
      setWaterBasedEnabled(false);
      appendChanged(presetBrandPIP && presetBrandPIP._id, '');
      appendChanged(presetFinishPIP && presetFinishPIP._id, '');
      appendChanged(prresetPaintPIP && prresetPaintPIP._id, '');
      appendChanged(materialPIP && materialPIP._id, true);
      appendChanged(presetPIP && presetPIP._id, false);
      appendChanged(presetTypePIP && presetTypePIP._id, 'Manual');
    }
    setValue(event.target.value);
  };
  const handleSave = (next) => {
    if (next === 'file') {
      let body2 = {
        projectId: id,
        values: myArray && myArray.length !== 0 && myArray,
      };
      axios.put(`/ProjectPIPMapping`, body2).then((response) => {
        swal({ icon: 'success', title: 'Data Saved' });
        props.setFalseAfterSomethingChanged();
        handlSetSomethingChanged(false);
        history.push(`/projects/${id}/edit?type=${next}`);
      });
    }
  };

  useEffect(() => {
    if (solventEnabled) setValue('preset1');
    if (waterBasedEnabled) setValue('preset2');
    if (manualEnabled) setValue('other');
  }, [solventEnabled, waterBasedEnabled, manualEnabled]);

  const filterFromData = (tooltipData, ParentPage, ChildPage, ComponentName) => {
    const result = tooltipData && tooltipData.find((d) => d.parentPage === ParentPage && d.childPage === ChildPage && d.componentName === ComponentName);
    return result && result.content;
  };

  const ExteriorPaintsTooltipData = filterFromData(
    TooltipData,
    ParentPage.INTERVENTIONS,
    ChildPage.INTERVENTIONS_SUMMARY,
    ComponentName.EXTERIOR_PAINTS,
    ComponentTooltipData.EXTERIOR_PAINTS
  );

  const handleError = (error) => swal({ icon: 'error', title: error });
  return (
    <>
      <Grid container justifyContent="flex-start" direction="column" item xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {data && data ? (
        <>
          {/* exterior implementation */}
          <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
            <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
              <Typography variant="h5">Exterior Paint</Typography>
              <CustomizedTooltips
                marginTop="15px"
                icon="info"
                title={ExteriorPaintsTooltipData.map((v) => (
                  <Typography>{v.data}</Typography>
                ))}
                placement="right"
              />
            </Grid>
            <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="exterior paint" name="paint schemes" value={value} onChange={handleChange}>
                  <FormControlLabel
                    value="preset1"
                    control={
                      <GreenRadio
                        checked={solventEnabled}
                        onChange={(e) => {
                          setSolventEnabled(e.target.checked);
                        }}
                      />
                    }
                    label="Solvent-based-paints"
                  />
                  <FormControlLabel
                    value="preset2"
                    control={
                      <GreenRadio
                        checked={waterBasedEnabled}
                        onChange={(e) => {
                          setWaterBasedEnabled(e.target.checked);
                        }}
                      />
                    }
                    label="Water-based Paint from popular brands"
                  />
                  {value === 'preset2' ? (
                    <Typography variant="h6" color={'textSecondary'} style={{ marginLeft: '8%', marginBottom: '3%', marginTop: '2%' }}>
                      Please select your paint brand
                    </Typography>
                  ) : (
                    ''
                  )}
                  {presetPips &&
                    value === 'preset2' &&
                    presetPips.map((pip) => {
                      if (pip.name === 'materials.preset.brand') {
                        return (
                          <>
                            <MaterialPresetBrand
                              value={value && value}
                              pip={pip && pip}
                              selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                              paintBrands={paintBrands && paintBrands}
                              setSelectedPaintBrand={(v) => setSelectedPaintBrand(v)}
                              appendChanged={(a, b) => appendChanged(a, b)}
                            />
                          </>
                        );
                      }
                      if (pip.name === 'materials.preset.name') {
                        return (
                          <>
                            <MaterialPresetPaint
                              value={value && value}
                              pip={pip && pip}
                              selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                              selectedPaintFinish={selectedPaintFinish && selectedPaintFinish}
                              paintNames={paintNames && paintNames}
                              selectedPaintName={selectedPaintName && selectedPaintName}
                              setSelectedPaintName={(v) => setSelectedPaintName(v)}
                              appendChanged={(a, b) => appendChanged(a, b)}
                            />
                          </>
                        );
                      }
                      if (pip.name === 'materials.preset.finish') {
                        return (
                          <>
                            <MaterialPresetFinish
                              value={value && value}
                              pip={pip && pip}
                              selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                              selectedPaintFinish={selectedPaintFinish && selectedPaintFinish}
                              paintFinishes={paintFinishes && paintFinishes}
                              setSelectedPaintFinish={(v) => setSelectedPaintFinish(v)}
                              appendChanged={(a, b) => appendChanged(a, b)}
                            />
                          </>
                        );
                      }
                    })}

                  <FormControlLabel
                    value="other"
                    control={<GreenRadio checked={manualEnabled} onChange={(e) => setManualEnabled(e.target.checked)} />}
                    label="Manual"
                  />
                  {value &&
                    value === 'other' &&
                    manualPips &&
                    manualPips.map((pip) => {
                      return (
                        <>
                          <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '2%' }}>
                            <Typography variant="h6">{pip.displayName}</Typography>
                          </Grid>
                          <Grid container alignItems="flex-start" item xs={7} style={{ marginLeft: '2%', marginTop: '3%' }}>
                            <Fields
                              placeholder="Enter name"
                              value={
                                myArray && myArray.find((d) => d.PIPScheme === pip._id)
                                  ? myArray && myArray.find((d) => d.PIPScheme === pip._id).value
                                  : projectPIP &&
                                    projectPIP.find((d) => d.PIPScheme.name === pip.name) &&
                                    projectPIP.find((d) => d.PIPScheme.name === pip.name).value
                              }
                              onChange={(e) => {
                                e.preventDefault();
                                appendChanged(pip._id, e.target.value);
                              }}
                              onClick={(e) => e.target.select()}
                              fullWidth
                              required
                            />
                          </Grid>
                        </>
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <InteriorPaints
            data={data && data}
            interiorPaintBrands={interiorPaintBrands && interiorPaintBrands}
            interiorPaintFinish={interiorPaintBrands && interiorPaintBrands}
            interiorPaintNames={interiorPaintBrands && interiorPaintBrands}
            appendChanged={(a, b) => appendChanged(a, b)}
            projectPIP={projectPIP && projectPIP}
            myArray={myArray && myArray}
          />

          <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
            <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
              <Typography variant="h5">{typeOfConcretePIP && typeOfConcretePIP.displayName}</Typography>
            </Grid>
            <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="exterior paint"
                  name="paint schemes"
                  value={
                    myArray &&
                    typeOfConcretePIP &&
                    myArray.length !== 0 &&
                    myArray.find((d) => d.PIPScheme === typeOfConcretePIP._id) &&
                    myArray.find((d) => d.PIPScheme === typeOfConcretePIP._id).value
                  }
                  onChange={(e) => {
                    appendChanged(typeOfConcretePIP && typeOfConcretePIP._id, e.target.value);
                  }}
                >
                  {typeOfConcretePIP.options.map((option, index) => {
                    return <FormControlLabel key={index} value={option} control={<GreenRadio />} label={option} />;
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <IndoorFlooring
            data={indoorFlooringPips && indoorFlooringPips}
            appendChanged={(a, b) => appendChanged(a, b)}
            myArray={myArray && myArray}
            projectPIP={projectPIP && projectPIP}
          />
        </>
      ) : (
        skeleton &&
        skeleton.map((d, index) => {
          return <Skeletons index={index} />;
        })
      )}
      <HandleErrorForWaterBasedSelection
        waterBasedEnabled={waterBasedEnabled && waterBasedEnabled}
        selectedPaintFinish={selectedPaintFinish && selectedPaintFinish}
        selectedPaintName={selectedPaintName && selectedPaintName}
        selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
        handleError={(v) => handleError(v)}
        handleNext={(v) => handleNext(v)}
        handleSave={(v) => handleSave(v)}
        next={next && next}
      />
    </>
  );
};

export default MaterialDetails;
