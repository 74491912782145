import React, { useContext, useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import noteicon from '../../../../../assets/interventions/Group 26086760.svg'
import useApiRequest from '../../../../hooks/useApiRequest';
import { useParams } from 'react-router';
import { NotetakingContext } from '../../../../../projectRouting/Index';

const useStyles = makeStyles((theme) => ({
    buttonstyle: {
        padding: '10px',
        borderRadius: "8px 0 0 8px",
        border: "1px solid rgba(38, 224, 163, 1)",
        boxShadow: '0px 4px 8px 0px #00000012',
        height: "auto",
        width: "auto",
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '0',
        borderRight: "none",
    },
    buttonimagestyle: {
        height: 'auto'
    },
    textstyle: {
        marginLeft: '10px',
        color: "rgba(0, 197, 138, 1)",
        fontWeight: 700
    }
}));

function FloatingButton({
    notetaking, setNotetaking,
    setAllNotes, makeapirequest }) {
    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);
    const { id, iid } = useParams();
    const { notetakingContext, handleNotetakingContext } = useContext(NotetakingContext)

    const [{ status, response }, makeRequest] = useApiRequest(`/note-taking?objectid=${iid && iid}`, {
        verb: 'get',
    });

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    if (response) {
        setAllNotes(response.data.data.data);
    }

    useEffect(() => {
        makeRequest()
    }, [makeapirequest])

    return (
        <>
            <Button
                className={classes.buttonstyle}
                style={{ background: notetaking ? 'transparent' : "rgba(255, 255, 255, 1)" }}
                onClick={() => {
                    setNotetaking(true)
                    makeRequest();
                    handleNotetakingContext()

                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <img src={noteicon} alt="" className={classes.buttonimagestyle} />
                {isHovered && <span className={classes.textstyle}>View Notes</span>}
            </Button>
        </>
    );
}

export default FloatingButton;
