import * as React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EnergyHeading from './Components-page-categories/EnergyHeading';
import WasteHeading from './Components-page-categories/WasteHeading';
import Waterheading from './Components-page-categories/WaterHeading';
import ProjectImpact from './Components-page-categories/ProjectImpact';
import CommonProjectSavingFooter from './Components-page-categories/CommonProjectSavingFooter';
import Footer from './Footer';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';

const useStyles = makeStyles((theme) => ({
  grid1: {
    marginTop: '3%',
    marginLeft: '5%',
  },
  spacing: {
    marginTop: '3%',
  },
}));

function Page3(props) {
  const classes = useStyles();
  const data = props.data;

  let capex = data && data[0].quantities.finance.capex.value;
  let opexsaving = data && data[0].quantities.finance.opexSavings.value;

  let barvalue1 = capex;
  let barvalue2 = opexsaving;

  let calculatedBarvalue1 = (barvalue2 / barvalue1) * 90;

  let totalsaving = data && data[0].quantities.finance.opexSavings.value;
  let totalressaving = data && data[0].quantities.resource.category.value;
  let unit = data && data[0].quantities.resource.category.unit;
  let energy =
    data && data[0].subcategories[0].interventions[0].quantities.details.find((para) => para && para.name === 'energy.annualbaselineenergyconsumption');
  let water = data && data[0].subcategories[0].interventions[0].quantities.details.find((para) => para && para.name === 'water.annualbaselinewaterconsumption');
  let waste = data && data[0].subcategories[0].interventions[0].quantities.details.find((para) => para && para.name === 'waste.annualbaselinewastegenration');

  let barvalue3 =
    props.category === 'Energy'
      ? energy && energy.value
      : props.category === 'Water'
      ? water && water.value
      : props.category === 'Waste'
      ? waste && waste.value
      : 0;
  let barvalue4 = barvalue3 - totalressaving;
  let calculatedBarvalue2 = (barvalue4 / barvalue3) * 90;
  let percentage = props.percentage;
  return (
    <>
      <Grid container xs={12} direction="column">
        <Grid container item xs={12} direction="row">
          {props.category === 'Energy' && <EnergyHeading companyName={props.companyName} />}
          {props.category === 'Water' && <Waterheading companyName={props.companyName} />}
          {props.category === 'Waste' && <WasteHeading companyName={props.companyName} pagenumber={props.pagenumber} />}
        </Grid>
        <Grid container item xs={12} direction="column" className={classes.grid1}>
          <ProjectImpact
            title="Financial Impact"
            title1="CAPEX"
            title2="Annual OPEX Savings"
            barvalue1={rsToCr('Rs', barvalue1)}
            barvalue2={rsToCr('Rs', barvalue2)}
            calculatedBarvalue2={calculatedBarvalue1}
            unit={'Cr'}
          />
        </Grid>
        <Grid container item xs={12} direction="column" className={classes.grid1}>
          {/* {barvalue3 && barvalue4 && ( */}
          <ProjectImpact
            title="Resource Impact"
            title1={
              props.category === 'Energy'
                ? 'Base Case Energy consumption'
                : props.category === 'Water'
                ? 'Base Case Water Consumption'
                : props.category === 'Waste'
                ? 'Base Case Waste Generated'
                : ''
            }
            title2={
              props.category === 'Energy'
                ? 'Sustainable Case Energy consumption'
                : props.category === 'Water'
                ? 'Sustainable Case Water Consumpion'
                : props.category === 'Waste'
                ? 'Sustainable Case Waste Generated'
                : ''
            }
            barvalue1={numberWithCommas(barvalue3)}
            barvalue2={numberWithCommas(barvalue4)}
            calculatedBarvalue2={calculatedBarvalue2}
            unit={unit}
          />
          {/* )} */}
        </Grid>
        <Grid container item xs={12} direction="column" style={{ marginTop: '5%', marginLeft: '5%' }}>
          <CommonProjectSavingFooter
            totalsaving={rsToCr('Rs', totalsaving)}
            totalressaving={totalressaving}
            unit={unit}
            percentage={percentage && percentage.value}
            subtitle1={
              props.category === 'Energy'
                ? 'is the annual savings on electricity bills for the'
                : props.category === 'Water'
                ? `Is the annual savings that would otherwise be`
                : props.category === 'Waste'
                ? 'Is the annual savings for the project, with'
                : ''
            }
            line2title={
              props.category === 'Energy'
                ? 'project'
                : props.category === 'Water'
                ? `spent to procure water for the project from`
                : props.category === 'Waste'
                ? 'reduced waste sent to landfills'
                : ''
            }
            line3title={props.category === 'Water' ? `external sources like water tankers` : ''}
            subtitle2={
              props.category === 'Energy'
                ? 'of energy saved by'
                : props.category === 'Water'
                ? 'of water being saved, the'
                : props.category === 'Waste'
                ? 'of waste avoided from being'
                : ''
            }
            subtitle3={
              props.category === 'Energy'
                ? 'deploying Renewable sources of energy, the'
                : props.category === 'Water'
                ? `project has ${percentage && percentage.value}% less water needed from`
                : props.category === 'Waste'
                ? `sent to the landfill, the project has ${percentage && percentage.value}% less`
                : ''
            }
            subtitle4={
              props.category === 'Energy'
                ? `project saves ${percentage && percentage.value}% energy`
                : props.category === 'Water'
                ? `external sources`
                : props.category === 'Waste'
                ? ` waste polluting the environment`
                : ''
            }
          />
        </Grid>
        <Grid container item xs={12} direction="column" style={{ marginTop: '10%', marginLeft: '5%' }}>
          <Footer />
        </Grid>
        {/* <div className={classes.spacing}></div> */}
      </Grid>
    </>
  );
}

export default Page3;
