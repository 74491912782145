import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import MenuItems from './MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ImageViewer from './ImageViewer';

const useStyles = makeStyles((theme) => ({
    paperStyle: {
        height: "auto",
        boxShadow: "none",
        borderWidth: '1px, 0px, 1px, 0px',
        borderStyle: 'solid',
        borderColor: 'rgba(204, 204, 204, 1)',
        borderLeftStyle: "none",
        borderRightStyle: "none",
        borderRadius: "0px",
        padding: '3%'
    },
    gridStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textStyle: {
        fontFamily: 'Lato',
        fontSize: '12px',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.5px',
        color: "rgba(0, 0, 0, 0.83)",
    },
    notetextStyle: {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
    },
    textspacingstyle: {
        paddingLeft: "10px"
    },
    attachmentgridstyle: {
        display: 'flex',
        alignItems: 'center',
    },
    spacing: {
        paddingTop: "10px"
    },
    iconStyle: {
        fontSize: 'large',
        color: "rgba(73, 221, 177, 1)",
        marginRight: "5px",
        Height: "20px",
        cursor: "pointer"
    },
    imagegridstyle: {
        paddingTop: "15px",
    },
    imagestyle: {
        marginRight: "10px",
        cursor: "pointer",
        border: '1px solid rgba(204, 204, 204, 1)',
        borderRadius: "8px",
        '&:hover': {
            border: '1px solid rgba(73, 221, 177, 1)'
        }
    },
}));
function NoteCard({
    data,
    allnotes,
    setMakeapirequest,
    setHideAddnotes,
    setNote,
    Uploadedimagesurls,
    setUploadedimageURLS,
    setNoteid,
    statcAssetids,
    drawerContentRef,
    makeapirequest }) {
    const classes = useStyles();
    const [openattachment, setOpenAttachment] = useState(false)
    const getTimeString = (lastEditedAt) => {
        const date = new Date(lastEditedAt);
        const timeString = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
        return timeString;
    };

    const getDateString = (lastEditedAt) => {
        const date = new Date(lastEditedAt);
        const dateStringFormatted = date.toLocaleDateString('en-IN', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
        return dateStringFormatted
    }
    const [showModal, setShowModal] = useState(false);
    const handleClick = () => {
        setShowModal(true);
    };

    function replaceUrlWithLink(text) {
        const urlRegex = /((?:https?:\/\/|www\.)\S+)/;
        const match = text.match(urlRegex);
        if (match) {
            const url = match[1];
            const parts = text.split(url);
            const fullUrl = url.startsWith("www.") ? `http://${url}` : url;
            parts.splice(1, 0, <a href={fullUrl} target="_blank" rel="noopener noreferrer">{url}</a>);
            return parts;
        }
        return text;
    }

    return (
        <>
            <Paper className={classes.paperStyle}>
                <Grid container item xs={12} className={classes.gridStyle}>
                    <div>
                        <Typography className={classes.textStyle}>{data?.user?.name}<span className={classes.textspacingstyle}>{getTimeString(data.lastEditedAt)}</span>
                            <span className={classes.textspacingstyle}>{getDateString(data.lastEditedAt)}</span></Typography>
                    </div>
                    <div>
                        <MenuItems
                            noteid={data._id}
                            setMakeapirequest={setMakeapirequest}
                            setHideAddnotes={setHideAddnotes}
                            setNote={setNote} data={data}
                            Uploadedimagesurls={Uploadedimagesurls}
                            setUploadedimageURLS={setUploadedimageURLS}
                            setNoteid={setNoteid}
                            statcAssetids={statcAssetids}
                            drawerContentRef={drawerContentRef}
                            makeapirequest={makeapirequest}
                        />
                    </div>
                </Grid>

                <Grid container item xs={12}>
                    <Typography className={classes.notetextStyle}> {replaceUrlWithLink(data?.note)}</Typography>
                </Grid>
                {data?.staticassetids.length !== 0 && <>
                    <Grid className={`${classes.attachmentgridstyle} ${classes.spacing}`}>
                        <Typography className={`${classes.notetextStyle} ${classes.attachmentgridstyle}`} style={{ cursor: "pointer" }} onClick={() => setOpenAttachment(!openattachment)}>
                            <InsertPhotoIcon className={classes.iconStyle} />Attachments ({data?.staticassetids.length})</Typography>
                        {!openattachment ? <ExpandMoreIcon className={classes.iconStyle} /> : <ExpandLessIcon className={classes.iconStyle} />}
                    </Grid>
                    {openattachment && <Grid container item xs={12} className={classes.imagegridstyle}>
                        {data?.staticassetids.map((assets) => <img src={assets?.URL} alt="..." height="75px" width="75px" className={classes.imagestyle} onClick={handleClick} />)}
                    </Grid>}
                </>}
            </Paper>
            <ImageViewer staticassetids={data?.staticassetids} showModal={showModal} setShowModal={setShowModal} />
        </>
    );
}

export default NoteCard;
