import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import RangeBar from './RangeBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    maxWidth: 560,
    height: 267,
    backgroundColor: '',
    marginLeft: 261,
    marginTop: 30,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    color: '#000000',
    marginTop: 20,
    marginLeft: '5%',
    lineHeight: '22px',
  },
  pos: {
    marginBottom: 12,
    marginLeft: 216,
    marginTop: 30,
  },
  para: {
    fontSize: 14,
    color: '#000000',
    marginLeft: '5%',
  },
  maincard: {
    maxWidth: '560px',
    borderRadius: 10,
    boxShadow: 'none',
  },
  cardwrapper: { marginTop: '30px' },
  letter: {
    letterSpacing: '1.72857px',
  },
});

export default function Cards({ cardName, title1, description1, description2, icon, style, id, rangevalue, handleChange, styleNew }, props) {
  const classes = useStyles();
  const changeGoalPercentage = (e, value) => handleChange({ goalProjectMappingId: id, value: value });
  return (
    <>
      <Grid className={classes.cardwrapper}>
        <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.38)', paddingBottom: '1%' }} className={classes.letter}>
          {cardName}
        </Typography>
        <Card className={classes.maincard} style={style}>
          <CardContent>
            <Grid container direction="row">
              <Grid>
                <img src={icon} alt="..." />
              </Grid>
              <Grid>
                <Typography variant="h4" style={{ color: '#000000', marginLeft: '10px', marginTop: '3%' }}>
                  {title1}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="h5" className={classes.title} gutterBottom>
              {description1}
            </Typography>
            <Grid container direction="row" alignItems="center" style={{ paddingTop: '3%', paddingBottom: '3%', textAlign: 'center' }}>
              <RangeBar style={styleNew} rangevalue={rangevalue} handleChange={changeGoalPercentage} />
            </Grid>
            <Grid container direction="row">
              <Typography variant="h5" component="p" className={classes.para}>
                {description2}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
