import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../../../../config/Axios';
import swal from 'sweetalert';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  spacing: {
    paddingTop: '5%',
  },
  spacing2: {
    marginTop: '-3%',
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doneicon: {
    color: 'green',
  },
  closeicon: {
    color: 'red',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const PIPSchemeTable = (props) => {
  const classes = useStyles();
  const [changedOptions, setChangedOptions] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [changedName, setChangedName] = useState('');
  const [changedDisplayName, setChangedDisplayName] = useState('');
  const [changedUnit, setChangedUnit] = useState('');
  const [changedCategory, setChangedCategory] = useState('');
  const [changedDescription, setChangedDescription] = useState('');
  const [changedRequired, setChangedRequired] = useState('');

  const handleChangeName = (e) => setChangedName(e.target.value);
  const handleChangeDisplayName = (e) => setChangedDisplayName(e.target.value);
  const handleChangeUnit = (e) => setChangedUnit(e.target.value);
  const handleChangeCategory = (e) => setChangedCategory(e.target.value);
  const handleChangeDescription = (e) => setChangedDescription(e.target.value);
  const handleRequired = (e) => setChangedRequired(e.target.value);
  const handleOptionChange = (e) => setChangedOptions(e.target.value);
  //name change
  const nameChange = (e) => {
    e.preventDefault();
    let body = {
      newName: changedName ? changedName : props.name,
    };
    axios
      .put(`/pipschemev2/${props.id}`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Name' });
        setSelectedValue('');
        setChangedName('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //display name change
  const displayNameChange = (e) => {
    e.preventDefault();
    let body = {
      newDisplayName: changedDisplayName ? changedDisplayName : props.displayName,
    };
    axios
      .put(`/pipschemev2/${props.id}`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Display Name' });
        setSelectedValue('');
        setChangedDisplayName('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //category change
  const categoryChange = (e) => {
    e.preventDefault();
    let body = {
      newCategory: changedCategory ? changedCategory : props.category,
    };
    axios
      .put(`/pipschemev2/${props.id}`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated category' });
        setSelectedValue('');
        setChangedUnit('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //unit change
  const unitChange = (e) => {
    e.preventDefault();
    let body = {
      newUnit: changedUnit ? changedUnit : props.unit,
    };
    axios
      .put(`/pipschemev2/${props.id}`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated unit' });
        setSelectedValue('');
        setChangedUnit('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //description change
  const descriptionChange = (e) => {
    e.preventDefault();
    let body = {
      newDescription: changedDescription ? changedDescription : props.description,
    };
    axios
      .put(`/pipschemev2/${props.id}`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated description' });
        setSelectedValue('');
        setChangedDescription('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //required change
  const requiredChange = (e) => {
    e.preventDefault();
    let body = {
      isRequired: changedRequired ? changedRequired : props.required,
    };
    axios
      .put(`/pipschemev2/${props.id}`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated required property' });
        setSelectedValue('');
        setChangedRequired('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //options change
  const optionsChange = (e) => {
    e.preventDefault();
    let body = {
      options: changedOptions ? changedOptions.split('|') : props.options,
    };
    axios
      .put(`/pipschemev2/${props.id}`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated required property' });
        setSelectedValue('');
        setChangedRequired('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  const handleSelect = (e) => {
    if (e.target.value === 'none') {
      setSelectedValue('');
    } else {
      setSelectedValue(e.target.value);
    }
  };
  const handleClose = () => {
    setSelectedValue('');
    setChangedName('');
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '2%' }}>
        <Grid item xs={12} align="left">
          <>
            <Accordion>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={2}>
                    <Typography className={classes.heading}>
                      <b>Name</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'name' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <TextField id="standard-basic" label="Username" value={changedName} onChange={handleChangeName} />
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={nameChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.name}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className={classes.heading}>
                      <b>Display Name</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'display name' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <TextField id="standard-basic" label="display name" value={changedDisplayName} onChange={handleChangeDisplayName} />
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={displayNameChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.displayName}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={1}>
                    <Typography className={classes.heading}>
                      <b>Category</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'category' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={handleChangeCategory}
                                className={classes.inputField}
                                style={{ width: '100%' }}
                              >
                                <MenuItem value="basic">Basic</MenuItem>
                                <MenuItem value="land">Land</MenuItem>
                                <MenuItem value="dwelling">Dwelling</MenuItem>
                                <MenuItem value="common">Common</MenuItem>
                                <MenuItem value="fixture">Fixture</MenuItem>
                              </Select>{' '}
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={categoryChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.category}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={1}>
                    <Typography className={classes.heading}>
                      <b>Unit</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'unit' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={handleChangeUnit}
                                className={classes.inputField}
                                style={{ width: '100%' }}
                              >
                                {props.units &&
                                  props.units.map((unit) => {
                                    return <MenuItem value={unit}>{unit}</MenuItem>;
                                  })}
                              </Select>{' '}
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={unitChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.unit}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className={classes.heading}>
                      <b>Description</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'description' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <TextField id="standard-basic" label="description" value={changedDescription} onChange={handleChangeDescription} />
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={descriptionChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.description}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={1}>
                    <Typography className={classes.heading}>
                      <b>Required</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'required' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleRequired}>
                                <MenuItem value="true">True</MenuItem>
                                <MenuItem value="false">False</MenuItem>
                              </Select>
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={requiredChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.required.toString()}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={1}>
                    <Typography className={classes.heading}>
                      <b>Options</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'options' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <TextField id="standard-basic" label="options" value={changedOptions} onChange={handleOptionChange} />
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={optionsChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : props.options && props.options.length > 0 ? (
                      props.options.map((s) => {
                        return (
                          <>
                            <Typography className={classes.secondaryHeading}>{s}</Typography>
                          </>
                        );
                      })
                    ) : null}
                  </Grid>

                  <Grid item xs={1} align="center">
                    <Typography className={classes.heading2}>
                      <b>Update</b>
                    </Typography>
                    <br />
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleSelect} defaultValue="none">
                      <MenuItem value="name">Name</MenuItem>
                      <MenuItem value="display name">Display Name</MenuItem>
                      <MenuItem value="unit">Unit</MenuItem>
                      <MenuItem value="category">Category</MenuItem>
                      <MenuItem value="description">Description</MenuItem>
                      <MenuItem value="required">Required</MenuItem>
                      <MenuItem value="options">Options</MenuItem>
                      <MenuItem value="none">None</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={1} align="center">
                    <Typography className={classes.heading2}>
                      <b>Delete</b>
                    </Typography>
                    <br />
                    <DeleteIcon onClick={props.delete} />
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default PIPSchemeTable;
