import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import SpericalWidgetCard from '../common/spericalWidgetCard';
import RightOverview from './RightOverview';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';

const useStyles = makeStyles((theme) => ({
  overviewgrid1: {
    marginTop: '2%',
  },
}));

function Overview2(props) {
  const classes = useStyles();
  const { inputs } = props;
  const total1 = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('overview-bar-total')) : '';
  const max = inputs ? inputs.filter((item) => item && item.tags.includes('overview-bar-max')) : '';
  const min = inputs ? inputs.filter((item) => item && item.tags.includes('overview-bar-min')) : '';
  const breakups = inputs ? inputs.filter((item) => item && item.tags.includes('overview-breakup')) : '';

  const [breakupcount, setBreakupcount] = useState([]);
  const [barvalues, setBarvalues] = useState([]);
  const [labels, setLabels] = useState([]);

  /*Don't add breakup in the dependency array it will go to infinity loop*/
  useEffect(() => {
    let arr = [];
    let arr3 = [];
    let arr4 = [];
    let arr2 = [1, 2, 3, 4, 5, 6];
    arr2.map((num) => {
      if (num <= breakups.length) {
        arr.push(`${num}`);
      }
      return setBreakupcount(arr);
    });
    breakups &&
      // eslint-disable-next-line array-callback-return
      breakups.map((breakup) => {
        arr3.push(breakup.value);
        arr4.push(breakup.label);
        setBarvalues(arr3);
        setLabels(arr4);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakups.length]);

  return (
    <>
      <Grid container alignItems="flex-start" direction="row" xs={12} className={classes.overviewgrid1}>
        <Grid xs>
          <Typography variant="h5">{props.title}</Typography>
        </Grid>
      </Grid>
      {inputs && inputs ? (
        <Grid container alignItems="flex-start" direction="row" xs={12}>
          <Grid item xs={6}>
            <RightOverview
              barmax={max && max[0] ? `${max[0].value}` : ''}
              barmaxUnit={total1 && total1[0] ? total1[0].unit : ''}
              barmin={min && min[0] ? `${min[0].value} ${min[0].unit}` : ''}
              heading={total1 && total1[0] ? total1[0].label : ''}
              color={props.color}
              title={labels}
              counts={breakupcount}
              barvalues={barvalues}
            />
          </Grid>
          {/* <Grid item xs={1} /> */}
          <Grid item xs={6}>
            <SpericalWidgetCard inputs={inputs} textcolor={props.textcolor} circlecolor={props.circlecolor} heading="Contribution to Total Savings" />
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="flex-start" direction="row" xs={12}>
          <Grid item xs={6}>
            <CasaSkeleton variant="rect" animation="wave" style={{ height: 450, minWidth: 490 }} />
          </Grid>
          <Grid item xs={5}>
            <CasaSkeleton variant="rect" animation="wave" style={{ height: 450, minWidth: 390, marginLeft: '28px' }} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Overview2;
