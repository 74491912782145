import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../../routing/IndexRouting';
import PreviosSavesData from './PreviousSaveData';

export const AllProjectContext = React.createContext();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '950px',
    outline: 'none',
    minHeight: 500,
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    padding: theme.spacing(2, 4, 3),
  },
  modalhead: {
    lineHeight: '25px',
    textAlign: 'center',
    color: '#000000',
  },
  heading: {
    marginTop: '3%',
    lineHeight: '25px',
    textAlign: 'left',
    color: '#000000',
  },
  close: {
    marginLeft: '98%',
  },
}));

const ViewPreviosSavesModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const { userDetails } = useContext(UserContext);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { inputs } = props;

  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <CloseIcon className={classes.close} onClick={handleClose} />
      <Typography variant="h4" className={classes.modalhead}>
        <b>View Previous States and Drafts</b>
      </Typography>
      <div>
        <Typography className={classes.heading} variant="h5">
          Currently Active
        </Typography>
        {props.activeSandbox &&
          props.activeSandbox.map((data) => {
            return (
              <PreviosSavesData
                savedOn={data.createdAt.slice(0, 10)}
                savedBy={data.createdBy !== 'system-default' ? userDetails.name : data.createdBy}
                waterSaved={'NA'}
                sumpSize={'NA'}
                capex={'NA'}
                opex={'NA'}
                buttonName="SELECTED"
                butonStyle={{
                  width: '150px',
                  background: 'linear-gradient(90deg, rgba(31, 222, 219, 0.5) 0%, rgba(66, 225, 152, 0.5) 100%, rgba(66, 225, 152, 0.5) 100%)',
                }}
              />
            );
          })}
      </div>
      <Typography className={classes.heading} variant="h5">
        Your Previous Saves and Drafts
      </Typography>
      <div>
        {props.allSandboxes &&
          props.allSandboxes.map((data) => {
            return (
              <PreviosSavesData
                savedOn={data.createdAt.slice(0, 10)}
                savedBy={data.createdBy !== 'system-default' ? userDetails.name : data.createdBy}
                waterSaved={'NA'}
                sumpSize={'NA'}
                capex={'NA'}
                opex={'NA'}
                buttonName="SELECT"
                butonStyle={{
                  width: '150px',
                }}
                buttonClick={() => props.handleRevert(data._id)}
              />
            );
          })}
      </div>
    </div>
  );

  return (
    <div>
      <Link
        className="button"
        style={{
          background: 'transparent',
          color: '#00C58A',
          cursor: 'pointer',
          width: '30%',
          fontWeight: 900,
          // display: 'none',
        }}
        to="#"
        // onClick={handleOpen}
      >
        <Typography variant="h6">
          <b>VIEW PREVIOUS STATES</b>
        </Typography>
      </Link>
      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        {body1}
      </Modal>
    </div>
  );
};
export default ViewPreviosSavesModal;
