import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import useApiRequest from '../../../hooks/useApiRequest';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CustomLink from '../../../common/link/CustomLink';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import PIPSchemeTable from './components/Table';
import axios from '../../../../config/Axios';
import swal from 'sweetalert';
import SearchBar from '../../../common/Searchbar/Search';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    paddingBottom: '5%',
  },
}));
const PIPScheme = () => {
  const skele = [1, 2, 3];
  const classes = useStyles();
  const [name, setName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [unit, setUnit] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [units, setUnits] = useState('');
  const [categories, setCategories] = useState('');
  const [options, setOptions] = useState('');
  const [required, setRequired] = useState(false);

  const [PIPSchemes, setPIPSchemes] = useState('');
  const [searchedData, setSearchedData] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [modal, setModal] = useState(false);
  const [{ status, response }, makeRequest] = useApiRequest('/pipschemev2', {
    verb: 'get',
  });
  if (status === null) makeRequest();
  useEffect(() => {
    if (response) {
      setPIPSchemes(response.data.data);
    }
  }, [response]);
  useEffect(() => {
    axios
      .get('/pipschemev2?value=categories')
      .then((res) => {
        if (res && res.data && res.data.data) {
          setCategories(res.data.data.data);
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  }, []);
  useEffect(() => {
    axios
      .get('/pipschemev2?value=units')
      .then((res) => {
        if (res && res.data && res.data.data) {
          setUnits(res.data.data.data);
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  }, []);
  const handleRequired = (e) => setRequired(e.target.value);
  const handleName = (e) => setName(e.target.value);
  const handleDisplayName = (e) => setDisplayName(e.target.value);
  const handleUnit = (e) => setUnit(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleCategory = (e) => setCategory(e.target.value);
  const handleCancelUpdate = () => setModal(false);
  const handleClose = () => setModal(false);
  const addModal = () => setModal(true);
  const handleDelete = (id) => {
    axios
      .delete(`/pipschemev2/${id}`)
      .then(() => {
        swal({ icon: 'success', title: 'Successfully deleted PIP' });
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };
  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = PIPSchemes && PIPSchemes.data.filter((data) => data.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setSearchedData(newData);
  };

  //options handle
  const handleOptions = (e) => setOptions(e.target.value);

  const handleAddPIP = () => {
    const body = {
      name: name,
      displayName: displayName,
      category: category,
      unit: unit,
      description: description,
      required: required,
      options: options ? options.split('|') : [],
    };
    axios
      .post(`/pipschemev2`, body)
      .then(() => {
        setModal(false);
        swal({ icon: 'success', title: 'Successfully added project input parameter' });
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={9} align="left">
          <Typography variant="h3">Project Input Parameters</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <CustomLink
            onClick={addModal}
            label={
              <Typography variant="h5">
                Add New
                <AddCircleRoundedIcon style={{ fontSize: '32px' }} />
              </Typography>
            }
          />
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="search by PIP Name" style={{ width: '80%' }} />
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        {searchedData && searchedData ? (
          searchedData.map((data) => {
            return (
              <PIPSchemeTable
                name={data.name}
                displayName={data.displayName}
                required={data.required}
                unit={data.unit}
                category={data.category}
                description={data.description}
                delete={() => handleDelete(data._id)}
                id={data._id}
                handleUpdate={() => makeRequest()}
                units={units && units}
                options={data.options}
              />
            );
          })
        ) : PIPSchemes && PIPSchemes.data ? (
          PIPSchemes.data.map((data) => {
            return (
              <PIPSchemeTable
                name={data.name}
                displayName={data.displayName}
                required={data.required}
                unit={data.unit}
                category={data.category}
                description={data.description}
                delete={() => handleDelete(data._id)}
                id={data._id}
                handleUpdate={() => makeRequest()}
                units={units && units}
                options={data.options}
              />
            );
          })
        ) : (
          <>
            {skele &&
              skele.map((data) => {
                return <Skeleton animation="wave" height={50} width="100%" />;
              })}
          </>
        )}
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <Typography gutterBottom variant="h5" component="h2">
              Add New PIP Scheme
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField id="standard-basic" label="Name" value={name} onChange={handleName} className={classes.inputField} /> <br />
              <TextField id="standard-basic" label="Display Name" value={displayName} onChange={handleDisplayName} className={classes.inputField} /> <br />
              <br />
              <Typography>select category</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleCategory}
                className={classes.inputField}
                style={{ width: '100%' }}
              >
                {categories &&
                  categories.map((value) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
              </Select>{' '}
              <br />
              <br />
              <Typography>select unit</Typography>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleUnit} className={classes.inputField} style={{ width: '100%' }}>
                {units &&
                  units.map((value) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
              </Select>{' '}
              <br />
              <TextField id="standard-basic" label="description" value={description} onChange={handleDescription} className={classes.inputField} />
              <br />
              <br />
              <TextField id="standard-basic" label="options" value={options} onChange={handleOptions} className={classes.inputField} />
              <br />
              <br />
              <Typography>required</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleRequired}
                className={classes.inputField}
                style={{ width: '100%' }}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>{' '}
              <br />
              <br />
              <Grid item xs />
              <br />
            </form>
            <Link size="small" color="primary" onClick={handleAddPIP}>
              Add
            </Link>
            <Link size="small" color="primary" onClick={handleCancelUpdate} style={{ marginLeft: '10%' }}>
              Cancel
            </Link>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default PIPScheme;
