import React from 'react';
import { Typography } from '@material-ui/core';

export default function CustomTooltipTitle({ data }) {
  return (
    <>
      {data &&
        data.map((d, index) => {
          return (
            <Typography variant="h6" style={index > 0 ? { marginTop: '3%', lineHeight: '20px' } : { lineHeight: '20px' }}>
              {d.data}
            </Typography>
          );
        })}
    </>
  );
}
