import { useReducer } from 'react';
import axios from 'axios';
import reducer, { initialState } from '../Common/reducer';

const useGraphqlRequest = (endpoint) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const SendRequest = () => {
    axios
      .post(
        process.env.REACT_APP_STRAPI_URL,
        {
          query: endpoint,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
          },
        }
      )
      .then((response) => {
        dispatch({ type: 'success', response: response });
      })
      .catch((e) => {
        dispatch({ type: 'failure', error: e });
      });
  };

  return [state, SendRequest];
};

export default useGraphqlRequest;
