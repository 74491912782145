import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import CommonCardNew from '../common/commomCardNew';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import WasteMandateImage from '../../../../../assets/interventions/WasteMandateImage.svg';
import noteicon from '../../../../../assets/interventions/Group 26086760.svg'
import NoteTaking from '../noteTaking/NoteTakingDrawer';
import FloatingButton from '../noteTaking/FloatingButton';
import { NotetakingContext } from '../../../../../projectRouting/Index';

const useStyles = makeStyles((theme) => ({
  paper2: {
    outline: 'none',
    minHeight: 70,
    minWidth: 160,
    padding: theme.spacing(2, 3, 2),
    marginTop: '1%',
    background: '#FFFFFF',
    borderRadius: '10px',
    height: 'auto',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    position: 'relative',
  },
  typo: {
    marginLeft: '5%',
    cursor: 'pointer',
  },
  spacing: {
    marginTop: theme.spacing(3),
  },
  errorIcon: {
    color: '#66E3BE',
    cursor: 'pointer',
  },
  summarytext: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    color: '#000000',
    paddingLeft: '5px',
  },
  floatingButtonStyle: {
    overflow: 'hidden',
    position: 'fixed',
    right: '0',
    bottom: '37%',
    padding: '100px',
  }
}));
function Overview1({ title, handleEditParameters, inputs, selectedIntervention, mandateSummary }) {
  const classes = useStyles();

  /*For new note taking feature*/
  // const { notetakingContext, handleNotetaking } = useContext(NotetakingContext)

  const [notetaking, setNotetaking] = useState(false);
  const [allnotes, setAllNotes] = useState([])
  const [hideaddnotes, setHideAddnotes] = useState(false)
  const [makeapirequest, setMakeapirequest] = useState(false)
  return (
    <>
      <Grid container xs={12} direction="row" style={{ overflow: 'hidden' }}>
        <Grid container item xs={3}>
          <Typography variant="h5">{title}</Typography>
          <CustomizedTooltips
            marginTop="15px"
            icon="info"
            title={
              <>
                <Typography>
                  {' '}
                  Explore different intervention results by changing key parameters.
                  <br />
                  As some parameters depend on each other, change only 1 at a time
                  <br />
                </Typography>
                {selectedIntervention && selectedIntervention === 'Rooftop RWH' && (
                  <Typography style={{ marginTop: '10px' }}>
                    Domestic includes water used for cooking, bathing, washing clothes and dishes, watering gardens, and maintaining pools.
                  </Typography>
                )}
                {selectedIntervention && selectedIntervention === 'Grey Water Re-use' && (
                  <Typography style={{ marginTop: '10px' }}>
                    Domestic includes water used for cooking, bathing, washing clothes and dishes, watering gardens, and maintaining pools.
                  </Typography>
                )}
              </>
            }
            placement="right"
          />
        </Grid>
        <Grid item xs={9} className={classes.floatingButtonStyle} style={{ zIndex: !notetaking ? 9999 : 0 }}>
          <FloatingButton style={{ pointerEvents: 'auto' }}
            notetaking={notetaking} setNotetaking={setNotetaking}
            setAllNotes={setAllNotes} hideaddnotes={hideaddnotes} makeapirequest={makeapirequest} />
        </Grid>
      </Grid>
      <Grid container direction="row" xs={12}>
        <Grid xs={4} container direction="column" data-tour="parameter">
          {inputs ? (
            <CommonCardNew
              title={title}
              showTotalCapex={false}
              inputs={inputs}
              height="auto"
              width="auto"
              selectedIntervention={selectedIntervention}
              tooltipReady={selectedIntervention && selectedIntervention === 'Sanitary Napkin Incinerator' ? true : false}
              tooltipBody={
                <span>
                  System Capacity is expressed in number of incinerators required,
                  <br />
                  where each incinerator can handle up to 500 napkins.
                </span>
              }
            />
          ) : (
            <CasaSkeleton variant="rect" height="25vh" width={350} animation="wave" style={{ marginTop: '1%' }} />
          )}
        </Grid>
      </Grid>
      <Grid xs={7} container direction="column" data-tour="parameter" style={{ paddingTop: '10px', paddingLeft: '10px' }}>
        {mandateSummary && mandateSummary[0].value[0] !== '' ? (
          <Typography>
            <img src={WasteMandateImage} height="16" alt="..." /> <b className={classes.summarytext}>{mandateSummary[0].value[0]}</b>
          </Typography>
        ) : (
          ''
        )}
      </Grid>
      <NoteTaking
        notetaking={notetaking}
        setNotetaking={setNotetaking}
        allnotes={allnotes}
        setAllNotes={setAllNotes}
        hideaddnotes={hideaddnotes}
        setHideAddnotes={setHideAddnotes}
        makeapirequest={makeapirequest}
        setMakeapirequest={setMakeapirequest}
      />
    </>
  );
}

export default Overview1;
