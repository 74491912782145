export const searchSuggestionQuery = (searchquery) => `query Q {
    searchSuggestions(searchParam: "${searchquery}") {
      supercategory{
        supercategoryname
      }
      category{
        supercategoryname
        categoryname
      }
      subcategory {
      subcategoryname
      categoryname
      }
        products {
          name
          id
          image
          category{
            id
          }
        }
      }
  }
  `;
