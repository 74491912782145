import React, { useState, useEffect, useContext } from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import Insights from './insights/insights';
import Rightbar from './components/Rightbar';
import { useParams } from 'react-router';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import axios from '../../../config/Axios';
import { wlakthroughContext } from '../../../projectRouting/Index';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PreviousPageContext, TimeframeContext } from '../../../routing/IndexRouting';

const useStyles = makeStyles((theme) => ({
  tourtexthead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '10px',
  },
  tourtext: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'justify',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function MainGrid(props) {
  const classes = useStyles();
  const [newintervention, setNewIntervention] = useState('');
  const [project, setProject] = useState('');
  const [goals, setGoals] = useState('');
  const [insights, setinsights] = useState('');
  const [sustainabilityLevel, setSustainabilityLevel] = useState('');

  let { id } = useParams();

  /*Context for how it works*/
  const { uiWalkthroughContext, handleMyWalkthrough } = useContext(wlakthroughContext);
  const { timeframeContext, loader, setContextLoader } = useContext(TimeframeContext);
  const { setPreviousPage } = useContext(PreviousPageContext);

  /*Get status of how it works*/
  const [userprofile, setUserprofile] = useState('');

  useEffect(() => {
    setPreviousPage('insights');
  }, []);

  useEffect(() => {
    axios.get(`/userprofiles`).then((resp) => {
      if (resp.data && resp.data.data && resp.data.data) {
        setUserprofile(resp.data.data.data);
        handleMyWalkthrough(resp.data.data.data.showhowitworks);
      }
    });
  }, []);
  /*End for all walkthrough*/
  const handleEnd = () => {
    axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => {});
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  /*Implementation of tour*/
  let projectId = props.match.params.id;
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const handleTourClick = () => {
    props.history.push(`/projects/${projectId}/interventions`);
  };

  const [isTourOpen, setIsTourOpen] = useState(false);

  const closeTour = () => {
    if (userprofile.showhowitworks === true) {
      axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => {});
    }
    setIsTourOpen(false);
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  useEffect(() => {
    if (uiWalkthroughContext) setIsTourOpen(uiWalkthroughContext);
  }, [uiWalkthroughContext]);

  let tourProps = {
    isOpen: isTourOpen,
    onRequestClose: closeTour,
    steps: [
      {
        selector: '[data-tour="sustainabilitylevel"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Sustainability Level</Typography>
            <Typography className={classes.tourtext}>
              An innovative design ranking system developed by SD+. This tells you how sustainable your building is. The highest ranking is forest, lowest is
              sprout.
            </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              You can also check the IGBC ratings of your building here.
            </Typography>
          </Grid>
        ),
        position: 'top',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '250px',
        },
      },
      {
        selector: '[data-tour="locationbasedinsights"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Location Based Insights</Typography>
            <Typography className={classes.tourtext}>Environmental data specific to the project location</Typography>
          </Grid>
        ),
        position: 'top',
        // position: [890, 150],
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '150px',
        },
      },
      {
        selector: '[data-tour="goaltracking"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Tracking Goals</Typography>
            <Typography className={classes.tourtext}>
              The goals set during project creation can be tracked to see the present status compared to the target goal.
            </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              The goals are displayed category wise
            </Typography>
            <Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleTourClick}>
              Interventions
            </Button>
          </Grid>
        ),
        position: 'top',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '220px',
        },
      },
    ],
  };

  useEffect(() => {
    if (id && timeframeContext) {
      setContextLoader(true);
      axios
        .get(`/projectv2/${id}?NumberOfYears=${timeframeContext}`)
        .then((res) => {
          const goals = res.data.data.data.goals.categories[0];
          const interv = res.data.data.data.interventions;
          const locationBasedInsight = res.data.data.data.locationBasedInsights;
          setSustainabilityLevel(res.data.data.data.sustainabilityLevel);
          setinsights(locationBasedInsight);
          setProject(res.data.data.data);
          setNewIntervention(interv);
          setGoals(goals);
          setContextLoader(false);
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    }
  }, [id, timeframeContext]);

  return (
    <>
      {/* uncomment this out while using this component in play area - without data */}
      {/* <main className={classes.content}> */}
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress style={{ color: '#28DEAA' }} />
      </Backdrop>
      <Grid container>
        <Grid item sm={8} lg={8} xl={8} style={{ backgroundColor: 'rgba(24, 50, 119, 0.07)' }}>
          <Insights
            sustainabilityLevel={sustainabilityLevel && sustainabilityLevel}
            newintervention={newintervention && newintervention}
            insights={insights && insights}
            goals={goals}
            project={project}
          />
        </Grid>
        <Grid item sm={4} lg={4} xl={4} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Rightbar newintervention={newintervention} goals={goals} project={project} />
        </Grid>

        <Tour
          {...tourProps}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          lastStepNextButton={
            <Button style={{ color: '#29E1AC', marginLeft: '150%', marginTop: '-60px', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleEnd}>
              End
            </Button>
          }
          disableKeyboardNavigation={['esc']}
          showNavigation={false}
          showNumber={false}
          prevButton
          nextButton={<Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', marginLeft: '150%', fontSize: '12px', marginTop: '-10px' }}>Next</Button>}
          disableInteraction={true}
          badgeContent={(curr, tot) => `${curr} of ${tot}`}
          rounded={12}
          startAt={0}
        />
      </Grid>
      {/* </main> */}
    </>
  );
}
