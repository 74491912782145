import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

function Mapper(props) {
  const GreenCheckbox = withStyles({
    root: {
      color: props.stpDisable && props.stpDisable === true ? 'grey' : '#2EE1AC',
      '&$checked': {
        color: props.stpDisable && props.stpDisable === true ? 'grey' : '#2EE1AC',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const [presetPIP, setPresetPIP] = useState('');
  const [faucetBrandPIP, setFaucetBrandPIP] = useState('');
  const [ceramicsBrandPIP, setCeramicsBrandPIP] = useState('');
  const [faucetTagPIP, setFaucetTagPIP] = useState('');
  const [ceramicsTagPIP, setCeramicsTagPIP] = useState('');

  useEffect(() => {
    if (props.presetEnabledData) {
      const v1 = props.fixturesData && props.fixturesData.find((D) => D.name === props.presetEnabledData);
      const v2 = props.fixturesData && props.fixturesData.find((D) => D.name === props.faucetBrand);
      const v3 = props.fixturesData && props.fixturesData.find((D) => D.name === props.ceramicsBrand);
      const v4 = props.fixturesData && props.fixturesData.find((D) => D.name === props.faucetTag);
      const v5 = props.fixturesData && props.fixturesData.find((D) => D.name === props.ceramicsTag);

      setPresetPIP(v1 && v1);
      setFaucetBrandPIP(v2 && v2);
      setCeramicsBrandPIP(v3 && v3);
      setFaucetTagPIP(v4 && v4);
      setCeramicsTagPIP(v5 && v5);
    }
  }, [props]);
  return (
    <>
      {props.name === 'usingnbc'
        ? props.fixturesData &&
          props.fixturesData.map((d) => {
            if (d.name === props.name) {
              return (
                <>
                  <Grid item xs={0}>
                    <GreenCheckbox
                      icon={props.icon}
                      checkedIcon={props.checkedIcon}
                      disabled={props.disabled}
                      checked={props.checked}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        props.appendChanged(presetPIP && presetPIP._id, e.target.checked);
                        props.appendChanged(faucetBrandPIP && faucetBrandPIP._id, e.target.checked ? 'Jaquar' : 'undefined');
                        props.appendChanged(ceramicsBrandPIP && ceramicsBrandPIP._id, e.target.checked ? 'Hindware' : 'undefined');
                        props.appendChanged(faucetTagPIP && faucetTagPIP._id, e.target.checked ? 'NBC Case' : 'undefined');
                        props.appendChanged(ceramicsTagPIP && ceramicsTagPIP._id, e.target.checked ? 'NBC Case' : 'undefined');
                      }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="h6" style={{ marginTop: '12px' }} color={props.typoColor}>
                      {d.displayName}
                    </Typography>
                  </Grid>
                </>
              );
            }
          })
        : props.fixturesData &&
          props.fixturesData.map((d) => {
            if (d.name === props.name) {
              return (
                <>
                  <Grid item xs={0}>
                    <GreenCheckbox
                      icon={props.icon}
                      checkedIcon={props.checkedIcon}
                      disabled={props.stpDisable && props.stpDisable === true ? true : props.disabled}
                      checked={props.checked}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        props.appendChanged(d._id, e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid container item xs={11}>
                    <Typography variant="h6" style={{ marginTop: '12px' }} color={props.typoColor}>
                      {d.displayName}
                    </Typography>
                    {props.name === 'gwr' && <CustomizedTooltips icon="info" marginTop="10px" title={props.tooltipData} placement="right" />}
                  </Grid>
                </>
              );
            }
          })}
    </>
  );
}

export default Mapper;
