import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import cookie from 'react-cookies';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../routing/IndexRouting';
import { ProjectContext } from '../../../routing/IndexRouting';
import ContactUs from './modal/Contact-Us';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    maxWidth: 120,
    marginLeft: 10,
    outline: 'none',
    textDecoration: 'noUnderline',
    overflow: 'hidden',
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    textDecoration: 'noUnderline',
    borderStyle: 'none',
    '& .MuiSvgIcon-root': {
      top: 7,
    },
  },
}));

export default function DropDown(props) {
  const classes = useStyles();
  const id = props.id;
  let history = useHistory();
  const { handleLoginToken, userDetails, setUserDetails } = useContext(UserContext);
  const { handleProjectData } = useContext(ProjectContext);

  const [option, setOption] = React.useState('');
  const [modal, setModal] = useState(false);
  const handleChange = (event) => setOption(event.target.value);

  useEffect(() => {
    if (option && option === 'help') history.push(`/get-help`);
    if (option && option === 'admin') history.push(`/admin/dropins`);
    if (option && option === 'marketplace') {
      window.open("/marketplace", "_blank")
      // history.push(`/marketplace`)
    };
    if (option && option === 'exit') history.push(`/projects`);
    if (option && option === 'contact') setModal(true);
    if (option && option === 'logout') {
      cookie.remove('SDPLUS_TOKEN', { path: '/' });
      history.push('/');
      setUserDetails(null);
      handleLoginToken(null);
      handleProjectData(null);
      localStorage.clear();
    }
  }, [option]);

  useEffect(() => {
    if (history.location && history.location.pathname === '/admin/dropins') setOption('admin');
    else if (history.location && history.location.pathname === '/get-help') setOption('help');
    // else if (history.location && history.location.pathname === '/marketplace') setOption('marketplace');
  }, [history]);

  const handleClose = () => {
    if (history.location && history.location.pathname === '/admin/dropins') setOption('admin');
    else if (history.location && history.location.pathname === '/get-help') setOption('help');
    // else if (history.location && history.location.pathname === '/marketplace') setOption('marketplace');
    else setOption('');
    setModal(false);
  };
  return (
    <>
      <Grid container direction="row">
        <Grid item xs></Grid>
        <Grid>
          <FormControl className={classes.formControl}>
            <Select value={option} onChange={handleChange} displayEmpty className={classes.selectEmpty}>
              <MenuItem value="" disabled>
                {userDetails && userDetails.name.charAt(0).toUpperCase() + userDetails.name.slice(1)}
              </MenuItem>
              <MenuItem value="help">Help</MenuItem>
              <MenuItem value="contact">Contact Us</MenuItem>
              <MenuItem value="marketplace">Marketplace</MenuItem>
              {userDetails && userDetails.role === 'admin' && <MenuItem value="admin">Admin</MenuItem>}
              <MenuItem value="logout">Logout</MenuItem>
            </Select>
          </FormControl>
          {modal && <ContactUs modal={modal} handleClose={handleClose} currentprojects={props.currentprojects} />}
        </Grid>
      </Grid>
    </>
  );
}
