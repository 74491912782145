import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from '../../common/textfield/Fields';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../common/button/Button';
import { UserContext } from '../../../routing/IndexRouting';
import { ProjectContext } from '../../../routing/IndexRouting';
import axios from '../../../config/Axios';
import swal from 'sweetalert';
import userRoles from '../../../config/roles';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';
import TooglebuttonGroupForCreateProject from './components/TooglebuttonGroupForCreateProject';
import LocationInput from './components/LocationInput';
import OrganisationInput from './components/OrganisationInput';
import ToogleSqmAndSqft from './components/ToogleSqmAndSqft';
import UnitAwareTextFieldInputs from './components/UnitAwareTextFieldsInput';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3vh',
  },
}));

const RequiredFields = () => {
  let skeleton = [1, 2, 3, 4];
  let history = useHistory();
  const classes = useStyles();
  const { userDetails } = useContext(UserContext);
  const { handleProjectData } = useContext(ProjectContext);
  const [name, setName] = useState('');
  const [organisationv2, setOrganisationV2] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [userV2Details, setUserV2Details] = useState(null);
  const [change, setChange] = useState(false);
  const [orgv2, setOrgv2] = useState('');
  const [orgname, setOrgname] = useState('');

  ///////
  const handleName = (event) => setName(event.target.value);
  const handleChange = (event, newSelected) => setCategory(newSelected);

  //////
  useEffect(() => {
    axios.get(`/users?email=${userDetails.email}`).then((response) => {
      setUserV2Details(response.data.data);
    });
  }, [userDetails]);
  useEffect(() => {
    axios.get(`/organisationsschemev2`).then((response) => {
      setOrgv2(response.data.data);
    });
    if (userV2Details && userDetails.role !== 'admin') {
      let organisation = userV2Details.data.organisation;
      setOrganisationV2(organisation);
    }
  }, [userV2Details, userDetails]);
  const handleOrganisation = (event) => {
    if (event && event.target.value !== 'none' && userDetails.role === 'admin') {
      setOrganisationV2(event.target.value);
      let org = orgv2 && orgv2.data.filter((option) => option._id === event.target.value)[0];
      setOrgname(org.name);
    } else if (event.target.value === 'none') {
      setOrganisationV2(event.target.value);
      setOrgname(event.target.value);
    } else {
      let organisation = userV2Details.data.organisation;
      setOrganisationV2(organisation);
    }
  };
  /////////////////required pips//////////
  const [requiredpips, setRequiredpips] = useState('');
  const [myArray, setMyArray] = useState([]);
  // const [location, setLocation] = useState('');
  useEffect(() => {
    axios.get(`/pipschemev2?required=true`).then((response) => {
      setRequiredpips(response.data.data);
    });
  }, []);

  //Location Service
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [newloc, setNewloc] = useState({});
  const [inputchange, setInputChange] = useState('');

  const handleSearch = () => {
    setSearch(true);
    setLoading(true);
    axios.get(`/location?location=${inputchange}`).then((resp) => {
      if (resp && resp.data && resp.data.data) setOptions([...resp.data.data.data]);
    });
    setOpen(true);
    setLoading(false);
    return () => setSearch(false);
  };

  const [clickedContinue, setClickedContinue] = useState(false);

  ///////////////////////////
  const handleClick = () => history.push(`/projects`);

  const handleContinue = (data) => {
    setClickedContinue(true);
    if (myArrayForRequiredPIP && myArrayForRequiredPIP.length < 3) {
      swal({ icon: 'error', title: 'you can not create a project without providing all the mandatory fields.' });
    } else if (!name || !category || !subCategory || !organisationv2 || !newloc.placeId) {
      swal({ icon: 'error', title: 'you can not create a project without providing all the mandatory fields.' });
    } else {
      axios.get(`/location/place?id=${newloc.placeId}`).then((ress) => {
        if (ress && ress.data && ress.data.data) {
          let loc = ress.data.data.data;
          let createdby = userDetails._id;

          let body = {
            name: name,
            category: category,
            subcategory: subCategory,
            createdBy: createdby,
            organisation: organisationv2,
            location: { name: loc.name, type: 'Point', coordinates: [loc.coordinates.lat, loc.coordinates.long] },
            payload: '',
            pipvalues: myArray && myArray.length !== 0 && myArray,
          };
          axios.post('/projectv2', body).then((response) => {
            let project = response.data.data.data;
            handleProjectData(project);
            let body2 = {
              projectId: project._id,
              values: myArray && myArray.length !== 0 && myArray,
            };
            axios.post(`/ProjectPIPMapping`, body2).then((response) => {
              swal({ icon: 'success', title: 'Successfully Created Project' });
              history.push(`/projects/${project._id}/edit?type=basic`);
            });
          });
        }
      });
    }
  };

  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  const [unit, setUnit] = useState('Sqm');
  const [radioButtonValue, setRadioButtonValue] = useState('');
  const [unitPIPid, setUnitPIPid] = useState('');

  const handleRadioButtonChange = (event) => setRadioButtonValue(event.target.value);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue) setUnit(radioButtonValue);
  }, [radioButtonValue]);

  useEffect(() => {
    if (unit && unit) setRadioButtonValue(unit);
  }, [unit]);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue === 'Sqm' && unitPIPid) appendChanged(unitPIPid, 'Sqm');
    if (radioButtonValue && radioButtonValue === 'Sqft' && unitPIPid) appendChanged(unitPIPid, 'Sqft');
  }, [radioButtonValue, unitPIPid]);

  const [myArrayForRequiredPIP, setMyarrayForRequiredPIP] = useState([]);

  const appendChangedForRequiredPIP = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArrayForRequiredPIP &&
      myArrayForRequiredPIP.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyarrayForRequiredPIP((oldArray) => [...oldArray]);
    } else {
      setMyarrayForRequiredPIP((oldArray) => [...oldArray, body]);
    }
  };

  // console.log(myArray && myArray, 'myArray');

  return (
    <>
      <Grid container justify="left" direction="row" item xs={12}>
        <Grid container item xs={12}>
          <CommonHeadingWithIcon heading=" Basic Information" handleClick={handleClick} />
        </Grid>
        <Grid container item xs={12} className={classes.spacing} style={{ marginTop: '3%' }}>
          <Typography variant="h6" color="textSecondary">
            <b>*</b>All fields are mandatory
          </Typography>
        </Grid>
        <Grid container justify="left" direction="row" item xs={12}>
          <Grid item xs={12} className={classes.spacing}>
            <Typography variant="h5"> Enter Project Name*</Typography>
          </Grid>
          <Grid item xs={2}>
            <Fields
              error={clickedContinue === true && !name ? true : false}
              id="standard-error-helper-text"
              helperText={clickedContinue === true && !name ? 'name missing.' : ''}
              placeholder="Project Name*"
              value={name}
              onChange={handleName}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <OrganisationInput
          organisationv2={organisationv2 && organisationv2}
          handleOrganisation={handleOrganisation}
          orgv2={orgv2 && orgv2}
          clickedContinue={clickedContinue && clickedContinue}
          userRoles={userRoles && userRoles}
        />
        <TooglebuttonGroupForCreateProject
          category={category && category}
          handleChange={handleChange}
          clickedContinue={clickedContinue && clickedContinue}
          subCategory={subCategory && subCategory}
          setSubCategory={(v) => setSubCategory(v)}
          setChange={(v) => setChange(v)}
          change={change && change}
          requiredpips={requiredpips && requiredpips}
          appendChanged={(a, b) => appendChanged(a, b)}
        />
        {/* toogle between sqm and sqft */}

        <ToogleSqmAndSqft
          requiredpips={requiredpips && requiredpips}
          radioButtonValue={radioButtonValue && radioButtonValue}
          handleRadioButtonChange={(v) => handleRadioButtonChange(v)}
          setUnitPIPid={(v) => setUnitPIPid(v)}
        />
        <LocationInput
          open={open && open}
          requiredpips={requiredpips && requiredpips}
          setOpen={(v) => setOpen(v)}
          setNewloc={(v) => setNewloc(v)}
          appendChanged={(a, b) => appendChanged(a, b)}
          setInputChange={(v) => setInputChange(v)}
          clickedContinue={clickedContinue && clickedContinue}
          newloc={newloc && newloc}
          inputchange={inputchange && inputchange}
          loading={loading && loading}
          options={options && options}
          handleSearch={handleSearch}
        />
        <UnitAwareTextFieldInputs
          area="createproject"
          requiredpips={requiredpips && requiredpips}
          unit={unit && unit}
          appendChanged={(a, b) => appendChanged(a, b)}
          skeleton={skeleton && skeleton}
          appendChangedForRequiredPIP={(a, b) => appendChangedForRequiredPIP(a, b)}
        />
        <Grid container className={classes.spacing} xs={12}>
          <Grid item xs={9}></Grid>
          <Grid item xs={2}>
            <PrimaryButton onClick={() => handleContinue(myArray && myArray)} children="Continue" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RequiredFields;
