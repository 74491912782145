import React, { useContext } from 'react';
import { Grid, makeStyles, withWidth } from '@material-ui/core';
import useScreenType from '../../../../../hooks/checkScreenType';
import { Skeleton } from '@material-ui/lab';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../utils/Defaults';
import CategoryCard from '../../../common/CategoryCard/CategoryCard';

import UIPC_logo from '../../../../../../assets/Marketplace/EcoLabels/UIPC-I.svg';
import IGBC_logo from '../../../../../../assets/Marketplace/EcoLabels/IGBC.svg';
import Griha_logo from '../../../../../../assets/Marketplace/EcoLabels/Griha.svg';
import GreenPro_logo from '../../../../../../assets/Marketplace/EcoLabels/GreenPro.svg';
import GreenCo_logo from '../../../../../../assets/Marketplace/EcoLabels/GreenCo.svg';
import Green_Assure_logo from '../../../../../../assets/Marketplace/EcoLabels/GreenAssure.svg';
import ECBC_logo from '../../../../../../assets/Marketplace/certificationfallback.svg';
import GreenLabel from '../../../../../../assets/databaseImages/certifications/Green Label.png';
import LeedLogo from '../../../../../../assets/Marketplace/EcoLabels/Leed.svg';
import { MarketplaceColors } from '../../../config';

const LogicalGroup = (props) => {
  const { URLS, appliedfilters, handleFilterData, handleAppliedFilters } = useContext(ProductsPageContext);
  const { source } = URLS;
  const { width, categories } = props;

  const { isMobile } = useScreenType(width);
  const useStyles = makeStyles((theme) => ({
    headerStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
    },
    gridList: {
      flexWrap: 'nowrap',
      width: '100%',
      height: 'auto',
      transform: 'translateZ(0)',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
      paddingTop: '3%',
    },
    skeletonsDesktop: {
      width: '140px',
      height: '140px',
      boxShadow: 'none',
      border: '1px solid #00000033',
    },
    skeletonsMobile: {
      width: '120px',
      margin: '120px',
      boxShadow: 'none',
      border: '1px solid #00000033',
    },
    container: {
      padding: theme.spacing(0, !isMobile ? 0 : 2, 0, !isMobile ? 0 : 2),
    },
  }));

  const classes = useStyles();
  function getIcon(name) {
    const icons = [
        { name: 'ECBC', icon: ECBC_logo },
        { name: 'Green Assure', icon: Green_Assure_logo },
        { name: 'GreenCo', icon: GreenCo_logo },
        { name: 'Green Label', icon: GreenLabel },
        { name: 'GreenPro', icon: GreenPro_logo },
        { name: 'Griha', icon: Griha_logo },
        { name: 'IGBC', icon: IGBC_logo },
        { name: 'LEED', icon: LeedLogo },
        { name: 'UIPC-I', icon: UIPC_logo },
    ];
    const val =  icons.filter(icon => {
        if (icon.name === name)
            return true;
    })[0].icon;
    return val;
};

  const SuperCategoriesSkeletons = (a, index) => (
    <Grid xs={2} style={index > 0 ? { marginLeft: '5%' } : { paddingLeft: '0%' }} container alignItems="center" justifyContent="center">
      <Skeleton className={!isMobile ? classes.skeletonsDesktop : classes.skeletonsMobile} animation="wave" variant="circle" width={`160px`} height={`160px`} />
      <Skeleton animation="wave" variant="text" width={`70px`} height={`16px`} style={{ marginTop: '3%' }} />
    </Grid>
  );
  const isEcoLabelSelected = (ecoLabel) => {
    return appliedfilters.find((ele) => ele?.value === ecoLabel)?.checked === true ? true : false
  };
  const handleItemClick = (ecoLabelName) => {
    handleAppliedFilters(NavigatorSource.ecolabels,ecoLabelName,!isEcoLabelSelected(ecoLabelName));
    handleFilterData(NavigatorSource.ecolabels,ecoLabelName,!isEcoLabelSelected(ecoLabelName));
  };
  return (
    <>
      {source === NavigatorSource.supercategories && (
        <>
          {categories
            ? categories.map((s, index) => (
                <Grid xs={2} style={index > 0 ? { marginLeft: '5%' } : { paddingLeft: '0%' }} container alignItems="center" justifyContent="center">
                  <CategoryCard
                    selected={appliedfilters.find((ele) => ele?.value === s?.attributes?.name)?.checked === true ? true : false}
                    name={s?.attributes?.name}
                    cardsource="categorydetails"
                  />
                </Grid>
              ))
            : [1, 2, 3, 4].map((a, index) => SuperCategoriesSkeletons(a, index))}
        </>
      )}
      {source === NavigatorSource.ecolabels && (
        <div style={{display:'flex', width: '90%'}}>
          {categories
            ? categories.map((s, index) => (
                <Grid xs={2} style={{padding: '1%'}}  
                      container direction="column" alignItems="center" justifyContent="center"
                      onClick={() => handleItemClick(s?.attributes?.name)}
                >
                  <div 
                  style={{
                   border: `1px solid ${MarketplaceColors.Primary}`, 
                   backgroundColor: isEcoLabelSelected(s?.attributes?.name) ? MarketplaceColors.Secondary : '',
                   display: 'flex',
                   borderRadius: '50%',
                   alignItems: 'center',
                   justifyContent: 'center',
                   flexDirection: 'column', 
                   width: '90px',
                   height: '90px'
                  }}>
                   <img style={{ 
                   width:'60px',
                   height: '60px'
                   }} 
                   src={getIcon(s?.attributes?.name)} alt="..." />
                  </div>
                   <p style={{alignText:'center', whiteSpace:'nowrap'}}>{s?.attributes?.name || ''}</p>

                </Grid>
              ))
            : [1, 2, 3, 4].map((a, index) => SuperCategoriesSkeletons(a, index))}
        </div>
      )}
    </>
  );
};

export default withWidth()(LogicalGroup);
