import React, { useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import ProductDetailsTabs from './ProductDetailsTabs';
import { useState } from 'react';
import ProductDetailsTableData from './ProductDetailsTableData';

// icon to be changed for all currently its common
import icon1 from '../../../../../../assets/Marketplace/icon1.svg';

export const Tabs = [
  { id: 0, name: 'Environmental Impact', icon: icon1 },
  { id: 1, name: 'Certifications and Standards', icon: icon1 },
  { id: 2, name: 'Properties', icon: icon1 },
  { id: 3, name: 'General', icon: icon1 },
];

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: '4%',
    marginBottom: '5%',
  },
  tableTitle: {
    marginTop: '1%',
  },
  tableGrid: {
    marginTop: '1%',
    marginBottom: '5%',
  },
}));

const ProductDetailsTable = (props) => {
  const classes = useStyles();
  const { productAttributes } = props;

  const [tabvalue, setTabvalue] = useState(0);
  // const [environempact, setEnvironmentalImpact] = useState('');
  const [properties, setProperties] = useState('');
  // const [general, setGeneral] = useState('');
  const [certifications_and_standards, setCertification_and_standards] = useState('');
  const [circularity, setCircularity] = useState('');
  const [climateHealth, setClimateHealth] = useState('');
  const [ecoSystemHealth, setEcoSystemHealth] = useState('');
  const [humanHealth, setHumanHealth] = useState('');
  const [referance, setReferance] = useState('');
  const [sdgs, setSDGS] = useState('');
  const handleChange = (event, newValue) => setTabvalue(newValue);

  useEffect(() => {
    if (productAttributes) {
      setCircularity(productAttributes?.environmental_impact_circularity);
      setClimateHealth(productAttributes?.environmental_impact_climate_health);
      setEcoSystemHealth(productAttributes?.environmental_impact_ecosystem_health);
      setHumanHealth(productAttributes?.environmental_impact_human_health);
      setReferance(productAttributes?.references);
      setSDGS(productAttributes?.sdgs);
      // setEnvironmentalImpact(productAttributes?.environmental_impacts);
      setCertification_and_standards(productAttributes?.certifications_and_standards);
      setProperties(productAttributes?.properties);
      // setGeneral(productAttributes?.general);
    }
  }, [productAttributes]);

  const sourcingRegex = /Sourcing,(.*?),End-of-Life/;
  const sourcingMatch = circularity && circularity.match(sourcingRegex);
  const sourcing = sourcingMatch ? sourcingMatch[1].trim() : null;
  const endoflifeRegex = /End-of-Life,(.*?),Waste/;
  const endoflifeMatch = circularity && circularity.match(endoflifeRegex);
  const endoflife = endoflifeMatch ? endoflifeMatch[1].trim() : null;
  const waste = circularity && circularity.split('Waste,')[1].trim();
  console.log(sourcing?.split(',')[1].split(':'), 'input');
  // console.log(endoflife, 'input');
  // console.log(waste, 'input');

  const staticdata = [
    {
      name: 'Environmental Impcat',
      data: [
        {
          header: 'Circularity',
          table: [
            {
              title: 'Sourcing',
              type: 'string',
              data: sourcing,
            },
            {
              title: 'End of Life',
              type: 'string',
              data: endoflife,
            },
            {
              title: 'Waste',
              type: 'string',
              data: waste,
            },
          ],
        },
        {
          header: 'Climate Health',
          table: [
            {
              title: '',
              type: 'string',
              data: climateHealth,
            },
          ],
        },
        {
          header: 'Ecosystem Health',
          table: [
            {
              title: '',
              type: 'string',
              data: ecoSystemHealth,
            },
          ],
        },
        {
          header: 'Human Health',
          table: [
            {
              title: '',
              type: 'special',
              data: humanHealth,
            },
          ],
        },
      ],
    },
    {
      name: 'General',
      data: [
        {
          header: '',
          table: [
            {
              title: 'Procurement Information',
              type: 'table',
              data: [
                { name: 'Product Code', value: productAttributes?.product_code },
                { name: 'Manufacturing Locations', value: productAttributes?.brand?.data?.attributes?.manufacturing_locations },
                { name: 'Region of Operation', value: productAttributes?.brand?.data?.attributes?.region_of_operation },
                { name: 'Quality Assurance and Reliability (QAR)', value: productAttributes?.quality_assurance_reliability },
                { name: 'Warranty and After Sales', value: productAttributes?.warranty_after_sales },
              ],
            },
          ],
        },
        {
          header: '',
          table: [
            {
              title: 'References',
              type: 'string',
              subtype: 'url',
              data: referance,
            },
          ],
        },
        {
          header: '',
          table: [
            {
              title: 'Sustainable Development Goals',
              type: 'string',
              subtype: 'nodash',
              data: sdgs,
            },
          ],
        },
        {
          header: 'About Us',
          data: productAttributes?.productDescription,
        },
      ],
    },
  ];
  console.log(properties && properties, 'propertiesproperties');
  return (
    <>
      <Grid container xs={12} alignItems="center" justifyContent="center" className={classes.tabs}>
        <ProductDetailsTabs tabvalue={tabvalue} handleChange={handleChange} />
      </Grid>
      {/* <Grid container alignItems="center" justifyContent="flex-start" className={classes.tableTitle}>
        <Grid xs={0}>
          <img src={Tabs[tabvalue]?.icon} alt="ggg" />
        </Grid>
        <Grid xs={10} style={{ paddingLeft: '2%' }}>
          <Typography variant="h5">{Tabs[tabvalue]?.name}</Typography>
        </Grid>
      </Grid> */}

      <Grid container xs={12} alignItems="center" justifyContent="center" className={classes.tableGrid}>
        {tabvalue === 0 && (
          <ProductDetailsTableData
            newValue={staticdata[0]}
            implement="new"
            // tableData={environempact && environempact}
            // tab={Tabs[tabvalue]?.name}
          />
        )}
        {tabvalue === 1 && <ProductDetailsTableData tableData={certifications_and_standards && certifications_and_standards} tab={Tabs[tabvalue]?.name} />}
        {tabvalue === 2 && <ProductDetailsTableData tableData={properties && properties} tab={Tabs[tabvalue]?.name} />}
        {tabvalue === 3 && (
          <ProductDetailsTableData
            newValue={staticdata[1]}
            implement="new"
            // tableData={general && general}
            // tab={Tabs[tabvalue]?.name}
          />
        )}
      </Grid>
    </>
  );
};

export default ProductDetailsTable;
