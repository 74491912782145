import React from 'react';
import InterventionCard from '../Components-page-categories/InterventionCard';
import { Grid, Paper, Typography } from '@material-ui/core';
import Header from '../Header';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../Footer';
import rsToCr from '../../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../../utils/numberToComma';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '14px',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.83)',
    letterSpacing: '0.71px',
    fontWeight: 700,
    textAlign: 'left',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
}));

const EnergySelectedInterv = (props) => {
  const classes = useStyles();

  const contentFunction = (name) => {
    if (name === 'Solar PV') return 'SPV System converts sunlight into electricity which can be either fed into the grid or used on site';
    if (name === 'Solar Water Heater') return 'Solar collectors heat up water stored in the tank & subsequently supply to the toilets in the upper floor units';
    else return 'Custom Intervention';
  };

  const capacityFunction = (intervention, data) => {
    if (intervention === 'Solar PV') {
      let value = data && data.find((D) => D.id === 'solarpv.SPVCapacity') ? data && data.find((D) => D.id === 'solarpv.SPVCapacity').value : '-';
      let unit = data && data.find((D) => D.id === 'solarpv.SPVCapacity') ? data && data.find((D) => D.id === 'solarpv.SPVCapacity').unit : '-';
      return { value, unit };
    }
    if (intervention === 'Solar Water Heater') {
      let value =
        data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity') ? data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity').value : '-';
      let unit =
        data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity') ? data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity').unit : '-';
      return { value, unit };
    }
  };

  const areaFunction = (intervention, data) => {
    if (intervention === 'Solar PV') {
      let value = data && data.find((D) => D.id === 'solarpv.RooftopAreaPerkWp') ? data && data.find((D) => D.id === 'solarpv.RooftopAreaPerkWp').value : '-';
      let unit = data && data.find((D) => D.id === 'solarpv.RooftopAreaPerkWp') ? data && data.find((D) => D.id === 'solarpv.RooftopAreaPerkWp').unit : '-';
      return { value, unit };
    }
    if (intervention === 'Solar Water Heater') {
      let value =
        data && data.find((D) => D.id === 'solarwaterheater.RooftopAreaPerLPD')
          ? data && data.find((D) => D.id === 'solarwaterheater.RooftopAreaPerLPD').value
          : '-';
      let unit =
        data && data.find((D) => D.id === 'solarwaterheater.RooftopAreaPerLPD')
          ? data && data.find((D) => D.id === 'solarwaterheater.RooftopAreaPerLPD').unit
          : '-';
      return { value, unit };
    }
  };

  const selectedVendorFunction = (index, mappingId, data, vendorMappings, vendors) => {
    if (mappingId && data && vendorMappings && vendors) {
      let A = data.find((a) => a._id === mappingId && a.hasOwnProperty('selectedVendor'));
      let B = A && vendorMappings.find((vendor) => vendor._id === A.selectedVendor);
      let C = B && vendors.find((v) => v._id === B.vendor._id);
      return { logo: C && C.logo.URL };
    }
  };

  return (
    <>
      <Grid container item xs={12} style={{ paddingTop: '15px', paddingBottom: '5px', marginRight: '30px', marginLeft: '20px' }}>
        <Header pagenumber="4" companyName={props.companyName} />
      </Grid>
      <Grid container item xs={11} direction="column" style={{ paddingLeft: '20px' }}>
        <Typography className={classes.text}>Implemented interventions</Typography>

        {props && props.group ? (
          props.group.map((group, index) => {
            return (
              <>
                <div className={classes.spacing}></div>
                <div className={classes.div1}>
                  <div id="pdfdiv7">
                    <InterventionCard
                      color="1px solid #F28D14"
                      textcolor="#F28D14"
                      content={contentFunction(group.name)}
                      name={group.name}
                      capex={
                        group.quantities && group.quantities.finance && group.quantities.finance.capex ? rsToCr('Rs', group.quantities.finance.capex.value) : 0
                      }
                      opex={
                        group.quantities && group.quantities.finance && group.quantities.finance.opexSavings
                          ? rsToCr('Rs', group.quantities.finance.opexSavings.value)
                          : 0
                      }
                      resource={
                        group.quantities && group.quantities.resource && group.quantities.resource.category
                          ? numberWithCommas(group.quantities.resource.category.value)
                          : 0
                      }
                      unit={group.quantities && group.quantities.resource && group.quantities.resource.category ? group.quantities.resource.category.unit : '-'}
                      capacity={
                        capacityFunction(group.name, group.quantities && group.quantities.details)
                          ? capacityFunction(group.name, group.quantities && group.quantities.details).value
                          : '-'
                      }
                      cpacityunit={
                        capacityFunction(group.name, group.quantities && group.quantities.details)
                          ? capacityFunction(group.name, group.quantities && group.quantities.details).unit
                          : '-'
                      }
                      areaunit={
                        areaFunction(group.name, group.quantities && group.quantities.details)
                          ? areaFunction(group.name, group.quantities && group.quantities.details).unit
                          : '-'
                      }
                      area={
                        areaFunction(group.name, group.quantities && group.quantities.details)
                          ? areaFunction(group.name, group.quantities && group.quantities.details).value
                          : '-'
                      }
                      selectedVendor={
                        selectedVendorFunction(
                          index,
                          group.interventionProjectMappingId,
                          props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                          props.allVendorMappings && props.allVendorMappings,
                          props.allvendors && props.allvendors
                        ) &&
                        selectedVendorFunction(
                          index,
                          group.interventionProjectMappingId,
                          props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                          props.allVendorMappings && props.allVendorMappings,
                          props.allvendors && props.allvendors
                        )
                      }
                      category={props.category}
                    />
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <InterventionCard
            color="1px solid #F28D14"
            textcolor="#F28D14"
            content="SPV System converts sunlight into electricity which can be either fed into the grid or used on site"
            name={'hello'}
            capex={0}
            opex={0}
            resource={0}
            unit={0}
            capacity={0}
            cpacityunit={0}
            areaunit={0}
            area={0}
          />
        )}
        {props.group.length === 2 ? (
          <Grid>
            <Paper style={{ height: '200px', border: 'none', boxShadow: 'none' }}></Paper>
          </Grid>
        ) : (
          ''
        )}
        {props.group.length === 1 ? (
          <Grid>
            <Paper style={{ height: '400px', border: 'none', boxShadow: 'none' }}></Paper>
          </Grid>
        ) : (
          ''
        )}
        <Footer />
      </Grid>
    </>
  );
};

export default EnergySelectedInterv;
