import { withWidth } from '@material-ui/core';
import React, { useEffect, useContext, useState } from 'react';
import { NavigatorSource } from '../../utils/Defaults';
import { useLocation, useHistory } from 'react-router-dom';
import useScreenType from '../../../../hooks/checkScreenType';
import { ProductsPageContext } from '../../../../../routing/IndexRouting';
import useCategoryDetails, { usePrevious } from '../../hooks/useCategoryDetailsData';
import CategoryDetailsMobile from './components/mobilecomponents/CategoryDetailsMobile';
import CategoryDetailsDesktop from './components/desktopcomponents/CategoryDetailsDesktop';
import { getURLStrings, constructURlforSourceCategory, constructURlforSourceSuperCategory, constructURLforSourceEcolabels } from './utils/URLParser';

function CertificationAndSuperCategories(props) {
  const { width } = props;
  const { isMobile } = useScreenType(width);
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const setURL = (URL) => history.push(URL);

  const [userSelectedFirstSection, setUserSelectedFirstSection] = useState('');

  //DATA FROM HOOKS
  const { productsresponse, categories, source, totalProducts, ecoLabels, allbrands } = useCategoryDetails();

  const { handleURL, URLS, appliedfilters, isDefault } = useContext(ProductsPageContext);
  console.log(productsresponse, 'productsresponse');
  const uniqueBrands = [...new Set(productsresponse && productsresponse.map((product) => product.brand.name))];
  const uniqueSubcategories = {};
  productsresponse &&
    productsresponse.forEach((product) => {
      const categoryId = product?.category?.id;
      const categoryName = product?.category?.name;
      const subcategoryName = product?.subcategory?.name;
      if (!uniqueSubcategories[categoryId]) {
        uniqueSubcategories[categoryId] = { categoryName, subcategories: new Set() };
      }
      uniqueSubcategories[categoryId].subcategories.add(subcategoryName);
    });
  const uniqueEcolabels = new Set();
  productsresponse &&
    productsresponse.forEach((product) => {
      if (product.ecolabels && product.ecolabels.length > 0) product.ecolabels.forEach((ecolabel) => uniqueEcolabels.add(ecolabel));
    });

  console.log(
    {
      productsresponse,
      userSelectedFirstSection,
      // uniqueBrands,
      // uniqueSubcategories,
      uniqueEcolabels: Array.from(uniqueEcolabels),
    },
    'unique data from products'
  );

  /*DATA RELOAD IF URL CHANGES*/
  useEffect(() => {
    if (previousLocation !== location) {
      handleURL(
        query.get('category'),
        query.get('subcategory'),
        query.get('brands'),
        query.get('supercategory'),
        query.get('page'),
        query.get('pagesize'),
        query.get('source'),
        query.get('filters'),
        query.get('sort'),
        query.get('searchquery')
      );
    }
  }, [location, previousLocation]);

  /* if apply filters then */
  useEffect(() => {
    if (appliedfilters.length !== 0) {
      setUserSelectedFirstSection(appliedfilters[0].id);
      if (URLS.source === NavigatorSource.categories) {
        const { subcategorystr, brandsstr, filterstr } = getURLStrings(appliedfilters);
        const url = constructURlforSourceCategory(
          URLS.source,
          URLS.category,
          subcategorystr,
          brandsstr,
          filterstr,
          URLS.page,
          URLS.pagesize,
          isDefault,
          URLS.sort
        );
        setURL(url);
      } else if (URLS.source === NavigatorSource.supercategories) {
        const { subcategorystr, brandsstr, filterstr, categoriesstr } = getURLStrings(appliedfilters);
        const url = constructURlforSourceSuperCategory(
          URLS.source,
          URLS.supercategory,
          subcategorystr,
          brandsstr,
          filterstr,
          categoriesstr,
          URLS.page,
          URLS.pagesize,
          URLS.sort
        );
        setURL(url);
      } else if (URLS.source === NavigatorSource.ecolabels) {
        const { subcategorystr, brandsstr, filterstr, categoriesstr, ecolabelstr } = getURLStrings(appliedfilters);
        const url = constructURLforSourceEcolabels(URLS.source, brandsstr, ecolabelstr, URLS.page, URLS.pagesize, URLS.sort);
        setURL(url);
      }
    } else {
      setUserSelectedFirstSection('');
    }
  }, [appliedfilters]);
  return (
    <>
      {!isMobile && (
        <CategoryDetailsDesktop
          products={productsresponse}
          categories={categories}
          totalproducts={totalProducts}
          ecoLabels={ecoLabels}
          allbrands={allbrands}
          // for filters
          uniqueBrands={uniqueBrands}
          uniqueSubcategories={uniqueSubcategories}
          userSelectedFirstSection={userSelectedFirstSection}
          uniqueEcolabels={Array.from(uniqueEcolabels)}
        />
      )}
      {isMobile && (
        <CategoryDetailsMobile products={productsresponse} categories={categories} totalproducts={totalProducts} ecoLabels={ecoLabels} allbrands={allbrands} />
      )}
    </>
  );
}

export default withWidth()(CertificationAndSuperCategories);
// const uniqueEcolabels = [...new Set(productsresponse && productsresponse.flatMap((product) => product.ecolabels || []))];
// const uniqueSubcategories = [...new Set(productsresponse && productsresponse.map((product) => product.subcategory.name))];
