import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '5%',
  },
  margin: {
    margin: theme.spacing(0),
    // borderRadius: '20px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingTop: '5%',
  },
}));

export default function CustomizedBar({ value, bgcolor, barvalue, height, typographyVariant, typographyGridStyle, unit }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: height,
      background: '#FFFFFF',
      // borderRadius: '0px 100px 100px 0px',
    },
    bar: {
      borderRadius: 25,
      background: `${bgcolor}`,
    },
  }))(LinearProgress);

  return (
    <Grid container xs={12} className={classes.root} direction="row">
      <>
        <Grid item xs={8}>
          <BorderLinearProgress className={classes.margin} variant="determinate" value={`${barvalue}`} />
        </Grid>
        <Grid item xs={4} style={typographyGridStyle}>
          <Typography variant={typographyVariant} style={{ color: `${bgcolor}`, marginTop: '10px' }}>
            {`${value} ${unit}`}
          </Typography>
        </Grid>
      </>
    </Grid>
  );
}
