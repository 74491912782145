import React from 'react';
import { Grid, Typography, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `10px`,
        width: '166px',
        paddingBottom: '30px',
        [theme.breakpoints.down('md')]: {
          width: '166px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '110px',
        },
      },
    },
  },
  select: {
    padding: '1px',
    borderRadius: `15px`,
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      padding: '2px',
      borderRadius: `15px`,
    },
  },
}));

function MaterialPresetPaint(props) {
  const classes = useStyles();
  const { value, pip, selectedPaintBrand, selectedPaintFinish, paintNames, selectedPaintName, setSelectedPaintName, appendChanged } = props;
  return (
    <>
      <Grid container alignItems="flex-start" direction="column" item xs={12} style={{ marginLeft: '8%', marginTop: '5%' }}>
        <Grid xs item alignItems="flex-start">
          <Typography variant="h6" color={(value && value === 'preset1') || (value && value === 'other') ? 'textSecondary' : 'textPrimary'}>
            {pip && pip.displayName}
          </Typography>
        </Grid>
        <Grid xs item alignItems="flex-start">
          <FormControl variant="outlined" style={{ width: '166px', borderRadius: `50px`, marginTop: '5%' }} className={classes.dropdown}>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              disabled={value && value === 'preset2' && selectedPaintBrand && selectedPaintFinish && paintNames ? false : true}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="hhhhhhhhhh"
              placeholder="placeholder"
              className={classes.select}
              value={selectedPaintName && selectedPaintName}
            >
              {pip && paintNames
                ? paintNames.map((value) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setSelectedPaintName(value);
                          appendChanged(pip._id, value);
                        }}
                        value={value}
                        name={value}
                        style={{ paddingTop: '2%' }}
                      >
                        {value}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default MaterialPresetPaint;
