import * as React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Page2Header from './Components-page-2/Page2Header';
import EnergyCard from './Components-page-2/Page2EnergyCard';
import WaterCard from './Components-page-2/Page2WaterCard';
import WasteCard from './Components-page-2/Page2WasteCard';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '8%',
  },
  spacing: {
    marginTop: '3%',
  },
}));

function Page2(props) {
  const classes = useStyles();
  const energy = props.energy;
  const water = props.water;
  const waste = props.waste;
  let energysaving = energy && energy[0].quantities.resource.category.value;
  let watersaving = water && water[0].quantities.resource.category.value;
  let wastesaving = waste && waste[0].quantities.resource.category.value;
  let energysavingconverted = energy && energy[0].quantities.resource.categoryConverted.value;
  let watersavingconverted = water && water[0].quantities.resource.categoryConverted.value;
  let wastesavingconverted = waste && waste[0].quantities.resource.categoryConverted.value;
  let energypercentage =
    energy && energy[0].subcategories[0].interventions[0].quantities.details.find((data) => data && data.name === 'energy.totalpercentagesaving');
  let waterpercentage =
    water && water[0].subcategories[0].interventions[0].quantities.details.find((data) => data && data.name === 'water.totalpercentagesaving');
  let wastepercentage =
    waste && waste[0].subcategories[0].interventions[0].quantities.details.find((data) => data && data.name === 'waste.totalpercentagesaving');

  return (
    <>
      <Grid container xs={12} direction="column">
        <Grid item xs={12} direction="row">
          <Page2Header companyName={props.companyName} />
        </Grid>
        <Grid container item xs={12} direction="row" className={classes.grid}>
          <EnergyCard
            energysaving={energysaving}
            energysavingconverted={energysavingconverted}
            percentage={`${energypercentage ? energypercentage.value : 0} %`}
          />
        </Grid>
        <Grid container item xs={12} direction="row" className={classes.grid}>
          <WaterCard watersaving={watersaving} watersavingconverted={watersavingconverted} percentage={`${waterpercentage ? waterpercentage.value : 0} %`} />
        </Grid>
        <Grid container item xs={12} direction="row" className={classes.grid}>
          <WasteCard wastesaving={wastesaving} wastesavingconverted={wastesavingconverted} percentage={`${wastepercentage ? wastepercentage.value : 0} %`} />
        </Grid>
        <Grid item xs={12} direction="column" style={{ marginTop: '10%', marginLeft: '3%' }}>
          <Footer />
        </Grid>
        {/* <div className={classes.spacing}></div> */}
      </Grid>
    </>
  );
}

export default Page2;
