import React, { useEffect, useState } from 'react';
import axios from '../../../../../config/Axios';
import ManualPIP from './interiorPaintComponents/ManualPIP';
import PresetsPIP from './interiorPaintComponents/PresetsPIP';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { GreenRadio } from '../../../../common/RadioButton/GreenRadio';
import { Grid, Typography, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';

export const CheckBoxItems = {
  solvent: 'materials.interiorpaint.solvent.enabled',
  preset: 'materials.interiorpaint.preset.enabled',
  manual: 'materials.interiorpaint.manual.enabled',
};

export const DropDownItems = {
  brand: 'materials.interiorpaint.preset.brand',
  finish: 'materials.interiorpaint.preset.finish',
  paint: 'materials.interiorpaint.preset.name',
};

export const TextBoxItems = {
  manualBrand: 'materials.interiorpaint.manual.brand',
  manualPaint: 'materials.interiorpaint.manual.name',
};

function InteriorPaints(props) {
  const { data, interiorPaintBrands, appendChanged, projectPIP, myArray } = props;

  const Checkboxs = Object.entries(CheckBoxItems).map((name) => data && data.find((pip) => pip.name === name[1]));
  const DropDowns = Object.entries(DropDownItems).map((name) => data && data.find((pip) => pip.name === name[1]));
  const TextBoxes = Object.entries(TextBoxItems).map((name) => data && data.find((pip) => pip.name === name[1]));

  const [radioCheckboxVal, setRadioCheckboxVal] = useState(undefined);

  // checkboxs
  const [solventEnabled, setSolventEnabled] = useState(false);
  const [presetEnabled, setPresetEnabled] = useState(false);
  const [manualEnabled, setManualEnabled] = useState(false);

  // all paints and finishes based upon brand
  const [selectedPaintFinishs, setSelectedPaintFinishs] = useState('');
  const [selectedPaints, setSelectedPaints] = useState('');

  // selected preset values
  const [selectedPaintBrand, setSelectedPaintBrand] = useState('');
  const [selectedPaintFinish, setSelectedPaintFinish] = useState('');
  const [selectedPaint, setSelectedPaint] = useState('');

  const handleChange = (event) => setRadioCheckboxVal(event.target.value);

  useEffect(() => {
    let solvent = data && data.find((pip) => pip.name === CheckBoxItems.solvent);
    let preset = data && data.find((pip) => pip.name === CheckBoxItems.preset);
    let manual = data && data.find((pip) => pip.name === CheckBoxItems.manual);

    if (radioCheckboxVal === CheckBoxItems.solvent) {
      setSolventEnabled(true);
      setPresetEnabled(false);
      setManualEnabled(false);
      appendChanged(solvent && solvent._id, true);
      appendChanged(preset && preset._id, false);
      appendChanged(manual && manual._id, false);
      setSelectedPaint('');
      setSelectedPaintFinish('');
      setSelectedPaintBrand('');
    }
    if (radioCheckboxVal === CheckBoxItems.preset) {
      setSolventEnabled(false);
      setPresetEnabled(true);
      setManualEnabled(false);
      appendChanged(solvent && solvent._id, false);
      appendChanged(preset && preset._id, true);
      appendChanged(manual && manual._id, false);
    }
    if (radioCheckboxVal === CheckBoxItems.manual) {
      setSolventEnabled(false);
      setPresetEnabled(false);
      setManualEnabled(true);
      appendChanged(solvent && solvent._id, false);
      appendChanged(preset && preset._id, false);
      appendChanged(manual && manual._id, true);
      setSelectedPaint('');
      setSelectedPaintFinish('');
      setSelectedPaintBrand('');
    }
  }, [radioCheckboxVal, data]);

  useEffect(() => {
    if (projectPIP) {
      const solventEnabled = projectPIP && projectPIP.find((d) => d.PIPScheme.name === CheckBoxItems.solvent)?.value;
      const presetEnabled = projectPIP && projectPIP.find((d) => d.PIPScheme.name === CheckBoxItems.preset)?.value;
      const manualEnabled = projectPIP && projectPIP.find((d) => d.PIPScheme.name === CheckBoxItems.manual)?.value;
      const selectedPresetBrand = projectPIP && projectPIP.find((d) => d.PIPScheme.name === DropDownItems.brand)?.value;
      const selectedPresetFinish = projectPIP && projectPIP.find((d) => d.PIPScheme.name === DropDownItems.finish)?.value;
      const selectedPresetPaint = projectPIP && projectPIP.find((d) => d.PIPScheme.name === DropDownItems.paint)?.value;

      setSolventEnabled(solventEnabled);
      setPresetEnabled(presetEnabled);
      setManualEnabled(manualEnabled);
      setSelectedPaintBrand(selectedPresetBrand);
      setSelectedPaintFinish(selectedPresetFinish);
      setSelectedPaint(selectedPresetPaint);
    }
  }, [projectPIP]);

  useEffect(() => {
    if (selectedPaintBrand)
      axios.get(`PaintProvider?list=Finish&type=Interior Paints&brand=${selectedPaintBrand}`).then((res) => setSelectedPaintFinishs(res && res.data.data.data));
  }, [selectedPaintBrand]);

  useEffect(() => {
    if (selectedPaintBrand && selectedPaintFinish)
      axios
        .get(`PaintProvider?list=Paint&brand=${selectedPaintBrand}&finish=${selectedPaintFinish}&type=Interior Paints`)
        .then((res) => setSelectedPaints(res && res.data.data.data));
  }, [selectedPaintBrand, selectedPaintFinish]);

  return (
    <>
      <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
        <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
          <Typography variant="h5">Interior Paint</Typography>
          <CustomizedTooltips
            marginTop="15px"
            icon="info"
            title={['tool tip placeholder'].map((v) => (
              <Typography>{v}</Typography>
            ))}
            placement="right"
          />
        </Grid>
        <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="interior paint" name="interior paint schemes" value={radioCheckboxVal} onChange={handleChange}>
              {Checkboxs &&
                Checkboxs.map(({ name, displayName }) => {
                  return (
                    <>
                      <FormControlLabel
                        value={name}
                        control={
                          <GreenRadio
                            checked={
                              (name === CheckBoxItems.solvent && solventEnabled) ||
                              (name === CheckBoxItems.preset && presetEnabled) ||
                              (name === CheckBoxItems.manual && manualEnabled)
                            }
                            onChange={(e) => {
                              name === CheckBoxItems.solvent && setSolventEnabled(e.target.checked);
                              name === CheckBoxItems.preset && setPresetEnabled(e.target.checked);
                              name === CheckBoxItems.manual && setManualEnabled(e.target.checked);
                            }}
                          />
                        }
                        label={displayName}
                      />
                      {name === CheckBoxItems.preset && presetEnabled ? (
                        <>
                          {DropDowns?.map(({ name: dropdown, displayName, _id }, index) => {
                            if (displayName === 'Brand') {
                              return (
                                <PresetsPIP
                                  key={index}
                                  _id={_id}
                                  displayName={displayName}
                                  appendChanged={(a, b) => appendChanged(a, b)}
                                  values={interiorPaintBrands}
                                  disabledValue={false}
                                  selectedValue={selectedPaintBrand}
                                  setSelected={(a) => setSelectedPaintBrand(a)}
                                />
                              );
                            }
                            if (displayName === 'Finish') {
                              return (
                                <PresetsPIP
                                  key={index}
                                  _id={_id}
                                  displayName={displayName}
                                  appendChanged={(a, b) => appendChanged(a, b)}
                                  values={selectedPaintFinishs}
                                  disabledValue={selectedPaintBrand ? false : true}
                                  selectedValue={selectedPaintFinish}
                                  setSelected={(b) => setSelectedPaintFinish(b)}
                                />
                              );
                            }
                            if (displayName === 'Paint') {
                              return (
                                <PresetsPIP
                                  key={index}
                                  _id={_id}
                                  displayName={displayName}
                                  appendChanged={(a, b) => appendChanged(a, b)}
                                  values={selectedPaints}
                                  disabledValue={selectedPaintFinish ? false : true}
                                  selectedValue={selectedPaint}
                                  setSelected={(c) => setSelectedPaint(c)}
                                />
                              );
                            }
                          })}
                        </>
                      ) : null}
                      {name === CheckBoxItems.manual &&
                        manualEnabled &&
                        TextBoxes &&
                        TextBoxes.map(({ name: textbox, displayName, _id }) => (
                          <ManualPIP
                            textbox={textbox}
                            displayName={displayName}
                            _id={_id}
                            myArray={myArray}
                            projectPIP={projectPIP}
                            appendChanged={(a, b) => appendChanged(a, b)}
                          />
                        ))}
                    </>
                  );
                })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default InteriorPaints;
