import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import ProgressBar from './child-components/ProgressBar';
import EclipseProgress from './child-components/EclipseProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import rsToCrore from '../../../../../utils/rsToCr';
import unit from '../../../../../utils/unitConfig';
import numberWithCommas from '../../../../../utils/numberToComma';

const Materials = (props) => {
  const { project, newintervention, energyGoals } = props;
  let newdata = project && project.goals ? project.goals.categories[0] : '';
  let materialdata = newdata && newdata.find((goal) => goal.name === 'Materials');
  let intervdata = newintervention && newintervention.categorized.find((int) => int.name === 'Materials');
  let capex = intervdata && intervdata.quantities.finance.capex.value;
  let saving = intervdata && intervdata.quantities.resource.category.value;
  let energyunit = intervdata && intervdata.quantities.resource.category.unit;
  return (
    <>
      <Container style={{ padding: '2%' }}>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {energyGoals ? energyGoals.metadata.title : <Skeleton variant="text" height={25} width={140} animation="wave" />}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ProgressBar
              value={`${energyGoals ? energyGoals.quantities.targetPercentage.value : 0} ${energyGoals ? energyGoals.quantities.targetPercentage.unit : '%'}`}
              progress={energyGoals ? energyGoals.quantities.targetPercentage.value : 0}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ paddingTop: '5%' }}>
          <Grid item xs={3}>
            <Typography variant="h5">Current</Typography>
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid container item xs={12} style={{ paddingTop: '2%' }}>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <EclipseProgress
                newvalue={
                  materialdata && materialdata.quantities && materialdata.quantities.actualPercentage.value > 100
                    ? 100
                    : materialdata && materialdata.quantities && materialdata.quantities.actualPercentage.value
                }
                range={
                  materialdata && materialdata.quantities && materialdata.quantities.actualPercentage.value > 100
                    ? 100
                    : materialdata && materialdata.quantities && materialdata.quantities.actualPercentage.value
                }
                staticvalue={
                  materialdata && materialdata.quantities && materialdata.quantities.actualPercentage.value > 100
                    ? 0
                    : materialdata && materialdata.quantities && materialdata.quantities.actualPercentage.value - 100
                }
              />
            </Grid>
            <Grid container item xs={6} sm={6} md={6} lg={3} style={{ padding: '2%' }}>
              <Grid item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  CAPEX Increase
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {capex > 0 || capex === 0 ? (
                  <Typography variant="h5">{`${rsToCrore('Rs', capex)} ${unit('cr')}`}</Typography>
                ) : (
                  <Skeleton variant="text" height={25} animation="wave" />
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6} style={{ padding: '2%' }}>
              <Grid item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  Materials Savings
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {saving > 0 || saving === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h5">{`${numberWithCommas(saving)} ${energyunit}`}</Typography>
                  </Grid>
                ) : (
                  <Grid item xs={9}>
                    <Skeleton variant="text" height={25} animation="wave" style={{ width: 100 }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Materials;
