import React, { useState, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from './Fields';

export function sqmToSqft(val) {
  return val * 10.764;
}

export function sqftToSqm(val) {
  let result = (val / 10.764).toFixed(2);
  return result;
}

function UnitAwareTextField(props) {
  const { unit, field, projectPIP, appendChanged, appendChangedForRequiredPIP, area, xs } = props;

  const getApplicableValue = (v) => {
    if (field.unit !== ('Sqm' || 'Sqft')) return v;
    if (unit === 'Sqm') return v;
    else if (unit === 'Sqft') return sqmToSqft(v).toFixed(2);
  };

  const getFormattedValue = (v) => {
    if (field.unit !== ('Sqm' || 'Sqft')) return Number(v).toLocaleString('en-in');
    if (unit === 'Sqm') return Number(v).toLocaleString('en-in');
    else if (unit === 'Sqft') return Number(v).toLocaleString('en-in');
  };

  let val = field && projectPIP && projectPIP.find((d) => d.PIPScheme.name === field.name) ? projectPIP.find((d) => d.PIPScheme.name === field.name).value : '';

  const valRef = useRef(getApplicableValue(val && val));
  const [actualValue, setActualValue] = useState(getFormattedValue(getApplicableValue(val && val)));

  const handleChange = (e) => {
    const input = e.target.value;
    let final = null;
    if (input === '') final = input;
    const matched = input.match('^[0-9][0-9]*\\.?[0-9]{0,2}$');
    if (matched) final = input;
    if (final === null) return;
    valRef.current = final;
    setActualValue(valRef.current);
  };

  const handleBlur = (e) => {
    const formatted = Number(e.target.value).toLocaleString('en-in');
    setActualValue(formatted);
  };

  const handleFocus = (e) => {
    setActualValue(valRef.current);
  };

  const handleClick = (e) => {
    e.target.select();
  };

  return (
    <>
      <Grid container direction="row" alignItems="center" item xs={12}>
        <Grid item xs={xs ? xs : 2}>
          <Fields
            placeholder={field.name}
            value={actualValue && actualValue}
            onChange={(e) => {
              if (area === 'createproject' && field.name === 'rooftop') {
                appendChangedForRequiredPIP(field._id, e.target.value);
              }
              if (area === 'createproject' && field.name === 'builtup') {
                appendChangedForRequiredPIP(field._id, e.target.value);
              }
              if (area === 'createproject' && field.name === 'sitearea') {
                appendChangedForRequiredPIP(field._id, e.target.value);
              }
              handleChange(e);
              if (field.unit !== ('Sqm' || 'Sqft')) appendChanged(field._id, e.target.value);
              else if (unit === 'Sqm') appendChanged(field._id, e.target.value);
              else if (unit === 'Sqft') appendChanged(field._id, sqftToSqm(e.target.value));
            }}
            onClick={handleClick}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">&nbsp;{field.unit === ('NA' || 'na') ? <></> : field.unit !== ('Sqm' || 'Sqft') ? field.unit : unit && unit}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default UnitAwareTextField;
