import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';
import ViewPreviosSavesModal from '../common/PreviousSave';
import DataAndCostAssumptions from '../components/dataAndCostAssumptions';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '2%',
    marginTop: '1%',
  },
  logo: {
    height: '90%',
    marginLeft: '13%',
  },
  cardheading: {
    textAlign: 'center',
    marginLeft: '0%',
    paddingLeft: '3%',
    paddingRight: '5%',
  },
  cardbody: {
    textAlign: 'center',
    marginTop: '8%',
  },
  subBody: {
    color: '#9E9E9E',
    paddingLeft: '2%',
  },
  paper: {
    height: '9vh',
    width: '120%',
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
  },
  grid1: {
    marginTop: '-2%',
  },
  grid2: {
    marginLeft: '3%',
  },
  line: {
    height: 40,
  },
}));
function Header(props) {
  const classes = useStyles();
  // console.log(props.inputs && props.inputs, 'input 1');
  return (
    <>
      <Grid container item xs={12} alignItems="center" justify="flex-start" spacing={2} className={classes.root}>
        {props.icon === null ? null : (
          <Grid item xs={1}>
            <img src={props.icon} alt="Back" />
          </Grid>
        )}
        <Grid item xs={9}>
          {props.selectedIntervention !== null ? (
            <Typography variant="h3" style={{ marginLeft: '-2%' }}>
              {props.selectedIntervention}
            </Typography>
          ) : (
            <CasaSkeleton variant="rect" height="6vh" width={200} animation="wave" />
          )}
        </Grid>
        <Grid item xs={2} style={{ display: 'none' }}>
          <PrimaryButton children="save changes" onClick={props.handleClickOpenSaveChanges} />
        </Grid>
      </Grid>
      <Grid container xs={12} direction="row-reverse">
        <Grid container item xs={6} style={{ paddingLeft: '6%' }}>
          <Grid item xs={6} data-tour="datacost" style={{ paddingLeft: '3%', paddingTop: '3%', borderRight: '0.7px solid #CCCCCC' }}>
            <DataAndCostAssumptions
              inputs={props.inputs && props.inputs}
              close={() => props.close()}
              interventionProjectMappingId={props.interventionProjectMappingId}
              projectId={props.projectId}
              activeSandboxId={props.activeSandboxId}
              handleRefresh={() => props.handleRefresh()}
              handleCancel={() => props.handleCancel()}
              handleBackdropOpen={() => props.handleBackdropOpen()}
              /*for new impl*/
              onClose={() => props.onClose()}
              customizable={props.customizable}
              refresh={props.refresh}
              boundaryMin={props.boundaryMin}
              boundaryMax={props.boundaryMax}
              mandates={props.mandates}
              mandateGenerals={props.mandateGenerals && props.mandateGenerals}
              mandateicon={props.mandateicon}
              mandateGeneralsIcon={props.mandateGeneralsIcon}
              projectinputs={props.projectinputs}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              padding: '3%',
            }}
          >
            <ViewPreviosSavesModal
              inputs={props.inputs && props.inputs}
              allSandboxes={props.allSandboxes}
              activeSandbox={props.activeSandbox}
              handleRevert={(data) => props.handleRevert(data)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
