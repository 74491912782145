export const impactProductsQuery = `{
  impactProducts(pagination:{limit:${1000}}) {
    data {
      id
      attributes {
        name
        products {
          data {
            id
            attributes {
              name
              category {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              subcategory {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              brand {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              filters
              shortDescription
              productDescription
              image
            }
          }
        }
      }
    }
  }
}`;
