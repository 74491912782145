import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from '../child-components/CustomSelect';
import CustomSlider from '../child-components/CustomSlider';
// import Level5 from '../child-components/level5';
import beforesdpluslogo from '../../../../../assets/NEWINSIGHTS/sustainabilityLevels/beforesdpluslogo.svg';
import aftersdpluslogo from '../../../../../assets/NEWINSIGHTS/sustainabilityLevels/aftersdpluslogo.svg';

import level1 from '../../../../../assets/bigSVGs/level1.png';
import level2 from '../../../../../assets/bigSVGs/level2.png';
import level3 from '../../../../../assets/bigSVGs/level3.png';
import level4 from '../../../../../assets/bigSVGs/level4.png';

// import ErrorIcon from '@material-ui/icons/Error';
// import AdjustIcon from '@material-ui/icons/Adjust';
// import RoomIcon from '@material-ui/icons/Room';
// import Box from '@material-ui/core/Box';
// import Slider from '@material-ui/core/Slider';

// const marks = [
//   {
//     value: 2,
//     label: 'Basic',
//   },
//   {
//     value: 4,
//     label: 'IGBC',
//   },
//   {
//     value: 6,
//     label: 'IGBC PLATINUM',
//   },
//   {
//     value: 8,
//     label: 'SD+',
//   },
//   {
//     value: 10,
//     label: 'SD+ ZEN',
//   },
// ];

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  text: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1,
    alignItems: 'center',
    letterSpacing: '0.801105px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  textSmall: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '10px',
    alignItems: 'center',
    letterSpacing: '0.801105px',
    paddingTop: theme.spacing(1),
  },
  gridPadding1: {
    padding: '1%',
  },
  customSliderGridPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  customSliderSpan: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  textAlignment: {
    textAlign: 'center',
  },
}));

const SustainabilityLevel = (props) => {
  const classes = useStyles();
  let sustainabilityLevel = props.sustainabilityLevel;

  return (
    <>
      <Container className={classes.gridContainer} disableGutters>
        <Grid container className={classes.gridPadding1} xs={12} data-tour="sustainabilitylevel">
          <Grid item container xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid item xs={4}>
              <CustomSelect />
            </Grid>
            <Grid item xs={8}></Grid>

            <Grid container item xs={12} className={classes.customSliderGridPadding}>
              {sustainabilityLevel && (
                <CustomSlider
                  curr={5}
                  max={
                    sustainabilityLevel.value === 1
                      ? 25
                      : sustainabilityLevel.value === 2
                      ? 50
                      : sustainabilityLevel.value === 3
                      ? 75
                      : sustainabilityLevel.value === 4
                      ? 95
                      : 80
                  }
                />
              )}
              <br />
              <Grid item xs={12} style={{ paddingTop: '25px' }}>
                <Typography className={classes.textSmall} color="textSecondary">
                  <img src={beforesdpluslogo} alt="before" />
                  <span className={classes.customSliderSpan}>Before SD+ {` `}</span>
                  {`  `}
                  <img src={aftersdpluslogo} alt="after" />
                  <span className={classes.customSliderSpan}> Current</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" justifyContent="center" xs={12} sm={12} md={4} lg={4} xl={4}>
            <Grid item xs={4} className={classes.textAlignment}>
              {sustainabilityLevel && sustainabilityLevel.value === 1 && <img src={level1} alt="..." className="img-fluid" style={{ height: '70px' }} />}
              {sustainabilityLevel && sustainabilityLevel.value === 2 && <img src={level2} alt="..." className="img-fluid" style={{ height: '70px' }} />}{' '}
              {sustainabilityLevel && sustainabilityLevel.value === 3 && <img src={level3} alt="..." className="img-fluid" style={{ height: '70px' }} />}{' '}
              {sustainabilityLevel && sustainabilityLevel.value === 4 && <img src={level4} alt="..." className="img-fluid" style={{ height: '70px' }} />}
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.text} style={{ color: '#9E9E9E' }}>
                You’re {sustainabilityLevel && sustainabilityLevel.value} times more sustainable{' '}
              </Typography>
              <Typography className={classes.text} style={{ color: '#9E9E9E' }}>
                85% of your building energy is sustainable
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SustainabilityLevel;
