import React from 'react';
import { Grid } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';

function HandleErrorForWaterBasedSelection(props) {
  const { waterBasedEnabled, selectedPaintFinish, selectedPaintName, selectedPaintBrand, handleError, handleNext, handleSave, next } = props;
  return (
    <>
      {waterBasedEnabled === true && (!selectedPaintFinish || !selectedPaintName || !selectedPaintBrand) ? (
        <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '6%' }}>
          <Grid item xs={2}>
            <PrimaryButton onClick={() => handleError('Please provide all inputs for Water-based Paint from popular brands')} children="Save" />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '6%' }}>
          <Grid item xs={2}>
            <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default HandleErrorForWaterBasedSelection;
