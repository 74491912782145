import React, { useEffect } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import PageTitle from './components/PageTitle';
import ComponentTitle from './components/ComponentTitle';
import SustainabilityLevel from './components/sustanabilityLevel';
import FianancialInsights from './components/Financial-Insights';
import InterventionsAndSelectedInterventions from './components/InterventionsAndSelectedInterventions';
import LocationBasedInsights from './components/LocationBasedInsights';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(11),
  },
}));

const Insights = (props) => {
  const classes = useStyles();
  const { goals, newintervention, sustainabilityLevel, insights } = props;
  useEffect(() => {
    localStorage.setItem('previouspage', 'insights');
  }, []);
  return (
    <>
      <Container className={classes.container}>
        <PageTitle title="Insights" />
        <ComponentTitle title="Sustainability level" marginbottom={0} />
        <SustainabilityLevel sustainabilityLevel={sustainabilityLevel} />
        <ComponentTitle title="Financial Insights" marginbottom={0} />
        <FianancialInsights newintervention={newintervention} />
        <InterventionsAndSelectedInterventions newintervention={newintervention} goals={goals} />
        <LocationBasedInsights insights={insights} />
      </Container>
    </>
  );
};

export default Insights;
