import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 380 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
  number: {
    fontSize: '16px',
    color: '#000000',

    lineHeight: '22px',
    marginTop: '8px',
    marginLeft: '15px',
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    background: '#E5E5E5',
    marginTop: 11,
    marginLeft: 22,
  },
  bar: {
    borderRadius: 20,
    background: `linear-gradient(90deg, #1FDEDB 0%, #42E198 100%, #42E198 100%)`,
  },
}))(LinearProgress);

export default function RangeBar({ rangevalue }) {
  const classes = useStyles();
  const [value, setValue] = useState(rangevalue);
  return (
    <>
      <Grid item xs={9}>
        <BorderLinearProgress variant="determinate" value={rangevalue} style={{ backgroundColor: `#E5E5E5` }} />
      </Grid>
      <Grid item xs={3}>
        <Typography className={classes.number}>{value}%</Typography>
      </Grid>
    </>
  );
}
