import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { GreenRadio } from '../../../../common/RadioButton/GreenRadio';

function PreferenceUnits(props) {
  const { field, type, index, radioButtonValue, onChange } = props;
  return (
    <>
      <Grid item container direction="row" xs={12} key={index} style={{ height: 'Auto', marginLeft: '2%', marginTop: '3%' }}>
        <Grid item alignItems="left" xs={12}>
          <Typography variant="h5">{field.displayName + `${type && type === 'basic' ? '*' : ''}`}</Typography>
        </Grid>
        {field.description && field.description !== 'NA' ? (
          <Grid item alignItems="center" xs={12} style={{ marginBottom: '1%' }}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {field.description}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        direction="row"
        item
        xs={12}
        key={index + 1}
        style={index === 0 ? { height: 'Auto', marginLeft: '2%' } : { height: 'Auto', marginLeft: '2%' }}
      >
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={radioButtonValue} onChange={onChange}>
            {field.options &&
              field.options.map((option, index) => {
                return (
                  <>
                    <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />
                  </>
                );
              })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
}

export default PreferenceUnits;
