import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Backdrop from '@material-ui/core/Backdrop';
import axios from '../../../../../config/Axios';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WaterSelectedInterv from './WaterSelectedInterv';
import EnergySelectedInterv from './Energy/EnergySelectedInterv';
import WasteSelectedInterv from './Waste/WasteSelected';

import CircularProgress from '@material-ui/core/CircularProgress';
import WaterFlowDiagram from '../flow-diagram/WaterFlowDiagram';
import WasteFlowDiagram from '../flow-diagram/WasteFlowDiagram';
import EnergyFlowDiagram from '../flow-diagram/EnergyFlowDiagram';
import Page0 from './Page0';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Footer from './Footer';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  centerdiv: {
    alignItems: 'center',
    margin: 'auto',
    width: '48%',
    border: '1px solid black',
  },
  div1: {
    alignItems: 'center',
    margin: 'auto',
    width: '48%',
    border: '1px solid black',
  },
  spacing: {
    marginTop: '3%',
  },
}));

const ProjectReportGeneration = (props) => {
  let projectId = props.match.params.id;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true); // while developing mark this as false
  const [values, setValues] = React.useState('');
  const [projectdata, setProjectData] = React.useState('');
  const [date, setDate] = React.useState('');
  const [ProjectinterventionMappingData, setprojectInterventionMappingData] = useState('');
  const [allVendorMapping, setAllvendorMappings] = useState('');
  const [allvendors, setAllvendors] = useState('');

  const [waterEnabledGrouped, setWaterEnabledGrouped] = useState('');
  const [EnergyEnabledGrouped, setEnergyEnabledGrouped] = useState('');
  const [WasteEnabledGrouped, setWasteEnabledGrouped] = useState('');

  const printDocument = () => {
    let promises = [];
    var elements = document.getElementsByClassName('test');
    for (var i = 0; i < elements.length; i++) {
      promises.push(html2canvas(elements[i]));
    }
    if (promises && promises.length > 0) {
      Promise.all(promises).then((value) => {
        setValues(value);
      });
    }
  };

  useEffect(() => {
    if (values && values.length !== 0 && open === true) {
      const pageWidth = 216;
      let e = [];
      values.forEach((element) => e.push({ height: (element.height * pageWidth) / element.width, data: element.toDataURL('image/JPEG', 0.8) }));
      if (e && e.length > 0) {
        let pdf = new jsPDF('p', 'mm', 'a4', false, true);
        e.forEach((d, index) => {
          var position = 0;
          pdf.setPage(index + 1);
          pdf.addImage(d.data, 'SVG', 0, position, pageWidth, d.height, undefined, 'SLOW');
          pdf.addPage('a4', 'p');
        });
        var pageCount = pdf.internal.getNumberOfPages();
        pdf.deletePage(pageCount);
        pdf.save('download.pdf');
        setOpen(false);
        // window.close();
      }
    }
  }, [values, open]);

  useEffect(() => {
    axios.get(`/projectv2/${projectId}`).then((response) => {
      axios.get(`/ProjectInterventionMapping/project/${projectId}`).then((res) => {
        if (response.data && response.data.data && response.data.data.data) setProjectData(response.data.data.data);
        if (res) setprojectInterventionMappingData(res.data.data.data);
      });
    });
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    setDate(today);
  }, [projectId]);

  useEffect(() => {
    axios.get(`/interventionVendorMapping`).then((response) => {
      axios.get(`/vendorsv2`).then((res) => {
        if (response) setAllvendorMappings(response.data.data.data);
        if (res) setAllvendors(res.data.data.data);
      });
    });
  }, []);

  useEffect(() => {
    if (projectdata && waterEnabledGrouped && WasteEnabledGrouped && EnergyEnabledGrouped) {
      printDocument();
    }
  }, [projectdata, waterEnabledGrouped, EnergyEnabledGrouped, WasteEnabledGrouped]);

  // flow diagram data
  let energyflowdiagram = projectdata && projectdata.interventions.categorized.find((interv) => interv && interv.name === 'Energy').quantities.flowDiagram;
  let waterflowdiagram = projectdata && projectdata.interventions.categorized.find((interv) => interv && interv.name === 'Water').quantities.flowDiagram;
  let wasteflowdiagram = projectdata && projectdata.interventions.categorized.find((interv) => interv && interv.name === 'Waste').quantities.flowDiagram;

  // for water interventions generate based on enabled values
  const WaterData = projectdata && projectdata.interventions.categorized.find((data) => data && data.name === 'Water');
  useEffect(() => {
    let arr = [];
    let n = 3;
    if (WaterData)
      WaterData &&
        WaterData.subcategories.filter((subcategory) => {
          subcategory.interventions &&
            subcategory.interventions
              .sort((a, b) =>
                a.name === 'Centralized RO' ||
                (a.name === 'Grey Water Re-use' && b.name === 'Black Water Re-use') ||
                (a.name === 'Rooftop RWH' && b.name === 'Surface RWH')
                  ? -1
                  : b.name === 'Dual Flush'
                  ? -1
                  : 0
              )
              .sort((a, b) => (b.name === 'Smart Water Meters' ? -1 : 0));
          subcategory.interventions &&
            subcategory.interventions.filter((intervention) => intervention && intervention.enabled === true && arr.push(intervention));
        });

    if (arr && arr.length > 0) {
      let groups = arr.reduce((r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r, []);
      setWaterEnabledGrouped(groups && groups);
    }
  }, [WaterData]);

  const EnergyData = projectdata && projectdata.interventions.categorized.find((data) => data && data.name === 'Energy');

  useEffect(() => {
    let arr = [];
    let n = 3;
    if (EnergyData)
      EnergyData &&
        EnergyData.subcategories.filter((subcategory) =>
          subcategory.interventions.filter((intervention) => intervention.enabled === true && arr.push(intervention))
        );
    if (arr && arr.length > 0) {
      let groups = arr.reduce((r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r, []);
      setEnergyEnabledGrouped(groups && groups);
    }
  }, [EnergyData]);

  const WasteData = projectdata && projectdata.interventions.categorized.find((data) => data && data.name === 'Waste');

  useEffect(() => {
    let arr = [];
    let n = 3;
    if (WasteData)
      WasteData &&
        WasteData.subcategories.filter((subcategory) =>
          subcategory.interventions.filter((intervention) => intervention && intervention.enabled === true && arr.push(intervention))
        );
    if (arr && arr.length > 0) {
      let groups = arr.reduce((r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r, []);
      setWasteEnabledGrouped(groups && groups);
    }
  }, [WasteData]);

  return (
    <>
      <div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {/* page 1 */}
      <div className={classes.centerdiv}>
        <div className="test">
          <Page0
            date={date}
            projectname={projectdata && projectdata.name}
            // 80 - letters 80 - (80/projectdata.name.length) /2
            space={projectdata && 220 - 220 / projectdata.name.length / 2}
          />
        </div>
      </div>

      {/* page 2 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Page1
            sustainabilitypercentage={
              projectdata
                ? projectdata.sustainabilityLevel && projectdata.sustainabilityLevel.value === 1
                  ? '25%'
                  : projectdata.sustainabilityLevel.value === 2
                  ? '50%'
                  : projectdata.sustainabilityLevel.value === 3
                  ? '75%'
                  : projectdata.sustainabilityLevel.value === 4
                  ? '100%'
                  : '0%'
                : '0%'
            }
            companyname={projectdata.name}
            data={projectdata}
          />
        </div>
      </div>

      {/* page 3 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Page2
            companyName={projectdata && projectdata.name}
            energy={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Energy')}
            water={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Water')}
            waste={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Waste')}
          />
        </div>
      </div>

      {/* page 4 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Page3
            category="Energy"
            companyName={projectdata && projectdata.name}
            data={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Energy')}
            let
            percentage={
              projectdata &&
              projectdata.interventions.categorized
                .filter((data) => data.name === 'Energy')[0]
                .subcategories[0].interventions[0].quantities.details.find((data) => data.name === 'energy.totalpercentagesaving')
            }
          />
        </div>
      </div>

      {/* Energy Interventions*/}
      {EnergyEnabledGrouped && EnergyEnabledGrouped.length > 0
        ? EnergyEnabledGrouped.map((group, index) => {
            return (
              <>
                <div className={classes.spacing} key={index}></div>
                <div className={classes.div1} key={index}>
                  <div className="test">
                    <EnergySelectedInterv
                      companyName={projectdata && projectdata.name}
                      category="Energy"
                      group={group && group}
                      ProjectinterventionMappingData={ProjectinterventionMappingData && ProjectinterventionMappingData}
                      allVendorMappings={allVendorMapping && allVendorMapping}
                      allvendors={allvendors && allvendors}
                    />
                  </div>
                </div>
              </>
            );
          })
        : null}

      {/* page 5 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Grid container xs={12} direction="column">
            <Grid container item xs={12} direction="column" style={{ marginTop: '5%', marginLeft: '3%' }}>
              <Header companyName={projectdata && projectdata.name} pagenumber="5" />
            </Grid>
            <Grid container item xs={12} direction="row" style={{ marginTop: '10%', marginLeft: '5%' }}>
              <Typography>Energy Flow Diagam</Typography>
            </Grid>
          </Grid>
          <div style={{ marginLeft: '3%', marginTop: '3%' }}>
            <EnergyFlowDiagram
              mainGrid={`${energyflowdiagram && energyflowdiagram.left.mainGrid.value}`}
              residentiallighting={`${energyflowdiagram && energyflowdiagram.right.residentialLighting.value}`}
              residentialhvac={`${energyflowdiagram && energyflowdiagram.right.residentialHvac.value}`}
              residentialfan={`${energyflowdiagram && energyflowdiagram.right.residentialFan.value}`}
              residentialhotwater={`${energyflowdiagram && energyflowdiagram.right.residentialHotWater.value}`}
              homeappliances={`${energyflowdiagram && energyflowdiagram.right.homeAppliances.value}`}
              commonarea={`${energyflowdiagram && energyflowdiagram.right.commonArea.value}`}
              dieselgenerator={`${energyflowdiagram && energyflowdiagram.left.dieselGenerator.value}`}
              solarweaterheater={`${energyflowdiagram && energyflowdiagram.left.solarWaterHeater.value}`}
              solarpv={`${energyflowdiagram && energyflowdiagram.left.solarPv.value}`}
              biogas={`${energyflowdiagram && energyflowdiagram.left.biogas.value}`}
              maingridtohotwater={`${energyflowdiagram && energyflowdiagram.connections[0].value.value}`}
              maingridtolighting={`${energyflowdiagram && energyflowdiagram.connections[1].value.value}`}
              dieslgeneratortolighting={`${energyflowdiagram && energyflowdiagram.connections[5].value.value}`}
              dieselgeneratortoappliances={`${energyflowdiagram && energyflowdiagram.connections[7].value.value}`}
              dieslgeneratortofan={`${energyflowdiagram && energyflowdiagram.connections[6].value.value}`}
              dieslgeneratortocommonarea="NA"
              maingridtoresidentialfan={`${energyflowdiagram && energyflowdiagram.connections[2].value.value}`}
              maingridtohomeappliances={`${energyflowdiagram && energyflowdiagram.connections[3].value.value}`}
              maingridtocommonarea="NA"
              maingridtohvac={`${energyflowdiagram && energyflowdiagram.connections[4].value.value}`}
              buiogastocommonarea={`${energyflowdiagram && energyflowdiagram.connections[10].value.value}`}
              solarwaterheatertoresidentialhotwater={`${energyflowdiagram && energyflowdiagram.connections[8].value.value}`}
              solarpvtocommonarea={`${energyflowdiagram && energyflowdiagram.connections[9].value.value}`}
            />
          </div>
          <Grid container xs={12} direction="column">
            <Grid container item xs={12} direction="column" style={{ marginTop: '10%', marginLeft: '3%' }}>
              <Footer />
            </Grid>
          </Grid>
          <div className={classes.spacing}></div>
        </div>
      </div>

      {/* page 6 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Page3
            category="Water"
            companyName={projectdata && projectdata.name}
            data={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Water')}
            let
            percentage={
              projectdata &&
              projectdata.interventions.categorized
                .filter((data) => data.name === 'Water')[0]
                .subcategories[0].interventions[0].quantities.details.find((data) => data.name === 'water.totalpercentagesaving')
            }
          />
        </div>
      </div>

      {/* page 7 - all water interventions */}

      {waterEnabledGrouped && waterEnabledGrouped.length > 0
        ? waterEnabledGrouped.map((group, index) => {
            return (
              <>
                <div className={classes.spacing} key={index}></div>
                <div className={classes.div1} key={index}>
                  <div className="test">
                    <WaterSelectedInterv
                      companyName={projectdata && projectdata.name}
                      category="Water"
                      pagenumber={`${7 + index}`}
                      group={group && group}
                      ProjectinterventionMappingData={ProjectinterventionMappingData && ProjectinterventionMappingData}
                      allVendorMappings={allVendorMapping && allVendorMapping}
                      allvendors={allvendors && allvendors}
                    />
                  </div>
                </div>
              </>
            );
          })
        : null}

      {/* page 8 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Grid container xs={6} direction="column">
            <Grid container item xs={12} direction="column" style={{ marginTop: '5%', marginLeft: '3%' }}>
              <Header companyName={projectdata && projectdata.name} pagenumber={waterEnabledGrouped && waterEnabledGrouped.length + 7} />
            </Grid>
            <Grid container item xs={12} direction="row" style={{ marginTop: '10%', marginLeft: '5%' }}>
              <Typography>Water Flow Diagam</Typography>
            </Grid>
            <div style={{ marginLeft: '3%', marginTop: '3%' }}>
              <WaterFlowDiagram
                TreatedGreyWater={waterflowdiagram && waterflowdiagram.left.treatedGreyWater.value}
                FreshWater={waterflowdiagram && waterflowdiagram.left.freshWater.value}
                CentralizedRo={waterflowdiagram && waterflowdiagram.left.centralizedRO.value}
                WTP={waterflowdiagram && waterflowdiagram.left.wtp.value}
                CommonAreaUse={waterflowdiagram && waterflowdiagram.right.commonAreaUse.value}
                DomesticWaterUse={waterflowdiagram && waterflowdiagram.right.domesticWaterUse.value}
                HealthFaucet={waterflowdiagram && waterflowdiagram.right.healthFaucet.value}
                Flushing={waterflowdiagram && waterflowdiagram.right.flushing.value}
                Drinking={waterflowdiagram && waterflowdiagram.right.drinking.value}
                GreyWaterSTP={waterflowdiagram && waterflowdiagram.right.greyWaterStp.value}
                BlackWaterSTP={waterflowdiagram && waterflowdiagram.right.blackWaterStp.value}
              />
            </div>
          </Grid>
          <Grid container xs={12} direction="column">
            <Grid container item xs={12} direction="column" style={{ marginTop: '10%', marginLeft: '3%' }}>
              <Footer />
            </Grid>
          </Grid>
        </div>
      </div>

      {/* page 9 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Page3
            category="Waste"
            pagenumber={waterEnabledGrouped && waterEnabledGrouped.length + 8}
            companyName={projectdata && projectdata.name}
            data={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Waste')}
            let
            percentage={
              projectdata &&
              projectdata.interventions.categorized
                .filter((data) => data.name === 'Waste')[0]
                .subcategories[0].interventions[0].quantities.details.find((data) => data.name === 'waste.totalpercentagesaving')
            }
          />
        </div>
      </div>

      {/* Waste Selected Intervention */}
      {WasteEnabledGrouped && WasteEnabledGrouped.length > 0
        ? WasteEnabledGrouped.map((group, index) => {
            return (
              <>
                <div className={classes.spacing} key={index}></div>
                <div className={classes.div1} key={index}>
                  <div className="test">
                    <WasteSelectedInterv
                      companyName={projectdata && projectdata.name}
                      category="Waste"
                      pagenumber={waterEnabledGrouped && waterEnabledGrouped.length + 9 + index}
                      group={group && group}
                      ProjectinterventionMappingData={ProjectinterventionMappingData && ProjectinterventionMappingData}
                      allVendorMappings={allVendorMapping && allVendorMapping}
                      allvendors={allvendors && allvendors}
                    />
                  </div>
                </div>
              </>
            );
          })
        : null}

      {/* page 10 */}
      <div className={classes.spacing}></div>
      <div className={classes.div1}>
        <div className="test">
          <Grid container xs={6} direction="column">
            <Grid container item xs={12} direction="column" style={{ marginTop: '5%', marginLeft: '3%' }}>
              <Header
                companyName={projectdata && projectdata.name}
                pagenumber={waterEnabledGrouped && WasteEnabledGrouped ? waterEnabledGrouped.length + 9 + WasteEnabledGrouped.length : 0}
              />
            </Grid>
            <Grid container item xs={12} direction="row" style={{ marginTop: '10%', marginLeft: '5%' }}>
              <Typography>Waste Flow Diagam</Typography>
            </Grid>
          </Grid>
          <div style={{ marginLeft: '3%', marginTop: '3%' }}>
            <WasteFlowDiagram
              rejectwaste={wasteflowdiagram && wasteflowdiagram.left.RejectWaste.value}
              sanitarywaste={wasteflowdiagram && wasteflowdiagram.left.SanitaryWaste.value}
              medicalwaste={wasteflowdiagram && wasteflowdiagram.left.MedicalWaste.value}
              ewaste={wasteflowdiagram && wasteflowdiagram.left.E_Waste.value}
              drywaste={wasteflowdiagram && wasteflowdiagram.left.DryWaste.value}
              organicwaste={wasteflowdiagram && wasteflowdiagram.left.OrganicWaste.value}
            />
          </div>
          <Grid container xs={12} direction="column">
            <Grid container item xs={12} direction="column" style={{ marginTop: '10%', marginLeft: '3%' }}>
              <Footer />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ProjectReportGeneration;

// var imgWidth = 455;
