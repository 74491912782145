export const NavigatorSource = {
  categories: 'Categories',
  supercategories: 'SuperCategories',
  certifications: 'Certifications',
  ecolabels: 'Ecolabels',
  search: 'search',
};

export const Default = {
  page: 1,
  pagesize: 20,
};

export const FilterSections = {
  NoSection: '',
  Brands: 'Brands',
  Filters: 'Filters',
};
export const FilterSubSections = {
  NoSection: '',
  SubCategory: 'SubCategory',
};
