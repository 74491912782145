import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Backdrop, CircularProgress } from '@material-ui/core';
import waterIcon from '../../../../assets/Fill 688.svg';
import powerIcon from '../../../../assets/Fill 1452.svg';
import CategoryTable from './components/categoryTable';
import wasteicon from '../../../../assets/interventions/Group 1365.svg';
import wasteActiveicon from '../../../../assets/interventions/wasteActivated.svg';
import waterActiveicon from '../../../../assets/interventions/waterActivated.svg';
import energyActiveicon from '../../../../assets/interventions/energyActivated.svg';
import activeMaterialIcon from '../../../../assets/interventions/materials/active-materials.svg';
import notActiveMaterialIcon from '../../../../assets/interventions/materials/not-active-materials.svg';

import Tour from 'reactour';
import HeadingAndButton from '../../../common/header/HeadingAndButton';
import useApiRequest from '../../../hooks/useApiRequest';
import InterventionOverview from './components/interventionOverview';
import TableOverview from './components/tableOverview';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import axios from '../../../../config/Axios';
import { wlakthroughContext } from '../../../../projectRouting/Index';
import { InterventionCategoryContext } from '../../../../projectRouting/Index';
import { InterventionMappingContext } from '../../../../projectRouting/Index';
import { calculateEnabledCount } from './utils';
import { PreviousPageContext } from '../../../../routing/IndexRouting';

export const StyledButton = withStyles((theme) => ({
  root: {
    '&:focus': {
      outline: 'none',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  gridWithPadding: {
    padding: theme.spacing(3),
  },
  main: {
    marginTop: '3%',
  },
  root: {
    paddingTop: '3%',
  },
  heading: {
    marginTop: '2%',
    width: '100%',
  },
  table: {
    marginTop: '3%',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1, 1, 0, 1),
    '& > *': {
      borderRadius: '10px',
      padding: theme.spacing(2, 2, 2, 2),
      margin: theme.spacing(1),
      width: theme.spacing(20),
      height: theme.spacing(8),
    },
  },
  tabbing: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginTop: '1%',
  },
  endIco: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  endIcoActive: {
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    letterSpacing: '0.571429px',
    color: '#FFFFFF',
    fontWeight: '800',
  },
  label: {
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  tourtexthead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '10px',
  },
  tourtext: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'justify',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
}));

function Intervention(props) {
  const classes = useStyles();
  let projectId = props.match.params.id;

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [stop, setStop] = useState(true);
  const [energyData, setEnergyData] = useState('');
  const [energyCount, setEnergyCount] = useState('');
  const [wasteData, setWasteData] = useState('');
  const [wasteCount, setWasteCount] = useState('');
  const [waterData, setWaterData] = useState('');
  const [materialsData, setMaterialsData] = useState('');
  const [waterCount, setWaterCount] = useState('');
  const [materialsCount, setMaterialsCount] = useState('');
  const [interventionData, setInterventionData] = useState('');
  const [editCustomintervention, setEditCustomintervention] = useState(false);
  const [editCustominterventionID, setEditCustominterventionID] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [userprofile, setUserprofile] = useState('');

  const { interventionCategoryContext, handleInterventionCategory } = useContext(InterventionCategoryContext);
  const { uiWalkthroughContext, handleMyWalkthrough } = useContext(wlakthroughContext);
  const { interventionMappingContext, handleInterventionMapping } = useContext(InterventionMappingContext);
  const { setPreviousPage } = useContext(PreviousPageContext);

  const [{ status, response }, makeRequest] = useApiRequest(`/projectv2/${projectId}/interventions`, {
    verb: 'get',
  });
  if (status === null && projectId) makeRequest();

  const handleEditCustomIntervention = (value) => setEditCustomintervention(value);
  const handleEditCustominterventionID = (value) => setEditCustominterventionID(value);

  const handleCloseBackdrop = (v) => setOpenBackdrop(v);
  const handleOpenBackdrop = (v) => setOpenBackdrop(v);
  const handleCreateReport = () => window.open(`/project/${projectId}/pdf`);

  /*Get status of how it works*/
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const handleEnd = () => {
    axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => { });
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };
  const handleStop = () => setStop(true);

  const handleTourClick = () => {
    if (interventionCategoryContext && interventionCategoryContext === 'energy') {
      let mappingid = energyData.subcategories[0].interventions[0].interventionProjectMappingId;
      props.history.push(`/projects/${projectId}/interventions/${mappingid}`);
    }
    if (interventionCategoryContext && interventionCategoryContext === 'water') {
      let mappingid = waterData.subcategories[0].interventions[0].interventionProjectMappingId;
      props.history.push(`/projects/${projectId}/interventions/${mappingid}`);
    }
    if (interventionCategoryContext && interventionCategoryContext === 'waste') {
      let mappingid = wasteData.subcategories[0].interventions[0].interventionProjectMappingId;
      props.history.push(`/projects/${projectId}/interventions/${mappingid}`);
    }
    if (interventionCategoryContext && interventionCategoryContext === 'materials') {
      let mappingid = materialsData.subcategories[0].interventions[0].interventionProjectMappingId;
      props.history.push(`/projects/${projectId}/interventions/${mappingid}`);
    }
  };

  const closeTour = () => {
    if (userprofile.showhowitworks === true) {
      axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => { });
    }
    setIsTourOpen(false);
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  const handleActiveBtn = (value) => {
    if (value === 'water') handleInterventionCategory('water');
    else if (value === 'energy') {
      window.scroll({
        top: 300,
        behavior: 'smooth',
      });
      handleInterventionCategory('energy');
    } else if (value === 'waste') handleInterventionCategory('waste');
    else if (value === 'materials') handleInterventionCategory('materials');
  };
  const handleRefresh = () => {
    if (projectId) {
      makeRequest();
      setStop(false);
      handleEditCustomIntervention(false);
      handleEditCustominterventionID('');
    }
  };

  useEffect(() => {
    setPreviousPage('interventions');
  }, []);

  useEffect(() => {
    if (response && response.data && response.data.data) {
      handleCloseBackdrop(false);
      setInterventionData(response.data.data.data);
      axios
        .get(`/ProjectInterventionMapping/project/${projectId}`)
        .then((respo) => {
          if (respo && respo.data && respo.data.data && respo.data.data.data) handleInterventionMapping(respo.data.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [response, projectId]);

  useEffect(() => {
    if (interventionData) {
      // console.log(interventionData, "intervention data")
      let energyData = interventionData.categorized.find((data) => data && data.name === 'Energy');
      let wastedata = interventionData.categorized.find((data) => data && data.name === 'Waste');
      let waterData = interventionData.categorized.find((data) => data && data.name === 'Water');
      let materialsData = interventionData.categorized.find((data) => data && data.name === 'Materials');
      setEnergyData(energyData && energyData);
      setWasteData(wastedata && wastedata);
      setWaterData(waterData && waterData);
      setMaterialsData(materialsData && materialsData);
    }
    if (interventionCategoryContext !== 'energy')
      window.scroll({
        top: 300,
        behavior: 'smooth',
      });
  }, [interventionCategoryContext, interventionData]);

  useEffect(() => {
    if (energyData) setEnergyCount(calculateEnabledCount(energyData));
    if (wasteData) setWasteCount(calculateEnabledCount(wasteData));
    if (waterData) setWaterCount(calculateEnabledCount(waterData));
    if (materialsData) setMaterialsCount(calculateEnabledCount(materialsData));
  }, [energyData, wasteData, waterData, materialsData]);

  useEffect(() => {
    if (response && stop === false) handleStop();
  }, [response, stop]);

  useEffect(() => {
    clearAllBodyScrollLocks();
    axios.get(`/userprofiles`).then((resp) => {
      if (resp.data && resp.data.data && resp.data.data) {
        setUserprofile(resp.data.data.data);
        handleMyWalkthrough(resp.data.data.data.showhowitworks);
      }
    });
  }, []);

  useEffect(() => {
    if (uiWalkthroughContext) setIsTourOpen(uiWalkthroughContext);
  }, [uiWalkthroughContext]);

  let tourProps = {
    isOpen: isTourOpen,
    onRequestClose: closeTour,
    steps: [
      {
        selector: '[data-tour="table"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Interventions/Solutions</Typography>
            <Typography className={classes.tourtext}>
              Solutions are listed under their respective category & summary of their financial & environmental impact are displayed
            </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Solutions can be enabled or disabled as per user’s requirement
            </Typography>
          </Grid>
        ),
        position: 'auto',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '240px',
        },
      },
      {
        selector: '[data-tour="diagram"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Flow Diagram</Typography>
            <Typography className={classes.tourtext}>A visual representaion of daily resource flow for each category</Typography>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '150px',
        },
      },
      {
        selector: '[data-tour="custom"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Custom Interventions</Typography>
            <Typography className={classes.tourtext}>
              Interventions that are not yet present on SD+ can be added manually so that they can be considered in the overall summary
            </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Please note that custom interventions will not have a intervention details page{' '}
            </Typography>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '240px',
        },
      },
      {
        selector: '[data-tour="report"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Create Report</Typography>
            <Typography className={classes.tourtext}>
              Download a shareable report (in pdf format) summarising all project information avaiable on SD+{' '}
            </Typography>
            <Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleTourClick}>
              Intervention Details
            </Button>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '170px',
        },
      },
    ],
  };

  return (
    <>
      <Grid className={classes.main}>
        <Grid className={classes.root}>
          <Grid className={classes.gridWithPadding}>
            {/* handleCreateReport={handleCreateReport} */}
            <HeadingAndButton heading="Interventions" className={classes.heading} handleCreateReport={handleCreateReport} />
            <InterventionOverview
              handleRefresh={() => handleRefresh()}
              financeSavings={interventionData && interventionData.quantities.overall.finance}
              resourceSavings={interventionData && interventionData.quantities.overall.resource}
              editCustomintervention={editCustomintervention && editCustomintervention}
              editCustominterventionID={editCustominterventionID && editCustominterventionID}
              editModalClose={(value) => handleEditCustomIntervention(value)}
              deselectID={(value) => handleEditCustominterventionID(value)}
              openBackdrop={openBackdrop}
              handleCloseBackdrop={(v) => handleCloseBackdrop(v)}
              handleOpenBackdrop={(v) => handleOpenBackdrop(v)}
            />
          </Grid>

          <Grid className={classes.tabbing}>
            <div className={classes.paper}>
              <StyledButton
                variant="text"
                style={
                  interventionCategoryContext && interventionCategoryContext === 'energy'
                    ? { backgroundColor: '#FFF4D5', marginLeft: '3%' }
                    : { backgroundColor: 'white', marginLeft: '3%' }
                }
                className={classes.button}
                startIcon={
                  <img
                    src={interventionCategoryContext && interventionCategoryContext === 'energy' ? energyActiveicon : powerIcon}
                    alt="..."
                    style={{ height: '3.5vh' }}
                  />
                }
                endIcon={
                  !energyCount ? (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'energy' ? classes.endIco : classes.endIco}>
                      0
                    </Typography>
                  ) : (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'energy' ? classes.endIco : classes.endIco}>
                      {energyCount}
                    </Typography>
                  )
                }
                children={
                  <Typography className={interventionCategoryContext && interventionCategoryContext === 'energy' ? classes.label : classes.label}>
                    Energy
                  </Typography>
                }
                onClick={() => handleActiveBtn('energy')}
              />
              <StyledButton
                variant="text"
                style={interventionCategoryContext && interventionCategoryContext === 'water' ? { backgroundColor: '#CFF2FA' } : { backgroundColor: 'white' }}
                className={classes.button}
                startIcon={
                  <img
                    src={interventionCategoryContext && interventionCategoryContext === 'water' ? waterActiveicon : waterIcon}
                    alt="..."
                    style={{ height: '3.5vh' }}
                  />
                }
                endIcon={
                  !waterCount ? (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'water' ? classes.endIco : classes.endIco}>
                      0
                    </Typography>
                  ) : (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'water' ? classes.endIco : classes.endIco}>
                      {waterCount}
                    </Typography>
                  )
                }
                children={
                  <Typography className={interventionCategoryContext && interventionCategoryContext === 'water' ? classes.label : classes.label}>
                    Water
                  </Typography>
                }
                onClick={() => handleActiveBtn('water')}
              />
              <StyledButton
                variant="text"
                style={interventionCategoryContext && interventionCategoryContext === 'waste' ? { backgroundColor: '#F3DDC8' } : { backgroundColor: 'white' }}
                className={classes.button}
                startIcon={
                  <img
                    src={interventionCategoryContext && interventionCategoryContext === 'waste' ? wasteActiveicon : wasteicon}
                    alt="..."
                    style={{ height: '3.5vh' }}
                  />
                }
                endIcon={
                  !wasteCount ? (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'waste' ? classes.endIco : classes.endIco}>
                      0
                    </Typography>
                  ) : (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'waste' ? classes.endIco : classes.endIco}>
                      {wasteCount}
                    </Typography>
                  )
                }
                children={
                  <Typography className={interventionCategoryContext && interventionCategoryContext === 'waste' ? classes.label : classes.label}>
                    Waste
                  </Typography>
                }
                onClick={() => handleActiveBtn('waste')}
              />
              <StyledButton
                variant="text"
                style={
                  interventionCategoryContext && interventionCategoryContext === 'materials' ? { backgroundColor: '#E1E6FF' } : { backgroundColor: 'white' }
                }
                className={classes.button}
                startIcon={
                  <img
                    src={interventionCategoryContext && interventionCategoryContext === 'materials' ? activeMaterialIcon : notActiveMaterialIcon}
                    alt="..."
                    style={{ height: '3.5vh' }}
                  />
                }
                endIcon={
                  !materialsCount ? (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'materials' ? classes.endIco : classes.endIco}>
                      0
                    </Typography>
                  ) : (
                    <Typography className={interventionCategoryContext && interventionCategoryContext === 'materials' ? classes.endIco : classes.endIco}>
                      {materialsCount}
                    </Typography>
                  )
                }
                children={
                  <Typography className={interventionCategoryContext && interventionCategoryContext === 'materials' ? classes.label : classes.label}>
                    Materials
                  </Typography>
                }
                onClick={() => handleActiveBtn('materials')}
              />
            </div>
          </Grid>

          <Grid className={classes.gridWithPadding}>
            {interventionCategoryContext && interventionCategoryContext === 'energy' && (
              <>
                <Grid alignItems="center" container xs={12}>
                  <TableOverview
                    resource="Energy"
                    carbonsaving={energyData && energyData.quantities && energyData.quantities.resource.carbonConverted}
                    financeSavings={energyData && energyData.quantities && energyData.quantities.finance}
                    resourceSavings={energyData && energyData.quantities && energyData.quantities.resource}
                    name={energyData.name}
                  />
                </Grid>
                <Grid alignItems="center" container xs={12} className={classes.table}>
                  <CategoryTable
                    data="Energy"
                    impactcolumn1="ENERGY SAVINGS"
                    impactcolumn2="(ANNUAL)"
                    tabledata={energyData && energyData.subcategories}
                    category={energyData.name}
                    projectID={projectId && projectId}
                    handleRefresh={() => handleRefresh()}
                    stopLoder={stop}
                    allinterv={interventionData.categorized}
                    handleEditCustomIntervention={(value) => handleEditCustomIntervention(value)}
                    handleEditCustominterventionID={(value) => handleEditCustominterventionID(value)}
                    handleOpenBackdrop={(v) => handleOpenBackdrop(v)}
                  />
                </Grid>
              </>
            )}

            {interventionCategoryContext && interventionCategoryContext === 'water' && (
              <>
                <Grid alignItems="center" container xs={12}>
                  <TableOverview
                    resource="Water"
                    carbonsaving={waterData && waterData.quantities && waterData.quantities.resource.carbonConverted}
                    financeSavings={waterData && waterData.quantities && waterData.quantities.finance}
                    resourceSavings={waterData && waterData.quantities && waterData.quantities.resource}
                    name={waterData.name}
                  />
                </Grid>
                <Grid alignItems="center" container xs={12} className={classes.table}>
                  <CategoryTable
                    data="Water"
                    impactcolumn1="WATER SAVINGS"
                    impactcolumn2="(ANNUAL)"
                    tabledata={waterData && waterData.subcategories}
                    category={waterData.name}
                    projectID={projectId && projectId}
                    handleRefresh={() => handleRefresh()}
                    stopLoder={stop}
                    allinterv={interventionData.categorized}
                    handleEditCustomIntervention={(value) => handleEditCustomIntervention(value)}
                    handleEditCustominterventionID={(value) => handleEditCustominterventionID(value)}
                    handleOpenBackdrop={(v) => handleOpenBackdrop(v)}
                  />
                </Grid>
              </>
            )}
            {/* waste */}
            {interventionCategoryContext && interventionCategoryContext === 'waste' && (
              <>
                <Grid alignItems="center" container xs={12}>
                  <TableOverview
                    resource="Waste"
                    carbonsaving={wasteData && wasteData.quantities && wasteData.quantities.resource.carbonConverted}
                    financeSavings={wasteData && wasteData.quantities && wasteData.quantities.finance}
                    resourceSavings={wasteData && wasteData.quantities && wasteData.quantities.resource}
                    name={wasteData.name}
                  />
                </Grid>
                <Grid alignItems="center" container xs={12} className={classes.table}>
                  <CategoryTable
                    data="Waste"
                    impactcolumn1="WASTE AVOIDED"
                    impactcolumn2="(ANNUAL)"
                    projectID={projectId && projectId}
                    handleRefresh={() => handleRefresh()}
                    category={wasteData.name}
                    stopLoder={stop}
                    tabledata={wasteData && wasteData.subcategories}
                    allinterv={interventionData.categorized}
                    handleEditCustomIntervention={(value) => handleEditCustomIntervention(value)}
                    handleEditCustominterventionID={(value) => handleEditCustominterventionID(value)}
                    handleOpenBackdrop={(v) => handleOpenBackdrop(v)}
                  />
                </Grid>
              </>
            )}
            {interventionCategoryContext && interventionCategoryContext === 'materials' && (
              <>
                <Grid alignItems="center" container xs={12}>
                  <TableOverview
                    resource="Materials"
                    carbonsaving={materialsData && materialsData.quantities && materialsData.quantities.resource.carbonConverted}
                    financeSavings={materialsData && materialsData.quantities && materialsData.quantities.finance}
                    resourceSavings={materialsData && materialsData.quantities && materialsData.quantities.resource}
                    name={materialsData.name}
                  />
                </Grid>
                <Grid alignItems="center" container xs={12} className={classes.table}>
                  <CategoryTable
                    data="Materials"
                    impactcolumn1="MATERIALS SAVINGS"
                    impactcolumn2="(ANNUAL)"
                    projectID={projectId && projectId}
                    handleRefresh={() => handleRefresh()}
                    category={materialsData.name}
                    stopLoder={stop}
                    tabledata={materialsData && materialsData.subcategories}
                    allinterv={interventionData.categorized}
                    handleEditCustomIntervention={(value) => handleEditCustomIntervention(value)}
                    handleEditCustominterventionID={(value) => handleEditCustominterventionID(value)}
                    handleOpenBackdrop={(v) => handleOpenBackdrop(v)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <div>
          <Backdrop open={openBackdrop} style={{ zIndex: 3, color: '#66E3BE' }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>

        <Tour
          {...tourProps}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          disableInteraction={true}
          scrollDuration={100}
          lastStepNextButton={
            <Button style={{ color: '#29E1AC', marginLeft: '150%', marginTop: '-60px', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleEnd}>
              End
            </Button>
          }
          disableKeyboardNavigation={['esc']}
          showNavigation={false}
          showNumber={false}
          prevButton
          nextButton={<Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', marginLeft: '150%', fontSize: '12px' }}>Next</Button>}
          badgeContent={(curr, tot) => `${curr} of ${tot}`}
          rounded={12}
          startAt={0}
        />
      </Grid>
    </>
  );
}

export default Intervention;
