import React from 'react';
import InterventionCard from './Components-page-categories/InterventionCard';
import { Grid, Typography, Paper } from '@material-ui/core';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import Footer from './Footer';
import numberWithCommas from '../../../../../utils/numberToComma';
import rsToCr from '../../../../../utils/rsToCr';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '14px',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.83)',
    letterSpacing: '0.71px',
    fontWeight: 700,
    textAlign: 'left',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
}));

const WaterSelectedInterv = (props) => {
  const classes = useStyles();

  const contentFunction = (name) => {
    if (name === 'Centralized RO') return 'Installing a Centralized RO plant instead of individual units in apartments is more water efficient';
    if (name === 'Efficient Fixtures') return 'With an added aerator, air is added to the water flow for water efficiency gains without a drop in pressure';
    if (name === 'Dual Flush') return 'It is a variation of the flush toilet that uses 2 buttons to flush different amounts of water';
    if (name === 'Smart Water Meters') return 'Flow sensors installed at unit level help occupants monitor water consumption to pay only for their usage';
    if (name === 'Rooftop RWH') return 'Rooftop rain water harvesting is the technique to capture rain water from the roof catchments and store in reservoirs';
    if (name === 'Surface RWH') return 'Surface rain water harvesting is the technique to capture rain water from the ground surfaces and store in reservoirs';
    if (name === 'Grey Water Re-use') return 'An ecofriendly FBR & FFR system based susbsurface flow system that works without power & daily surveillance';
    if (name === 'Black Water Re-use') return 'An ecofriendly FBR & FFR system based susbsurface flow system that works without power & daily surveillance';
    else return 'Custom Intervention';
  };

  const capacityFunction = (intervention, data) => {
    if (intervention === 'Centralized RO') {
      let value =
        data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily')
          ? data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily').value
          : '-';
      let unit =
        data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily')
          ? data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily').unit
          : '-';
      return { value, unit };
    }
    if (intervention === 'Grey Water Re-use') {
      let value =
        data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity').value
          : '-';
      let unit =
        data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity').unit
          : '-';
      return { value, unit };
    }
    if (intervention === 'Black Water Re-use') {
      let value =
        data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity').value
          : '-';
      let unit =
        data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity').unit
          : '-';
      return { value, unit };
    }
    if (intervention === 'Rooftop RWH') {
      let value =
        data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize') ? data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize').value : '-';
      let unit =
        data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize') ? data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize').unit : '-';
      return { value, unit };
    }
    if (intervention === 'Surface RWH') {
      let value =
        data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize').value : '-';
      let unit =
        data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize').unit : '-';
      return { value, unit };
    }
  };

  // const areaFunction = (intervention, data) => {
  //   if (intervention === 'Centralized RO') {
  //     let value =
  //       data && data.find((D) => D.id === 'centralizedro.outcomes.crodaily') ? data && data.find((D) => D.id === 'centralizedro.outcomes.crodaily').value : '-';
  //     let unit =
  //       data && data.find((D) => D.id === 'centralizedro.outcomes.crodaily') ? data && data.find((D) => D.id === 'centralizedro.outcomes.crodaily').unit : '-';
  //     return { value, unit };
  //   }
  //   if (intervention === 'Grey Water Re-use') {
  //     let value =
  //       data && data.find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity')
  //         ? data && data.find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity').value
  //         : '-';
  //     let unit =
  //       data && data.find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity')
  //         ? data && data.find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity').unit
  //         : '-';
  //     return { value, unit };
  //   }
  //   if (intervention === 'Black Water Re-use') {
  //     let value =
  //       data && data.find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity')
  //         ? data && data.find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity').value
  //         : '-';
  //     let unit =
  //       data && data.find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity')
  //         ? data && data.find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity').unit
  //         : '-';
  //     return { value, unit };
  //   }
  //   if (intervention === 'Rooftop RWH') {
  //     let value = data && data.find((D) => D.id === 'rooftoprwh.rrwhtanksize') ? data && data.find((D) => D.id === 'rooftoprwh.rrwhtanksize').value : '-';
  //     let unit = data && data.find((D) => D.id === 'rooftoprwh.rrwhtanksize') ? data && data.find((D) => D.id === 'rooftoprwh.rrwhtanksize').unit : '-';
  //     return { value, unit };
  //   }
  //   if (intervention === 'Surface RWH') {
  //     let value = data && data.find((D) => D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D.id === 'surfacerwh.srwhtanksize').value : '-';
  //     let unit = data && data.find((D) => D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D.id === 'surfacerwh.srwhtanksize').unit : '-';
  //     return { value, unit };
  //   }
  // };

  const selectedVendorFunction = (index, mappingId, data, vendorMappings, vendors) => {
    if (mappingId && data && vendorMappings && vendors) {
      let A = data.find((a) => a._id === mappingId && a.hasOwnProperty('selectedVendor'));
      let B = A && vendorMappings.find((vendor) => vendor._id === A.selectedVendor);
      let C = B && vendors.find((v) => v._id === B.vendor._id);
      return { logo: C && C.logo.URL };
    }
  };

  return (
    <>
      <Grid container item xs={12} style={{ paddingTop: '15px', paddingBottom: '5px', marginRight: '30px', marginLeft: '20px' }}>
        <Header pagenumber={props.pagenumber} companyName={props.companyName} />
      </Grid>
      <Grid container item xs={11} direction="column" style={{ paddingLeft: '20px' }}>
        <Typography className={classes.text}>Implemented interventions</Typography>

        {props && props.group ? (
          props.group.map((group, index) => {
            return (
              <>
                <div className={classes.spacing}></div>
                <div className={classes.div1}>
                  <div id="pdfdiv7">
                    <InterventionCard
                      color="1px solid #3BC7EE"
                      textcolor="#3BC7EE"
                      content={contentFunction(group.name)}
                      name={group.name}
                      capex={
                        group.quantities && group.quantities.finance && group.quantities.finance.capex ? rsToCr('Rs', group.quantities.finance.capex.value) : 0
                      }
                      opex={
                        group.quantities && group.quantities.finance && group.quantities.finance.opexSavings
                          ? rsToCr('Rs', group.quantities.finance.opexSavings.value)
                          : 0
                      }
                      resource={
                        group.quantities && group.quantities.resource && group.quantities.resource.category
                          ? numberWithCommas(group.quantities.resource.category.value)
                          : 0
                      }
                      unit={
                        group.quantities && group.quantities.resource && group.quantities.resource.category
                          ? numberWithCommas(group.quantities.resource.category.unit)
                          : '-'
                      }
                      capacity={
                        capacityFunction(group.name, group.quantities && group.quantities.details)
                          ? capacityFunction(group.name, group.quantities && group.quantities.details).value
                          : '-'
                      }
                      cpacityunit={
                        capacityFunction(group.name, group.quantities && group.quantities.details)
                          ? capacityFunction(group.name, group.quantities && group.quantities.details).unit
                          : '-'
                      }
                      areaunit={'-'}
                      area={0}
                      selectedVendor={
                        selectedVendorFunction(
                          index,
                          group.interventionProjectMappingId,
                          props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                          props.allVendorMappings && props.allVendorMappings,
                          props.allvendors && props.allvendors
                        ) &&
                        selectedVendorFunction(
                          index,
                          group.interventionProjectMappingId,
                          props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                          props.allVendorMappings && props.allVendorMappings,
                          props.allvendors && props.allvendors
                        )
                      }
                      category={props.category}
                    />
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <InterventionCard
            color="1px solid #3BC7EE"
            textcolor="#3BC7EE"
            content="SPV System converts sunlight into electricity which can be either fed into the grid or used on site"
            name={'hello'}
            capex={0}
            opex={0}
            resource={0}
            unit={0}
            capacity={0}
            cpacityunit={0}
            areaunit={0}
            area={0}
          />
        )}

        {props.group.length === 2 ? (
          <Grid>
            <Paper style={{ height: '200px', border: 'none', boxShadow: 'none' }}></Paper>
          </Grid>
        ) : (
          ''
        )}
        {props.group.length === 1 ? (
          <Grid>
            <Paper style={{ height: '400px', border: 'none', boxShadow: 'none' }}></Paper>
          </Grid>
        ) : (
          ''
        )}

        <Footer />
      </Grid>
    </>
  );
};

export default WaterSelectedInterv;

// data.find((d) => {
//   if (d._id === mappingId && d.hasOwnProperty('selectedVendor')) {
//     vendorMappings.find((vendor) => {
//       if (vendor && vendor._id === d.selectedVendor) {
//         vendors &&
//           vendors.find((v) => {
//             if (v._id === vendor.vendor._id) {
//               return v.logo.URL;
//             }
//           });
//       }
//     });
//   }
// });
