import React from 'react';
import { Grid, Typography, Card, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import numberWithCommas from '../../../../../utils/numberToComma';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  paper1: {
    // outline: 'none',
    // minHeight: 290,
    // height: 450,
    // minWidth: 630,
    // height: '432px',
    // padding: theme.spacing(2, 0, 2, 4),
    // background: '#FFFFFF',
    // borderRadius: '10px',
    // width: '50%',
    // boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    // position: 'relative',
    // new
    position: 'relative',
    width: 'auto',
    // height: 'auto',
    height: '435px',
    borderRadius: '10px',
    padding: theme.spacing(3, 2, 5, 2),
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
  },
  paper2: {
    position: 'absolute',
    top: '10%',
    height: '80%',
    width: '50px',
    margin: '1%',
    display: 'flex',
    flexDirection: ' column-reverse',
  },
  alignUnits: {
    // paddingLeft: 10,
    fontWeight: 100,
    // padding: theme.spacing(-10, 0, 0, 2),
    // color: '#9E9E9E',
    marginBottom: '50%',
  },
  textcolor: {
    color: '#9E9E9E',
  },
  position: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  paper3: {
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  paper4: {
    position: 'absolute',
    top: '12%',
    height: '80%',
    width: '10px',
    display: 'flex',
    flexDirection: ' column',
    boxShadow: 'none',
  },
  paper5: {
    height: '100%',
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '0px',
    color: '#9E9E9E',
  },
  paper6: {
    position: 'absolute',
    top: '12%',
    height: '80%',
    width: '30px',
    display: 'flex',
    flexDirection: ' column',
    boxShadow: 'none',
  },
}));
function getArraySum(a) {
  var total = 0;
  for (var i in a) {
    total += a[i];
  }
  return total;
}

const RightOverview = (props) => {
  const classes = useStyles();
  let color = props.color;
  let title = props.title;
  let counts = props.counts;
  let value = props.barvalues;
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);
  const TotalNumber = getArraySum(value);
  return (
    <div>
      <Card className={classes.paper1}>
        <Grid container direction="row" xs={12}>
          <Grid item xs={6} container alignItems="left" direction="column">
            <Grid xs item>
              <Typography variant="h5" style={{ color: '#9E9E9E', lineHeight: '20px' }}>
                {props.heading}
              </Typography>
            </Grid>
            <Grid xs item style={{ marginTop: '10%' }}>
              <Typography variant="h6">Savings (Annual)</Typography>
            </Grid>
            <Grid xs item>
              <Typography variant="body2" style={{ marginTop: '5%' }}>
                <b>{`${numberWithCommas(props.barmax)} ${props.barmaxUnit}`}</b>
              </Typography>
            </Grid>
            <Grid item container xs direction="row" alignItems="center" alignContent="center" style={{ paddingTop: '10%' }}>
              {title.map((name, index) => {
                return (
                  <>
                    <Grid item xs={2} style={{ paddingTop: '3%' }}>
                      <StopRoundedIcon style={{ border: 'none', color: `${color[index]}`, height: '40px', width: '40px' }} />
                    </Grid>
                    <Grid item xs={9} alignItems="center" alignContent="center" style={{ paddingTop: '3%' }}>
                      <Typography variant="h6">
                        <span className={classes.alignUnits}>{name}</span>
                      </Typography>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={6} container alignItems="left" direction="row">
            <Grid item xs={4} container alignItems="left" direction="column">
              <Grid item xs>
                <Typography variant="h6" className={classes.position} style={{ position: 'relative', top: '10%' }}>
                  {`${numberWithCommas(props.barmax)} ${props.barmaxUnit}`}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" style={{ position: 'absolute', bottom: '40px' }}>
                  {props.barmin}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper2}>
                {counts.map((tiles, index) => {
                  return (
                    <>
                      {value[index] > 0 ? (
                        <LightTooltip
                          title={`${numberWithCommas(value[index])} ${props.barmaxUnit} (${title[index]}) / ${numberWithCommas(props.barmax)} ${
                            props.barmaxUnit
                          }`}
                          placement="top"
                        >
                          <Paper
                            style={{
                              backgroundColor: `${color[index]}`,
                              height: `${(value[index] / TotalNumber) * 100}%`,
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                            className={classes.paper3}
                          >
                            {/* <Typography variant="h6" style={{ color: '#FFFFFF', paddingTop: `${value[index] >= 50 ? 80 : 10}%` }}>
                              {value[index] > 20 ? value[index] : ''}
                            </Typography> */}
                          </Paper>
                        </LightTooltip>
                      ) : (
                        <Paper
                          style={{
                            backgroundColor: `${color[index]}`,
                            height: `${(value[index] / TotalNumber) * 100}%`,
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }}
                          className={classes.paper3}
                        >
                          {/* <Typography variant="h6" style={{ color: '#FFFFFF', paddingTop: `${value[index] >= 50 ? 80 : 10}%` }}>
                            {value[index] > 20 ? value[index] : ''}
                          </Typography> */}
                        </Paper>
                      )}
                    </>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Grid container alignItems="left" direction="column" xs={12}>
                <Paper className={classes.paper4}>
                  <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                    <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                  </Paper>
                  <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                    <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                  </Paper>
                  <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                    <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                  </Paper>
                  <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>
                    <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                  </Paper>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container alignItems="left" direction="column" xs={12}>
                <Paper className={classes.paper6}>
                  <Paper className={classes.paper5}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                      100%
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper5}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                      75%
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper5}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                      50%
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper5}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                      20%
                    </Typography>
                  </Paper>
                  <Typography variant="h6" style={{ textAlign: 'center', color: '#9E9E9E' }}>
                    0%
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default RightOverview;

/*<Grid container direction="row" xs={12}>
          <Grid item xs={6}>
            <Grid container alignItems="left" direction="column" xs={12}>
              <Typography variant="h5" className={classes.textcolor}>
                {props.heading}
              </Typography>
              <Typography variant="h6" className={classes.textcolor}>
                Total Savings
              </Typography>
              <Typography variant="body2" style={{ paddingTop: '5%' }}>
                <b>{props.barmax}</b>
                <span className={classes.alignUnits}></span>
              </Typography>
              {title.map((name, index) => {
                return (
                  <Typography variant="h5">
                    <b>
                      <StopRoundedIcon style={{ border: 'none', color: `${color[index]}` }} />
                    </b>
                    <span className={classes.alignUnits}>{name.name}</span>
                  </Typography>
                );
              })}
            </Grid>
          </Grid>


          <Grid item xs={6}>
            <Grid container alignItems="left" direction="row" xs={12}>
              <Grid item xs={4}>
                <Grid container alignItems="left" direction="column" xs={12}>
                  <Typography variant="h6" className={classes.position} style={{ position: 'absolute', top: '10%', color: '#9E9E9E' }}>
                    {props.barmax}
                  </Typography>
                  <Typography variant="h6" style={{ position: 'absolute', top: '90%', color: '#9E9E9E' }}>
                    {props.barmin}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper2}>
                  {counts.map((tiles, index) => {
                    return (
                      <Paper
                        style={{ backgroundColor: `${color[index]}`, height: `${value[index]}%`, textAlign: 'center', verticalAlign: 'middle' }}
                        className={classes.paper3}
                      >
                        <Typography variant="h6" style={{ color: '#FFFFFF', paddingTop: `${value[index] >= 50 ? 80 : 10}%` }}>
                          {value[index]}
                        </Typography>
                      </Paper>
                    );
                  })}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Grid container alignItems="left" direction="column" xs={12}>
                  <Paper className={classes.paper4}>
                    <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                      <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                    </Paper>
                    <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                      <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                    </Paper>
                    <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                      <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                    </Paper>
                    <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>
                      <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                    </Paper>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container alignItems="left" direction="column" xs={12}>
                  <Paper className={classes.paper6}>
                    <Paper className={classes.paper5}>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        100%
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper5}>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        75%
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper5}>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        50%
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper5}>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        20%
                      </Typography>
                    </Paper>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                      0%
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */
