import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';



const CommonCardNewInputs = ({ capex, capexTotalValue, capexTotalConvertedValue, title, inputs, height, width, tooltipBody, tooltipReady, selectedIntervention, showTotalCapex }) => {
    const useStyles = makeStyles((theme) => ({
        paper1: {
            outline: 'none',
            height: height,
            minWidth: width,
            padding: theme.spacing(2, 1, 2, 4),
            background: '#FFFFFF',
            borderRadius: '10px',
            boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
        },
        alignTypo: {
            marginTop: '5%',
        },
        alignUnits: {
            paddingLeft: 10,
        },
        errorIcon: {
            color: '#66E3BE',
            cursor: 'pointer',
            height: '70%',
            marginTop: '2%',
        },
    }));
    const classes = useStyles();

    const ids = [{ id: 'Capex', tag: ['Capex'] }, { id: 'Opex', tag: ['Opex'] }, { id: 'IRR', tag: ['result-financial'] }, { id: 'PaybackPeriod', tag: ['result-financial'] }]
    const CapexConvertedIds = [{ id: 'CapexConverted', tag: ['CapexConverted'] }]
    const TotalCapexId = [{ id: 'CapexTotal', tag: ['_TotalCapex'] }]

    let filteredObjects = inputs.filter(obj =>
        ids.some(idObj => obj.id.includes(idObj.id) && idObj.tag.some(tag => obj.tags.includes(tag)))
    );
    let capexConvertedIdsfilteredObjects = inputs.filter(obj =>
        CapexConvertedIds.some(idObj => obj.id.includes(idObj.id) && idObj.tag.some(tag => obj.tags.includes(tag)))
    );
    let TotalCapexfilteredObjects = inputs.filter(obj =>
        TotalCapexId.some(idObj => obj.id.includes(idObj.id) && idObj.tag.some(tag => obj.tags.includes(tag)))
    );

    console.log({ filteredObjects, capexConvertedIdsfilteredObjects, TotalCapexfilteredObjects, inputs }, "filtereddddddddddddd")


    return (
        <Card className={classes.paper1}>

            <Grid container direction="row" xs={12} >
                {filteredObjects &&
                    filteredObjects.map((item, index) => {
                        return (
                            <>
                                {/* {item.label !== `Tank Size - Default Recommendation` && ( */}
                                <Grid xs={6} container direction="row" style={index > 1 ? { marginTop: '6%', } : null}>
                                    <Grid xs={12} container direction="row" >
                                        <Typography color="textSecondary">{item?.label?.toLowerCase() === 'capex' ? 'Capex Increase' : item?.label} </Typography>
                                        {tooltipReady && (item.label === 'SDG Number' || (selectedIntervention && selectedIntervention === 'Sanitary Napkin Incinerator')) ? (
                                            <>
                                                <CustomizedTooltips
                                                    icon="info"
                                                    title={
                                                        <>
                                                            <Typography>{tooltipBody && tooltipBody ? tooltipBody : ''}</Typography>
                                                        </>
                                                    }
                                                    placement="right"
                                                />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </Grid>
                                    <Grid xs={12} direction="row">
                                        <Typography className={classes.alignTypo}>
                                            {index === 0 && title === 'Intervention Results' && capex === false && capexConvertedIdsfilteredObjects && <b>{capexConvertedIdsfilteredObjects[0]?.value}</b>}

                                            {index === 0 && title === 'Intervention Results' && capex === true && <b style={item.value < 0 ? { color: '#26E0A3' } : { color: 'black' }}>{item && item.value && isNaN(item.value) ? item.value : item && item.value && `${numberWithCommas(item.value)}`}</b>}

                                            {index != 0 && <b style={item.value < 0 ? { color: '#26E0A3' } : { color: 'black' }}>{item && item.value && isNaN(item.value) ? item.value : item && item.value && `${numberWithCommas(item.value)}`}</b>}

                                            <span className={classes.alignUnits}>{index === 0 && title === 'Intervention Results' && capex === false ? 'Rs/Sqft' : item?.unit}</span>
                                        </Typography>

                                    </Grid>
                                </Grid >
                                {index === 1 && title === 'Intervention Results' && <Grid xs={12} style={{ marginTop: '2%', }}>
                                    {capex ? <Typography style={{ fontSize: '11px' }} ><b style={{ marginRight: '2%' }}>{numberWithCommas(capexTotalValue?.value)}</b>{`Rs`} in Total CAPEX</Typography> : <Typography style={{ fontSize: '11px' }} ><b style={{ marginRight: '2%' }}>{numberWithCommas(capexTotalConvertedValue?.value)}</b>{`Rs/Sqft`} in Total CAPEX</Typography>}
                                </Grid >}

                                {/* )} */}
                            </>
                        );
                    })}
            </Grid>

        </Card >
    )
}

export default CommonCardNewInputs