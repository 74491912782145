import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import PlumbingDetailsComponent from '../plumbing/PlumbingDetailsComponent';
import { ProjectContext } from '../../../../routing/IndexRouting';
import PreferenceUnits from './components/PreferenceUnits';
import LocationBox from './components/Location';
import Skeletons from '../../../common/skeleton/Skeletons';
import SimplePIPS from './components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import ComplexPIPS from './components/ComplexPIPS';
import CustomDwellingPIP from './components/CustomDwellingPIP';

export const CAPEXPIPsForWWB = {
  OrganicComposter: 'wwb.userbasecase.organicwastecomposter.capex',
  Composting: 'wwb.userbasecase.composting.capex',
};

export const renewableenergy = {
  spv: 'basecasespvcapacity',
};

export const Dwelling = {
  perimeter: 'perimeter',
  wwr: 'wwr',
  floors: 'floors',
  floorheight: 'floor2floorheight',
};

const CommonEditProject = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let skeleton = [1, 2, 3, 4, 5];
  let { type, next, name } = props;
  const [myArray, setMyArray] = useState([]);
  const { projectData, handleProjectData } = useContext(ProjectContext);
  const [unitPreferencesPIP, setUnitPreferencesPIP] = useState('');
  const [simplePIPs, setSimplePIPs] = useState('');
  const [locationBox, setLocationBoxPIP] = useState('');

  // others
  const [data, setData] = useState('');
  const [projectPIP, setProjectPIP] = useState('');
  const [builtup, setBuiltuparea] = useState('');
  const [dwelling, setDwelling] = useState('');
  const [mannuals, setmannuals] = useState('');
  const [projectdetails, setProjectDetails] = useState('');

  //Location Service
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [newloc, setNewloc] = useState({});
  const [value, setValue] = React.useState(options[0]);
  const [inputchange, setInputChange] = useState('');

  // unit states
  const [unit, setUnit] = useState('');
  const [radioButtonValue, setRadioButtonValue] = useState('');
  const [unitPIPid, setUnitPIPid] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
  const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
    verb: 'get',
  });
  if (status === null && type && type !== 'file') makeRequest();

  useEffect(() => {
    if (data) {
      const unitPreferences = data.filter((field) => field.name === 'preferences.units');
      const SimplePIPs = data.filter((field) => field.name !== 'clubhouse' && field.name !== 'location' && field.name !== 'preferences.units');
      const LocationBox = data.filter((field) => field.name === 'location');
      setSimplePIPs(SimplePIPs);
      setUnitPreferencesPIP(unitPreferences);
      setLocationBoxPIP(LocationBox);
    }
  }, [data]);

  useEffect(() => {
    if (simplePIPs && type) {
      if (type === 'basic') {
        const sortedData = simplePIPs.sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setSimplePIPs(sortedData);
      }
    }
  }, [simplePIPs, type]);

  //useEffects
  useEffect(() => {
    if (projectPIP.length !== 0) {
      let arr2 = [];
      projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
      if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
    }
  }, [projectPIP]);

  useEffect(() => {
    if (projectPIP.length !== 0 && myArray.length !== 0) {
      myArray.forEach((ele) => {
        let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
        if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
      });
    }
  }, [projectPIP, myArray]);

  useEffect(() => {
    if (response) {
      const unsortedData = response && response.data.data.data;
      const sorted = unsortedData.sort((a, b) => compare(a, b));
      setData(sorted);
    }
  }, [response]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/ProjectPIPMapping/get?project=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) {
            setProjectPIP(response.data.data.data);
            axios.get(`/projectv2/${id}`).then((res) => {
              if (res && res.data && res.data.data) setProjectDetails(res.data.data.data);
            });
          }
        })
        .catch((err) => console.log(err, 'error'));
    }
  }, [id]);

  useEffect(() => {
    if (projectdetails) handleProjectData(projectdetails);
  }, [projectdetails]);

  useEffect(() => {
    if (data) {
      const mannualData = data.filter(
        (d) =>
          d.category === 'fixture' &&
          (d.name === 'sink' || d.name === 'shower' || d.name === 'bathroom' || d.name === 'health' || d.name === 'fullwc' || d.name === 'halfwc')
      );
      setmannuals(mannualData);
    }
  }, [data]);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue === 'Sqm' && unitPIPid) appendChanged(unitPIPid, 'Sqm');
    if (radioButtonValue && radioButtonValue === 'Sqft' && unitPIPid) appendChanged(unitPIPid, 'Sqft');
  }, [radioButtonValue, unitPIPid]);

  useEffect(() => {
    if (unit && unit) setRadioButtonValue(unit);
  }, [unit]);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue) setUnit(radioButtonValue);
  }, [radioButtonValue]);
  const [studio, setStudio] = useState('');
  const [single, setSingle] = useState('');
  const [double, setDouble] = useState('');
  const [three, setThree] = useState('');
  const [four, setFour] = useState('');
  const [studioCarpet, setStudioCarpet] = useState('');
  const [singleCarpet, setSingleCarpet] = useState('');
  const [doublecarpet, setDoublecarpet] = useState('');
  const [threeecarpet, setThreeecarpet] = useState('');
  const [fourcarpet, setFourcarpet] = useState('');

  useEffect(() => {
    if (projectPIP) {
      const builtupArea = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'builtup');
      const studio = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'studio');
      const single = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'single');
      const double = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'double');
      const three = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'three');
      const four = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'four');
      // check for pips for existing unit for the project
      const unit = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'preferences.units');
      const StudioUnits = studio && studio.value ? parseInt(studio.value) : 0;
      const OneBHKUnits = single && single.value ? parseInt(single.value) : 0;
      const TwoBHKUnits = double && double.value ? parseInt(double.value) : 0;
      const ThreeBHKUnits = three && three.value ? parseInt(three.value) : 0;
      const FourBHKUnits = four && four.value ? parseInt(four.value) : 0;

      // find the value as sqm or sqft from pip mapping
      const projectUnit = unit && unit.value ? unit.value : 'Sqm';
      setBuiltuparea(builtupArea && builtupArea.value);
      setDwelling(StudioUnits + OneBHKUnits + TwoBHKUnits + ThreeBHKUnits + FourBHKUnits);
      // set project unit if found from pips
      setUnit(projectUnit && projectUnit);
    }
  }, [projectPIP]);

  const compare = (a, b) => {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    else return 0;
  };
  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    if (props.saveAfterSomethingChanged === true && next) {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      props.SETNEXT('');
      handleSave(next && next);
    }
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };
  const handleSearch = (e) => {
    setSearch(true);
    setLoading(true);
    axios.get(`/location/?location=${inputchange}`).then((resp) => {
      if (resp && resp.data && resp.data.data) setOptions([...resp.data.data.data]);
    });
    setOpen(true);
    setLoading(false);
    return () => setSearch(false);
  };

  const handleSave = (next) => {
    if (next === 'materials') {
      let body2 = {
        projectId: id,
        values: myArray && myArray.length !== 0 && myArray,
      };
      axios.put(`/ProjectPIPMapping`, body2).then((response) => {
        props.toogleShowModal(false);
        handlSetSomethingChanged(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');

        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
    } else if (type === 'basic' && newloc && newloc.placeId) {
      axios.get(`/location/place?id=${newloc.placeId}`).then((ress) => {
        if (ress && ress.data && ress.data.data) {
          let loc = ress.data.data.data;
          // sends data
          let body2 = {
            projectId: id,
            values: myArray && myArray.length !== 0 && myArray,
          };
          let body3 = {
            newLocation: {
              name: loc.name,
              type: 'Point',
              coordinates: [loc.coordinates.lat, loc.coordinates.long],
            },
          };
          axios.put(`/ProjectPIPMapping`, body2).then((response) => {
            axios.put(`/projectv2/${id}`, body3).then((response) => {
              props.toogleShowModal(false);
              handlSetSomethingChanged(false);
              props.setFalseAfterSomethingChanged();
              props.SETNEXT('');

              swal({ icon: 'success', title: 'Data Saved' });
              if (next && next === 'insights') history.push(`/projects/${id}/insights`);
              if (next && next === 'projects') history.push(`/projects`);
              else history.push(`/projects/${id}/edit?type=${next}`);
            });
          });
        }
      });
    } else {
      // sends data
      let body2 = {
        projectId: id,
        values: myArray && myArray.length !== 0 && myArray,
      };
      axios.put(`/ProjectPIPMapping`, body2).then((response) => {
        props.toogleShowModal(false);
        handlSetSomethingChanged(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
    }
  };

  // creates myArray for sending all values together
  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };
  useEffect(() => {
    if (simplePIPs) {
      const studio = simplePIPs && simplePIPs.find((d) => d.name === 'studio');
      const single = simplePIPs && simplePIPs.find((d) => d.name === 'single');
      const double = simplePIPs && simplePIPs.find((d) => d.name === 'double');
      const three = simplePIPs && simplePIPs.find((d) => d.name === 'three');
      const four = simplePIPs && simplePIPs.find((d) => d.name === 'four');
      const studioCarpet = simplePIPs && simplePIPs.find((d) => d.name === 'studiocarpetarea');
      const singleCarpet = simplePIPs && simplePIPs.find((d) => d.name === 'singlecarpetarea');
      const doublecarpet = simplePIPs && simplePIPs.find((d) => d.name === 'doublecarpetarea');
      const threeecarpet = simplePIPs && simplePIPs.find((d) => d.name === 'threecarpetarea');
      const fourcarpet = simplePIPs && simplePIPs.find((d) => d.name === 'fourcarpetarea');
      setFourcarpet(fourcarpet);
      setThreeecarpet(threeecarpet);
      setDoublecarpet(doublecarpet);
      setSingleCarpet(singleCarpet);
      setStudioCarpet(studioCarpet);
      setFour(four);
      setThree(three);
      setDouble(double);
      setSingle(single);
      setStudio(studio);
    }
  }, [simplePIPs]);

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData !== null ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>
      {/* units ////////////////////////////////////////////////////////////////////// */}
      {type && type !== 'fixture' && type !== 'file' && unitPreferencesPIP && projectdetails
        ? unitPreferencesPIP.map((field, index) => {
            return (
              <PreferenceUnits
                field={field}
                type={type}
                index={index}
                radioButtonValue={radioButtonValue}
                onChange={(e) => {
                  setRadioButtonValue(e.target.value);
                  setUnitPIPid(field._id);
                }}
              />
            );
          })
        : null}
      {/* others ////////////////////////////////////////////////////////////////////////// */}
      {type && type !== 'fixture' && type !== 'file' && locationBox && projectdetails
        ? locationBox.map((field, index) => {
            return (
              <LocationBox
                field={field}
                type={type}
                index={index}
                options={options}
                loading={loading}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  setNewloc(newValue);
                  if (newValue != null) {
                    appendChanged(field._id, newValue.description);
                  }
                }}
                onInputChange={(e, newInputValue) => {
                  setInputChange(newInputValue);
                }}
                handleSearch={handleSearch}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) => option.description === value.description}
                defaultValue={{
                  description: projectdetails.location.name,
                  co_ordinates: projectdetails.location.coordinates,
                }}
              />
            );
          })
        : type &&
          type !== 'fixture' &&
          type !== 'file' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      {type && type !== 'fixture' && type !== 'file' && simplePIPs && projectdetails && type === 'dwelling' && (
        <>
          <Grid container xs={12} style={{ height: 'Auto', marginTop: '3%', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">Studio</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={studio && studio}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={1}
                  unit={unit && unit}
                  field={studioCarpet && studioCarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              1 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {single?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={single && single}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={singleCarpet && singleCarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              2 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {double?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={double && double}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={doublecarpet && doublecarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              3 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {three?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={three && three}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={threeecarpet && threeecarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              4 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {four?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={four && four}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={fourcarpet && fourcarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {type && type !== 'fixture' && type !== 'file' && simplePIPs && projectdetails
        ? simplePIPs.map((field, index) => {
            // eslint-disable-next-line no-lone-blocks
            {
              field.name === renewableenergy.spv && <></>;
            }
            if (
              field.name !== CAPEXPIPsForWWB.OrganicComposter &&
              field.name !== CAPEXPIPsForWWB.Composting &&
              field.name !== renewableenergy.spv &&
              field.name !== 'single' &&
              field.name !== 'double' &&
              field.name !== 'three' &&
              field.name !== 'four' &&
              field.name !== 'studio' &&
              field.name !== 'singlecarpetarea' &&
              field.name !== 'doublecarpetarea' &&
              field.name !== 'threecarpetarea' &&
              field.name !== 'fourcarpetarea' &&
              field.name !== 'studiocarpetarea'
            ) {
              if (field && field.options.length === 0) {
                return (
                  <SimplePIPS
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                  />
                );
              } else {
                return (
                  <ComplexPIPS
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                );
              }
            } else if (field.name === renewableenergy.spv) {
              return (
                <>
                  <Grid item alignItems="left" xs={12}>
                    <Typography variant="body2">Renewable Energy</Typography>
                  </Grid>
                  <SimplePIPS
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                  />
                </>
              );
            } else {
              return '';
            }
          })
        : type &&
          type !== 'fixture' &&
          type !== 'file' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      {/* for fixture new component*/}
      {type && type === 'fixture' && (
        <PlumbingDetailsComponent
          myArray={myArray && myArray}
          fixturesData={data && data}
          projectPIP={projectPIP && projectPIP}
          appendChanged={(id, value) => appendChanged(id, value)}
          type={type && type}
          mannuals={mannuals && mannuals}
          builtup={builtup && builtup}
          dwelling={dwelling && dwelling}
        />
      )}
      {type && type === 'basic' ? (
        <Typography variant="h6" color="textSecondary" style={{ marginLeft: '2%' }}>
          *All fields are mandatory
        </Typography>
      ) : (
        ''
      )}
      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
export default CommonEditProject;
