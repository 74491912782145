import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelectInterventions from '../../insights/child-components/CustomSelectInterventions';
import { useHistory } from 'react-router-dom';

import lightManagementSystem from '../../../../../assets/lightManagementSystem.svg';
import toxicNew from '../../../../../assets/icons/waste/segregation.svg';
import solarPV from '../../../../../assets/solarPV.svg';
import EfficientFixtures from '../../../../../assets/efficientFixtures.svg';
import rooftopRunoff from '../../../../../assets/rooftopRunoff.svg';
import surfaceRunoff from '../../../../../assets/surfaceRunoff.svg';
import solarwaterheater from '../../../../../assets/solarwaterheater.svg';
import smartWaterMeter from '../../../../../assets/smartwatermeter.svg';
import medicalWaste from '../../../../../assets/medical.png';
import eWastehandling from '../../../../../assets/eWastehandling.svg';
import dualflush from '../../../../../assets/dualflush.svg';
import CentralisedRO from '../../../../../assets/CentralisedRO.svg';
import blackwaterReuse from '../../../../../assets/blackwaterReuse.svg';
import biogasPlant from '../../../../../assets/icons/waste/biogas.svg';
import greywaterReuse from '../../../../../assets/greywater.svg';
import incinaretor from '../../../../../assets/icons/waste/snitary.svg';
import composter from '../../../../../assets/composter.svg';
import EnergyCustom from '../../../../../assets/icons/energy/custom-icon.png';
import WaterCustom from '../../../../../assets/icons/water/custom-icon.png';
import WasteCustom from '../../../../../assets/icons/waste/custom-icon.png';
import MaterialsCustom from '../../../../../assets/interventions/materials/custom.svg';
import ExteriorPaints from '../../../../../assets/interventions/materials/exterior-paints.svg';
import { Link } from 'react-router-dom';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useParams } from 'react-router';
import PrimaryButton from '../../../../../components/common/button/Button';
import { InterventionCategoryContext } from '../../../../../projectRouting/Index';
import concrete from '../../../../../assets/interventions/materials/concrete.svg';
import structure from '../../../../../assets/interventions/materials/structure.svg';
import balconyandutility from '../../../../../assets/interventions/materials/balconyandutility.svg';
import livinganddining from '../../../../../assets/interventions/materials/livinganddining.svg';
import bedroom from '../../../../../assets/interventions/materials/bedroom.svg';
import bathroom from '../../../../../assets/interventions/materials/bathroom.svg';
import kitchen from '../../../../../assets/interventions/materials/kitchen.svg';

const icons = [
  { intervention: 'Biogas To Electricity Generation', icon: lightManagementSystem, color: '#FFAC49' },
  { intervention: 'Solar PV', icon: solarPV, color: '#FFAC49' },
  { intervention: 'Efficient Fixtures', icon: EfficientFixtures, color: '#3BC7EE' },
  { intervention: 'Collection & Segregation Point', icon: toxicNew, color: '#BC8A5F' },
  { intervention: 'Solar Water Heater', icon: solarwaterheater, color: '#FFAC49' },
  { intervention: 'Centralized RO', icon: CentralisedRO, color: '#3BC7EE' },
  { intervention: 'Smart Water Meters', icon: smartWaterMeter, color: '#3BC7EE' },
  { intervention: 'Dual Flush', icon: dualflush, color: '#3BC7EE' },
  { intervention: 'Rooftop RWH', icon: rooftopRunoff, color: '#3BC7EE' },
  { intervention: 'Surface RWH', icon: surfaceRunoff, color: '#3BC7EE' },
  { intervention: 'Black Water Re-use', icon: blackwaterReuse, color: '#3BC7EE' },
  { intervention: 'Wet Waste Management', icon: biogasPlant, color: '#BC8A5F' },
  { intervention: 'E-Waste Handling', icon: eWastehandling, color: '#BC8A5F' },
  { intervention: 'Medical Waste Handling', icon: medicalWaste, color: '#BC8A5F' },
  { intervention: 'Grey Water Re-use', icon: greywaterReuse, color: '#3BC7EE' },
  { intervention: 'Sanitary Napkin Incinerator', icon: incinaretor, color: '#BC8A5F' },
  { intervention: 'Segregation Point', icon: composter, color: '#BC8A5F' },
  { intervention: 'Exterior Paints', icon: ExteriorPaints, color: '#BC8A5F' },
  { intervention: 'Energy', icon: EnergyCustom, color: '#FFAC49' },
  { intervention: 'Water', icon: WaterCustom, color: '#3BC7EE' },
  { intervention: 'Waste', icon: WasteCustom, color: '#BC8A5F' },
  { intervention: 'Materials', icon: MaterialsCustom, color: '#BC8A5F' },
  { intervention: 'Structural Steel', icon: structure, color: '#BC8A5F' },
  { intervention: 'Interior Paints', icon: ExteriorPaints, color: '#BC8A5F' },
  { intervention: 'Concrete', icon: concrete, color: '#BC8A5F' }, //
  { intervention: 'Living & Dining', icon: livinganddining, color: '#BC8A5F' }, //
  { intervention: 'Bedroom', icon: bedroom, color: '#BC8A5F' }, //
  { intervention: 'Kitchen', icon: kitchen, color: '#BC8A5F' }, //
  { intervention: 'Bathroom', icon: bathroom, color: '#BC8A5F' }, //
  { intervention: 'Utility & Balcony', icon: balconyandutility, color: '#BC8A5F' }, //
];

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: 290,
    padding: 18,
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
  },
}));

// check out for a common component
const SelectedInterv = (props) => {
  let history = useHistory();

  const classes = useStyles();
  let { id } = useParams();
  const [filteredInterventions, setFilteredIntervention] = useState('');
  const [selectedValue, setSelectedValue] = useState(5);

  const { interventionCategoryContext, handleInterventionCategory } = useContext(InterventionCategoryContext);

  useEffect(() => {
    let array = [];
    const unfilteredData = props.newintervention;
    unfilteredData.categorized &&
      unfilteredData.categorized.filter((category) => {
        let categoryName = category && category.name;
        category.subcategories.filter((subcategory) =>
          subcategory.interventions.filter((intervention) => {
            let object = { category: categoryName, intervention: intervention };
            array.push(object);
          })
        );
      });

    if (selectedValue === 1) {
      let energyFilteredArray = array && array.filter((val) => val.category === 'Energy');
      let enabledValues = energyFilteredArray.filter((val) => val.intervention.enabled === true);
      let filteredValue =
        enabledValues &&
        enabledValues.sort((a, b) => b.intervention.quantities.resource.category.value - a.intervention.quantities.resource.category.value).slice(0, 4);
      setFilteredIntervention(filteredValue);
    }
    if (selectedValue === 2) {
      let waterFilteredArray = array && array.filter((val) => val.category === 'Water');
      let enabledValues = waterFilteredArray.filter((val) => val.intervention.enabled === true);
      let filteredValue =
        enabledValues &&
        enabledValues.sort((a, b) => b.intervention.quantities.resource.category.value - a.intervention.quantities.resource.category.value).slice(0, 4);
      setFilteredIntervention(filteredValue);
    }
    if (selectedValue === 3) {
      let wasteFilteredArray = array && array.filter((val) => val.category === 'Waste');
      let enabledValues = wasteFilteredArray.filter((val) => val.intervention.enabled === true);
      let filteredValue =
        enabledValues &&
        enabledValues.sort((a, b) => b.intervention.quantities.resource.category.value - a.intervention.quantities.resource.category.value).slice(0, 4);
      setFilteredIntervention(filteredValue);
    } else if (selectedValue === 4) {
      let enabledValues = array && array.filter((val) => val.intervention.enabled === true);

      let filteredValue =
        enabledValues &&
        enabledValues.sort((a, b) => b.intervention.quantities.finance.capex.value - a.intervention.quantities.finance.capex.value).slice(0, 4);
      setFilteredIntervention(filteredValue);
    } else if (selectedValue === 5) {
      let enabledValues = array && array.filter((val) => val.intervention.enabled === true);

      let filteredValue =
        enabledValues &&
        enabledValues.sort((a, b) => b.intervention.quantities.finance.opexSavings.value - a.intervention.quantities.finance.opexSavings.value).slice(0, 4);
      setFilteredIntervention(filteredValue);
    }
  }, [props.newintervention, selectedValue]);

  const handleChange = (e) => setSelectedValue(e.target.value);

  const iconData =
    filteredInterventions &&
    filteredInterventions.length !== 0 &&
    filteredInterventions.map((row, index) => {
      let icon;
      for (let i = 0; i < icons.length; i++) {
        if (icons[i].intervention === row.intervention.name) {
          icon = icons[i].icon;
        }
      }
      return icon;
    });

  const colorData =
    filteredInterventions &&
    filteredInterventions.length !== 0 &&
    filteredInterventions.map((row, index) => {
      let color;
      for (let i = 0; i < icons.length; i++) {
        if (icons[i].intervention === row.intervention.name) {
          color = icons[i].color;
        }
      }
      return color;
    });

  const energyCustomIcon = icons && icons.find((a) => a.intervention === 'Energy');
  const waterCustomIcon = icons && icons.find((a) => a.intervention === 'Water');
  const wasteCustomIcon = icons && icons.find((a) => a.intervention === 'Waste');

  const activateIntervention = (val) => {
    handleInterventionCategory(val);
    history.push(`/projects/${id && id}/interventions`);
  };

  return (
    <>
      <Container className={classes.gridContainer}>
        <Grid container xs={12}>
          {/* <Grid item xs={2} style={{ textAlign: 'center', paddingTop: '10px' }}>
            <Typography variant="h6" className={classes.secondaryTextcolor}>
              Sort by
            </Typography>
          </Grid> */}
          <Grid item xs={5}>
            <CustomSelectInterventions selectedvalue={selectedValue} handleChange={handleChange} />
          </Grid>
          <Grid item xs={7} style={{ textAlign: 'center', padding: '2%' }}>
            <Link style={{ fontSize: '12px', color: '#00C58A', lineHeight: '20%' }} to={`/projects/${id}/interventions`}>
              View All Interventions
            </Link>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ paddingTop: '10px', paddingLeft: '10px' }}>
          {filteredInterventions && filteredInterventions.length !== 0 ? (
            filteredInterventions.map((intervention, index) => {
              return (
                <>
                  <Grid item xs={2}>
                    {intervention && intervention.category === 'Energy' && intervention.intervention.interventionProjectMappingId.includes('custom') ? (
                      <img src={energyCustomIcon.icon} alt={index} style={{ height: '90%' }} />
                    ) : intervention && intervention.category === 'Water' && intervention.intervention.interventionProjectMappingId.includes('custom') ? (
                      <img src={waterCustomIcon.icon} alt={index} style={{ height: '90%' }} />
                    ) : intervention && intervention.category === 'Waste' && intervention.intervention.interventionProjectMappingId.includes('custom') ? (
                      <img src={wasteCustomIcon.icon} alt={index} style={{ height: '90%' }} />
                    ) : (
                      <img src={iconData[index]} alt={index} style={{ height: '80%' }} />
                    )}
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left', paddingTop: '10px' }}>
                    <Typography
                      variant="h6"
                      style={
                        intervention && intervention.category === 'Energy' && intervention.intervention.interventionProjectMappingId.includes('custom')
                          ? { color: energyCustomIcon.color }
                          : intervention && intervention.category === 'Water' && intervention.intervention.interventionProjectMappingId.includes('custom')
                          ? { color: waterCustomIcon.color }
                          : intervention && intervention.category === 'Waste' && intervention.intervention.interventionProjectMappingId.includes('custom')
                          ? { color: wasteCustomIcon.color }
                          : { color: colorData[index] }
                      }
                    >
                      {intervention.intervention.name.length > 15 ? intervention.intervention.name.substr(0, 17) + '..' : intervention.intervention.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'left', paddingTop: '10px' }}>
                    <Typography variant="h6" style={{ color: ' #666666' }}>
                      <b>
                        {selectedValue &&
                          selectedValue === 1 &&
                          `${numberWithCommas(intervention.intervention.quantities.resource.category.value)} ${
                            intervention.intervention.quantities.resource.category.unit
                          }`}
                        {selectedValue &&
                          selectedValue === 2 &&
                          `${numberWithCommas(intervention.intervention.quantities.resource.category.value)} ${
                            intervention.intervention.quantities.resource.category.unit
                          }`}
                        {selectedValue &&
                          selectedValue === 3 &&
                          `${numberWithCommas(intervention.intervention.quantities.resource.category.value)} ${
                            intervention.intervention.quantities.resource.category.unit
                          }`}
                        {selectedValue &&
                          selectedValue === 4 &&
                          `${numberWithCommas(intervention.intervention.quantities.finance.capex.value)} ${
                            intervention.intervention.quantities.finance.capex.unit
                          }`}
                        {selectedValue &&
                          selectedValue === 5 &&
                          `${numberWithCommas(intervention.intervention.quantities.finance.opexSavings.value)} ${
                            intervention.intervention.quantities.finance.opexSavings.unit
                          }`}
                      </b>
                    </Typography>
                  </Grid>
                </>
              );
            })
          ) : (
            <>
              <Grid container item xs={12} style={{ paddingTop: '8%' }} justifyContent="center" alignItems="center">
                {selectedValue && selectedValue === 1 ? (
                  <Typography variant="h5" color="textSecondary" style={{ textAlign: 'center', lineHeight: '35px' }}>
                    No energy intervention
                    <br /> is currently active
                  </Typography>
                ) : selectedValue && selectedValue === 2 ? (
                  <Typography variant="h5" color="textSecondary" style={{ textAlign: 'center', lineHeight: '35px' }}>
                    No water intervention
                    <br /> is currently active
                  </Typography>
                ) : selectedValue && selectedValue === 3 ? (
                  <Typography variant="h5" color="textSecondary" style={{ textAlign: 'center', lineHeight: '35px' }}>
                    No waste intervention
                    <br /> is currently active
                  </Typography>
                ) : selectedValue && selectedValue === 4 ? (
                  <Typography variant="h5" color="textSecondary" style={{ textAlign: 'center', lineHeight: '35px' }}>
                    No intervention
                    <br /> is currently active
                  </Typography>
                ) : (
                  <Typography variant="h5" color="textSecondary" style={{ textAlign: 'center', lineHeight: '35px' }}>
                    No intervention
                    <br /> is currently active
                  </Typography>
                )}
              </Grid>
              <Grid container item xs={12} style={{ paddingTop: '8%' }} justifyContent="center" alignItems="center">
                {selectedValue && selectedValue === 1 ? (
                  <PrimaryButton children={`Activate Energy Interventions`} onClick={() => activateIntervention('energy')} />
                ) : selectedValue && selectedValue === 2 ? (
                  <PrimaryButton children={`Activate Water Interventions`} onClick={() => activateIntervention('water')} />
                ) : selectedValue && selectedValue === 3 ? (
                  <PrimaryButton children={`Activate Waste Interventions`} onClick={() => activateIntervention('waste')} />
                ) : selectedValue && selectedValue === 4 ? (
                  <PrimaryButton children={`Activate Interventions`} onClick={() => activateIntervention('energy')} />
                ) : (
                  <PrimaryButton children={`Activate Interventions`} onClick={() => activateIntervention('energy')} />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SelectedInterv;

// const interventions = [
//   { intervention: 'Light Management', icon: lightManagementSystem, capex: '5,03,440 kwh', color: '#FFAC49' },
//   { intervention: 'Efficient Fixtures', icon: EfficientFixtures, capex: '3,25,350 kL', color: '#3BC7EE' },
//   { intervention: 'Solar PV', icon: solarPV, capex: '3,00,000 kwh', color: '#FFAC49' },
//   { intervention: 'Collection & Segre..', icon: toxicNew, capex: '23,456 kg', color: '#BC8A5F' },
// ];
