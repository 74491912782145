import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
  progressvalue: {
    marginTop: '-9px',
    marginLeft: '-8px',
    color: '#fffff',
  },
}));

export default function PrimaryBar({ value, bg, progressvalue, color, newcolor }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 6,
      // backgroundColor: lighten('#42E198', 0.8),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: `${color}`,
    },
  }))(LinearProgress);

  return (
    <div className={classes.root}>
      <div className="row">
        <div className="col-md-8">
          <BorderLinearProgress
            className={classes.margin}
            variant="determinate"
            value={value}
            // barcolor="#FBA9AA"
            style={{ backgroundColor: `${bg}` }}
          />
        </div>
        <div className="col-md-3">
          <Typography className={classes.progressvalue} style={{ color: `${newcolor}` }}>
            {`${Math.round(value && value)}`}
          </Typography>
        </div>
      </div>

      {/* <Typography></Typography> */}
    </div>
  );
}
