export const categoriesQuery = `{
  categories(pagination:{limit:${100}}) {
	data{
    id
    attributes{
      subcategories{
        data{
          id
          attributes{
            name
          }
        }
      }
      name
      type
    }
  }
  }
}`;

export const productsQuery = `{
    products(pagination:{limit:${20000}}) {
      data {
        id
        attributes {
          name
          category{
            data{
              id
              attributes{
                name
              }
            }
          }
        }
      }
    }
  }`;
