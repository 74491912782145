import * as React from 'react';

const WaterFlowDiagram = (props) => {
  const {
    TreatedGreyWater,
    FreshWater,
    CentralizedRo,
    WTP,
    CommonAreaUse,
    DomesticWaterUse,
    HealthFaucet,
    Flushing,
    Drinking,
    GreyWaterSTP,
    BlackWaterSTP,
    //
    // FreshWaterToStp,
    // FreshWatertoCentralizedRo,
    // CentralizedRoDrinking,
    // Treatedgreywatertodomesticwateruse,
    // Healthfaucettoblackwaterstp,
    // GreywaterstptoTreatedGreyWater,
    // flushingtoblackwaterstp,
    // blackwaterstptoFlushing,
    // HealthFaucettoBlackwaterStp,
    // WtptoHealthFaucet,
    // TreatedGreywatertocommonAreause,
  } = props;
  return (
    <svg width={550} height={550} viewBox="0 0 1435 1369" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0h1395c11.05 0 20 8.954 20 20v1329c0 11.05-8.95 20-20 20H20c-11.046 0-20-8.95-20-20V20Z" fill="#fff" />
      <path
        d="M.5 20C.5 9.23 9.23.5 20 .5h1395c10.77 0 19.5 8.73 19.5 19.5v1329c0 10.77-8.73 19.5-19.5 19.5H20c-10.77 0-19.5-8.73-19.5-19.5V20Z"
        stroke="#000"
        strokeOpacity={0.14}
      />
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        letterSpacing={0.714}
      >
        <tspan x={57.266} y={43.514}>
          {'All values are in daily'}
        </tspan>
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={500}
        letterSpacing={0.714}
      >
        <tspan x={273.628} y={43.514} />
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.714}
      >
        <tspan x={279.907} y={43.514}>
          {'KL'}
        </tspan>
      </text>
      <path fill="url(#a)" fillOpacity={0.38} d="M809 70h125v239H809z" />
      <path fill="url(#b)" fillOpacity={0.38} d="M809 330h125v286H809z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.473 455.906C555.291 448.96 545.99 445.5 537.5 445.5v-14c12.314 0 24.359 4.993 35.863 12.841 11.524 7.861 22.981 18.909 34.266 31.818 22.551 25.796 45.263 60.03 67.162 93.781 2.416 3.723 4.821 7.44 7.215 11.14 19.458 30.063 38.198 59.019 55.872 81.305 9.918 12.504 19.263 22.598 27.959 29.513C774.637 698.897 781.974 702 788 702v1.04l6.289 5.895a.5.5 0 0 1 0 .73L788 715.561V716l-.465-.003-.43.403v-.412a34.249 34.249 0 0 1-5.605-.632v.144c-32.779 0-71.59-13.439-110.907-27.053-2.484-.86-4.971-1.721-7.458-2.58C620.576 671.179 577.245 657 537 657v-14c42.974 0 88.509 15.071 130.703 29.633l6.406 2.214.001.001c27.901 9.649 54.039 18.688 77.532 23.354-8.144-7.342-16.411-16.625-24.733-27.118-18.125-22.854-37.263-52.428-56.638-82.369v-.001l-.008-.011-.058-.09c-2.383-3.683-4.77-7.371-7.158-11.053-21.978-33.873-44.171-67.264-65.958-92.187-10.884-12.45-21.454-22.535-31.616-29.467Z"
        fill="url(#c)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.334 686.021c.986 0 3.636.457 8.263 5.298 4.607 4.82 9.613 12.406 15.07 22.799 8.17 15.563 16.109 34.896 24.774 55.996 2.98 7.257 6.046 14.723 9.237 22.317 12.139 28.888 25.963 59.264 42.908 82.501 16.757 22.979 38.946 42.183 68.415 42.286v.082l.08-.081h.159v-.16l13.36-13.449a2 2 0 0 0 0-2.82l-13.36-13.449v-.122h-.122l-.117-.119v.118c-15.059-.101-29.444-9.76-44.175-29.962-14.666-20.112-27.342-47.536-39.49-76.447-2.902-6.905-5.785-13.928-8.658-20.926v-.002c-8.829-21.507-17.563-42.782-26.449-59.708-5.923-11.281-12.467-21.758-19.944-29.582-7.457-7.802-17.424-14.57-29.951-14.57v30Z"
        fill="url(#d)"
      />
      <path fill="#C9F5FF" d="M180 593h11v121h-11zM53 593h10v121H53z" />
      <path fill="#D2E6FE" d="M526 583h11v140h-11zM370 583h11v140h-11zM370 812h11v169h-11zM527 812h11v169h-11z" />
      <path fill="#D8DBFE" d="M798 634h11v154h-11zM934 634h11v154h-11zM798.062 1150.66h10.781v142.552h-10.781zM934.26 1150.66h10.781v142.552H934.26z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M532.125 902.328c6.637 0 13.865 3.054 22.047 10.214 8.206 7.181 16.588 17.825 25.288 31.34 13.813 21.458 27.464 48.566 42.009 77.448h.001c3.843 7.63 7.749 15.39 11.737 23.2 18.838 36.88 39.404 74.71 63.129 103.34 23.639 28.53 51.937 49.76 86.476 49.76v-20c-25.636 0-48.835-15.68-71.076-42.52-22.156-26.74-41.852-62.74-60.717-99.68-3.826-7.49-7.623-15.03-11.398-22.53-14.606-29.013-28.9-57.405-43.345-79.844-9.121-14.17-18.686-26.596-28.933-35.564-10.272-8.99-22.005-15.164-35.218-15.164v20ZM782.812 1197.7l11.243-9.33c.481-.4.481-1.14 0-1.54l-11.243-9.33v20.2Z"
        fill="url(#e)"
      />
      <path fill="#A6ABD8" d="M1094 442h11v196h-11zM1245 442h11v196h-11z" />
      <path d="M945 709c56.19 0 102.34 67 138 67" stroke="url(#f)" strokeWidth={10} />
      <path d="M945 891c61.51 0 99.68-112 138-112" stroke="url(#g)" strokeWidth={15} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1255.07 786.173c1.83 0 3.55-.008 5.19-.016 8.15-.038 13.98-.066 18.84.992 4.9 1.068 8.23 3.145 11.08 8.423 3.22 5.971 5.8 16.092 7.47 33.508 1.65 17.231 2.35 40.753 2.35 72.921 0 45.497-29.46 78.198-77.97 101.699-48.58 23.53-114.63 36.9-183.64 43.96-137.849 14.09-284.688 2.75-319.939-.31-15.702-1.37-26.951-7.89-35.069-17.17-8.262-9.44-13.55-22.07-16.412-35.786-2.854-13.681-3.201-28.004-1.931-40.348 1.295-12.582 4.171-22.186 7.057-26.916 4.259-6.982 14.17-11.42 34.348-13.427 15.931-1.584 36.181-1.515 61.085-1.43h.003c5.919.02 12.102.041 18.552.041v.086l7.552-6.754a1.001 1.001 0 0 0 0-1.491l-7.552-6.755v-.086c-6.231 0-12.268-.021-18.104-.042h-.001c-24.977-.088-46.245-.163-63.019 1.505-20.232 2.012-37.274 6.778-45.67 20.541-4.601 7.543-7.79 19.759-9.172 33.193-1.406 13.673-1.041 29.563 2.169 44.946 3.202 15.343 9.332 30.633 19.806 42.603 10.619 12.14 25.424 20.53 45.061 22.23 35.426 3.08 183.436 14.53 322.766.29 69.58-7.12 137.7-20.7 188.65-45.38 51.01-24.715 86.43-61.487 86.43-115.199 0-32.266-.69-56.377-2.41-74.352-1.71-17.788-4.49-30.446-9.21-39.198-5.09-9.445-12.28-14.037-21.09-15.958-6.62-1.444-14.5-1.393-22.6-1.34-1.54.01-3.08.02-4.62.02v15Z"
        fill="url(#h)"
      />
      <path fill="#D8DBFE" d="M798 833h11v139h-11zM934 833h11v139h-11z" />
      <path fill="url(#i)" fillOpacity={0.38} d="M63 593h117v121H63z" />
      <path
        d="M120.559 609c-.41.842-7.719 7.469-7.715 12.729.004 3.945 2.546 8.608 7.715 8.652 5.172-.044 7.714-4.707 7.714-8.652 0-5.26-7.306-11.887-7.714-12.729"
        stroke="#000"
        strokeOpacity={0.82}
        strokeWidth={2}
      />
      <g
        transform="translate(50,0)"
      >
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing="0em"
        textAnchor='middle'
      >
        <tspan x={70} y={659.427}>
          {'Fresh water'}
        </tspan>
      </text>
      <text
        fill="#000B07"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={74.067} y={689.212}>
          {FreshWater}
        </tspan>
      </text>
      </g>
      <path fill="url(#j)" fillOpacity={0.38} d="M381 812h146v169H381z" />
      <g transform='translate(50,0)'>
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={403.069} y={901.74}>
          {'Centralized\n'}
        </tspan>
        <tspan x={400.963} y={925.74}>
          {'RO'}
        </tspan>
      </text>
      <text
        fill="#000B07"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={400.463} y={957.514}>
          {CentralizedRo}
        </tspan>
      </text>
      </g>
      <path
        d="M456.509 845.969h-4.003a.5.5 0 0 0-.474.658l.864 2.594c.069.204.26.341.475.341h2.274a.5.5 0 0 0 .474-.341l.865-2.594a.5.5 0 0 0-.475-.658Z"
        stroke="#000"
      />
      <rect x={443.229} y={829} width={23.156} height={26.75} rx={3} stroke="#000" strokeOpacity={0.82} strokeWidth={2} />
      <path d="M443.426 842.375h23.359" stroke="#000" strokeOpacity={0.82} />
      <path fill="url(#k)" fillOpacity={0.38} d="M381 583h145v140H381z" />
      <g transform='translate(50,0)'>
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={400.062} y={669.094}>
          {'WTP'}
        </tspan>
      </text>
      <text
        fill="#000B07"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={400.068} y={697.868}>
          {WTP}
        </tspan>
      </text>
      </g>
      <rect x={436.062} y={600} width={7.883} height={24.354} rx={1} stroke="#000" strokeOpacity={0.82} strokeWidth={2} />
      <rect x={449.24} y={605.491} width={7.883} height={18.864} rx={1} stroke="#000" strokeOpacity={0.82} strokeWidth={2} />
      <rect x={462.416} y={608.785} width={7.883} height={15.569} rx={1} stroke="#000" strokeOpacity={0.82} strokeWidth={2} />
      <path d="M444.646 613.375h5.491M456.625 619.365h5.49" stroke="#000" strokeOpacity={0.82} />
      <path fill="url(#l)" fillOpacity={0.38} d="M381 341h145v168H381z" />
      <path fill="#D2E6FE" d="M370 341h11v168h-11zM526 341h11v168h-11z" />
      <path
        d="M454.281 374.969h-4.003a.5.5 0 0 0-.475.658l.865 2.594a.5.5 0 0 0 .474.341h2.274a.5.5 0 0 0 .475-.341l.864-2.594a.5.5 0 0 0-.474-.658Z"
        stroke="#000"
      />
      <rect x={441} y={358} width={23.156} height={26.75} rx={3} stroke="#000" strokeOpacity={0.82} strokeWidth={2} />
      <path d="M441.197 371.375h23.36" stroke="#000" strokeOpacity={0.82} />
      <g transform='translate(50,0)'>
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={405.853} y={427.49}>
          {'Treated\n'}
        </tspan>
        <tspan x={403.061} y={451.49}>
          {'Grey Water '}
        </tspan>
      </text>
      <text
        fill="#000B07"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={400.853} y={485.264}>
          {TreatedGreyWater}
        </tspan>
      </text>
      </g>
      <path d="M945 436c69.42 0 104.55 102 138 102" stroke="url(#m)" strokeWidth={20} />
      <path fill="#D8DBFE" d="M798 70h11v239h-11zM934 70h11v239h-11z" />
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={16}
        fontWeight="bold"
        letterSpacing={0.714}
      >
        <tspan x={830.424} y={241.627}>
          {'Landscape'}
        </tspan>
      </text>
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={16}
        fontWeight="bold"
        letterSpacing={0.714}
      >
        <tspan x={835.223} y={266.627}>
          {'Car Wash'}
        </tspan>
      </text>
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={16}
        fontWeight="bold"
        letterSpacing={0.714}
      >
        <tspan x={837.281} y={291.627}>
          {'Cleaning'}
        </tspan>
      </text>
      <path d="M871.327 98.088V87h-13.784a1 1 0 0 0-1 1v7.984M885.188 95.385V88a1 1 0 0 0-1-1h-12.86v11.088" stroke="#000" strokeWidth={1.2} />
      <path
        d="M853.135 103.443h1.438v4.413a.72.72 0 0 1-1.438 0v-4.413ZM886.903 103.443h1.438v4.413a.72.72 0 0 1-1.438 0v-4.413Z"
        fill="#000"
        stroke="#000"
        strokeWidth={1.2}
      />
      <path
        d="M856.544 92.544H852a1 1 0 0 0-1 1v8.626a1 1 0 0 0 1 1h37.731a1 1 0 0 0 1-1v-8.626a1 1 0 0 0-1-1h-4.544v4.544a1 1 0 0 1-1 1h-26.643a1 1 0 0 1-1-1v-4.544Z"
        stroke="#000"
        strokeWidth={1.2}
      />
      <g transform="translate(50,0)">
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={829.773} y={152.915}>
          {'Common\n'}
        </tspan>
        <tspan x={829.039} y={176.915}>
          {'Area Use'}
        </tspan>
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={822.067} y={208.689}>
          {CommonAreaUse}
        </tspan>
      </text>
      </g>
      <path fill="#D8DBFE" d="M934 330h11v286h-11zM798 330h11v286h-11z" />
      <path
        d="M862.594 357.781v9.583h17.37v-9.583M859 354.786 871.578 347l11.979 7.786"
        stroke="#2E2E2E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(50,0)">
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={824.873} y={411.104}>
          {'Domestic \n'}
        </tspan>
        <tspan x={822.002} y={435.104}>
          {'Water Use'}
        </tspan>
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={824.64} y={466.878}>
          {DomesticWaterUse}
        </tspan>
      </text>
      </g>
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={16}
        fontWeight="bold"
        letterSpacing={0.714}
      >
        <tspan x={838.293} y={503.056}>
          {'Showers'}
        </tspan>
      </text>
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={16}
        fontWeight="bold"
        letterSpacing={0.714}
      >
        <tspan x={832.752} y={528.056}>
          {'Bathroom\n'}
        </tspan>
        <tspan x={854.891} y={547.056}>
          {'Sink'}
        </tspan>
      </text>
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={16}
        fontWeight="bold"
        letterSpacing={0.714}
      >
        <tspan x={838.472} y={572.056}>
          {'Washing'}
        </tspan>
      </text>
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={16}
        fontWeight="bold"
        letterSpacing={0.714}
      >
        <tspan x={840.898} y={597.056}>
          {'Kitchen'}
        </tspan>
      </text>
      <path fill="url(#n)" fillOpacity={0.38} d="M809 833h125v139H809z" />
      <g transform='translate(50,0)'>
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={826} y={917.896}>
          {'Flushing'}
        </tspan>
      </text>
      <text
        fill="#000B07"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={821.448} y={946.67}>
          {Flushing}
        </tspan>
      </text>
      </g>
      <circle cx={870.844} cy={858.844} r={3.875} stroke="#000" />
      <path
        d="M879.688 861.578c0 6.637-4.174 11.578-8.844 11.578-4.67 0-8.844-4.941-8.844-11.578 0-6.636 4.174-11.578 8.844-11.578 4.67 0 8.844 4.942 8.844 11.578Z"
        stroke="#000"
        strokeWidth={2}
      />
      <path fill="url(#o)" fillOpacity={0.38} d="M809 634h125v154H809z" />
      <g transform='translate(50,0)'>
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={830.207} y={715.13}>
          {'Health \n'}
        </tspan>
        <tspan x={825.283} y={739.13}>
          {'Faucet'}
        </tspan>
      </text>
      <text
        fill="#000B07"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={825.807} y={766.018}>
          {HealthFaucet}
        </tspan>
      </text>
      </g>
      <path d="M875.679 651.639h9.278m-10.18 2.98h5.389" stroke="#000" strokeOpacity={0.82} />
      <mask id="p" fill="#fff">
        <path fillRule="evenodd" clipRule="evenodd" d="M859.406 649.827a2 2 0 0 0-2 2v19.563h8.386v-10.781h5.187a2 2 0 0 0 2-2v-6.782a2 2 0 0 0-2-2h-11.573Z" />
      </mask>
      <path
        d="M857.406 671.39h-2v2h2v-2Zm8.386 0v2h2v-2h-2Zm0-10.781v-2h-2v2h2Zm0-10.782h-2v2h2v-2Zm0 0h2v-2h-2v2Zm-6.386 2v-4a4 4 0 0 0-4 4h4Zm0 19.563v-19.563h-4v19.563h4Zm6.386-2h-8.386v4h8.386v-4Zm-2-8.781v10.781h4v-10.781h-4Zm7.187-2h-5.187v4h5.187v-4Zm0 0v4a4 4 0 0 0 4-4h-4Zm0-6.782v6.782h4v-6.782h-4Zm0 0h4a4 4 0 0 0-4-4v4Zm-5.187 0h5.187v-4h-5.187v4Zm-2-2h4-4Zm-4.386 2h6.386v-4h-6.386v4Z"
        fill="#000"
        fillOpacity={0.82}
        mask="url(#p)"
      />
      <path d="M868.307 651.624v6.588" stroke="#000" strokeOpacity={0.82} />
      <path fill="url(#q)" fillOpacity={0.38} d="M809 1151h125v142H809z" />
      <g translate="transform(50,0)">
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={870} y={1235.47}>
          {'Drinking'}
        </tspan>
      </text>
      <text
        fill="#000B07"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={866.234} y={1268.39}>
          {Drinking}
        </tspan>
      </text>
      </g>
      <path d="M864.602 1177.8h14.446" stroke="#000" strokeOpacity={0.82} />
      <path
        d="M862.383 1170.17a1 1 0 0 1 .985-1.17h16.863c.63 0 1.103.57.982 1.19l-3.545 18.12a2.995 2.995 0 0 1-2.944 2.42h-6.242a2.992 2.992 0 0 1-2.955-2.48l-3.144-18.08Z"
        stroke="#000"
        strokeOpacity={0.82}
        strokeWidth={2}
      />
      <path fill="url(#r)" fillOpacity={0.38} d="M1105 442h140v196h-140z" />
      <g transform='translate(50,0)'>
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={1128.13} y={554.864}>
          {'Grey Water \n'}
        </tspan>
        <tspan x={1120.97} y={578.864}>
          {'STP'}
        </tspan>
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={1120.26} y={610.638}>
          {GreyWaterSTP}
        </tspan>
      </text>
      </g>
      <path
        d="m1163.14 460.33-.74-.822-.74.822c-1.75 1.932-4.39 4.918-6.59 8.16-2.18 3.195-4.07 6.836-4.07 10.048 0 2.485.76 5.687 2.55 8.304 1.81 2.654 4.7 4.719 8.87 4.755h.02c4.15-.036 7.03-2.103 8.83-4.755 1.78-2.617 2.53-5.818 2.53-8.303 0-3.212-1.89-6.855-4.07-10.05-2.2-3.243-4.84-6.229-6.59-8.159Z"
        stroke="#000"
        strokeWidth={2}
      />
      <path d="M1155.73 477.935c0 3.206 1.78 8.001 6.68 8.062" stroke="#000" strokeWidth={2} strokeLinecap="round" />
      <path
        d="M1179.76 505.379h-3.57c-1.53 0-2.5-1.651-1.74-2.984l4.81-8.516m0 0 1 5m-1-5-5.5 1.5M1182.01 487.941l1.79-3.094c.76-1.326 2.67-1.335 3.45-.015l4.97 8.426m0 0-4.83-1.634m4.83 1.634 1.45-5.513M1194.97 499.312l1.79 3.093c.76 1.327-.19 2.986-1.72 3l-9.78.09m0 0 3.83-3.366m-3.83 3.366 4.05 4.013"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M367.198 654.147a2 2 0 0 0 0-2.294L354.001 633v40l13.197-18.853Zm-181.27 18.905h167.997v-40H185.928v40Z"
        fill="url(#s)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M728.985 444.635h-196.86v-24h248.218v31.188c-18.751 0-38.4 10.49-59.486 29.175-20.913 18.533-41.455 43.496-62.094 69.335-2.242 2.807-4.487 5.627-6.734 8.449l-.006.007v.001c-18.12 22.758-36.361 45.67-54.283 63.409-19.688 19.488-41.778 35.666-65.615 35.666v-24c13.42 0 29.39-9.578 48.732-28.723 16.838-16.666 34.126-38.372 52.431-61.354 2.225-2.795 4.466-5.608 6.723-8.433 20.504-25.671 42.247-52.219 64.929-72.319 7.699-6.822 15.719-13.097 24.045-18.401Zm64.855-7.253c.74-.773.74-1.991 0-2.764L780.343 420.5v31l13.497-14.118Z"
        fill="url(#t)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M794.333 190.046a.799.799 0 0 0 0-1.192l-8.251-7.381v-.015h-.017L786 181.4v.058c-9.234.031-18.289 5.204-26.593 11.921-8.57 6.933-17.655 16.609-27.067 27.804-12.837 15.269-26.878 34.104-41.578 53.823-6.784 9.1-13.709 18.389-20.72 27.602-22.419 29.46-45.934 58.45-69.719 80.043-24.007 21.796-46.938 34.797-68.198 34.797v16c27.461 0 54.223-16.498 78.953-38.95 24.953-22.655 49.219-52.663 71.697-82.2 7.271-9.555 14.335-19.033 21.186-28.222l.001-.002v-.001c14.529-19.491 28.094-37.689 40.625-52.593 9.188-10.929 17.501-19.689 24.883-25.662 7.567-6.121 12.968-8.326 16.53-8.359v.041l.047-.042h.035v-.031l8.251-7.381Z"
        fill="url(#u)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1262.62 165.807C1125.05 46.054 966.631 47.615 768.725 49.565l-1.406.014-.208.002c-190.432 1.876-350.896 3.457-484.572 119.821-67.135 58.44-83.531 123.596-64.269 174.794 19.194 51.018 73.127 86.252 142.552 86.252v-10c-66.125 0-115.747-33.404-133.193-79.773-17.377-46.19-3.498-107.172 61.476-163.731C419.6 63.35 576.164 61.463 767.417 59.579c199.279-1.964 354.063-3.38 488.643 113.771 66.49 57.878 85.47 147.68 77.36 223.508-4.05 37.872-14.83 71.852-29.46 96.206-14.76 24.576-32.67 38.191-50.69 38.191v10c23.31 0 43.86-17.396 59.26-43.041 15.55-25.867 26.67-61.32 30.83-100.293 8.33-77.861-10.99-171.398-80.74-232.114ZM360.822 430.458l5.07-4.232a1 1 0 0 0 0-1.536l-5.07-4.232v10Z"
        fill="url(#v)"
      />
      <path
        d="M1090.88 537.198c.65.749.65 1.855 0 2.604L1083 549v-21l7.88 9.198ZM1090.22 777.77c.42.396.42 1.064 0 1.46L1083 786v-15l7.22 6.77Z"
        fill="#A7ACD9"
      />
      <path d="M1090.27 778.084c.4.392.4 1.04 0 1.432L1083 786.6V771l7.27 7.084Z" fill="#A7ACD9" />
      <path fill="#fff" d="M969.365 639.875h98.229v39.531h-98.229z" />
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={14}
        fontWeight={500}
        letterSpacing={0.571}
      >
        {/* <tspan x={984.663} y={662.178}>
          {'RO Reject'}
        </tspan> */}
      </text>
      <path fill="#A6ABD8" d="M1094 681h11v194h-11zM1245 681h11v194h-11z" />
      <path fill="url(#w)" fillOpacity={0.38} d="M1105 681h140v196h-140z" />
      <g transform='translate(50,0)'>
      <text
        fill="#000"
        fillOpacity={0.82}
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={20}
        letterSpacing={0.714}
        textAnchor='middle'
      >
        <tspan x={1127.16} y={793.864}>
          {'Black Water\n'}
        </tspan>
        <tspan x={1126.47} y={817.864}>
          {'STP'}
        </tspan>
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor='middle'
      >
        <tspan x={1125.23} y={849.638}>
          {BlackWaterSTP}
        </tspan>
      </text>
      </g>
      <path
        d="m1163.14 699.33-.74-.822-.74.822c-1.75 1.932-4.39 4.918-6.59 8.16-2.18 3.195-4.07 6.836-4.07 10.048 0 2.485.76 5.687 2.55 8.304 1.81 2.654 4.7 4.719 8.87 4.755h.02c4.15-.036 7.03-2.103 8.83-4.755 1.78-2.617 2.53-5.818 2.53-8.303 0-3.212-1.89-6.855-4.07-10.05-2.2-3.243-4.84-6.229-6.59-8.159Z"
        stroke="#000"
        strokeWidth={2}
      />
      <path d="M1155.73 716.935c0 3.206 1.78 8.001 6.68 8.062" stroke="#000" strokeWidth={2} strokeLinecap="round" />
      <path
        d="M1179.76 744.379h-3.57c-1.53 0-2.5-1.651-1.74-2.984l4.81-8.516m0 0 1 5m-1-5-5.5 1.5M1182.01 726.941l1.79-3.094c.76-1.326 2.67-1.335 3.45-.015l4.97 8.426m0 0-4.83-1.634m4.83 1.634 1.45-5.513M1194.97 738.312l1.79 3.093c.76 1.327-.19 2.986-1.72 3l-9.78.09m0 0 3.83-3.366m-3.83 3.366 4.05 4.013"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="a" x1={871.5} y1={70} x2={871.5} y2={309} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#D8DBFE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="b" x1={871.5} y1={330} x2={871.5} y2={616} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#D8DBFE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="c" x1={541.552} y1={557.921} x2={774.045} y2={644.846} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#D8DBFE" />
        </linearGradient>
        <linearGradient id="d" x1={182.334} y1={656.021} x2={365.337} y2={902.124} gradientUnits="userSpaceOnUse">
          <stop stopColor="#C9F5FF" />
          <stop offset={1} stopColor="#D2E6FE" />
        </linearGradient>
        <linearGradient id="e" x1={532.125} y1={895.1} x2={785.874} y2={1194.37} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#D8DBFE" />
        </linearGradient>
        <linearGradient id="f" x1={945} y1={709} x2={1088.92} y2={749.838} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#A6ABD8" />
        </linearGradient>
        <linearGradient id="g" x1={944.728} y1={891.602} x2={1097.96} y2={865.693} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#A6ABD8" />
        </linearGradient>
        <linearGradient id="h" x1={1083.14} y1={771.133} x2={766.543} y2={864.663} gradientUnits="userSpaceOnUse">
          <stop stopColor="#A6ABD8" />
          <stop offset={1} stopColor="#D8DBFE" />
        </linearGradient>
        <linearGradient id="i" x1={121.5} y1={593} x2={121.5} y2={714} gradientUnits="userSpaceOnUse">
          <stop stopColor="#C9F5FF" />
          <stop offset={1} stopColor="#C9F5FF" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="j" x1={454} y1={812} x2={454} y2={981} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#D2E6FE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="k" x1={453.5} y1={583} x2={453.5} y2={723} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#D2E6FE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="l" x1={453.5} y1={341} x2={453.5} y2={509} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#D2E6FE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="m" x1={945} y1={436} x2={1096.95} y2={461.24} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#A6ABD8" />
        </linearGradient>
        <linearGradient id="n" x1={871.5} y1={833} x2={871.5} y2={972} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#D8DBFE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="o" x1={871.5} y1={634} x2={871.5} y2={788} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#D8DBFE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="q" x1={871.5} y1={1151} x2={871.5} y2={1293} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8DBFE" />
          <stop offset={1} stopColor="#D8DBFE" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="r" x1={1175} y1={442} x2={1175} y2={638} gradientUnits="userSpaceOnUse">
          <stop stopColor="#A6ABD8" />
          <stop offset={1} stopColor="#A6ABD8" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="s" x1={185.928} y1={653.052} x2={368.576} y2={653.052} gradientUnits="userSpaceOnUse">
          <stop stopColor="#C9F5FF" />
          <stop offset={1} stopColor="#D2E6FE" />
        </linearGradient>
        <linearGradient id="t" x1={532.125} y1={512.56} x2={794.163} y2={435.794} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#D8DBFE" />
        </linearGradient>
        <linearGradient id="u" x1={532.125} y1={422.733} x2={792.162} y2={168.231} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#D8DBFE" />
        </linearGradient>
        <linearGradient id="v" x1={358.561} y1={421.347} x2={1253.57} y2={541.192} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2E6FE" />
          <stop offset={1} stopColor="#A6ABD8" />
        </linearGradient>
        <linearGradient id="w" x1={1175} y1={681} x2={1175} y2={877} gradientUnits="userSpaceOnUse">
          <stop stopColor="#A6ABD8" />
          <stop offset={1} stopColor="#A6ABD8" stopOpacity={0.38} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WaterFlowDiagram;
