import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Silver from '../../../../../assets/insights/carousal/silver.svg';
import GoldIGBC from '../../../../../assets/bigSVGs/GoldIGBC.png';
import Platinum from '../../../../../assets/insights/carousal/platinum.svg';
import IGBCFaded from '../../../../../assets/insights/carousal/IGBC-Faded.svg';
import IGBCModal from './IGBCModal';
import numberWithCommas from '../../../../../utils/numberToComma';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
  text1: {
    textAlign: 'center',
    color: '#3BC7EE',
    paddingTop: '10px',
  },
  text2: {
    color: '#666666',
    lineHeight: '130%',
    letterSpacing: '0.5px',
    textAlign: 'center',
    paddingTop: '12px',
  },
  text3: {
    color: '#3BC7EE',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.714286px',
    textAlign: 'center',
    paddingTop: '10px',
  },
  text4: {
    color: '#42E198',
    lineHeight: '120%',
    letterSpacing: '0.714286px',
    textAlign: 'center',
    paddingTop: '10px',
  },
  text5: {
    color: '#666666',
    lineHeight: '120%',
    letterSpacing: '0.714286px',
    textAlign: 'center',
    paddingTop: '5px',
  },
  text6: {
    color: '#F28D14',
    lineHeight: '120%',
    letterSpacing: '0.5px',
    textAlign: 'center',
  },
  text7: {
    color: '#666666',
    lineHeight: '130%',
    letterSpacing: '0.714286px',
    textAlign: 'center',
  },
  text8: {
    color: '#00C58A',
    lineHeight: '120.5%',
    letterSpacing: '0.5px',
    textAlign: 'center',
    textDecorationLine: 'underline',
    paddingTop: '10px',
  },
  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: '3%',
  },
}));

const IGBC = (props) => {
  const classes = useStyles();
  const igbc = props.igbc.value[0];
  const data = props.igbc;
  const logo =
    props.igbc.value.length === 0
      ? IGBCFaded
      : igbc && props.igbc.value.length !== 0 && igbc.igbcRating === 'Platinum'
      ? Platinum
      : igbc.igbcRating === 'Gold'
      ? GoldIGBC
      : igbc.igbcRating === 'Silver'
      ? Silver
      : IGBCFaded;
  return (
    <>
      <Grid container item xs={12} style={{ paddingTop: 1, minHeight: '18vh' }}>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
          style={{
            borderRight: '1px solid #E6E6E6',
            textAlign: 'center',
            paddingRight: '2%',
            marginLeft: '-1%',
          }}
        >
          <Typography variant="h4" className={classes.text1}>
            {props.igbc.value.length}
          </Typography>
          <Typography variant="h6" className={classes.text2}>
            IGBC projects within 5 km
          </Typography>
          <IGBCModal igbc={data} purpose="IGBC" />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} alignItems="center" style={{ borderRight: '1px solid #E6E6E6', textAlign: 'center' }}>
          <img src={logo} alt="..." height="50px" className={classes.image} />
          <Typography
            className={classes.text4}
            style={{
              paddingTop: '10px',
              color: `${
                props.igbc.value.length === 0
                  ? '#42E198'
                  : igbc && igbc.igbcRating === 'Platinum'
                  ? '#ADB5BD'
                  : igbc.igbcRating === 'Gold'
                  ? '#E4C761'
                  : igbc.igbcRating === 'Silver'
                  ? '#8D949B'
                  : '#42E198'
              }`,
            }}
          >
            {props.igbc.value.length === 0 ? '0' : igbc && `1 ${igbc && igbc.igbcRating}`}
          </Typography>
          <Typography className={classes.text5}>Certified project</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} style={{ padding: 5, textAlign: 'center', borderRight: '1px solid #E6E6E6' }}>
          <Typography variant="h4" className={classes.text6}>
            {props.igbc.value.length === 0 ? 'N/A' : igbc.avgPricePerSqftForProject === null ? 'N/A' : `₹${numberWithCommas(igbc.avgPricePerSqftForProject)}`}
          </Typography>
          <Typography variant="h6" className={classes.text7}>
            Average price per sqft for similar projects
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} style={{ padding: 13, textAlign: 'center' }}>
          <IGBCModal igbc={data} purpose="img" />
          {/* <Typography
            variant="h6"
            className={classes.text8}
            style={{ cursor: 'pointer' }}
            onClick={() => swal('Thank you for your interest', 'We are working hard to bring this functionality to you, the earliest.', 'success')}
          >

            Explore IGBC certification for your project
          </Typography> */}
        </Grid>
      </Grid>
    </>
  );
};

export default IGBC;
