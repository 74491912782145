import React, { useState, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from './Fields';

export function sqmToSqft(val) {
  return val * 10.764;
}

export function sqftToSqm(val) {
  let result = (val / 10.764).toFixed(2);
  return result;
}

function UnitAwareTextFieldForPlumbing(props) {
  const { unit, field, projectPIP, appendChanged } = props;

  const getApplicableValue = (v) => {
    if (field.unit !== 'Sqm') return v;
  };

  const getFormattedValue = (v) => {
    if (field.unit !== 'Sqm') return Number(v).toLocaleString('en-in');
  };

  let val = field && projectPIP && projectPIP.find((d) => d.PIPScheme.name === field.name) && projectPIP.find((d) => d.PIPScheme.name === field.name).value;

  const valRef = useRef(val && getApplicableValue(val));
  const [actualValue, setActualValue] = useState(val && getFormattedValue(getApplicableValue(val)));

  const handleChange = (e) => {
    const input = e.target.value;
    let final = null;
    if (input === '') final = input;
    const matched = input.match('^[0-9][0-9]*\\.?[0-9]{0,2}$');
    if (matched) final = input;
    if (final === null) return;
    valRef.current = final;
    setActualValue(valRef.current);
  };

  const handleBlur = (e) => {
    const formatted = Number(e.target.value).toLocaleString('en-in');
    setActualValue(formatted);
  };

  const handleFocus = (e) => {
    setActualValue(valRef.current);
  };

  const handleClick = (e) => {
    e.target.select();
  };
  return (
    <>
      <Grid container direction="row" alignItems="center" item xs={12}>
        <Grid item xs={5}>
          <Fields
            placeholder={field.name}
            value={actualValue && actualValue}
            onChange={(e) => {
              e.preventDefault();
              handleChange(e);
              appendChanged(field._id, e.target.value);
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onClick={handleClick}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">&nbsp;{field.unit === ('NA' || 'na') ? <></> : field.unit !== ('Sqm' || 'Sqft') ? field.unit : unit && unit}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default UnitAwareTextFieldForPlumbing;
