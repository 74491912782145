import React, { useContext, useState, useEffect, createContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CustomBreadCrumbs from '../../../common/breadcrumbs/CustomBreadCrumbs';
import Header from './common/Header';
import cleanwaterlogo from '../../../../assets/version 2/Intervention details images/cleanwaterlogo.svg';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import EditInterventionDetails from './components/editInterventionDetails';
import Overview1 from './components/overview1';
import Overview1New from './components/overview1New';
import Overview2 from './components/overview2';
import Overview3 from './components/overview3';
import VendorsCrad from '../../../common/card/vendors/VendorsCard';
import profilevector from '../../../../assets/profilevector.svg';
import { useParams } from 'react-router';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import useApiRequest from '../../../hooks/useApiRequest';
import axios from '../../../../config/Axios';
import rooftopRunoff from '../../../../assets/rooftopRunoff.svg';
import surfaceRunoff from '../../../../assets/surfaceRunoff.svg';
import solarwaterheater from '../../../../assets/solarwaterheater.svg';
import solarPV from '../../../../assets/solarPV.svg';
import smartWaterMeter from '../../../../assets/smartwatermeter.svg';
import medicalWaste from '../../../../assets/medical.png';
import eWastehandling from '../../../../assets/eWastehandling.svg';
import EfficientFixtures from '../../../../assets/efficientFixtures.svg';
import dualflush from '../../../../assets/dualflush.svg';
import CentralisedRO from '../../../../assets/CentralisedRO.svg';
import blackwaterReuse from '../../../../assets/blackwaterReuse.svg';
import biogasPlant from '../../../../assets/icons/waste/biogas.svg';
import greywaterReuse from '../../../../assets/greywater.svg';
import incinaretor from '../../../../assets/icons/waste/snitary.svg';
import lightManagementSystem from '../../../../assets/lightManagementSystem.svg';
import composter from '../../../../assets/composter.svg';
import toxicNew from '../../../../assets/icons/waste/segregation.svg';
import ExteriorPaints from '../../../../assets/interventions/materials/exterior-paints.svg';
import concrete from '../../../../assets/interventions/materials/concrete.svg';
import structure from '../../../../assets/interventions/materials/structure.svg';
import balconyandutility from '../../../../assets/interventions/materials/balconyandutility.svg';
import livinganddining from '../../../../assets/interventions/materials/livinganddining.svg';
import bedroom from '../../../../assets/interventions/materials/bedroom.svg';
import bathroom from '../../../../assets/interventions/materials/bathroom.svg';
import kitchen from '../../../../assets/interventions/materials/kitchen.svg';

import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../../../routing/IndexRouting';
import { InterventionsContext } from '../../../../projectRouting/Index';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { wlakthroughContext } from '../../../../projectRouting/Index';
import WasteMandateImage from '../../../../assets/interventions/WasteMandateImage.svg';
import mandateGeneralsIcon from '../../../../assets/mandateGeneralsIcon.svg';
import { PreviousPageContext } from '../../../../routing/IndexRouting';

//  intervention details context
export const InterventionDetailsContext = createContext();

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '3%',
    padding: theme.spacing(3),
  },
  root: {
    paddingTop: '3%',
  },
  overview: {
    flexGrow: '1',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  rightOverview: {
    marginTop: '1%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: 900,
    minWidth: 700,
    minHeight: 400,
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    borderRadius: '10px',
  },
  paperModal: {
    position: 'absolute',
    outline: 'none',
    height: 'auto',
    width: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vendorGrid: {
    marginTop: '3%',
  },
  close: {
    marginLeft: '95%',
  },
  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
  tourtexthead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '10px',
  },
  tourtext: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'justify',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const icons = [
  { intervention: 'Biogas To Electricity Generation', icon: lightManagementSystem },
  { intervention: 'Solar Water Heater', icon: solarwaterheater },
  { intervention: 'Solar PV', icon: solarPV },
  { intervention: 'Centralized RO', icon: CentralisedRO },
  { intervention: 'Efficient Fixtures', icon: EfficientFixtures },
  { intervention: 'Smart Water Meters', icon: smartWaterMeter },
  { intervention: 'Dual Flush', icon: dualflush },
  { intervention: 'Rooftop RWH', icon: rooftopRunoff },
  { intervention: 'Surface RWH', icon: surfaceRunoff },
  { intervention: 'Black Water Re-use', icon: blackwaterReuse },
  { intervention: 'Wet Waste Management', icon: biogasPlant },
  { intervention: 'E-Waste Handling', icon: eWastehandling },
  { intervention: 'Medical Waste Handling', icon: medicalWaste }, ////
  { intervention: 'Grey Water Re-use', icon: greywaterReuse },
  { intervention: 'Sanitary Napkin Incinerator', icon: incinaretor },
  { intervention: 'Collection & Segregation Point', icon: toxicNew }, //////
  { intervention: 'Recycling Dry Waste', icon: composter },
  { intervention: 'Exterior Paints', icon: ExteriorPaints },
  { intervention: 'Interior Paints', icon: ExteriorPaints },
  { intervention: 'Structural Steel', icon: structure },
  { intervention: 'Concrete', icon: concrete },
  { intervention: 'Living & Dining', icon: livinganddining },
  { intervention: 'Bedroom', icon: bedroom },
  { intervention: 'Kitchen', icon: kitchen },
  { intervention: 'Bathroom', icon: bathroom },
  { intervention: 'Utility & Balcony', icon: balconyandutility },
];

let iconData;
function InterventionDetails(props) {
  const { userDetails } = useContext(UserContext);
  const [modalStyle] = React.useState(getModalStyle);
  const [myArray, setMyArray] = useState(null);
  const [newArray, setNewArray] = useState(null);
  const [result, setResult] = useState(null);
  const [allVendors, setAllVendors] = useState(null);
  const { interventions } = useContext(InterventionsContext);
  const { setPreviousPage, setProjectID, setIntervnetionMappingID } = useContext(PreviousPageContext);

  const classes = useStyles();
  let { id } = useParams();
  let iidd = props.match.params.iid;

  const [interventionSchemeDetails, setInterventionSchemeDetails] = useState('');
  const [interventiondetails, setInterventiondetails] = useState('');
  const [intervMapping, setIntervMapping] = useState([]);
  const [vendors, setvendors] = useState('');
  const [modal, setModal] = useState(false);

  const handleModalClose = () => setOpen(false);
  const [allAssets, setAllAssets] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleEditParameters = () => setModal(true);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [notSelectedVendor, setNotSelectedVendor] = useState('');
  const [refeshVendor, setRefreshVendor] = useState(false);
  const [{ status, response }, makeRequest] = useApiRequest(`/projectv2/${id}/interventions/${iidd}`, {
    verb: 'get',
  });

  if (status === null) makeRequest();
  const handleClose = () => {
    setModal(false);
    makeRequest();
  };
  const handleRefresh = () => makeRequest();
  const handleMyArray = (data) => setMyArray(data);
  const handleResult = (data) => setResult(data);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (id && iidd) {
      setPreviousPage('interventionDetails');
      setProjectID(id);
      setIntervnetionMappingID(iidd);
    }
  }, [id, iidd]);

  useEffect(() => {
    if (response) {
      setInterventiondetails(response.data.data.data);
      axios
        .get(`/ProjectInterventionMapping/${iidd}`)
        .then((response) => {
          setOpenBackdrop(false);

          if (response && response.data && response.data.data) {
            let data = response.data.data.data.find((d) => d && d._id === iidd).hasOwnProperty('selectedVendor');
            if (data === true) {
              let resp = response.data.data.data.find((d) => d && d._id === iidd);
              setIntervMapping(resp);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }, [response, iidd]);

  useEffect(() => {
    if (interventiondetails) {
      const name = interventiondetails.name;
      axios
        .get(`/interventionschemev2?name=${name}`)
        .then((response) => {
          if (response && response.data && response.data.data) setInterventionSchemeDetails(response.data.data.data);
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }, [interventiondetails]);

  useEffect(() => {
    if (interventionSchemeDetails) {
      const id = interventionSchemeDetails._id;
      axios
        .get(`/interventionVendorMapping?interventionScheme=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) setvendors(response.data.data.data);
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }, [interventionSchemeDetails]);

  console.log(interventiondetails, "interventiondetails")

  const capexTotalValue = interventiondetails && interventiondetails?.quantities?.finance?.totalCapex
  const capexTotalConvertedValue = interventiondetails && interventiondetails?.quantities?.finance?.totalCapexConverted

  const inputs = interventiondetails && interventiondetails.quantities && interventiondetails.quantities.details ? interventiondetails.quantities.details : '';
  const customizable = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('customizable')) : '';
  const customizableexcluderrwhtankreco =
    inputs && inputs ? inputs.filter((item) => item && item.tags.includes('customizable') && !item.tags.includes('tank-size-options')) : '';

  const mandates = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('Mandate')) : '';
  const mandateSummary = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('Summary')) : '';
  const mandateGenerals = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('Generals')) : '';

  const result1 = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('result-financial')) : '';
  const result2 = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('result-resource')) : '';

  const sdgNumbers = inputs && inputs ? inputs.find((item) => item && item.label === 'SDG Number') : '';
  const sdgText = inputs && inputs ? inputs.find((item) => item && item.label === 'SDG Text') : '';

  const capexConvertedTag = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('CapexConverted')) : '';
  const capexConverted = inputs && inputs && capexConvertedTag ? capexConvertedTag.filter((item) => item && item.tags.includes('Intervention')) : '';
  const projectinputs = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('project-input')) : '';
  const boundaryMin = inputs && inputs ? inputs.find((item) => item && item.tags.includes('BoundaryMin')) : '';
  const boundaryMax = inputs && inputs ? inputs.find((item) => item && item.tags.includes('BoundaryMax')) : '';

  useEffect(() => {
    let inputData =
      inputs &&
      inputs
        .filter((c) => c && c.editable === true)
        .map((c) => {
          return { id: c && c.id, value: c && c.value };
        });
    setNewArray(inputData);
  }, [inputs]);

  useEffect(() => {
    axios
      .get(`/vendorsv2`)
      .then((res) => {
        setAllVendors(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/sign-s3`)
      .then((res) => {
        if (res) {
          setAllAssets(res.data.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (result && result.length !== 0) {
      setInterventiondetails(result);
    }
  }, [result]);

  useEffect(() => {
    if (intervMapping.length !== 0) {
      setRefreshVendor(false);
      let selectedVendor = vendors && vendors.filter((d) => d && d._id === intervMapping.selectedVendor);
      let notSelectedVendor = vendors && vendors.filter((d) => d && d._id !== intervMapping.selectedVendor);
      setSelectedVendor(selectedVendor);
      setNotSelectedVendor(notSelectedVendor);
    } else {
      setRefreshVendor(false);
      setNotSelectedVendor(vendors);
    }
  }, [intervMapping, vendors]);

  const handleSelectVendor = (id) => {
    const body = {
      selectedVendor: id,
    };
    axios
      .put(`/ProjectInterventionMapping/${iidd}`, body)
      .then((response) => {
        setOpenBackdrop(true);
        setRefreshVendor(true);
        handleRefresh();
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };
  const handleVendorIcon = (vendors, id) => {
    let data;
    let result = vendors && vendors.find((e) => e && e.logo && e.logo._id === id);
    data = result && result.logo && result.logo.URL;
    return data;
  };

  const handleProfileIcon = (allAssets, id) => {
    let data;
    let result = allAssets && allAssets.find((e) => e && e._id === id);
    data = result && result.URL;
    return data;
  };

  const handleRequestQuote = (id) => handleOpen();

  if (interventiondetails) {
    iconData = icons && icons.find((icon) => icon && icon.intervention === interventiondetails.name);
  }
  const body1 = (
    <div style={modalStyle} className={classes.paperModal}>
      <CloseIcon className={classes.close} onClick={handleModalClose} />
      <br />
      <Typography variant="h5">Our Team is Working with our Vendor partners to bring this feature to you at the earliest.</Typography>
    </div>
  );

  // sandbox impmementation ____________________________________________________________

  const [allSandboxes, setAllSandboxes] = useState('');
  const [activeSandbox, setActiveSandbox] = useState('');
  const [activeSandboxId, setActiveSandboxId] = useState('');
  const [re, sendRequest] = useApiRequest(`/projectv2/${id}/interventions/${iidd}/sandboxes`, {
    verb: 'get',
  });
  const [mo, doRequest] = useApiRequest(`/projectv2/${id}/interventions/${iidd}/sandboxes?active=true`, {
    verb: 'get',
  });
  const [openSaveChanges, setOpenSaveChanges] = React.useState(false);
  const handleClickOpenSaveChanges = () => setOpenSaveChanges(true);
  const handleCloseSaveChanges = () => setOpenSaveChanges(false);

  // refresh after update sandbox dirty value
  const handleRefresh2 = () => {
    makeRequest();
    sendRequest();
    doRequest();
  };

  useEffect(() => {
    if (re.status === null && mo.status === null) {
      sendRequest();
      doRequest();
    }
  }, [re, mo]);

  useEffect(() => {
    if (re.response && mo.response) {
      setAllSandboxes(re.response.data.data.data);
      setActiveSandbox(mo.response.data.data.data);
      setActiveSandboxId(
        mo &&
        mo.response &&
        mo.response.data &&
        mo.response.data.data &&
        mo.response.data.data.data &&
        mo.response.data.data.data[0] &&
        mo.response.data.data.data[0]._id
      );
    }
  }, [re, mo, interventiondetails]);

  // update current sandbox
  const handleUpdateCurrentSandbox = () => {
    if (activeSandboxId && activeSandbox) {
      const body = {
        type: 'persistent',
        values: activeSandbox && activeSandbox[0].dirty,
      };
      axios
        .put(`/projectv2/${id}/interventions/${iidd}/sandboxes/${activeSandboxId}`, body)
        .then((res) => {
          if (res) {
            setOpenSaveChanges(false);
            handleRefresh2();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //create new sandbox
  const createnewSandbox = () => {
    if (activeSandboxId && activeSandbox) {
      const body = {
        dirty: [],
        message: 'message',
        persistent: activeSandbox && activeSandbox[0].dirty,
        createdBy: userDetails && userDetails._id,
      };
      axios
        .post(`/projectv2/${id}/interventions/${iidd}/sandboxes`, body)
        .then((res) => {
          if (res) {
            handleRefresh2();
            setOpenSaveChanges(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // revert changes
  const revertChanges = (value) => {
    if (value) {
      const body = {
        sandboxId: value && value,
      };
      axios
        .put(`/projectv2/${id}/interventions/${iidd}/sandboxes`, body)
        .then((res) => {
          if (res) {
            handleRefresh2();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  let material = ['#6300A5', '#BF55FF', '#DA9AFF'];
  let waste = ['#F3DDC8', '#E29D69', '#BC8A5F', '#DF530D', '#C24100', '#69461A'];
  let water = ['#CAF0F8', '#90E0EF', '#00B4D8', '#0077B6', '#03045E'];
  let energy = ['#FFC971CC', '#F9C74F', '#F9844A', '#F94144', '#C32F27', '#6D0101'];
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleBackdropClose = () => setOpenBackdrop(false);
  const handleBackdropOpen = () => setOpenBackdrop(true);

  /*Implementation of tour*/
  const { uiWalkthroughContext, handleMyWalkthrough } = useContext(wlakthroughContext);
  /*Get status of how it works*/
  const [userprofile, setUserprofile] = useState('');
  useEffect(() => {
    clearAllBodyScrollLocks();
    axios.get(`/userprofiles`).then((resp) => {
      if (resp.data && resp.data.data && resp.data.data) {
        setUserprofile(resp.data.data.data);
        handleMyWalkthrough(resp.data.data.data.showhowitworks);
      }
    });
  }, []);

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const handleClick = () => {
    props.history.push(`/projects/${id}/insights`);
  };

  const [isTourOpen, setIsTourOpen] = useState(false);
  const closeTour = () => {
    if (userprofile.showhowitworks === true) {
      axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => { });
    }
    setIsTourOpen(false);
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  useEffect(() => {
    if (uiWalkthroughContext) setIsTourOpen(uiWalkthroughContext);
  }, [uiWalkthroughContext]);

  let tourProps = {
    isOpen: isTourOpen,
    onRequestClose: closeTour,
    steps: [
      {
        selector: '[data-tour="parameter"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Intervention Parameters</Typography>
            <Typography className={classes.tourtext}>Edit these parameters to customise solutions as per user requirements</Typography>
          </Grid>
        ),
        position: 'right',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '150px',
        },
      },
      {
        selector: '[data-tour="datacost"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Data & Cost Assumptions</Typography>
            <Typography className={classes.tourtext}>View all data & cost assumptions that were used to calculate the solution values. </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Some assumptions can be edited while some are considered universal values and cannot be edited
            </Typography>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '240px',
        },
      },

      {
        selector: '[data-tour="vendor"',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Selected Vendor</Typography>
            <Typography className={classes.tourtext}>
              Select a vendor from the list of curated vendors to change the corresponding values of the solutions as per the vendor costing & area requirement.
            </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Click on the Vendor to see more details about the vendor.
            </Typography>
            <Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleClick}>
              insights
            </Button>
          </Grid>
        ),
        // position: [200, 350],
        position: 'top',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '250px',
        },
      },
    ],
  };

  /*End for all walkthrough*/
  const handleEnd = () => {
    axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => { });
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };
  return (
    <Grid className={classes.main}>
      <>
        <InterventionDetailsContext.Provider value={{ myArrayContext: newArray, handleMyArray, myArray: myArray, handleResult }}>
          {openBackdrop && openBackdrop ? (
            <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleBackdropClose} style={{ zIndex: 3, color: '#66E3BE' }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <>
              <Grid container alignItems="center" className={classes.root}>
                <CustomBreadCrumbs
                  links={[{ href: `/projects/${props.match.params.id}/interventions`, text: 'Interventions' }]}
                  stage={
                    interventiondetails && interventiondetails ? (
                      <b>{interventiondetails.name}</b>
                    ) : (
                      <CasaSkeleton variant="rect" height="2vh" width={80} animation="wave" style={{ marginTop: '1%' }} />
                    )
                  }
                />
                <Header
                  selectedIntervention={interventiondetails ? interventiondetails.name : null}
                  logo={cleanwaterlogo}
                  icon={iconData ? iconData.icon : null}
                  inputs={inputs}
                  interventionProjectMappingId={iidd}
                  projectId={id}
                  allSandboxes={allSandboxes && allSandboxes}
                  handleClickOpenSaveChanges={handleClickOpenSaveChanges}
                  activeSandbox={activeSandbox}
                  activeSandboxId={activeSandboxId && activeSandboxId}
                  handleRevert={(data) => revertChanges(data)}
                  handleRefresh={() => handleRefresh2()}
                  handleCancel={handleRefresh}
                  handleBackdropOpen={() => handleBackdropOpen()}
                  /*for new impl*/
                  onClose={() => handleClose()}
                  customizable={customizable}
                  refresh={handleRefresh}
                  boundaryMin={boundaryMin && boundaryMin}
                  boundaryMax={boundaryMax && boundaryMax}
                  mandates={mandates}
                  mandateGenerals={mandateGenerals && mandateGenerals}
                  mandateicon={WasteMandateImage}
                  mandateGeneralsIcon={mandateGeneralsIcon}
                  projectinputs={projectinputs}
                />
              </Grid>

              {/*_________________________________intervention page main data ________________________________________ */}
              {/* <Grid> */}
              <Overview1
                title={'Intervention Parameters'}
                handleEditParameters={handleEditParameters}
                inputs={customizableexcluderrwhtankreco}
                activeSandboxId={activeSandboxId}
                selectedIntervention={interventiondetails ? interventiondetails.name : null}
                mandateSummary={mandateSummary}
              />
              {/* </Grid> */}
              {/* <Grid> */}
              <Overview1New
                capexTotalValue={capexTotalValue}
                capexTotalConvertedValue={capexTotalConvertedValue}
                title1={'Intervention Results'}
                title2={'Show CAPEX(total)'}
                capexConverted={capexConverted}
                result1={result1}
                result2={result2}
                selectedIntervention={interventiondetails ? interventiondetails.name : null}
                sdgNumbers={sdgNumbers && sdgNumbers}
                sdgText={sdgText && sdgText}
              />
              {/* </Grid> */}
              <Overview2
                title={'Overview'}
                inputs={inputs}
                circlecolor={
                  interventiondetails && (interventiondetails.name === 'Solar PV' || interventiondetails.name === 'Solar Water Heater')
                    ? '#F28D14'
                    : interventiondetails.name === 'Wet Waste Management' ||
                      interventiondetails.name === 'Sanitary Napkin Incinerator' ||
                      interventiondetails.name === 'Collection & Segregation Point'
                      ? '#BC8A5F'
                      : interventiondetails.name === 'Exterior Paints'
                        ? '#BF55FF'
                        : '#91E6FA'
                }
                textcolor={
                  interventiondetails && (interventiondetails.name === 'Solar PV' || interventiondetails.name === 'Solar Water Heater')
                    ? '#F28D14'
                    : interventiondetails.name === 'Wet Waste Management' ||
                      interventiondetails.name === 'Sanitary Napkin Incinerator' ||
                      interventiondetails.name === 'Collection & Segregation Point'
                      ? '#BC8A5F'
                      : interventiondetails.name === 'Exterior Paints'
                        ? '#BF55FF'
                        : '#91E6FA'
                }
                color={
                  interventiondetails && (interventiondetails.name === 'Solar PV' || interventiondetails.name === 'Solar Water Heater')
                    ? energy
                    : interventiondetails.name === 'Wet Waste Management' ||
                      interventiondetails.name === 'Sanitary Napkin Incinerator' ||
                      interventiondetails.name === 'Collection & Segregation Point'
                      ? waste
                      : interventiondetails.name === 'Exterior Paints'
                        ? material
                        : water
                }
              />
              <Overview3
                title1={'Secondary Impact'}
                title2={'Contribution to Goals'}
                inputs={inputs}
                goalcontributioncolor={
                  interventiondetails && (interventiondetails.name === 'Solar PV' || interventiondetails.name === 'Solar Water Heater')
                    ? '#F28D14'
                    : interventiondetails.name === 'Wet Waste Management' ||
                      interventiondetails.name === 'Sanitary Napkin Incinerator' ||
                      interventiondetails.name === 'Collection & Segregation Point '
                      ? '#BC8A5F'
                      : '#91E6FA'
                }
              />
              <br />

              {/* ___________________________________ vendors component _____________________________________________*/}

              <Grid container direction="column" item xs={12} className={classes.vendorGrid}>
                <Grid container direction="column" item xs={3} data-tour="vendor">
                  <Typography variant="h5">Selected Vendor</Typography>
                </Grid>

                {selectedVendor && refeshVendor === false ? (
                  selectedVendor.map((vendor, index) => {
                    return (
                      // <Grid >
                      <VendorsCrad
                        key={index}
                        icon={handleVendorIcon(allVendors && allVendors, vendor.vendor.logo)}
                        selected={true}
                        value={Math.random() * 100}
                        profile={
                          handleProfileIcon(allAssets && allAssets, vendor && vendor.contact.photo)
                            ? handleProfileIcon(allAssets && allAssets, vendor && vendor.contact.photo)
                            : profilevector
                        }
                        name={vendor.contact && vendor.contact.manager}
                        mobile={vendor.contact && vendor.contact.phone}
                        email={vendor.contact && vendor.contact.email}
                        area={vendor.quantities && vendor.quantities.areaRequiredtoInstall}
                        price={'100'}
                        vendorname={vendor ? vendor.vendor.vendorname : 'Demo vendor'}
                        handleClick={() => {
                          props.history.push(`/projects/${id}/interventions/${iidd}/vendors/${vendor._id}`);
                        }}
                        capexmultiplier={vendor.quantities.capexmultiplier}
                        handleSelectVendor={() => handleSelectVendor(vendor._id)}
                        handleRequestQuote={() => handleRequestQuote(vendor._id)}
                      />
                      // </Grid>
                    );
                  })
                ) : !selectedVendor ? (
                  <Grid container style={{ padding: '5%' }}>
                    <Typography variant="h6" color="textSecondary" style={{ fontSize: '16px' }}>
                      No Vendor has been selected. Please select one
                    </Typography>{' '}
                  </Grid>
                ) : (
                  <CasaSkeleton variant="rect" height="30vh" width={1020} animation="wave" />
                )}
              </Grid>
              {/* <Title /> */}
              <Grid container alignItems="center" item xs={12} className={classes.spacing3}>
                <div style={{ width: '100%' }}>
                  <Typography variant="h5">Vendors</Typography>
                  {vendors && notSelectedVendor && refeshVendor === false ? (
                    notSelectedVendor.map((vendor, index) => {
                      return (
                        <VendorsCrad
                          key={index}
                          icon={handleVendorIcon(allVendors && allVendors, vendor.vendor.logo)}
                          selected={false}
                          value={Math.random() * 100}
                          profile={
                            handleProfileIcon(allAssets && allAssets, vendor && vendor.contact.photo)
                              ? handleProfileIcon(allAssets && allAssets, vendor && vendor.contact.photo)
                              : profilevector
                          }
                          capexmultiplier={vendor.quantities.capexmultiplier}
                          name={vendor.contact && vendor.contact.manager}
                          mobile={vendor.contact && vendor.contact.phone}
                          email={vendor.contact && vendor.contact.email}
                          area={vendor.quantities && vendor.quantities.areaRequiredtoInstall}
                          price={'100'}
                          vendorname={vendor ? vendor.vendor.vendorname : 'Demo vendor'}
                          handleClick={() => {
                            props.history.push(`/projects/${id}/interventions/${iidd}/vendors/${vendor._id}`);
                          }}
                          handleSelectVendor={() => handleSelectVendor(vendor._id)}
                          handleRequestQuote={() => handleRequestQuote(vendor._id)}
                        />
                      );
                    })
                  ) : vendors && refeshVendor === false ? (
                    vendors.map((vendor, index) => {
                      return (
                        <VendorsCrad
                          key={index}
                          icon={handleVendorIcon(allVendors && allVendors, vendor.vendor.logo)}
                          selected={false}
                          value={Math.random() * 100}
                          profile={
                            handleProfileIcon(allAssets && allAssets, vendor && vendor.contact.photo)
                              ? handleProfileIcon(allAssets && allAssets, vendor && vendor.contact.photo)
                              : profilevector
                          }
                          capexmultiplier={vendor.quantities.capexmultiplier}
                          name={vendor.contact && vendor.contact.manager}
                          mobile={vendor.contact && vendor.contact.phone}
                          email={vendor.contact && vendor.contact.email}
                          area={vendor.quantities && vendor.quantities.areaRequiredtoInstall}
                          price={'100'}
                          vendorname={vendor ? vendor.vendor.vendorname : 'Demo vendor'}
                          handleClick={() => {
                            props.history.push(`/projects/${id}/interventions/${iidd}/vendors/${vendor._id}`);
                          }}
                          handleSelectVendor={() => handleSelectVendor(vendor._id)}
                        />
                      );
                    })
                  ) : (
                    <CasaSkeleton variant="rect" height="30vh" width={1020} animation="wave" />
                  )}
                </div>
              </Grid>
              {/*__________________________________edit modal _____________________________________________________*/}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={modal}>
                  <div className={classes.paper}>
                    <EditInterventionDetails
                      onClose={() => handleClose()}
                      customizable={customizable}
                      interventionProjectMappingId={iidd}
                      projectId={id}
                      refresh={handleRefresh}
                      inputs={inputs}
                      activeSandboxId={activeSandboxId && activeSandboxId}
                      handleRefresh={() => handleRefresh2()}
                      handleBackdropOpen={() => handleBackdropOpen()}
                      boundaryMin={boundaryMin && boundaryMin}
                      boundaryMax={boundaryMax && boundaryMax}
                      mandates={mandates}
                      mandateicon={WasteMandateImage}
                    // customizableTankSize={customizableTankSize && customizableTankSize}
                    />
                  </div>
                </Fade>
              </Modal>
              <Modal open={open} onClose={handleModalClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                {body1}
              </Modal>
            </>
          )}
        </InterventionDetailsContext.Provider>

        {/* dilog */}
        <Dialog open={openSaveChanges} onClose={handleCloseSaveChanges} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <b>Do you want to update this sandbox or create a new one?</b>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleUpdateCurrentSandbox} color="primary">
              Update
            </Button>
            <Button onClick={createnewSandbox} color="primary" autoFocus>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </>
      {/* ) : (
        <OldInterventionDetails />
      )} */}

      <Tour
        {...tourProps}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        lastStepNextButton={
          <Button style={{ color: '#29E1AC', marginLeft: '150%', marginTop: '-60px', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleEnd}>
            End
          </Button>
        }
        disableKeyboardNavigation={['esc']}
        showNavigation={false}
        showNumber={false}
        prevButton
        nextButton={<Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', marginLeft: '150%', fontSize: '12px' }}>Next</Button>}
        disableInteraction={true}
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        rounded={12}
        startAt={0}
      // maskSpace="10"
      // accentColor="#29E1AC"
      // highlightedMaskClassName="mask"
      />
    </Grid>
  );
}

export default InterventionDetails;
