import DropinsView from '../components/views/adminpannel/dropins/DropinsView';
// import CreateVendor from '../components/views/adminpannel/CreateVendor';
import CategoryScheme from '../components/views/adminpannel/category/Category';
import SubCategory from '../components/views/adminpannel/sub-category/SubCategory';
import Organisation from '../components/views/adminpannel/organisations/Organisation';
import Users from '../components/views/adminpannel/Users/index';
import PIPScheme from '../components/views/adminpannel/PIPScheme/index';
import Intervention from '../components/views/adminpannel/interventions/InterventionScheme';
import Goals from '../components/views/adminpannel/goals/GoalsScheme';
import PIPActivity from '../components/views/adminpannel/PIPActivities/index';
import Vendors from '../components/views/adminpannel/vendor/Vendors';
import VendorInterventionMapping from '../components/views/adminpannel/interventionVendorMapping/index';
import CreateVendorInterventionMapping from '../components/views/adminpannel/interventionVendorMapping/createMapping';
import UpdateMapping from '../components/views/adminpannel/interventionVendorMapping/updateMapping';
import PlatformAssets from '../components/views/adminpannel/PlatformAssets/PlatformAsset';
import ProjectsService from '../components/views/adminpannel/project-services/ProjectServices';
import VendorRequestView from '../components/views/adminpannel/vendorRequest/VendorRequest';

const adminRouting = [
  { exact: true, path: '/vendors', component: Vendors },
  { exact: true, path: '/dropins', component: DropinsView },
  { exact: true, path: '/category-scheme', component: CategoryScheme },
  { exact: true, path: '/subcategory-scheme', component: SubCategory },
  { exact: true, path: '/organisation-scheme', component: Organisation },
  { exact: true, path: '/users', component: Users },
  { exact: true, path: '/platformassets', component: PlatformAssets },
  { exact: true, path: '/pip-scheme', component: PIPScheme },
  { exact: true, path: '/interventions-scheme', component: Intervention },
  { exact: true, path: '/goals', component: Goals },
  { exact: true, path: '/pip-activities', component: PIPActivity },
  { exact: true, path: '/intervention-vendor-mapping', component: VendorInterventionMapping },
  { exact: true, path: '/intervention-vendor-mapping/create', component: CreateVendorInterventionMapping },
  { exact: true, path: '/intervention-vendor-mapping/update/:id', component: UpdateMapping },
  { exact: true, path: '/projects', component: ProjectsService },
  { exact: true, path: '/vendorrequest', component: VendorRequestView },
];

export default adminRouting;
