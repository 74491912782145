import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelectFI from '../child-components/CustomSelectFinancialinsights';
import CustomizedBar from '../child-components/CustomizedBar';
import rsToCrore from '../../../../../utils/rsToCr';
import unit from '../../../../../utils/unitConfig';
import Skeleton from '@material-ui/lab/Skeleton';

import { TimeframeContext } from '../../../../../routing/IndexRouting';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  maingridPadding: {
    padding: '2%',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
  },
  gridPaddingtop1: {
    paddingTop: '15px',
  },
  gridPaddingtop2: {
    paddingTop: '7px',
  },
  gridTextalign: {
    textAlign: 'center',
  },
  timeframe: {
    textAlign: 'center',
    padding: '10px',
  },
}));

const FianancialInsights = (props) => {
  const { timeframeContext, handleSelectedYear } = useContext(TimeframeContext);

  const classes = useStyles();
  const newintervention = props.newintervention;
  let capex =
    !newintervention || (newintervention.categorized && newintervention.categorized.length === 0)
      ? 0
      : newintervention.quantities &&
      newintervention.quantities.overall.finance &&
      newintervention.quantities.overall.finance &&
      newintervention.quantities.overall.finance.capex.value;
  let financeSavings = newintervention && newintervention.quantities.overall.finance;
  let irr = newintervention && newintervention.quantities.overall.finance.irr.value;
  let irrunit = newintervention && newintervention.quantities.overall.finance.irr.unit;
  let paybackPeriod = newintervention && newintervention.quantities.overall.finance.paybackPeriod.value;
  let paybackPeriodUnit = newintervention && newintervention.quantities.overall.finance.paybackPeriod.unit;
  let rsPerSqft = newintervention && newintervention.quantities.overall.finance.capexConverted.value;
  let rsPerSqftUnit = newintervention && newintervention.quantities.overall.finance.capexConverted.unit;
  let overallExpenses = newintervention && newintervention.quantities.overall.finance.overallExpenses.value;

  return (
    <>
      <Container className={classes.gridContainer} disableGutters>
        <Grid container className={classes.maingridPadding} xs={12}>
          <Grid item container xs={6}>
            <Grid item xs={9} sm={7} md={5} lg={3} className={classes.timeframe}>
              <Typography variant="h6" className={classes.secondaryTextcolor}>
                Timeframe
              </Typography>
            </Grid>
            <Grid item xs={3} sm={5} md={7} lg={9}>
              <CustomSelectFI age={timeframeContext && timeframeContext} handleChange={handleSelectedYear} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid container item xs={6} spacing={3} className={classes.maingridPadding}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.secondaryTextcolor}>
                  CAPEX Increase
                </Typography>
                {
                  <CustomizedBar
                    bgcolor="#FFCE93"
                    value={
                      capex && (capex > 0 || capex === 0) ? `${rsToCrore('Rs', capex)} ${unit('cr')}` : <Skeleton variant="text" height={25} animation="wave" />
                    }
                    barvalue={
                      capex && financeSavings && capex > financeSavings.opexSavings.value
                        ? 100
                        : capex && financeSavings && (capex / financeSavings.opexSavings.value) * 100
                    }
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.secondaryTextcolor}>
                  OPEX Savings
                </Typography>
                <CustomizedBar
                  bgcolor="#74DEFB"
                  value={
                    financeSavings && financeSavings.opexSavings ? (
                      `${rsToCrore('Rs', financeSavings.opexSavings.value)} ${unit('cr')}`
                    ) : (
                      <Skeleton variant="text" height={25} animation="wave" />
                    )
                  }
                  barvalue={
                    capex && financeSavings && financeSavings.opexSavings.value > capex
                      ? 100
                      : capex && financeSavings && (financeSavings.opexSavings.value / capex) * 100
                  }
                />
              </Grid>
            </Grid>
            <Grid container item xs={3} style={{ borderRight: '1px solid #E6E6E6', borderLeft: '1px solid #E6E6E6' }}>
              <Grid container item xs={12} style={{ borderBottom: '1px solid #E6E6E6', textAlign: 'center' }}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    Overall Expenses
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.gridPaddingtop2}>
                  <Typography variant="h6">
                    <b>
                      {rsToCrore('Rs', overallExpenses && overallExpenses)} {unit('cr')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridTextalign}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    IRR
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.gridPaddingtop2}>
                  {irr && irrunit ? (
                    <Typography variant="h6">
                      <b>{`${irr} ${irrunit}`}</b>
                    </Typography>
                  ) : (
                    <Typography variant="h6">
                      <b>{`0 %`}</b>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={3}>
              <Grid container item xs={12} style={{ borderBottom: '1px solid #E6E6E6', textAlign: 'center' }}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    Payback Period
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.gridPaddingtop2}>
                  {paybackPeriod && paybackPeriodUnit ? (
                    <Typography variant="h6">
                      <b>{`${paybackPeriod} ${paybackPeriodUnit}`}</b>
                    </Typography>
                  ) : (
                    <Typography variant="h6">
                      <b>0 Years</b>
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridTextalign}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    Rs/Sqft
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.gridPaddingtop2}>
                  {rsPerSqft && rsPerSqftUnit ? (
                    <Typography variant="h6">
                      <b>{`${rsPerSqft} ${rsPerSqftUnit}`}</b>
                    </Typography>
                  ) : (
                    <Typography variant="h6">
                      <b>0 Years</b>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FianancialInsights;
