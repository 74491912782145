import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import useScreenType from '../../../hooks/checkScreenType';
import { makeStyles, withWidth } from '@material-ui/core';
import _ from 'lodash';
import GreenLabel from '../../../../assets/databaseImages/certifications/Green Label.png';
import notAvailable from '../../../../assets/Marketplace/Image not available 1.svg';
import Links from './Typography/Links';
import UIPC_logo from '../../../../assets/Marketplace/EcoLabels/UIPC-I.svg';
import IGBC_logo from '../../../../assets/Marketplace/EcoLabels/IGBC.svg';
import Griha_logo from '../../../../assets/Marketplace/EcoLabels/Griha.svg';
import GreenPro_logo from '../../../../assets/Marketplace/EcoLabels/GreenPro.svg';
import Green_Label_logo from '../../../../assets/Marketplace/EcoLabels/Green Label.svg';
import GreenCo_logo from '../../../../assets/Marketplace/EcoLabels/GreenCo.svg';
import Green_Assure_logo from '../../../../assets/Marketplace/EcoLabels/GreenAssure.svg';
import ECBC_logo from '../../../../assets/Marketplace/certificationfallback.svg';
import LEED_logo from '../../../../assets/Marketplace/EcoLabels/Leed.svg';

const useStyles = makeStyles((theme) => ({
  cardDesktop: {
    width: '220px',
    height: '340px',
    // margin: '10px',
    marginRight: '2%',
    marginBottom: '2%',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  cardMobile: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  media: {
    margin: 10,
    height: 150,
    backgroundSize: 'contain',
  },
  mediamobile: {
    height: 100,
    margin: 10,
    backgroundSize: 'contain',
  },
  name: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  subname: {
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '12px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  icon: {
    textAlign: 'left',
    paddingTop: '8%',
  },
}));

export function getIcon(name) {
  let icons = [
    { name: 'ECBC', icon: ECBC_logo },
    { name: 'Green Assure', icon: Green_Assure_logo },
    { name: 'GreenCo', icon: GreenCo_logo },
    { name: 'Green Label', icon: Green_Label_logo },
    { name: 'GreenPro', icon: GreenPro_logo },
    { name: 'Griha', icon: Griha_logo },
    { name: 'IGBC', icon: IGBC_logo },
    { name: 'LEED', icon: LEED_logo },
    { name: 'UIPC-I', icon: UIPC_logo },
  ];
  for (let i = 0; i < icons.length; i++) {
    if (icons[i].name === name) {
      return icons[i].icon;
    }
  }
}

function ProductCard(props) {
  const classes = useStyles();
  const { width, widthstyle, productImage, productName, productBrandName, productShortDescription, productCategoryID, productID, ecolabels } = props;

  const { isMobile } = useScreenType(width);
  const [isImageAccessible, setIsImageAccessible] = useState(false);

  useEffect(() => {
    if (productImage && productImage.trim() !== '') {
      const img = new Image();
      img.src = productImage;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [productImage]);

  return (
    <Card fullwidth className={isMobile ? classes.cardMobile : classes.cardDesktop} style={widthstyle}>
      <a
        target="_blank"
        onclick="return false;"
        href={`/marketplace/categories/${productCategoryID}/products/${productID}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <CardActionArea>
          <CardMedia
            // style={{ paddingTop: '10%' }}
            className={isMobile ? classes.mediamobile : classes.media}
            image={isImageAccessible ? productImage : notAvailable}
            title={productName}
          />
          {/* <CardMedia className={isMobile ? classes.mediamobile : classes.media} image={(productImage) => GetImage(productImage)} title={productName} /> */}
          <CardContent style={{ paddingLeft: '15px' }}>
            <Links
              href={`/marketplace/categories/${productCategoryID}/products/${productID}`}
              variant="string"
              tag={`${productName.length > 21 ? `${productName.slice(0, 20)}...` : productName}`}
              isBold={false}
              className={classes.name}
              color="inherit"
            />
            <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
              {productBrandName}
            </Typography>
            <Typography
              className={classes.description}
              style={{
                color: '#9E9E9E',
                paddingTop: '5%',
                height: '40px',
              }}
            >
              {productShortDescription.length > 50 ? `${productShortDescription.slice(0, 50)}...` : productShortDescription}
            </Typography>
            <Typography className={classes.icon}>
              {ecolabels?.map((name) => (getIcon(name) ? <img key={name} src={getIcon(name)} alt="..." height={23} width={23} /> : null))}
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
}

export default withWidth()(ProductCard);
