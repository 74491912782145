import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Mapper from './components/Mapper';
import MapperSelect from './components/MapperSelect';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import UnitAwareTextFieldForPlumbing from '../../../common/textfield/UnitAwareTextFieldForPlumbingDetails';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
import { TooltipData, ParentPage, ChildPage, ComponentName, ComponentTooltipData } from '../../../../staticData/TooltipStaticData';
import { GreenRadio } from '../../../common/RadioButton/GreenRadio';

const PlumbingDetailsComponent = (props) => {
  const [individualRO, setIndividualRO] = useState(false);
  let skeleton = [1, 2, 3];
  const [cro, setCRO] = useState(false);
  const [stpUsed, setSTPUsed] = useState(false);
  const [typeOfSTP, setTypeOfSTP] = useState('');
  const [gwrUsed, setGWRused] = useState(false);
  const [stpDisable, setDisableSTP] = useState(false);

  const [faucetsBrand, setFaucetsBrand] = useState('');
  const [ceramicsBrand, setCeramicsBrand] = useState('');
  const [faucetsTag, setFaucetsTag] = useState('');
  const [ceramicsTag, setCeramicsTag] = useState('');

  // radio button
  const [radioButtonValue, setRadioButtonValue] = useState('');
  const handleRadioButtonChange = (event) => setRadioButtonValue(event.target.value);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue === 'usingnbc') {
      const selectedPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'usingnbc');
      const presetPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'preset.enabled');
      const manualPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'manual.enabled');
      const presetFaucetBrandPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'preset.faucets.brand');
      const presetFaucetTagPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'preset.faucets.tag');
      const presetCeramicsBrandPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'preset.ceramics.brand');
      const presetCeramicsTagPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'preset.ceramics.tag');

      if (selectedPIP && presetFaucetBrandPIP && presetFaucetTagPIP && presetCeramicsBrandPIP && presetCeramicsTagPIP) {
        props && props.appendChanged(selectedPIP._id, true);
        props && props.appendChanged(presetFaucetBrandPIP._id, 'Jaquar');
        props && props.appendChanged(presetCeramicsBrandPIP._id, 'Hindware');
        props && props.appendChanged(presetFaucetTagPIP._id, 'Basic');
        props && props.appendChanged(presetCeramicsTagPIP._id, 'Basic');
        props && props.appendChanged(presetPIP._id, true);
        props && props.appendChanged(manualPIP._id, false);
      }
    } else if (radioButtonValue && radioButtonValue === 'preset.enabled') {
      const presetPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'preset.enabled');
      const nbcPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'usingnbc');
      const manualPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'manual.enabled');

      if (presetPIP && nbcPIP && manualPIP) {
        props && props.appendChanged(presetPIP._id, true);
        props && props.appendChanged(nbcPIP._id, false);
        props && props.appendChanged(manualPIP._id, false);
      }
    } else if (radioButtonValue && radioButtonValue === 'manual.enabled') {
      const presetPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'preset.enabled');
      const nbcPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'usingnbc');
      const manualPIP = props.fixturesData && props.fixturesData.find((data) => data.name === 'manual.enabled');

      if (presetPIP && nbcPIP && manualPIP) {
        props && props.appendChanged(presetPIP._id, false);
        props && props.appendChanged(nbcPIP._id, false);
        props && props.appendChanged(manualPIP._id, true);
      }
    }
  }, [radioButtonValue]);

  useEffect(() => {
    if (props.projectPIP && props.builtup) {
      const IndividualRO = props.projectPIP.find((d) => d.PIPScheme.name === 'individualrosystem');
      const CRO = props.projectPIP.find((d) => d.PIPScheme.name === 'croinstalled');
      const STPbeingUsed = props.projectPIP.find((d) => d.PIPScheme.name === 'stp');
      const STPtype = props.projectPIP.find((d) => d.PIPScheme.name === 'typeofstp');
      const gwrReused = props.projectPIP.find((d) => d.PIPScheme.name === 'gwr');
      const usingNBC = props.projectPIP.find((d) => d.PIPScheme.name === 'usingnbc');
      const presetEnabled = props.projectPIP.find((d) => d.PIPScheme.name === 'preset.enabled');
      const manual = props.projectPIP.find((d) => d.PIPScheme.name === 'manual.enabled');

      const faucetBrand = props.projectPIP.find((d) => d.PIPScheme.name === 'preset.faucets.brand');
      const ceramicsBrand = props.projectPIP.find((d) => d.PIPScheme.name === 'preset.ceramics.brand');
      const faucetTag = props.projectPIP.find((d) => d.PIPScheme.name === 'preset.faucets.tag');
      const ceramicsTag = props.projectPIP.find((d) => d.PIPScheme.name === 'preset.ceramics.tag');

      if (IndividualRO && IndividualRO.value === undefined) {
        setIndividualRO(false);
      } else if (IndividualRO && IndividualRO.value === false) {
        setIndividualRO(false);
      } else if (IndividualRO && IndividualRO.value === true) {
        setIndividualRO(true);
      }

      if (CRO && CRO.value === undefined) {
        setCRO(false);
      } else if (CRO && CRO.value === false) {
        setCRO(false);
      } else if (CRO && CRO.value === true) {
        setCRO(true);
      }

      // changed
      if (props.builtup >= 2000 || props.dwelling >= 20) {
        setSTPUsed(true);
        setDisableSTP(true);
      } else if (STPbeingUsed && STPbeingUsed.value === undefined) {
        setSTPUsed(false);
        setDisableSTP(false);
      } else if (STPbeingUsed && STPbeingUsed.value === false) {
        setSTPUsed(false);
        setDisableSTP(false);
      } else if (STPbeingUsed && STPbeingUsed.value === true) {
        setSTPUsed(true);
        setDisableSTP(false);
      }

      if (STPtype && STPtype.value === undefined) {
        setTypeOfSTP('');
      } else if (STPtype && STPtype.value) {
        setTypeOfSTP(STPtype.value);
      }

      if (gwrReused && gwrReused.value === undefined) {
        setGWRused(false);
      } else if (gwrReused && gwrReused.value === false) {
        setGWRused(false);
      } else if (gwrReused && gwrReused.value === true) {
        setGWRused(true);
      }

      if (usingNBC && usingNBC.value === true) {
        setRadioButtonValue('usingnbc');
      } else if (presetEnabled && presetEnabled.value === true) {
        setRadioButtonValue('preset.enabled');
      } else if (manual && manual.value === true) {
        setRadioButtonValue('manual.enabled');
      } else if ((usingNBC && usingNBC.value === undefined) || (presetEnabled && presetEnabled.value === undefined) || (manual && manual.value === undefined)) {
        setRadioButtonValue('');
      } else if ((usingNBC && usingNBC.value === false) || (presetEnabled && presetEnabled.value === false) || (manual && manual.value === false)) {
        setRadioButtonValue('');
      }

      if (faucetBrand && faucetBrand.value === undefined) {
        setFaucetsBrand('');
      } else if (faucetBrand && faucetBrand.value) {
        setFaucetsBrand(faucetBrand.value);
      }
      if (ceramicsBrand && ceramicsBrand.value === undefined) {
        setCeramicsBrand('');
      } else if (ceramicsBrand && ceramicsBrand.value) {
        setCeramicsBrand(ceramicsBrand.value);
      }
      if (faucetTag && faucetTag.value === undefined) {
        setFaucetsTag('');
      } else if (faucetTag && faucetTag.value) {
        setFaucetsTag(faucetTag.value);
      }
      if (ceramicsTag && ceramicsTag.value === undefined) {
        setCeramicsTag('');
      } else if (ceramicsTag && ceramicsTag.value) {
        setCeramicsTag(ceramicsTag.value);
      }
    }
  }, [props.projectPIP, props.builtup]);

  const filterFromData = (tooltipData, ParentPage, ChildPage, ComponentName) => {
    const result = tooltipData && tooltipData.find((d) => d.parentPage === ParentPage && d.childPage === ChildPage && d.componentName === ComponentName);
    return result && result.content;
  };

  const greyWaterReusedTooltipData = filterFromData(
    TooltipData,
    ParentPage.EDIT_DETAILS,
    ChildPage.PLUMBING_DETAILS,
    ComponentName.GREYWATER_REUSED_TOOLTIP,
    ComponentTooltipData.GREYWATER_REUSED_TOOLTIP
  );

  const roTooltipData = filterFromData(
    TooltipData,
    ParentPage.EDIT_DETAILS,
    ChildPage.PLUMBING_DETAILS,
    ComponentName.RO_SYSTEM_FOR_DRINKING_WATER,
    ComponentTooltipData.RO_SYSTEM_FOR_DRINKING_WATER
  );

  const sewageTooltipData = filterFromData(
    TooltipData,
    ParentPage.EDIT_DETAILS,
    ChildPage.PLUMBING_DETAILS,
    ComponentName.SEAWAGE_TREATMENT_PLANT,
    ComponentTooltipData.SEAWAGE_TREATMENT_PLANT
  );

  const fixtureTooltipData = filterFromData(
    TooltipData,
    ParentPage.EDIT_DETAILS,
    ChildPage.PLUMBING_DETAILS,
    ComponentName.FIXTURE_FLOW_OR_FLUSH_RATES,
    ComponentTooltipData.FIXTURE_FLOW_OR_FLUSH_RATES
  );

  return (
    <>
      {/* //////part 1/////////////////////// */}
      <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
        <Grid container item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
          <Typography variant="h5">RO System for Drinking Water</Typography>
          <CustomizedTooltips
            marginTop="15px"
            icon="info"
            title={
              <>
                <Typography>{roTooltipData && roTooltipData.map((d) => d.data)[0]}</Typography>
                <Typography style={{ marginTop: '10px' }}>{roTooltipData && roTooltipData.map((d) => d.data)[1]}</Typography>
                <Typography style={{ marginTop: '10px' }}>{roTooltipData && roTooltipData.map((d) => d.data)[2]}</Typography>
              </>
            }
            placement="right"
          />
        </Grid>
        <Grid container alignItems="left" item xs={12} style={{ marginLeft: '1%' }} direction="row">
          {props.fixturesData ? (
            <Mapper
              name="individualrosystem"
              fixturesData={props.fixturesData && props.fixturesData}
              checked={individualRO && individualRO}
              onChange={(value) => {
                setIndividualRO(value);
                value === false && setCRO(value);
                props.fixturesData.find((v) => v.name === 'croinstalled' && props.appendChanged(v._id, value));
              }}
              appendChanged={(id, value) => props.appendChanged(id, value)}
            />
          ) : (
            <CasaSkeleton variant="rect" height="2vh" width="40%" animation="wave" style={{ marginTop: '1%', marginLeft: '1%' }} />
          )}
        </Grid>
        <Grid container alignItems="left" item xs={12} style={{ marginLeft: '1%' }} direction="row">
          {props.fixturesData ? (
            <Mapper
              disabled={individualRO !== true ? true : false}
              name="croinstalled"
              fixturesData={props.fixturesData && props.fixturesData}
              checked={cro && cro}
              onChange={(value) => setCRO(value)}
              appendChanged={(id, value) => props.appendChanged(id, value)}
              typoColor={individualRO !== true ? 'textSecondary' : 'textPrimary'}
            />
          ) : (
            <CasaSkeleton variant="rect" height="2vh" width="30%" animation="wave" style={{ marginTop: '2%', marginLeft: '1%' }} />
          )}
        </Grid>
      </Grid>

      {/* /////////part 2////////////////// */}
      <Grid container direction="row" xs={12} style={{ height: 'Auto', marginTop: '1%' }}>
        <Grid container alignItems="left" item xs={12} style={{ marginLeft: '2%' }}>
          <Typography variant="h5">Sewage Treatment Plant</Typography>
          <CustomizedTooltips
            icon="info"
            marginTop="15px"
            title={
              <>
                <Typography>{sewageTooltipData && sewageTooltipData.map((d) => d.data)[0]}</Typography>
                <Typography style={{ marginTop: '5px' }}>{sewageTooltipData && sewageTooltipData.map((d) => d.data)[1]}</Typography>
                <Typography style={{ marginTop: '5px' }}>
                  <b>{sewageTooltipData && sewageTooltipData.map((d) => d.data)[2].slice(0, 8)}</b>{' '}
                  {sewageTooltipData && sewageTooltipData.map((d) => d.data)[2].slice(8, 200)}
                </Typography>
                <Typography style={{ marginTop: '5px' }}>
                  <b>{sewageTooltipData && sewageTooltipData.map((d) => d.data)[3].slice(0, 8)}</b>{' '}
                  {sewageTooltipData && sewageTooltipData.map((d) => d.data)[3].slice(8, 200)}
                </Typography>
              </>
            }
            placement="right"
          />
        </Grid>
        <Grid alignItems="left" item xs={12} style={{ marginLeft: '2%' }}>
          <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
            {(props.builtup && props.builtup < 2000) || (props.dwelling && props.dwelling < 20)
              ? `For Built-Up Area below 2000 sqm or Dwelling units below 20, govt. does not currently mandate STP installation.`
              : `For Built-Up Area greater than 2,000 sqm or Dwelling units greater than 20, govt. mandates STP installation`}
          </Typography>
        </Grid>
        <Grid container alignItems="left" item xs={12} style={{ marginLeft: '1%', marginTop: '1%' }} direction="row">
          {props.fixturesData ? (
            <Mapper
              name="stp"
              fixturesData={props.fixturesData && props.fixturesData}
              checked={stpUsed && stpUsed}
              onChange={(value) => setSTPUsed(value)}
              appendChanged={(id, value) => props.appendChanged(id, value)}
              stpDisable={stpDisable && stpDisable}
              typoColor={stpDisable && stpDisable === true ? 'textSecondary' : 'textPrimary'}
            />
          ) : (
            <CasaSkeleton variant="rect" height="2vh" width="20%" animation="wave" style={{ marginTop: '1%' }} />
          )}
        </Grid>
        <Grid container alignItems="left" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
          <MapperSelect
            name="typeofstp"
            fixturesData={props.fixturesData && props.fixturesData}
            typoColor={!stpUsed ? 'textSecondary' : 'textPrimary'}
            disabled={!stpUsed ? true : false}
            value={typeOfSTP && typeOfSTP}
            onChange={(value) => setTypeOfSTP(value)}
            appendChanged={(id, value) => props.appendChanged(id, value)}
            xs={12}
            stpDisable={stpDisable && stpDisable}
          />
        </Grid>
        <Grid container alignItems="left" item xs={12} style={{ marginLeft: '1%', marginTop: '1%' }} direction="row">
          {props.fixturesData ? (
            <Mapper
              name="gwr"
              fixturesData={props.fixturesData && props.fixturesData}
              disabled={!stpUsed || typeOfSTP === 'Combined' ? true : false}
              checked={gwrUsed && gwrUsed}
              onChange={(value) => setGWRused(value)}
              appendChanged={(id, value) => props.appendChanged(id, value)}
              typoColor={!stpUsed || typeOfSTP === 'Combined' ? 'textSecondary' : 'textPrimary'}
              tooltipData={<Typography>{greyWaterReusedTooltipData && greyWaterReusedTooltipData.map((d) => d.data)[0]}</Typography>}
            />
          ) : (
            <CasaSkeleton variant="rect" height="2vh" width="20%" animation="wave" style={{ marginTop: '1%' }} />
          )}
        </Grid>{' '}
      </Grid>

      {/* ////////part 3//////////// */}
      <Grid container xs={12} style={{ height: 'Auto', marginTop: '1%' }}>
        <Grid container alignItems="left" item xs={12} style={{ marginLeft: '2%' }}>
          <Typography variant="h5">Fixture Flow Rates</Typography>
          <CustomizedTooltips
            marginTop="15px"
            icon="info"
            title={<Typography>{fixtureTooltipData && fixtureTooltipData.map((d) => d.data)}</Typography>}
            placement="right"
          />
        </Grid>
      </Grid>

      <Grid container xs={12} style={{ height: 'Auto' }}>
        <Grid alignItems="left" item xs={12} style={{ marginLeft: '1%' }}>
          {props.fixturesData ? (
            <FormControl component="fieldset">
              <RadioGroup aria-label="gender" name="gender1" value={radioButtonValue} onChange={handleRadioButtonChange}>
                {props.fixturesData &&
                  props.fixturesData.map((pip, index) => {
                    if (pip.name === 'usingnbc' || pip.name === 'preset.enabled' || pip.name === 'manual.enabled') {
                      return (
                        <>
                          <Grid container direction="row" xs={12} style={{ height: 'Auto', marginTop: '1%', marginLeft: '2%' }}>
                            <FormControlLabel value={pip.name} control={<GreenRadio />} label={pip.displayName} />
                          </Grid>
                          {pip.name === 'preset.enabled' && radioButtonValue === 'preset.enabled' && (
                            <>
                              <Grid alignItems="left" item xs={12} style={{ marginBottom: '3%', marginLeft: '2%' }}>
                                <Typography variant="h6" style={{ color: ' rgba(0, 0, 0, 0.38)' }}>
                                  Please select your fixture brand
                                </Typography>
                              </Grid>
                              <Grid container direction="row" xs={12} style={{ height: 'Auto', marginTop: '2%', marginLeft: '2%' }}>
                                <Typography variant="h6">
                                  <b>Faucets </b>
                                  <InfoIcon style={{ color: '#66E3BE' }} />
                                </Typography>
                              </Grid>

                              <Grid container direction="row" xs={12} style={{ height: 'Auto', marginTop: '2%', marginLeft: '2%' }}>
                                <MapperSelect
                                  name="preset.faucets.brand"
                                  fixturesData={props.fixturesData && props.fixturesData}
                                  // disabled={!popularBrands ? true : false}
                                  value={faucetsBrand && faucetsBrand}
                                  onChange={(value) => setFaucetsBrand(value)}
                                  appendChanged={(id, value) => props.appendChanged(id, value)}
                                  xs={6}
                                />
                                <MapperSelect
                                  name="preset.faucets.tag"
                                  fixturesData={props.fixturesData && props.fixturesData}
                                  value={faucetsTag && faucetsTag}
                                  onChange={(value) => setFaucetsTag(value)}
                                  appendChanged={(id, value) => props.appendChanged(id, value)}
                                  xs={3}
                                />
                              </Grid>
                              <br />

                              <Grid container direction="row" xs={12} style={{ height: 'Auto', marginTop: '3%', marginLeft: '2%' }}>
                                <Typography variant="h6">
                                  <b>Ceramics </b>

                                  <InfoIcon style={{ color: '#66E3BE' }} />
                                </Typography>
                              </Grid>
                              <Grid container direction="row" xs={12} style={{ height: 'Auto', marginTop: '2%', marginBottom: '2%', marginLeft: '2%' }}>
                                <MapperSelect
                                  name="preset.ceramics.brand"
                                  fixturesData={props.fixturesData && props.fixturesData}
                                  // disabled={!popularBrands ? true : false}
                                  value={ceramicsBrand && ceramicsBrand}
                                  onChange={(value) => setCeramicsBrand(value)}
                                  appendChanged={(id, value) => props.appendChanged(id, value)}
                                  xs={6}
                                />
                                <MapperSelect
                                  name="preset.ceramics.tag"
                                  fixturesData={props.fixturesData && props.fixturesData}
                                  value={ceramicsTag && ceramicsTag}
                                  onChange={(value) => setCeramicsTag(value)}
                                  appendChanged={(id, value) => props.appendChanged(id, value)}
                                  xs={3}
                                />
                              </Grid>
                            </>
                          )}

                          {/* Mannual ones */}
                          {pip.name === 'manual.enabled' && radioButtonValue === 'manual.enabled' && (
                            <>
                              <Grid alignItems="left" item xs={12} style={{ marginLeft: '2%' }}>
                                <Typography variant="h6" style={{ color: ' rgba(0, 0, 0, 0.38)' }}>
                                  Please enter your fixture flow rates
                                </Typography>
                              </Grid>

                              {props &&
                                props.mannuals &&
                                props.mannuals.map((field, index) => {
                                  return (
                                    <>
                                      <Grid
                                        container
                                        direction="row"
                                        item
                                        xs={12}
                                        key={index}
                                        style={
                                          index === 0
                                            ? { height: 'Auto', marginTop: '3%', marginBottom: '7%', marginLeft: '2%' }
                                            : { height: 'Auto', marginBottom: '7%', marginLeft: '2%' }
                                        }
                                      >
                                        <Grid alignItems="left" item xs={12}>
                                          <Typography variant="h5">{field.displayName}</Typography>
                                        </Grid>
                                        {field.description && field.description !== 'NA' ? (
                                          <Grid alignItems="center" item xs={12} style={{ marginBottom: '2%', marginLeft: '2%' }}>
                                            <Typography variant="h6" style={{ color: '#666666', lineHeight: '22px', letterSpacing: '0.571429px' }}>
                                              {field.description}
                                            </Typography>
                                          </Grid>
                                        ) : null}

                                        <UnitAwareTextFieldForPlumbing
                                          unit={field.unit}
                                          field={field}
                                          projectPIP={props.projectPIP}
                                          appendChanged={(id, value) => props.appendChanged(id, value)}
                                        />
                                      </Grid>
                                    </>
                                  );
                                })}
                            </>
                          )}
                        </>
                      );
                    }
                  })}
              </RadioGroup>
            </FormControl>
          ) : (
            skeleton &&
            skeleton.map((d, index) => {
              return (
                <>
                  <Grid container direction="row" xs={12} style={{ height: 'Auto', marginBottom: '2%' }}>
                    <CasaSkeleton variant="rect" height="2vh" width="35%" animation="wave" />
                  </Grid>
                </>
              );
            })
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PlumbingDetailsComponent;
